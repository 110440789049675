<app-main-fiminize-layout>
    <div class="container" *ngIf="type === 'category'">
        <div class="d-flex justify-content-between align-items-center">
            <h2>{{ categoryOrSymbolName }} Article List</h2>
            <button nz-button [nzType]="isFollowed ? 'default' : 'primary'" (click)="toggleFollow()">
                {{ isFollowed ? 'Unfollow' : 'Follow' }}
            </button>
        </div>

        <nz-divider></nz-divider>

        <app-article-card-design [articleData]="articles" [showSlideButtons]="true" (slideLeftEvent)="slideLeft()"
            (slideRightEvent)="slideRight()">
        </app-article-card-design>
    </div> 
    <div class="container mt-4" *ngIf="type === 'tags'">
        <div class="row justify-content-center">
            <div class="col-md-6 card py-3">
                <h2>#{{categoryOrSymbolName}}</h2>
                <p>
                  BTC halving is past us, this usually marks the start of the bull run, stay updated here.
                </p>
           
                <div class="likes-info">Likes: 689 | Market: Crypto</div>
                <button nz-button nzType="primary" (click)="toggleFollow()">
                    {{ isFollowed ? 'Liked' : 'Like' }}
                  </button>
            </div>
         
        </div>
      
        <div class="row">
          <div class="col-md-8">
            <nz-page-header class="site-page-header" *ngFor="let article of articles">
                <nz-page-header-title>"{{ article.title }}"</nz-page-header-title>
                <nz-page-header-extra>
                  <nz-space>
                    {{ article.publish_date | date }}
                  </nz-space>
                </nz-page-header-extra>
                <nz-page-header-content>
                  <div nz-row>
                    <div class="content">
                      <p nz-paragraph>
                        <nz-avatar nz-page-header-avatar nzSrc="https://avatars0.githubusercontent.com/u/22736418?s=88&v=4"></nz-avatar>
                        <nz-page-header-subtitle>{{ article.created_by_string }}</nz-page-header-subtitle>
                      </p>
                      <p>{{ article.meta_description }}</p>
                      <a href="">Read more</a>
                    </div>
                 
                    <nz-divider></nz-divider>
                  </div>
                </nz-page-header-content>
              </nz-page-header>

          </div>
          <div class="col-md-4">
            <div class="sidebar-section mb-4">
             <nz-card>
                <h4>Authors Posting about #{{categoryOrSymbolName}}</h4>
                <ul nz-list nzBordered>
                    <li nz-list-item *ngFor="let author of authors">{{ author }}</li>
                  </ul>
             </nz-card>
            </div>
            <div class="sidebar-section mb-4">
          
              <nz-card>
                <h4>Similar Tags</h4>
                <ul nz-list nzBordered>
                    <li nz-list-item *ngFor="let tag of similarTags">{{ tag }}</li>
                  </ul>
              </nz-card>
            </div>
      
            <!-- Related Stocks Section -->
            <div class="sidebar-section">
             
             <nz-card>
                <h4>Related Stocks</h4>
                <ul nz-list nzBordered>
                    <li nz-list-item *ngFor="let stock of relatedStocks">{{ stock }}</li>
                  </ul>
             </nz-card>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" *ngIf="type === 'tickers'">
        <div class="row justify-content-center">
          <div class="col-md-8 card p-3">
            <h2>#{{categoryOrSymbolName}}</h2>
            <p>
              BTC halving is past us, this usually marks the start of the bull run, stay updated here.
            </p>
            <div class="likes-info">Likes: 689 | Market: Crypto</div>
            <button nz-button nzType="primary" (click)="toggleFollow()">
              {{ isFollowed ? 'Liked' : 'Like' }}
            </button>
          </div>
        </div>
      
        <div class="row">
          <div class="col-md-8">
            <ul class="nav nav-tabs mb-3">
              <li class="nav-item">
                <a class="nav-link active" href="#">Latest</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">Top</a>
              </li>
            </ul>
      
            <div class="row">
              <div class="col-md-4 mb-3" *ngFor="let i of [1, 2, 3]">
                <nz-card>
                  <nz-card-meta [nzTitle]="'Post ' + i" nzDescription="This is a placeholder for post content."></nz-card-meta>
                </nz-card>
              </div>
            </div>
      
            <nz-tabset nzSize="large" class="mb-3">
                <nz-tab *ngFor="let tab of ['Dost Fact', 'Dost Secrets', 'Dost Tips', 'Dost Insights', 'Dost Solution']" [nzTitle]="tab">
                  <ng-template nz-tab>
                    <div class="card mb-2" *ngFor="let i of [1, 2, 3, 4]">
                        <div class="card-body">
                          <h5 class="card-title">Post {{i}}</h5>
                          <p class="card-text">This is a placeholder for post content.</p>
                        </div>
                      </div>
                  </ng-template>
                </nz-tab>
              </nz-tabset>
      
           
          </div>
      
          <div class="col-md-4">
            <div class="card mb-4">
              <div class="card-body">
                <h5 class="card-title">Authors Covering</h5>
                <div class="row">
                  <div class="col-4 text-center" *ngFor="let i of [1, 2, 3, 4, 5, 6]">
                    <div class="rounded-circle bg-secondary mb-2" style="width: 50px; height: 50px; margin: auto;"></div>
                  </div>
                </div>
              </div>
            </div>
      
            <div class="card mb-4">
              <div class="card-body">
                <h5 class="card-title">Post Alerts</h5>
                <div class="mb-2" *ngFor="let i of [1, 2]">
                 
                </div>
              </div>
            </div>
      
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Test Yourself!</h5>
                <ol>
                  <li *ngFor="let i of [1, 2, 3, 4]">Q{{i}}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      
</app-main-fiminize-layout>