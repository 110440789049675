import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-author-dashboard',
  templateUrl: './author-dashboard.component.html',
  styleUrls: ['./author-dashboard.component.scss']
})
export class AuthorDashboardComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }


}
