<nz-layout style="min-height: 100vh; ">
    <nz-sider [nzWidth]="250"  nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" >
        <div class="mx-auto d-flex align-items-center bg-white px-4 " style="height: 64px;" routerLink="/home-page" >
            <img class="sidebar-collapse-logo" *ngIf="isCollapsed" [src]="sidebarCollapseLogo" alt="Invest DOst Logo">
            <img class="sidebar-expand-logo" *ngIf="!isCollapsed" [src]="sidebarExpandLogo" alt="Invest Dost Logo">
        </div>

        <ul nz-menu nzMode="inline">

            <li routerLink="/author_dashboard" nz-menu-item [nzTooltipTitle]="isCollapsed ? 'Dashboard': null"
                nzTooltipPlacement="right" nz-tooltip>
                <span nz-icon nzType="dashboard"></span>
                <span>Dashboard</span>
            </li>

            <li routerLink="/messages" nz-menu-item [nzTooltipTitle]="isCollapsed ? 'Messages': null"
                nzTooltipPlacement="right" nz-tooltip>
                <span nz-icon nzType="message"></span>
                <span>Messages</span>
            </li>

            <li nz-submenu nzTitle="Content Management" nzIcon="form">
                <ul>
                    <li *ngIf="isCollapsed" nz-menu-group nzTitle="Content Management">
                        <nz-divider *ngIf="isCollapsed" class="border-white"></nz-divider>
                    <!-- <li routerLink="/add_article" nz-menu-item>Create New Article</li> -->
                    <li routerLink="/articles" nz-menu-item>Articles</li>
                    <li routerLink="/draft_articles" nz-menu-item>Manage Drafts</li>
                    <li routerLink="/categories" nz-menu-item>Categories</li>
                    <li routerLink="/articles-filter-setup" nz-menu-item>Filters</li>
                    <!-- <li routerLink="/tags" nz-menu-item>Tags</li> -->
                </ul>
            </li>

            <li nz-submenu nzTitle="Quiz Management" nzIcon="calculator">
                <ul>
                    <li *ngIf="isCollapsed" nz-menu-group nzTitle="Quiz Management">
                        <nz-divider *ngIf="isCollapsed" class="border-white"></nz-divider>
                    <li routerLink="/quizzes" nz-menu-item>Quizzes</li>
                    <li routerLink="/quiz_questions" nz-menu-item>Questions</li>
                    <li routerLink="/quiz_categories" nz-menu-item>Quiz Categories</li>
                </ul>
            </li>

            <li nz-submenu nzTitle="Course Management" nzIcon="read">
                <ul>
                    <li *ngIf="isCollapsed" nz-menu-group nzTitle="Course Management">
                        <nz-divider *ngIf="isCollapsed" class="border-white"></nz-divider>
                    <li routerLink="/courses" nz-menu-item>Courses</li>
                    <li routerLink="/sections" nz-menu-item>Sections</li>
                    <li routerLink="/lectures" nz-menu-item>Lectures</li>
                    <li routerLink="/course_categories" nz-menu-item>Course Categories</li>
                </ul>
            </li>

            <!-- <li nz-submenu nzTitle="User Management" nzIcon="team">
                <ul>
                    <li nz-menu-item>Users</li>
                    <li nz-menu-item>Roles</li>
                    <li nz-menu-item>Permissions</li>
                </ul>
            </li> -->

            <!-- <li nz-submenu nzTitle="Comments" nzIcon="comment">
                <ul>
                    <li *ngIf="isCollapsed" nz-menu-group nzTitle="Comments">
                        <nz-divider *ngIf="isCollapsed" class="border-white"></nz-divider>
                    <li nz-menu-item>All Comments</li>
                    <li nz-menu-item>Pending</li>
               
                    <li nz-menu-item>Spam</li>
                </ul>
            </li> -->

            <!-- <li nz-menu-item [nzTooltipTitle]="isCollapsed ? 'Profile Settings': null" nzTooltipPlacement="right"
                nz-tooltip>
                <span nz-icon nzType="user"></span>
                <span>Profile Settings</span>
            </li> -->


            <!-- <li nz-submenu nzTitle="Data Management" nzIcon="cloud-server">
                <ul>
                    <li *ngIf="isCollapsed" nz-menu-group nzTitle="Data Management">
                        <nz-divider *ngIf="isCollapsed" class="border-white"></nz-divider>
                    <li nz-menu-item>Database</li>
                    <li nz-menu-item>Backups</li>
                    <li nz-menu-item>Import/Export</li>

                </ul>
            </li> -->

            <li nz-submenu nzTitle="Help & Support" nzIcon="customer-service">
                <ul>
                    <li *ngIf="isCollapsed" nz-menu-group nzTitle="Help & Support">
                        <nz-divider *ngIf="isCollapsed" class="border-white"></nz-divider>
                    <li nz-menu-item>Documentation</li>
                    <li nz-menu-item>FAQs</li>
                    <li nz-menu-item>Contact Support</li>
                </ul>
            </li>
        </ul>
    </nz-sider>

    <nz-layout>
        <nz-header>
            <div class="container-fluid d-flex align-items-center">
                <!-- <span class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
                    (click)="isCollapsed = !isCollapsed"></span> -->
                    <span class="trigger p-2" (click)="isCollapsed = !isCollapsed">
                        <i class="fa-solid" [ngClass]="{'fa-chevron-right': isCollapsed, 'fa-chevron-left': !isCollapsed}"></i>
                      </span>
                <!-- <ul nz-menu nzMode="horizontal">
                    <li nzMatchRouter routerLink="/admin_dashboard" nz-menu-item>Admin Panel</li>
                    <li nzMatchRouter routerLink="/author_dashboard" nz-menu-item>Author Panel</li>
                    <li nzMatchRouter routerLink="/subscriber_homepage" nz-menu-item nz-tooltip
                        nzTooltipPlacement="bottom" nzTooltipTitle="Logged-In user (with Subscription)">
                        Subscriber
                    </li>
                    <li nzMatchRouter routerLink="/reader_homepage" nz-menu-item nz-tooltip nzTooltipPlacement="bottom"
                        nzTooltipTitle="Logged-In user">Reader</li>
                </ul> -->

                <!-- <button class="ms-auto" nzTrigger="click" nz-button nz-dropdown [nzDropdownMenu]="languageDropDownMenu">
                    Language
                    <img class="ms-2" height="21px" width="21px" src="assets/language-switch.png" />
                </button>

                <nz-dropdown-menu #languageDropDownMenu="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item>English</li>
                        <li nz-menu-item>French</li>
                        <li nz-menu-item>Dutch</li>
                    </ul>
                </nz-dropdown-menu> -->
                <div class="ms-auto">
                    <nz-avatar nz-dropdown [nzDropdownMenu]="avatarDropDownMenu"
                    class="ms-3 d-flex justify-content-center align-items-center" [nzSize]="40"
                    nzIcon="user"></nz-avatar>

                </div>
                
                <nz-dropdown-menu #avatarDropDownMenu="nzDropdownMenu">
                    <ul nz-menu nzSelectable>
                        <li nz-menu-item (click)="underDevelopmentMessage()"><span nz-icon nzType="setting"
                                nzTheme="outline" class="me-3"></span> Account
                            Settings</li>
                        <li nz-menu-item (click)="underDevelopmentMessage()"><span nz-icon nzType="user"
                                nzTheme="outline" class="me-3"></span> My Profile
                        </li>
                        <!-- <li nz-menu-item><span nz-icon nzType="crown" nzTheme="outline" class="me-3"></span>Get Subscription</li> -->
                        <nz-divider class="m-0"></nz-divider>
                        <li nz-menu-item (click)="logout()"><span nz-icon nzType="logout" nzTheme="outline"
                                class="me-3"></span>Logout</li>
                    </ul>
                </nz-dropdown-menu>

            </div>

        </nz-header>
        <nz-content class="p-3 px-3 border-bottom">
            <div class="container-fluid">
                <ng-content></ng-content>
            </div>
        </nz-content>
        <nz-footer>
            <div class="d-flex">
                <span>&#169; {{ currentYear }} Version 1.0.3 </span>
                <span class="ms-auto"> InvestDost | Development | Designed By
                    <a class="ms-1 " target="_blank" rel="noopener noreferrer" href="https://portalwiz.com/">Portalwiz
                        Technologies</a>
                </span>
            </div>
        </nz-footer>
    </nz-layout>
</nz-layout>
