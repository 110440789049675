<ng-container >
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
  </ng-container>
  
  <!-- <ng-template #authorLayout>
    <app-author-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-author-layout>
  </ng-template> -->

<ng-template #content>
    <div class="d-flex">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Content Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Add Article</nz-breadcrumb-item>
        </nz-breadcrumb>
        <button class="ms-auto mb-2" routerLink="/draft_articles" nz-button nzType="default"><span nz-icon
                nzType="file-text"></span>Draft Articles</button>
        <button class="ms-3 mb-2" routerLink="/articles" nz-button nzType="default"><span nz-icon
                nzType="file-done"></span>Published Article</button>
    </div>

    <form [formGroup]="articleForm" (ngSubmit)="onSubmit(1)" nz-form nzLayout="vertical">
        <div class="row">
            <div class="col-md-8">
                <nz-card>
                    <nz-form-item>
                        <nz-form-label nzRequired>Title</nz-form-label>
                        <nz-form-control nzErrorTip="Please enter title">
                            <nz-input-group>
                                <input type="text" nz-input formControlName="title" required placeholder="Title" />
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <div class="d-flex justify-content-between align-items-center">
                            <nz-form-label nzRequired>Content</nz-form-label>

                            <button (click)="getCourseLinkModalVisible = !getCourseLinkModalVisible" type="button"
                                class="mb-2" nz-button><span nz-icon nzType="link"></span>
                                Courses Link</button>
                        </div>
                        <nz-form-control nzErrorTip="Please write something here!">
                            <quill-editor [modules]="editorOptions" formControlName="content" [sanitize]="true"
                                placeholder="Enter your content here..."
                                style="width: 100%; height: 400px; margin-bottom: 3rem;"></quill-editor>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-modal [(nzVisible)]="getCourseLinkModalVisible" nzTitle="Copy Course Link"
                        (nzOnCancel)="getCourseLinkModalVisible = !getCourseLinkModalVisible" [nzFooter]="null">
                        <ng-container *nzModalContent>
                            <div class="row">
                                <div class="col-12 d-flex">
                                    <nz-select class="flex-grow-1" nzShowSearch nzAllowClear
                                        [(ngModel)]="selectedCourseSlug" [ngModelOptions]="{standalone: true}"
                                        nzPlaceHolder="Select Course">
                                        <nz-option *ngFor="let course of coursesSlugs" [nzLabel]="course.title"
                                            [nzValue]="course.course_slug"></nz-option>
                                    </nz-select>
                                </div>
                                <div class="col-12 mt-3">
                                    <button nz-button nzType="primary" [disabled]="selectedCourseSlug === null"
                                        (click)="copySlugToClipboard()">Copy
                                        Link</button>
                                </div>
                            </div>
                        </ng-container>
                    </nz-modal>
                </nz-card>
            </div>
            <div class="col-md-4">
                <nz-card>
                    <button nz-button nzType="primary" type="submit">Submit</button>
                    <button class="ms-3" nz-button nzType="default" type="button" (click)="onSubmit(0)">Save As
                        Draft</button>
                    <nz-divider></nz-divider>

                    <nz-form-item>
                        <nz-form-label>Is this for InvestDost+?</nz-form-label>
                        <nz-radio-group formControlName="is_free_artical">
                            <label nz-radio [nzValue]="FreeArticle">Yes</label>
                            <label nz-radio [nzValue]="PaidArticle">No</label>
                        </nz-radio-group>

                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label nzRequired>Category</nz-form-label>
                        <nz-form-control nzErrorTip="Please select category!">

                            <nz-tree-select [nzNodes]="categories" nzShowSearch nzPlaceHolder="Select Category"
                                formControlName="category_id"></nz-tree-select>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label nzRequired>Slug</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <input type="text" nz-input formControlName="slug" placeholder="enter-title-slug" />
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>


                    <nz-form-item>
                        <nz-form-label>Tags <span nz-popover
                                nzPopoverContent="You can add multiple tags by typing a tag and pressing the 'Enter' key."
                                class="ms-2" nz-icon nzType="question-circle" nzTheme="outline"></span></nz-form-label>
                        <nz-select nzMode="tags" nzPlaceHolder="To add tags, simply type a tag and press 'Enter'."
                            formControlName="tag_id">
                        </nz-select>
                    </nz-form-item>
                    <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more
                        selected</ng-template>

                    <nz-form-item>
                        <div class="d-flex justify-content-between align-items-center">
                            <span>Featured Image</span>
                            <button (click)="isImageInstructionsModalVisible = !isImageInstructionsModalVisible"
                                type="button" class="mb-2" nz-button><span nz-icon nzType="exclamation-circle"></span>
                                Image Instructions</button>
                        </div>
                        <label *ngIf="!showImage" for="fileInput" class="file-upload-container">
                            <input type="file" (change)="onFileSelected($event)" accept="image/*" id="fileInput"
                                #fileInput style="display: none;" />
                            <div *ngIf="!selectedFile">
                                <div>Upload Featured Image</div>
                            </div>
                        </label>
                        <img *ngIf="showImage" nz-image width="50px" height="50px" [nzSrc]="imageSrc"
                            alt="Uploaded Image" />

                    </nz-form-item>


                    <nz-modal [(nzVisible)]="isImageInstructionsModalVisible" nzTitle="Featured Image Requirements"
                        (nzOnCancel)="isImageInstructionsModalVisible = !isImageInstructionsModalVisible"
                        [nzFooter]="null">
                        <ng-container *nzModalContent>
                            <ol class="list-group list-group-flush">
                                <li class="list-group-item"><strong>Format:</strong> Only WebP format images are
                                    allowed.</li>
                                <li class="list-group-item"><strong>Size:</strong> 1280x720 pixels (16:9 aspect ratio),
                                    minimum width 640 pixels.
                                </li>
                                <li class="list-group-item"><strong>File Size:</strong> Keep it under 1MB.</li>

                                <li class="list-group-item"><strong>Conversion:</strong> You can use <a
                                        href="https://pixelied.com/convert/png-converter/png-to-webp"
                                        target="_blank">This Tool</a> to convert PNG to WebP.</li>
                            </ol>
                        </ng-container>
                    </nz-modal>

                    <nz-form-item>
                        <nz-form-label>Meta Description</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>

                                <textarea rows="5" nz-input formControlName="meta_description"
                                    placeholder="Enter Meta description"></textarea>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <div class="row">
                        <div class="col-md-6">
                            <nz-form-item>
                                <nz-form-control>
                                    <nz-select formControlName="post_type" nzPlaceHolder="Select a Post Type">
                                        <nz-option *ngFor="let item of postTypes" [nzValue]="item.ideahub_dropdown_id"
                                            [nzLabel]="item.sub_name"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <!-- <div class="col-md-6">
                          <nz-form-item>
                            <nz-form-control>
                              <nz-select formControlName="asset_class" nzPlaceHolder="Select an Asset Class">
                                <nz-option *ngFor="let item of assetClasses" [nzValue]="item.ideahub_dropdown_id" [nzLabel]="item.sub_name"></nz-option>
                              </nz-select>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                       -->
                        <div class="col-md-6">
                            <nz-form-item>
                                <nz-form-control>
                                    <nz-select formControlName="sentiments" nzPlaceHolder="Select a Sentiment">
                                        <nz-option *ngFor="let item of sentiments" [nzValue]="item.ideahub_dropdown_id"
                                            [nzLabel]="item.sub_name"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div class="col-md-6">
                            <nz-form-item>
                                <nz-form-control>
                                    <nz-select formControlName="holding_period" nzPlaceHolder="Select a Holding Period">
                                        <nz-option *ngFor="let item of holdingPeriods"
                                            [nzValue]="item.ideahub_dropdown_id" [nzLabel]="item.sub_name"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div class="col-md-6">
                            <nz-form-item>
                                <nz-form-control>
                                    <nz-select formControlName="risk" nzPlaceHolder="Select a Risk">
                                        <nz-option *ngFor="let item of risks" [nzValue]="item.ideahub_dropdown_id"
                                            [nzLabel]="item.sub_name"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                    </div>
                </nz-card>
            </div>
        </div>
    </form>
</ng-template>