import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { Tag } from 'src/app/Interfaces/Content';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent {
  useAdminLayout: boolean = false; // Default to non-admin layout
  addTagForm: FormGroup;
  tags: Tag[] = [];
  filteredTags = [...this.tags]; 
  tableLoading: boolean = true;
  searchQuery = '';


  constructor(private http: HttpClient, private apiService: FetchApiService, private message: NzMessageService) {
    this.addTagForm = new FormGroup({
      tag_name: new FormControl('', [Validators.required]),
    });
  }

  editTagForm  = new FormGroup({
    tag_name: new FormControl(null),
    tag_id: new FormControl(null),
    sequence: new FormControl(null),
    artical_id: new FormControl(null), 
    is_approved: new FormControl(''),
    created_at: new FormControl({value: '', disabled: true}),
    updated_at: new FormControl({value: '', disabled: true})

  });



  ngOnInit() {
    // Get the 'role' value from session storage
    const role = sessionStorage.getItem('role');
    // Set useAdminLayout based on the 'role' value
    this.useAdminLayout = role === 'admin';
    this.fetchInitialData();
  }



  addDrawer: boolean = false;
  editDrawer: boolean = false;

  openEditDrawer(id: number): void {
    var tagDetails;
    tagDetails = this.tags.find(tag => tag.tag_id === id);
    // this.editTagForm.patchValue(tagDetails!);
    console.log(this.editTagForm.value);
    this.editDrawer = true;
  }

  closeEditDrawer(): void {
    this.editDrawer = false;
  }

  openAddDrawer(): void {
    this.addDrawer = true;
  }

  closeAddDrawer() {
    this.addDrawer = false;
  }

  cancel(): void {
  }

  confirm(id: number): void {
    this.deleteTag(id);
  }

  updateApprovalStatus(ID: any,isApproved: boolean): void {
    debugger
    const apiUrl = `${environment.API_URL}approve_tag`;
    const requestData = {
      updated_by: localStorage.getItem('userId'),
      tag_id: ID,
      is_approved: isApproved ? 1 : 0
    };

    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.message.success('Tag approval status updated');
          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        }
      });
  }


  submitAddForm() {
    if (isFormValid(this.addTagForm)) {
      const apiUrl = `${environment.API_URL}add_tag`;
      const requestData = {
        created_by: sessionStorage.getItem('userId'),
        ...this.addTagForm.value
      };
      console.log(requestData);
      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            console.log('Response: ', response);
            if (response.success) {
              this.fetchInitialData().then(() => {
                this.message.success('Tag added');
                this.addTagForm.reset();
                this.closeAddDrawer();
              });

            } else {
              this.message.error('Error occurred, please try later');
            }
          },
          error: (error) => {
            console.error('Error: ', error);
            this.message.error('Error occurred, please try later');
          }
        });
    }
  }


  submitEditForm() {
    if (isFormValid(this.editTagForm)) {
      const apiUrl = `${environment.API_URL}edit_tag`;
      const requestData = {
        updated_by: sessionStorage.getItem('userId'),
        tag_id: this.editTagForm.controls['tag_id'].value,
        ...this.editTagForm.value
      };
      console.log(requestData);
      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            console.log('Response: ', response);
            if (response.success) {
              this.fetchInitialData().then(() => {
                this.message.success('Tag updated');
                this.editTagForm.reset();
                this.closeEditDrawer();
              });
            } else {
              this.message.error('Error occurred, please try later');
            }
          },
          error: (error) => {
            console.error('Error: ', error);
          }
        });
    }
  }

  deleteTag(id: number) {
    const apiUrl = `${environment.API_URL}delete_tag`;

    const requestData = {
      tag_id: id,
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchInitialData().then(() => {
              this.message.success('Tag deleted');
            });

          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        }
      });
  }
  sortByName = (a: any, b: any): number => {
    return (a.tag_name || '').localeCompare(b.tag_name || '');
  };

  sortByCreatedAt = (a: any, b: any): number => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  };

  sortByUpdatedAt = (a: any, b: any): number => {
    return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
  };

  sortByApproved = (a: any, b: any): number => {
    return a.is_approved - b.is_approved;
  };
  filterTags() {
    const query = this.searchQuery.toLowerCase();
    this.filteredTags = this.tags.filter(tag => 
      tag.tag_name.toLowerCase().includes(query)
    );
  }

  fetchInitialData(): Promise<any> {
    this.apiService.getTags().subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        this.tags = response.data;
        this.filteredTags = [...this.tags]; // Initialize filteredTags with the fetched data
        this.filterTags();
        
      },
      error: (error) => {
        console.error('Error: ', error);
        this.tableLoading = false;
      }, complete: () => {
        this.tableLoading = false;
      }
    });
    return Promise.resolve(true);
  }
}

