<nz-layout style="min-height: 100vh;" class="d-flex">
    <nz-layout>
        <nz-header>
            <div class="container d-flex">
                <span>
                    <img routerLink="/" class="me-5 header-logo pointer" [src]="headerLogo" alt="Invest Dost Logo">
                </span>

                <div class="ms-auto">
                    <button nz-button routerLink="/login">Login</button>
                </div>
            </div>
        </nz-header>

        <div class="container bg-white p-5 m-3 mx-auto flex-grow-1">

        </div>

        <nz-footer class="border-top">
            <div class="d-flex">
                <span>&#169; {{ currentYear }} Version 1.0.3 </span>
                <span class="ms-auto"> InvestDost | Development | Designed By
                    <a class="ms-1 " target="_blank" rel="noopener noreferrer" href="https://portalwiz.com/">Portalwiz
                        Technologies</a>
                </span>
            </div>
        </nz-footer>
    </nz-layout>
</nz-layout>