import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DndDropEvent } from 'ngx-drag-drop';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { convertToCategoryTree } from 'src/app/Functions/arrayFunctions';
import { formatBytes } from 'src/app/Functions/formatBytes';
import { Article } from 'src/app/Interfaces/Content';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';
import { NzFormatEmitEvent } from 'ng-zorro-antd/tree';
import { NzTableComponent, NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import Quill from 'quill';

interface Tag {
  tag_id: number;
  tag_name: string;
}



interface ColumnItem {
  sortFn: NzTableSortFn<Article> | null;

}
interface Tag {
  tag_name: string;
  // Add other properties if needed
}
@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss'],
})
export class ArticlesComponent {

  quill: Quill | undefined;

  editorOptions = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        ['clean'],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        ['course'] // Add custom button identifier
        
      ],
      handlers: {
        
        'course': this.customCourseHandler.bind(this), // Bind 'this' to customCourseHandler
        'link': this.customLinkHandler.bind(this) // Bind 'this' to customLinkHandler
      },
      clipboard: {
        matchVisual: false
      },
      imageResize: true 
    }
  };

  sortByTitle = (a: any, b: any) => a.title.localeCompare(b.title);
  sortByIsFreeArticle = (a: any, b: any) => a.is_free_artical - b.is_free_artical;
  sortByCategory = (a: any, b: any) => a.category_string.localeCompare(b.category_string);
  sortByStatus = (a: any, b: any) => a.status.localeCompare(b.status);
  sortByTag = (a: any, b: any) => a.tag_id.localeCompare(b.tag_id);
  sortBySymbol = (a: any, b: any) => a.symbol_string.localeCompare(b.symbol_string);
  sortByCreatedBy = (a: any, b: any) => a.created_by_string.localeCompare(b.created_by_string);
  sortByUpdatedBy = (a: any, b: any) => a.updated_by_string.localeCompare(b.updated_by_string);
  sortByPublishDate = (a: any, b: any) => new Date(a.publish_date).getTime() - new Date(b.publish_date).getTime();
  sortByUpdatedAt = (a: any, b: any) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();


  initializeQuill(): void {
    const editorElement = document.querySelector('#editor-container');
    this.quill = new Quill(editorElement!, {
      modules: {
        toolbar: this.editorOptions.toolbar
      },
      theme: 'snow'
    });
  }

  customLinkHandler(): void {
    const value = prompt('Enter the URL or author details (e.g., author/John-Doe/123)');
    if (value) {
      const range = this.quill!.getSelection();
      if (range) {
        const [prefix, name, id] = value.split('/');
        let link = value;
        if (prefix === 'author') {
          link = `/author/${name}/${id}`;
        }
        this.quill!.formatText(range.index, range.length, 'link', link);
      }
    }
  }

  customCourseHandler(): void {
    const range = this.quill!.getSelection();
    if (range) {
      if (range.length > 0) {
        const selectedText = this.quill!.getText(range.index, range.length);
        // Replace the selected text with <course>selectedText</course>
        this.quill!.clipboard.dangerouslyPasteHTML(range.index, `<course>${selectedText}</course>`);
        // Format the text
        this.quill!.formatText(range.index, selectedText.length, { bold: true, underline: true });
      } else {
        // If no text is selected, just insert the <course></course> tag
        this.quill!.clipboard.dangerouslyPasteHTML(range.index, '<course></course>');
        // Move the cursor inside the new tag
        // this.quill!.setSelection(range.index + 8); // Adjust the cursor position as needed
      }
    }
  }

  useAdminLayout: boolean = false; // Default to non-admin layout
  articles: Article[] = [];
filteredArticles: Article[] = this.articles; 
  tags: any = [];
  categories: any = [];
  roleID = localStorage.getItem('role_id');
  searchQuery: string = '';
 
  FreeArticle = 1;
  PaidArticle = 0;

  treeSelectExpandKeys: string[] = [];
  editDrawer: boolean = false;
  sectionTypes: any = [];
  allTags: Tag[] = [];
  selectedProvince!: string;
  selectedSectionType: any;
  dropdownData: any = [];
  postTypes: any = [];
  assetClasses: any = [];
  sentiments: any = [];
  quizzes: any[] = [];
  holdingPeriods: any = [];
  risks: any = [];
  editorInstance: any;
  allSymbols: any[] = [];
  symbolsList: any[] = [];
  tableLoading: boolean = true;
  editFormLoading: boolean = false;
  characterCount: number = 0;
  featuredImagePresent: boolean = false;
  featuredImagePath: string = '';
  fallbackImage: string = 'assets/fallback.webp';

  isImageInstructionsModalVisible: boolean = false;
  modules = {};
  webpImageUrl: any;
  constructor(
    private http: HttpClient,
    private apiService: FetchApiService,
    private message: NzMessageService,
    private fb: FormBuilder
  ) {}
  editArticleForm = new FormGroup({
    id: new FormControl(null),
    title: new FormControl('', Validators.required),
    content: new FormControl('', Validators.required),
    author_id: new FormControl(localStorage.getItem('userId')),
    category_id: new FormControl('', Validators.required),
    parent_category_string: new FormControl(null),
    category_string: new FormControl(''),
    tag_id: new FormControl(null),
    slug: new FormControl('', Validators.required),
    meta_description: new FormControl(''),
    is_free_artical: new FormControl(null),
    tag_name: new FormControl([]), 
    article_id: new FormControl(null),
    symbol_string: new FormControl('', Validators.required),
    is_published: new FormControl(null),
    risk: new FormControl(null),
    quiz_id: new FormControl(null),
    symbol_id: new FormControl('', ),
    sentiments: new FormControl(null),
    post_type: new FormControl(null),
  });
  updateCharacterCount(): void {
    const contentControl = this.editArticleForm.get('content');
    if (contentControl) {
      this.characterCount = contentControl.value
        ? contentControl.value.length
        : 0;
    }
  }

 

  onSearch(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.searchQuery = target.value;
    this.fetchInitialData(this.searchQuery);
  }

  filterArticles(): void {
    const query = this.searchQuery.toLowerCase();
    if (query) {
      this.filteredArticles = this.articles.filter(article =>
        article.title.toLowerCase().includes(query)
      );
    } else {
      this.filteredArticles = this.articles; // If search query is empty, show all articles
    }
  }

  addArticleID() {
    const apiUrl = `${environment.API_URL}add_artical`;
    const requestData = {
      author_id: localStorage.getItem('userId'),
    };
    this.http.post(apiUrl, requestData).subscribe({
      next: async (response: any) => {
        console.log('API Response: ', response); // Log the API response
        if (response.success) {
          await this.openEditDrawer(response.id);
        } else {
          console.error('article ID Not Present: ');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.tableLoading = false;
      },
      complete: () => {
        this.sectionTypes;
        this.tableLoading = false;
      },
    });
    return Promise.resolve(true);
  }
 
 

  addRichTextEditor() {
    const Content = {
      artical_section_id: null,
      sequence: null,
      section_content: null,
      section_type_id: null,
      symbol_id: null,
      section_type: '',
      symbol: '',
    };
    this.Content.push(Content);
  }
  roleId: any;
  ngOnInit(): void {
    this.fetchTags();
    this.roleId = localStorage.getItem('role_id');
    this.useAdminLayout = this.roleId === '1' || this.roleId === '2';
    this.fetchInitialData();
    this.fetchDropDowns();
    this.fetchSectionType();
    this.loadIdeaHubTypes();
    this.fetchQuizzes();
    this.initializeQuill();
    this.fetchAllSymbols();
  }

  
  

  fetchQuizzes() {
    this.apiService.getAllQuizzes().subscribe((data: any) => {
      this.quizzes = data.data;
    });
  }
  loadIdeaHubTypes() {
    this.apiService.fetchDropdowns().subscribe((response) => {
      this.postTypes = response['Post Type'];
      this.assetClasses = response['Asset Class'];
      this.sentiments = response['Sentiment'];
      this.holdingPeriods = response['Holding Period'];
      this.risks = response['Risk'];
    });
  }
  fetchAllSymbols(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}symbol_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.allSymbols = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }


  fetchTags(): void {
    const apiUrl = `${environment.API_URL}fetch_tags`;
    this.http.get(apiUrl )
      .subscribe({
        next: (response: any) => {
          this.allTags = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        }
      });
  }


  
  fetchSectionType(): Promise<any> {
    const apiUrl = `${environment.API_URL}fetch_section_type`;
    this.http.get(apiUrl).subscribe({
      next: (response: any) => {
        console.log('API Response: ', response); // Log the API response
        if (Array.isArray(response) && response.length > 0) {
          this.sectionTypes = response;
        } else {
          console.log('Empty or invalid response data.');
        }
      },
      error: (error) => {
        console.log('editor Type: ', error);
        console.error('Error: ', error);
        this.tableLoading = false;
      },
      complete: () => {
        this.sectionTypes;
        this.tableLoading = false;
      },
    });
    return Promise.resolve(true);
  }
  fetchInitialData(ID?: any): Promise<any> {
    const apiUrl = `${environment.API_URL}my_articles`;

    const requestData = {
      role_id: localStorage.getItem('role_id'),
      author_id: localStorage.getItem('userId') || null,
      is_published: ID ?? null,
    };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('All Articles: ', response);
        this.articles = response.data;
      

        this.filteredArticles = this.articles;
        console.log(this.articles);
        console.log(this.filteredArticles);
      },
      error: (error) => {
        console.error('Error: ', error);
        this.tableLoading = false;
      },
      complete: () => {
        this.tableLoading = false;
      },
    });
    return Promise.resolve(true);
  }

  sortFn = (key: string) => (a: any, b: any) => {
    const aValue = a[key];
    const bValue = b[key];

    if (aValue < bValue) {
        return -1;
    } else if (aValue > bValue) {
        return 1;
    } else {
        return 0;
    }
};



  defaultSelectedKeys: string[] = [];

  handleTreeSelectClick(event: any): void {
    const key = event.node.key;
    const parentNode = event.node.parentNode;

    if (parentNode) {
      // Clicked on a child node, select both parent and child
      this.defaultSelectedKeys = [parentNode.key, key];
    } else {
      // Clicked on a parent node, select only the parent
      this.defaultSelectedKeys = [key];
    }
  }

  onChange($event: string[]): void {
    console.log($event);
  }


  fetchDropDowns(): Promise<any> {
    this.apiService.getCategories().subscribe({
      next: (response: any) => {
        console.log('Category Dropdown: ', response);
        const treeData = convertToCategoryTree(response.data);
        console.log(treeData);
        this.categories = treeData;
      },
      error: (error) => {
        console.error('Error: ', error);
      },
    });
    return Promise.resolve(true);
  }

  onSubmit() {
    debugger
    const apiUrl = `${environment.API_URL}edit_artical`;

      const requestData = {
        ...this.editArticleForm.value,
        artical_id: this.editArticleForm.controls['id'].value,
        updated_by: sessionStorage.getItem('userId'),
      };
      console.log('Request Data Submit', requestData);

      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          console.log('Update Response: ', response);
          if (response.success) {
            this.uploadFeaturedImage(this.editArticleForm.controls['id'].value!)
              .then(() => this.fetchInitialData())
              .then(() =>
                this.getArticleDetails(
                  this.editArticleForm.controls['id'].value!
                )
              )
              .then(() => {
                this.message.success('Aricle Update ');
              })
              .catch(() => {
                this.message.success('Error Occurred');
              });
          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
        },
      });
  }

  onDrop(event: DndDropEvent, index: number): void {
    const droppedData = event.data;
    this.Content.splice(
      index,
      0,
      this.Content.splice(this.Content.indexOf(droppedData), 1)[0]
    );
  }

  onDrag(event: any, index: number): void {}
  uploadFeaturedImage(articleId: string): Promise<boolean> {
    if (this.selectedFile) {
      const imageUrl = `${environment.API_URL}update_featured_image`; // Replace with the actual URL for image upload
      const formData = new FormData();
      formData.append(
        'image',
        this.selectedFile as Blob,
        this.selectedFile.name
      );
      formData.append('article_id', articleId);

      return new Promise((resolve, reject) => {
        this.http.post(imageUrl, formData).subscribe({
          next: (response: any) => {
            console.log('Image Upload Response: ', response);
            if (response.success) {
              return resolve(true);
            } else {
              return reject(false);
            }
          },
          error: (error) => {
            console.error('Image Upload Error: ', error);
            return reject(false);
          },
        });
      });
    } else {
      return Promise.resolve(true);
    }
  }

  openEditDrawer(id: number): void {
    this.editFormLoading = true;
    this.editDrawer = true;
    this.getArticleDetails(id);
  }
  Content: any[] = [];
  getArticleDetails(id: number): Promise<void> {
    const apiUrl = `${environment.API_URL}fetch_single_artical`;
    const requestData = { artical_id: id };
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          console.log('Article Details: ', response);
          this.Content = response.article.content;
          console.log(this.Content);
          if (response.article.featured_img_path) {
            this.featuredImagePath = `${environment.STORAGE_URL}${response.article.featured_img_path}`;
            console.log(this.featuredImagePath);
            this.featuredImagePresent = true;
          } else this.featuredImagePresent = false;
          this.editArticleForm.patchValue(response.article);
          return resolve();
        },
        error: (error) => {
          console.error('Error: ', error);
          return reject();
        },
        complete: () => {
          this.editFormLoading = false;
          // console.log(this.editArticleForm.value);
        },
      });
    });
  }

  closeEditDrawer(): void {
    this.editDrawer = false;
  }

  cancel(): void {}

  confirm(id: number): void {
    this.deleteRecord(id);
  }
  delelteArticle(id: number): void {
    this.deleteEditArticle(id);
  }
  deleteEditArticle(id: number) {
    const apiUrl = `${environment.API_URL}delete_artical_section`;
    const requestData = {
      artical_section_id: [id],
    };
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          const id = this.editArticleForm.controls['id'].value;
          if (id) {
            this.getArticleDetails(id).then(() => {
              this.message.success('Article deleted');
            });
          }
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }

  deleteRecord(id: number) {
    const apiUrl = `${environment.API_URL}delete_artical`;

    const requestData = {
      artical_id: id,
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchInitialData().then(() => {
            this.message.success('Article deleted');
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }

  // File Upload

  selectedFile: File | null = null;
  showImage: boolean = false;
  imageSrc: string = '';

  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;

  onFileSelected(event: any): void {
    const file = event.target.files[0];

    if (file) {
      // Perform size validation
      const maxSizeInBytes = 1024 * 1024; // 1MB

      // Validate file type
      if (!file.type.startsWith('image/')) {
        this.message.error(`Invalid file type. Please select an image.`);
        return;
      }

      // Validate file size
      if (file.size > maxSizeInBytes) {
        this.message.error(
          `File size exceeds the limit (1MB). Current size: ${formatBytes(
            file.size
          )}. Please choose a smaller file.`
        );
        return;
      }

      this.selectedFile = file;
      this.viewImage();
    }
  }

  viewImage(): void {
    if (this.selectedFile) {
      this.showImage = true;
      // Assuming you want to display the image as a base64 data URL
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }




  removeSelectedImage() {
    this.featuredImagePresent = false;
    this.featuredImagePath = '';
    this.selectedFile = null;
    this.showImage = false;
    this.imageSrc = '';
  }
  onTagChange(tags: any[]) {
    // Detect new tag
    const currentTags = this.allTags.map(tag => tag.tag_name);
    const newTags = tags.filter(tag => !currentTags.includes(tag));
    console.log(tags);
    
    if (newTags.length > 0) {
      newTags.forEach(tagName => {
        this.addTag({ tag_name: tagName });
      });
    }
  }

  addTag(tag: { tag_name: string } ) {
    const apiUrl = `${environment.API_URL}add_tag`;
    const articleId = this.editArticleForm.get('id')?.value;
    const requestData = {
      article_id :articleId,
      tag_name: tag.tag_name,
      created_by: localStorage.getItem('userId')
    };
    console.log(requestData);
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
      
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
}
