// import { HttpClient } from '@angular/common/http';
// import { Component, ElementRef, ViewChild } from '@angular/core';
// import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { NzMessageService } from 'ng-zorro-antd/message';
// import { isFormValid } from 'src/app/Functions/FormFunctions';
// import { Modules } from 'src/app/Interfaces/modules';
// import { environment } from 'src/environments/environment';

// @Component({
//   selector: 'app-all-modules',
//   templateUrl: './all-modules.component.html',
//   styleUrls: ['./all-modules.component.scss'],
// })
// export class AllModulesComponent {
//   useAdminLayout: boolean = false; // Default to non-admin layout
//   viewModules: Modules[] = [];
//   modules: any[] = [];
//   tableLoading: boolean = true;
//   editFormLoading: boolean = false;
//   ShortLongTable: boolean = false;
//   _addModule: boolean = false;
//   _editModule: boolean = false;

//   roleId: any;

//   constructor(private http: HttpClient, private message: NzMessageService) {}

//   ngOnInit() {
//     this.roleId = localStorage.getItem('role_id');
//     this.useAdminLayout = this.roleId === '1' || this.roleId === '2';

//     this.fetchInitialData();
//     this.fetchModulesDropdown();
//   }

//   fetchInitialData(): Promise<any> {
//     const apiUrl = `${environment.API_URL}fetch_all_modules`;

//     const requestData = {
//       account_id: sessionStorage.getItem('account_id'),
//     };
//     console.log(requestData);

//     this.http.post(apiUrl, requestData).subscribe({
//       next: (response: any) => {
//         console.log('All Modules: ', response);
//         this.viewModules = response.data;
//       },
//       error: (error) => {
//         console.error('Error: ', error);
//         this.tableLoading = false;
//       },
//       complete: () => {
//         this.tableLoading = false;
//       },
//     });
//     return Promise.resolve(true);
//   }

//   fetchModulesDropdown(): Promise<any> {
//     const apiUrl = `${environment.API_URL}fetch_modules_dropdown`;

//     this.http.get(apiUrl).subscribe({
//       next: (response: any) => {
//         console.log('All Modules: ', response);
//         this.modules = response.data;
//       },
//       error: (error) => {
//         console.error('Error: ', error);
//         this.tableLoading = false;
//       },
//       complete: () => {
//         this.tableLoading = false;
//       },
//     });
//     return Promise.resolve(true);
//   }
//   editModulesForm = new FormGroup({
//     account_id: new FormControl(null),
//     module_id: new FormControl(null),
//     parent_module: new FormControl(null),
//     module_name: new FormControl('', Validators.required),
//     description: new FormControl(''),
//     active_status_id: new FormControl(null),
//     created_at: new FormControl(),
//     updated_at: new FormControl(),
//     created_by_string: new FormControl(),
//     updated_by_string: new FormControl(),
//   });

//   editModule(module_id: number) {
//     this.ShortLongTable = true;
//     this._editModule = true;
//     this._addModule = false;
//     this.getArticleDetails(module_id);
//   }
//   ModuleUpdated() {
//     if (isFormValid(this.editModulesForm)) {
//       // Handle form submission, e.g., send data to the server
//       const apiUrl = `${environment.API_URL}update_module`;
//       const requestData = {
//         ...this.editModulesForm.value,
//         updated_by: localStorage.getItem('userId'),
//       };

//       this.http.post(apiUrl, requestData).subscribe({
//         next: (response: any) => {
//           console.log('Update Response: ', response);
//           if (response.success) {
//             this.fetchInitialData()
//               .then(() => {
//                 const message = 'Module Updated';
//                 this.message.success(message);
//               })
//               .catch(() => {
//                 this.message.success('Error Occurred');
//               });
//           } else {
//             this.message.error('Error occurred, please try later');
//           }
//         },
//         error: (error) => {
//           console.error('Error: ', error);
//         },
//         complete: () => {},
//       });
//     }
//   }

//   addModule() {
//     this.ShortLongTable = true;
//     this._addModule = true;
//     this._editModule = false;
//   }
//   ModuleAdded() {
//     if (isFormValid(this.editModulesForm)) {
//       // Handle form submission, e.g., send data to the server
//       const apiUrl = `${environment.API_URL}add_module`;
//       const requestData = {
//         ...this.editModulesForm.value,
//         user_id: localStorage.getItem('userId'),
//       };

//       this.http.post(apiUrl, requestData).subscribe({
//         next: (response: any) => {
//           console.log('Update Response: ', response);
//           if (response.success) {
//             this.fetchInitialData()
//               .then(() =>
//                 this.getArticleDetails(
//                   this.editModulesForm.controls['module_id'].value!
//                 )
//               )
//               .then(() => {
//                 const message = 'Module Added';
//                 this.message.success(message);
//               })
//               .catch(() => {
//                 this.message.success('Error Occurred');
//               });
//           } else {
//             this.message.error('Error occurred, please try later');
//           }
//         },
//         error: (error) => {
//           console.error('Error: ', error);
//         },
//       });
//     }
//   }
//   closed() {
//     (this.ShortLongTable = false), this.editModulesForm.reset();
//   }
//   cancel(): void {
//   }

//   getArticleDetails(module_id: number): Promise<void> {
//     const apiUrl = `${environment.API_URL}fetch_single_module`;
//     const requestData = { module_id: module_id };
//     return new Promise((resolve, reject) => {
//       this.http.post(apiUrl, requestData).subscribe({
//         next: (response: any) => {
//           console.log('Module Details: ', response[0]);

//           this.editModulesForm.patchValue(response[0]);
//           return resolve();
//         },
//         error: (error) => {
//           console.error('Error: ', error);
//           return reject();
//         },
//         complete: () => {
//           this.editFormLoading = false;
//         },
//       });
//     });
//   }

//   confirm(id: number): void {
//     this.deleteRecord(id);
//   }

//   deleteRecord(module_id: number) {
//     const apiUrl = `${environment.API_URL}delete_module`;

//     const requestData = {
//       module_id: module_id,
//     };

//     console.log(requestData);
//     this.http.post(apiUrl, requestData).subscribe({
//       next: (response: any) => {
//         console.log('Response: ', response);
//         if (response.success) {
//           this.fetchInitialData().then(() => {
//             this.message.success('Module deleted');
//           });
//         } else {
//           this.message.error('Error occurred, please try later');
//         }
//       },
//       error: (error) => {
//         console.error('Error: ', error);
//         this.message.error('Error occurred, please try later');
//       },
//     });
//   }
// }



import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { Category, modules } from 'src/app/Interfaces/Content';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-all-modules',
  templateUrl: './all-modules.component.html',
  styleUrls: ['./all-modules.component.scss'],
})
export class AllModulesComponent {
  modules: modules[] = [];
  parentModules: any[] = [];
  useAdminLayout: boolean = false; // Default to non-admin layout
  
  editModulesForm: FormGroup;
  roleId:any;
  tableLoading: boolean = true;
  constructor(private http: HttpClient, private apiService: FetchApiService, private message: NzMessageService) {
    this.editModulesForm = new FormGroup({
      account_id: new FormControl(null),
      module_id: new FormControl(null),
      parent_module: new FormControl(null),
      module_name: new FormControl('', Validators.required),
      description: new FormControl(''),
      active_status_id: new FormControl(null),
      created_at: new FormControl(),
      updated_at: new FormControl(),
      created_by_string: new FormControl(),
      updated_by_string: new FormControl(),
    });

  }

  ngOnInit() {
    this.roleId = localStorage.getItem('role_id');
    this.useAdminLayout = this.roleId === '1' || this.roleId === '2';
    this.fetchInitialData();
    this.fetchModulesDropdown()
  }


  
  fetchInitialData(): Promise<any> {
    const apiUrl = `${environment.API_URL}fetch_all_modules`;
    const requestData = {
      created_by: sessionStorage.getItem('userId'),
      ...this.editModulesForm.value
    };
    console.log(requestData);
    this.http.get(apiUrl).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        this.modules = response.data;
      },
      error: (error) => {
        console.error('Error: ', error);
        this.tableLoading = false;
      }, complete: () => {
        this.tableLoading = false;
      }
    });
    return Promise.resolve(true);
  }

  expandSet = new Set<number>();
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  addDrawer: boolean = false;
  editDrawer: boolean = false;

  openEditDrawer(parentCategoryId: number, childCategoryId?: number): void {
    var categoryDetails;
    if (childCategoryId) {
      // Its Child Category
      let filter1 = this.modules.find(category => category.module_id === parentCategoryId)!;
      categoryDetails = filter1.child.find((child: { module_id: number; }) => child.module_id === childCategoryId);
    } else {
      // Its Parent Category
      categoryDetails = this.modules.find(category => category.module_id === parentCategoryId);
    }
    this.editModulesForm.patchValue(categoryDetails!);
    console.log(this.editModulesForm.value);
    this.editDrawer = true;
  }

  closeEditDrawer(): void {
    this.editDrawer = false;
  }

  openAddDrawer(): void {
    this.addDrawer = true;
  }

  closeAddDrawer() {
    this.addDrawer = false;
  }

  cancel(): void {
  }

  confirm(id: number): void {
    this.deleteRecord(id);
  }
  fetchModulesDropdown(): Promise<any> {
    const apiUrl = `${environment.API_URL}fetch_modules_dropdown`;

    this.http.get(apiUrl).subscribe({
      next: (response: any) => {
        console.log('All Modules: ', response);
        this.parentModules = response.data;
      },
      error: (error) => {
        console.error('Error: ', error);
        this.tableLoading = false;
      },
      complete: () => {
        this.tableLoading = false;
      },
    });
    return Promise.resolve(true);
  }
  submitAddForm() {
    if (isFormValid(this.editModulesForm)) {
      const apiUrl = `${environment.API_URL}add_module`;
      const requestData = {
               ...this.editModulesForm.value,
               user_id: localStorage.getItem('userId'),
             };
      console.log(requestData);
      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            console.log('Response: ', response);
            if (response.success) {
              this.fetchInitialData().then(() => {
                this.message.success('Module added');
                this.editModulesForm.reset();
                this.closeAddDrawer();
              });

            } else {
              this.message.error('Error occurred, please try later');
            }
          },
          error: (error) => {
            console.error('Error: ', error);
            this.message.error('Error occurred, please try later');
          }
        });
    }
  }

  submitEditForm() {
    debugger
    if (isFormValid(this.editModulesForm)) {
      const apiUrl = `${environment.API_URL}update_module`;
      const requestData = {
        module_id:this.editModulesForm.value.module_id,
        module_name:this.editModulesForm.value.module_name,
        description:this.editModulesForm.value.description,
        active_status_id:this.editModulesForm.value.active_status_id,
        parent_module:this.editModulesForm.value.parent_module,
        updated_by: sessionStorage.getItem('userId'),
      };
      console.log(requestData);
      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            console.log('Response: ', response);
            if (response.success) {
              this.fetchInitialData().then(() => {
                this.message.success('Module updated');
                this.editModulesForm.reset();
                this.closeEditDrawer();
              });
            } else {
              this.message.error('Error occurred, please try later');
            }
          },
          error: (error) => {
            console.error('Error: ', error);
          }
        });
    }
  }

  deleteRecord(module_id: number) {
        const apiUrl = `${environment.API_URL}delete_module`;
    
        const requestData = {
          module_id: module_id,
        };
    
        console.log(requestData);
        this.http.post(apiUrl, requestData).subscribe({
          next: (response: any) => {
            console.log('Response: ', response);
            if (response.success) {
              this.fetchInitialData().then(() => {
                this.message.success('Module deleted');
              });
            } else {
              this.message.error('Error occurred, please try later');
            }
          },
          error: (error) => {
            console.error('Error: ', error);
            this.message.error('Error occurred, please try later');
          },
        });
      }
    }
