


import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { allModule, permissionInt } from 'src/app/Interfaces/modules';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { PermissionService } from 'src/app/Services/Permission/permission.service';
import { environment } from 'src/environments/environment';
import { convertToModuleTree } from 'src/app/Functions/moduleFunction';

@Component({
  selector: 'app-new-permission',
  templateUrl: './new-permission.component.html',
  styleUrls: ['./new-permission.component.scss']
})
export class NewPermissionComponent {
  useAdminLayout: boolean = false; // Default to non-admin layout
  viewPermission: permissionInt[] = [];
  tableLoading: boolean = true;
  editFormLoading: boolean = false;
  ShortLongTable: boolean = false;
  _addPermission: boolean = false;
  _editPermission: boolean = false;
  allModule:allModule[]=[];

  roleId:any;

  constructor(private http: HttpClient, private message: NzMessageService, private permissionApi :PermissionService, 
    private apiService: FetchApiService,
  ) {}

  ngOnInit() {
    this.roleId = localStorage.getItem('role_id');
    this.useAdminLayout = this.roleId === '1' || this.roleId === '2';

    this.fetchInitialData();
    // this.getModuleDropdown();
this.fetchDropDowns();
  }

  fetchInitialData(): Promise<any> {
    const apiUrl = `${environment.API_URL}fetch_all_permissions`;

    this.http.get(apiUrl).subscribe({
      next: (response: any) => {
        console.log('All Permission: ', response);
        this.viewPermission = response;
      },
      error: (error) => {
        console.error('Error: ', error);
        this.tableLoading = false;
      },
      complete: () => {
        this.tableLoading = false;
      },
    });
    return Promise.resolve(true);
  }

  editPermissionsForm = new FormGroup({
    account_id: new FormControl(null),
    permission_id: new FormControl(null),
    module_id: new FormControl(null, Validators.required),
    permission_name: new FormControl('', Validators.required),
    permission_code: new FormControl('', Validators.required),
    description: new FormControl(''),
    active_status_id: new FormControl(null),
    created_at: new FormControl(),
    updated_at: new FormControl(),
    created_by_string: new FormControl(),
    updated_by_string: new FormControl(),
  });

  editPermission(permission_id: number) {
    this.ShortLongTable = true;
    this._editPermission = true;
    this._addPermission = false;
    this.getArticleDetails(permission_id);
  }
  PermissionUpdated() {
    if (isFormValid(this.editPermissionsForm)) {
      // Handle form submission, e.g., send data to the server
      const apiUrl = `${environment.API_URL}update_permission`;
      const requestData = {

        permission_id:this.editPermissionsForm.value.permission_id,
        module_id:this.editPermissionsForm.value.module_id,
        permission_name:this.editPermissionsForm.value.permission_name ,
        permission_code:this.editPermissionsForm.value.permission_code ,
        description:this.editPermissionsForm.value.description ,
        active_status_id:this.editPermissionsForm.value.active_status_id,
        updated_by:localStorage.getItem('userId'),

      };

      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          console.log('Update Response: ', response);
          if (response.success) {
            this.fetchInitialData()
              .then(() => {
                const message = 'Permission Updated';
                this.message.success(message);
                this.closed();
              })
              .catch(() => {
                this.message.success('Error Occurred');
              });
          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
        },
        complete:()=> {

        },
      });
    }
  }
  addPermission() {
    this.ShortLongTable = true;
    this._addPermission = true;
    this._editPermission = false;
  }
  PermissionAdded() {
    if (isFormValid(this.editPermissionsForm)) {
      // Handle form submission, e.g., send data to the server
      const apiUrl = `${environment.API_URL}add_permission`;
      const requestData = {
        permission_name: this.editPermissionsForm.value.permission_name,
        permission_code: this.editPermissionsForm.value. permission_code,
        module_id: this.editPermissionsForm.value.module_id,
        description: this.editPermissionsForm.value.description,
        active_status_id: this.editPermissionsForm.value.active_status_id,
        created_by: localStorage.getItem('userId'),
      };

      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          console.log('Update Response: ', response);
          if (response.success) {
            this.closed();
            this.fetchInitialData()
              .then(() =>
                this.getArticleDetails(
                  this.editPermissionsForm.controls['permission_id'].value!
                )
              )
              .then(() => {

                const message = 'Permission Updated';
                this.message.success(message);
              })
              .catch(() => {
                this.message.success('Error Occurred');
              });
          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
        },
        complete:() => {

        },
      });
    }
  }
  closed() {
    (this.ShortLongTable = false), this.editPermissionsForm.reset();
  }

  getArticleDetails(permission_id: number): Promise<void> {
    const apiUrl = `${environment.API_URL}fetch_single_permission`;
    const requestData = { permission_id: permission_id };
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          console.log('Single Permission Details: ', response[0]);

          this.editPermissionsForm.patchValue(response[0]);
          return resolve();
        },
        error: (error) => {
          console.error('Error: ', error);
          return reject();
        },
        complete: () => {
          this.editFormLoading = false;
        },
      });
    });
  }




  // getModuleDropdown(){
  //   this.permissionApi.getModuleDropdown().subscribe({
  //     next: (response: any) => {
  //       console.log('Module Details: ', response);
  //       this.allModule =response


  //     },
  //     error: (error) => {
  //       console.error('Error: ', error);

  //     },
  //     complete: () => {
  //       this.editFormLoading = false;
  //     },
  //   });

  // }
  treeSelectExpandKeys: string[] = [];
  categories: any = [];
  fetchDropDowns(): Promise<any> {
    this.permissionApi.getModuleDropdown().subscribe({
      next: (response: any) => {
        console.log('Category Dropdown: ', response);
        const treeData = convertToModuleTree(response.data);
        console.log(treeData);
        this.categories = treeData;
      },
      error: (error) => {
        console.error('Error: ', error);
      },
    });
    return Promise.resolve(true);
  }



  confirm(id: number): void {
    this.deleteRecord(id);
  }

  deleteRecord(permission_id: number) {
    const apiUrl = `${environment.API_URL}delete_permission`;

    const requestData = {
      permission_id: permission_id,
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchInitialData().then(() => {
            this.message.success('Permission deleted');
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
}

