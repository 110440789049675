import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { removeHtmlTags } from 'src/app/Functions/Sanitize';
import { shuffleArray } from 'src/app/Functions/arrayFunctions';
import { Article } from 'src/app/Interfaces/Content';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subscriber-home-page',
  templateUrl: './subscriber-home-page.component.html',
  styleUrls: ['./subscriber-home-page.component.scss']
})
export class SubscriberHomePageComponent {

  articles: Article[] = [];
  topPicks: Article[] = [];
  trending: Article[] = [];

  // Function to randomly select a specific number of articles
  getRandomArticles(articles: any[], count: number) {
    // Shuffle the array to randomize the order of articles
    shuffleArray(articles);
    // Slice the first 'count' articles to select
    return articles.slice(0, count);
  }

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
  }
  removeTags(content: string) {
    return removeHtmlTags(this.sanitizer, content);
  }


  ngOnInit() {
    this.fetchInitialData();
  }

  randomImageUrls: string[] = [];

  populateRandomImageUrls() {
    for (let i = 0; i < this.articles.length; i++) {
      const randomIndex = Math.floor(Math.random() * 8); // 8 images preset in dummy-images folder
      this.randomImageUrls[i] = `assets/dummy-images/dumyimg${randomIndex + 1}.jpg`;
    }
  }


  fetchInitialData(): Promise<any> {
    const apiUrl = `${environment.API_URL}articles`;
    this.http.get(apiUrl)
      .subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          this.articles = response.data;
          this.topPicks = this.getRandomArticles(response.data, 3);
          this.trending = this.getRandomArticles(response.data, 5);

          this.populateRandomImageUrls();

        },
        error: (error) => {
          console.error('Error: ', error);
        }
      });
    return Promise.resolve(true);
  }
}
