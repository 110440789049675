<ng-container >
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
  </ng-container>


<ng-template #content>
    <div class="d-flex">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Content Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Categories</nz-breadcrumb-item>
        </nz-breadcrumb>
        <button class="ms-auto mb-2" nz-button nzType="primary" (click)="openAddDrawer()"><span nz-icon
                nzType="plus"></span>Add Category</button>
    </div>
    <div class="row">
        <div class="col-md-6 mb-3">
            <nz-input-group [nzSuffix]="suffixIconSearch">
                <input type="text" nz-input placeholder="Search Category" [(ngModel)]="searchQuery" (ngModelChange)="filterSymbols()">
            </nz-input-group>
              <ng-template #suffixIconSearch>
                <span nz-icon nzType="search"></span>
              </ng-template>
  
        </div>
    </div>

    <nz-card>
        <div class="table-responsive">

            <nz-table #categoryTable [nzData]="categories" nzTableLayout="fixed" [nzLoading]="tableLoading" nzShowSizeChanger>
                <thead>
                    <tr>
                        <th nzWidth="60px" nzAlign="center"></th>
                        <th [nzSortFn]="sortByName">Name</th>
                        <th [nzSortFn]="sortByDescription">Description</th>
                        <th>Child Categories</th>
                        <th [nzSortFn]="sortByDate">Created At</th>
                        <th>Created By</th>
                        <th [nzSortFn]="sortByDate">Updated At</th>
                        <th>Updated By</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let category of categoryTable.data">

                        <!-- Main Table -->
                        <tr (click)="openEditDrawer(category.id)">
                            <td nzAlign="center" [nzExpand]="expandSet.has(category.id)"
                                (click)="$event.stopPropagation()" [nzDisabled]="true"
                                (nzExpandChange)="onExpandChange(category.id, $event)">
                            </td>
                            <td>{{ category.category_name }}</td>
                            <td nzEllipsis>{{ category.description ? category.description: '--' }}</td>
                            <td>{{ category.child.length }} Present</td>
                            <td>{{ category.created_at | date: 'dd MMM yyyy' }}</td>
                            <td>{{ category.created_by_name }}</td>
                            <td>{{ category.updated_at | date: 'dd MMM yyyy' }}</td>
                            <td>{{ category.updated_by_name }}</td>
                            <td nzAlign="center" (click)="$event.stopPropagation()">
                                <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                    nzPopconfirmTitle="Are you sure you want to delete this record?"
                                    (nzOnConfirm)="confirm(category.id)" (nzOnCancel)="cancel()">
                                    <span nz-icon nzType="delete"></span>
                                </button>
                            </td>
                        </tr>
                        <!-- Child Category Section -->
                        <tr [nzExpand]="expandSet.has(category.id)">
                            <div (click)="openEditDrawer(category.id, child.id)" class="hstack gap-2 mb-1"
                                *ngFor="let child of category.child; let i = index;">
                                <span class="ps-1" style="width: 50px;">{{i+1}}.</span>
                                <span class="col-2">{{ child.category_name }}</span>
                                <nz-divider nzType="vertical"></nz-divider>
                                <span class="col-2">{{ child.description ? child.description: '--' }}</span>
                                <nz-divider nzType="vertical"></nz-divider>
                                <div class=" text-center" style="width: 100px;" (click)="$event.stopPropagation()">
                                    <button nz-button nzSize="small" nzDanger nzShape="circle" nz-popconfirm
                                        nzPopconfirmTitle="Are you sure you want to delete this record?"
                                        (nzOnConfirm)="confirm(child.id)" (nzOnCancel)="cancel()">
                                        <span nz-icon nzType="delete"></span>
                                    </button>
                                </div>
                            </div>
                            <span *ngIf="!category.child.length">No child category present!</span>
                        </tr>
                    </ng-container>
                </tbody>
            </nz-table>
        </div>

        <nz-drawer [nzVisible]="editDrawer" nzTitle="Edit Category" (nzOnClose)="closeEditDrawer()">
            <ng-container *nzDrawerContent>

                <form nz-form nzLayout="vertical" [formGroup]="editCategoryForm" (ngSubmit)="submitEditForm()">
                    <nz-form-item>
                        <nz-form-label>Category Name</nz-form-label>
                        <nz-form-control nzErrorTip="Category Name">
                            <nz-input-group>
                                <input type="text" nz-input formControlName="category_name" required
                                    placeholder="Name" />
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label>Description</nz-form-label>
                        <nz-form-control nzHasFeedback nzErrorTip="Please write something here!">
                            <nz-textarea-count [nzMaxCharacterCount]="2000">
                                <textarea formControlName="description" nz-input rows="5"
                                    placeholder="write your description here"></textarea>
                            </nz-textarea-count>
                        </nz-form-control>
                    </nz-form-item>

                    <button nz-button nzType="primary" type="submit">Submit</button>
                </form>

            </ng-container>
        </nz-drawer>


        <nz-drawer [nzVisible]="addDrawer" nzTitle="Add Category" (nzOnClose)="closeAddDrawer()">
            <ng-container *nzDrawerContent>

                <form nz-form nzLayout="vertical" [formGroup]="addCategoryForm" (ngSubmit)="submitAddForm()">
                    <nz-form-item>
                        <nz-form-label nzRequired>Category Name</nz-form-label>
                        <nz-form-control nzErrorTip="Category Name">
                            <nz-input-group>
                                <input type="text" nz-input formControlName="category_name" required
                                    placeholder="Name" />
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label>Parent Category</nz-form-label>

                        <nz-select formControlName="parent_category_id" nzShowSearch nzAllowClear
                            nzPlaceHolder="Select Category">
                            <nz-option *ngFor="let category of categories" [nzLabel]="category.category_name"
                                [nzValue]="category.id"></nz-option>
                        </nz-select>

                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label>Description</nz-form-label>
                        <nz-form-control nzHasFeedback nzErrorTip="Please write something here!">
                            <nz-textarea-count [nzMaxCharacterCount]="2000">
                                <textarea formControlName="description" nz-input rows="5"
                                    placeholder="write your description here"></textarea>
                            </nz-textarea-count>
                        </nz-form-control>
                    </nz-form-item>

                    <button nz-button nzType="primary" type="submit">Submit</button>
                </form>

            </ng-container>
        </nz-drawer>
    </nz-card>

</ng-template>