<app-home-page-layout>
    <nz-spin [ngClass]="{'spinner-with-overlay': isLoading}" [nzSpinning]="isLoading" nzSize="large"></nz-spin>

    <nz-card class="mb-4 promo-banner">
        <div class="row">
            <div class="col-12 col-md-6 ">
                <h3>InvestDost - Your Trusted Finance and Investment Companion</h3>
                <p> Join InvestDost for expert insights on finance, investments, stock markets, and more. Your
                    pathway
                    to financial success begins here. Sign up or log in now!</p>
                <div class="hstack gap-3">
                    <button [routerLink]="['/register']" id="signupButton" nz-button nzSize="large"
                        nzType="primary">JOIN NOW, ITS FREE!</button>
                    <button [routerLink]="['/login']" nz-button nzSize="large" nzType="text">SIGN IN</button>
                </div>
            </div>
        </div>
    </nz-card>

    <nz-card>
        <div class="row mb-4">
            <div class="col-md-3" *ngIf="allPublishedArticles.length > 1">
                <app-article-display-style-one *ngIf="allPublishedArticles[1]"
                    [article]="allPublishedArticles[1]"></app-article-display-style-one>
                <app-article-display-style-one *ngIf="allPublishedArticles[2]"
                    [article]="allPublishedArticles[2]"></app-article-display-style-one>
            </div>

            <div class="col-md-6" *ngIf="allPublishedArticles[0]">
                <app-article-display-style-one *ngIf="allPublishedArticles[0]" [article]="allPublishedArticles[0]"
                    [centerAlign]="true"></app-article-display-style-one>
            </div>

            <div class="col-md">
                <app-section-heading title="Editor's Picks"></app-section-heading>
                <ng-container *ngIf="editorsPicksArticles.length === 0">
                    <nz-empty></nz-empty>
                </ng-container>
                <nz-list *ngIf="editorsPicksArticles.length">
                    <nz-list-item *ngFor="let article of editorsPicksArticles; let i = index;">
                        <app-article-display-style-two [article]="article"></app-article-display-style-two>
                    </nz-list-item>
                </nz-list>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-12">
                <app-section-heading title="Trending Articles"></app-section-heading>
            </div>

            <ng-container *ngIf="trendingArticles.length === 0">
                <nz-empty></nz-empty>
            </ng-container>

            <div class="col-12 col-md-3" *ngFor="let article of trendingArticles; let i = index;">
                <app-article-display-style-one [article]="article"></app-article-display-style-one>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-12">
                <app-section-heading title="Latest Articles"></app-section-heading>
            </div>

            <ng-container *ngIf="latestArticles.length === 0">
                <nz-empty></nz-empty>
            </ng-container>

            <div class="col-12">
                <nz-list>
                    <nz-list-item *ngFor="let article of latestArticles; let i = index;">
                        <app-article-display-style-three [article]="article"></app-article-display-style-three>
                    </nz-list-item>
                </nz-list>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-12">
                <app-section-heading title="Random Picks"></app-section-heading>
            </div>
            <ng-container *ngIf="allPublishedArticles.length === 0">
                <nz-empty></nz-empty>
            </ng-container>

            <div class="col-12 col-md-3" *ngFor="let article of allPublishedArticles; let i = index;">
                <app-article-display-style-one [article]="article"></app-article-display-style-one>
            </div>
        </div>
    </nz-card>
</app-home-page-layout>