<ng-container >
  <app-admin-layout>
      <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-admin-layout>
</ng-container>

<!-- <ng-template #authorLayout>
  <app-author-layout>
      <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-author-layout>
</ng-template> -->

<ng-template #content>
  <!-- Content specific to both admin and author layouts -->
  <div class="d-flex">
      <nz-breadcrumb>
          <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
          <nz-breadcrumb-item>Permission</nz-breadcrumb-item>
          <nz-breadcrumb-item>New Permission</nz-breadcrumb-item>
      </nz-breadcrumb>
      <button class="ms-auto mb-2"  nz-button nzType="primary"  (click)="addPermission()"><span nz-icon
              nzType="plus" ></span>Add Permission</button>
  </div>

<div class="row">
  <div  class="col-md-{{ ShortLongTable ? 8 : 12 }}">
    <nz-card>
      <div class="table-responsive">
          <nz-table #articlesTable [nzData]="viewPermission" nzTableLayout="fixed" [nzLoading]="tableLoading"
              nzShowSizeChanger>
              <thead>
                  <tr>
                    <th>Module Name</th>
                    <th>Permission ID</th>
                    <th>Permission Name</th>
                    <th>Permission Code</th>

                      <th>Active</th>
                      <th>Description</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th>Created By</th>
                      <th>Updated By</th>
                      <th nzWidth="100px" nzAlign="center">Action</th> <!-- Add an Action column -->
                  </tr>







              </thead>
              <tbody>

                <!-- (click)="openEditDrawer(viewPermission.module_id)" -->
                  <tr *ngFor="let viewPermission of articlesTable.data"  (click)="editPermission(viewPermission.permission_id)">
                    <td nzEllipsis>{{ viewPermission.module_name }}</td>
                    <td nzEllipsis>{{ viewPermission.permission_id }}</td>
                    <td nzEllipsis>{{ viewPermission.permission_name }}</td>
                    <td nzEllipsis>{{ viewPermission.permission_code }}</td>
                      <td nzEllipsis>{{ viewPermission.active_status_id === 1 ? 'Yes' : ' No' }}</td>
                      <td>{{ viewPermission.description }}</td>
                      <td>{{ viewPermission.created_at | date: 'yyyy-MM-dd' }}</td>
                      <td>{{ viewPermission.updated_at | date: 'yyyy-MM-dd' }}</td>
                      <td nzEllipsis>{{ viewPermission.created_by_string }}</td>
                      <td nzEllipsis>{{ viewPermission.updated_by_string }}</td>
                      <td nzAlign="center" (click)="$event.stopPropagation()">
                          <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                              nzPopconfirmTitle="Are you sure you want to delete this record?"
                              (nzOnConfirm)="confirm(viewPermission.permission_id)" >
                              <span nz-icon nzType="delete"></span>
                          </button>
                      </td>
                  </tr>
              </tbody>
          </nz-table>
      </div>
  </nz-card>
  </div>
  <div  *ngIf="ShortLongTable" class="col-md-4">
    <nz-card>
      <ng-container >
        <nz-spin  class="position-absolute start-50 top-50" nzSimple></nz-spin>
        <form  [formGroup]="editPermissionsForm"  nz-form
            nzLayout="vertical">
            <p *ngIf="this._addPermission" >Add New Permission</p>
              <p *ngIf="this._editPermission">Update New Permission</p>
            <nz-card>
              <button *ngIf="this._addPermission"  nz-button nzType="primary" type="submit" (click)="PermissionAdded()">Add Permission</button>
              <button *ngIf="this._editPermission"  nz-button nzType="primary" type="submit" (click)="PermissionUpdated()">Update Permission</button>
              <button class="ms-3" nz-button nzType="default" type="button" (click)="closed()">Close
                  </button>
              <nz-divider></nz-divider>
              <nz-form-item>
                <nz-form-label nzRequired>Is Active?</nz-form-label>
                <nz-radio-group formControlName="active_status_id">
                  <label nz-radio [nzValue]="1">Yes</label>
                    <label nz-radio [nzValue]="2">No</label>
                </nz-radio-group>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label>Module</nz-form-label>
              <!-- <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Module"
                  formControlName="module_id">
                  <nz-option *ngFor="let module of allModule" [nzLabel]="module.module_name"
                      [nzValue]="module.module_id"></nz-option>
              </nz-select> -->
              <div class="row">
                <div class="col-md-12">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please select Module!">
                            <nz-tree-select [nzExpandedKeys]="treeSelectExpandKeys"
                                [nzNodes]="categories" nzShowSearch nzPlaceHolder="Select Module"
                                formControlName="module_id"></nz-tree-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
          </nz-form-item>
              <nz-form-item>
                  <nz-form-label nzRequired>Permission Name</nz-form-label>
                  <nz-form-control>
                      <nz-input-group>
                          <input type="text" nz-input formControlName="permission_name"
                              placeholder="Enter Permission Name" />
                      </nz-input-group>
                  </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzRequired>Permission Code</nz-form-label>
                <nz-form-control>
                    <nz-input-group>
                        <input type="text" nz-input formControlName="permission_code"
                            placeholder="Enter Permission Name" />
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
              <nz-form-item>
                  <nz-form-label>Description</nz-form-label>
                  <nz-form-control>
                      <nz-input-group>
                          <textarea rows="5" nz-input formControlName="description"
                              placeholder="Enter Description"></textarea>
                      </nz-input-group>
                  </nz-form-control>
              </nz-form-item>
          </nz-card>
        </form>
    </ng-container>
    </nz-card>
  </div>
</div>
</ng-template>
