import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-popular-tags',
  templateUrl: './popular-tags.component.html',
  styleUrls: ['./popular-tags.component.scss'],
})
export class PopularTagsComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private _activatedRoute: ActivatedRoute,
    private apiService: FetchApiService,
    private message: NzMessageService
  ) {}
  ngOnInit(): void {
    this.getPopularTags();
  }
  
  popularTags: any[] = [];
  storageUrl = environment.STORAGE_URL;
  getPopularTags(): Promise<any> {
    this._activatedRoute.params.subscribe((params) => {
      console.log(params);
      const tagId = +params['id'];
      console.log(tagId);

      if (tagId) {
        this.apiService.fetchPopularTags(tagId).subscribe({
          next: (response: any) => {
            console.log('Response: ', response.data);
            this.popularTags = response.data;
          },
          error: (error) => {
            console.error('Error: ', error);
          },
          complete: () => {},
        });
      } else {
        console.log(tagId, ' this data not get');
      }
    });

    return Promise.resolve(true);
  }
}
