<app-main-fiminize-layout>
  <div class="container mt-5">
    <div class="row">
      <!-- Profile Section -->
      <div class="col-md-8">
      <nz-page-header >
        <nz-avatar [nzSize]="64" nz-page-header-avatar nzSrc="https://avatars0.githubusercontent.com/u/22736418?s=88&v=4"></nz-avatar>
        <nz-page-header-title>{{ userDetails?.name }}</nz-page-header-title>
        <nz-page-header-subtitle>{{ userDetails?.username }}</nz-page-header-subtitle>
          <nz-page-header-content>
            <div class="d-flex justify-content-start align-items-center mt-3">
              <span>{{ userDetails?.followers }} Followers</span>
              <span class="mx-2">·</span>
              <span>{{ userDetails?.following }} Following</span>
            </div>
            <div nz-page-header-extra class="mt-3">
              <button *ngIf="dostUserID !== userID" nz-button nzType="primary" class="me-2"   (click)="toggleFollow()">
                {{ userDetails?.already_following ? 'Following' : 'Follow' }}
              </button>
              <button nz-button nzType="default" class="me-2" (click)="notifyMe()">
                {{ userDetails?.already_notified ? 'Notified' : 'Notify Me' }}
              </button>
              <button nz-button nzType="default" class="me-2">Message</button>
              <button nz-button nzType="default"><i nz-icon nzType="ellipsis"></i></button>
            </div>
          </nz-page-header-content>
        </nz-page-header>

   
          <nz-tabset>
            <nz-tab nzTitle="DostDiary Posts">
              <ng-template nz-tab>
                <div *ngFor="let post of posts">
                  <nz-page-header class="bordered-header " [ngStyle]="{ 'background-color': post.is_featured_post === 1 ? '#FFF9EB' : 'inherit' }">
                    <nz-page-header-content>
                      <p nz-paragraph [innerHTML]="post.caption"></p>
                      <nz-page-header-subtitle>{{ post.created_at | date: 'MMMM yyyy' }}</nz-page-header-subtitle>
                      <nz-divider></nz-divider>
                      <div class="d-flex align-items-center justify-content-between mt-1">
                        <div class="flex-grow-1 d-flex align-items-center">
                          <button nz-button nzShape="circle" nzType="default" (click)="likeButton(post)">
                            <i [ngClass]="post.like_count > 0 ? 'fa-solid fa-thumbs-up' : 'fa-regular fa-thumbs-up'"
                              [ngStyle]="{ color: post.like_count > 0 ? '#1890ff' : '' }"></i>
                          </button>
                          <span class="me-2"><b>{{ post.like_count }}</b></span>
                          <button nz-button nzType="text" nzShape="circle">
                            <i class="fa-solid fa-comment-dots" (click)="toggleCommentSection(post.diary_post_id)"></i>
    
                          </button>
                          <button nz-button nzType="text" (click)="openShareModal()" nzShape="circle">
                            <i class="fa-solid fa-share-nodes"></i>
                          </button>
                          <button nz-button nzType="text" nzShape="circle">
                            <i class="fa-solid fa-bookmark"></i>
                          </button>
                          <button nz-button nzType="text" nzShape="circle">
                            <i class="fa-solid fa-exclamation"></i>
                          </button>
                          <!-- Pushes the lock icon to the end -->
                          <button *ngIf="post.is_public" nz-button nzType="text" nzShape="circle" class="ms-auto">
                            <i class="fa-solid fa-lock"></i>
                          </button>
                        </div>
                      </div>
                      <div *ngIf="commentVisible[post.diary_post_id]">
                        <nz-comment nzAuthor="{{ post.created_by_name }}" nzDatetime="{{ post.created_at | date:'short' }}">
                          <nz-avatar nz-comment-avatar nzIcon="user" nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"></nz-avatar>
                          <nz-comment-content>
                            <form [formGroup]="postForm" >
                              <nz-input-group [nzSuffix]="suffixIconSearch">
                                <input type="text" nz-input formControlName="comment" placeholder="Enter post comment" />
                              </nz-input-group>
                              <ng-template #suffixIconSearch>
                                <button type="submit" nz-button nzType="dashed" nzShape="circle"><span nz-icon nzType="send"></span></button>
                              </ng-template>
                            </form>
                          </nz-comment-content>
                        </nz-comment>
                      </div>
                    </nz-page-header-content>
                    
                  </nz-page-header>
           
                </div>
              </ng-template>
            </nz-tab>

            <!-- Portfolio Tab -->
            <nz-tab nzTitle="DostPortfolio">
              <ng-template nz-tab>
                DostPortfolio Notes content
              </ng-template>
            </nz-tab>

            <!-- Community Notes Tab -->
            <nz-tab nzTitle="Community Notes">
              <ng-template nz-tab>
                <!-- Community notes content goes here -->
                Community Notes content
              </ng-template>
            </nz-tab>

            <!-- Achievements Tab -->
            <nz-tab nzTitle="Achievements">
              <ng-template nz-tab>
                <!-- Achievements content goes here -->
                Achievements content
              </ng-template>
            </nz-tab>
          </nz-tabset>
  


      </div>

      <!-- Right Sidebar Section -->
      <div class="col-md-4">
        <!-- Bio Section -->
        <nz-card nzTitle="Bio">
          <p>{{ userDetails?.bio }}</p>
        </nz-card>

        <!-- Interested In Section -->
        <nz-card nzTitle="Interested In" class="mt-3">
          <ul class="list-unstyled">
            <li  *ngFor="let intrest of userDetails.interested_in">{{intrest.category_name}}</li>
          </ul>
        </nz-card>

        <!-- Likes Section -->
        <nz-card nzTitle="Likes" class="mt-3">
          <ul class="list-unstyled">
            <li  *ngFor="let intrest of userDetails.likes">{{intrest.tag_name}}</li>
          </ul>
        </nz-card>
      </div>
    </div>
  </div>
  <nz-modal [(nzVisible)]="isModalVisible" nzTitle="Share this Post" (nzOnCancel)="handleCancel()">
    <div nz-modal-body>
      <ng-container *nzModalContent>
        <button nz-button nzType="text" (click)="share('facebook')">
          <i nz-icon nzType="facebook"></i> Facebook
        </button>
        <button nz-button nzType="text" (click)="share('twitter')">
          <i nz-icon nzType="twitter"></i> Twitter
        </button>
        <button nz-button nzType="text" (click)="share('linkedin')">
          <i nz-icon nzType="linkedin"></i> LinkedIn
        </button>
        <button nz-button nzType="text" (click)="share('Instagram')">
          <i nz-icon nzType="instagram"></i> Instagram
        </button>
      </ng-container>

    </div>
    <div nz-modal-footer>
      <button nz-button nzType="default" (click)="handleCancel()">Close</button>
    </div>
  </nz-modal>
</app-main-fiminize-layout>