<nz-layout style="min-height: 100vh;">
  <nz-layout>
    <nz-header>
      <div class="container-fluid d-flex align-items-center">
        <span>
          <img class="me-5 header-logo" [src]="sidebarExpandLogo" alt="Invest Dost Logo">
        </span>
        <!-- 
        <ul nz-menu nzMode="horizontal">
          <li nzMatchRouter routerLink="/admin_dashboard" nz-menu-item>Admin Panel</li>
          <li nzMatchRouter routerLink="/author_dashboard" nz-menu-item>Author Panel</li>
          <li nzMatchRouter routerLink="/subscriber_homepage" nz-menu-item nz-tooltip nzTooltipPlacement="bottom"
            nzTooltipTitle="Logged-In user (with Subscription)">
            Subscriber
          </li>
          <li nzMatchRouter routerLink="/reader_homepage" nz-menu-item nz-tooltip nzTooltipPlacement="bottom"
            nzTooltipTitle="Logged-In user">Reader</li>
        </ul> -->

        <button class="ms-auto" nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="languageDropDownMenu">
          Language
          <img class="ms-2" height="21px" width="21px" src="assets/language-switch.png" />
        </button>

        <nz-dropdown-menu #languageDropDownMenu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item>English</li>
            <li nz-menu-item>French</li>
            <li nz-menu-item>Dutch</li>
          </ul>
        </nz-dropdown-menu>

        <nz-avatar nz-dropdown [nzDropdownMenu]="avatarDropDownMenu"
          class="ms-3 d-flex justify-content-center align-items-center" [nzSize]="40" nzIcon="user"></nz-avatar>

        <nz-dropdown-menu #avatarDropDownMenu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item (click)="underDevelopmentMessage()"><span nz-icon nzType="setting" nzTheme="outline"
                class="me-3"></span> Account Settings</li>
            <li nz-menu-item (click)="underDevelopmentMessage()"><span nz-icon nzType="user" nzTheme="outline"
                class="me-3"></span> My Profile</li>
            <!-- <li nz-menu-item><span nz-icon nzType="crown" nzTheme="outline" class="me-3"></span>Get Subscription</li> -->
            <nz-divider class="m-0"></nz-divider>
            <li nz-menu-item (click)="logout()"><span nz-icon nzType="logout" nzTheme="outline" class="me-3"></span>Logout
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </nz-header>
    <nz-content class="py-3 border-bottom">
      <div class="container-fluid">
        <ng-content></ng-content>
      </div>
    </nz-content>
    <nz-footer>
      <div class="d-flex">
        <span>&#169; {{ currentYear }} Version 1.0.3 </span>
        <span class="ms-auto"> InvestDost | Development | Designed By
          <a class="ms-1 " target="_blank" rel="noopener noreferrer" href="https://portalwiz.com/">Portalwiz
            Technologies</a>
        </span>
      </div>
    </nz-footer>
  </nz-layout>
</nz-layout>