import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { forkJoin } from 'rxjs';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-symbols',
  templateUrl: './symbols.component.html',
  styleUrls: ['./symbols.component.scss'],
})
export class SymbolsComponent implements OnInit {
  allSymbols: any[] = [];
  
  addDrawer: boolean = false;
  editDrawer: boolean = false;
  tableLoading: boolean = true;
  industries: any[] = [];
  exchanges: any[] = [];
  sectors: any[] = [];
  searchQuery: string = '';
  addSymbolForm: FormGroup = new FormGroup({
    user_id: new FormControl('', [Validators.required]),
    symbol: new FormControl(''),
    name: new FormControl(''),
    sector_id: new FormControl(''),
    exchange_id: new FormControl(''),
    industry_id: new FormControl(''),
    active_status_id: new FormControl(''),
  });

  editSymbolForm: FormGroup = new FormGroup({
    user_id: new FormControl('', [Validators.required]),
    symbol: new FormControl(''),
    name: new FormControl(''),
    symbol_id: new FormControl(''),
    sector_id: new FormControl(''),
    exchange_id: new FormControl([]),
    industry_id: new FormControl(''),
    active_status_id: new FormControl(''),
  });

  constructor(
    private apiService: FetchApiService,
    private http: HttpClient,
    private message: NzMessageService,
    private fetchApiService: FetchApiService
  ) {}

  ngOnInit(): void {
    this.fetchAllSymbols();
    this.fetchInitialData();
  }

  fetchInitialData(): Promise<void> {
    this.tableLoading = true;
    return new Promise((resolve, reject) => {
      const industriesDropdown$ = this.fetchApiService.getIndustryDropdown();
      const ExchangeDropdown$ = this.fetchApiService.getExchangeDropdown();
      const SectorDropdown$ = this.fetchApiService.getSectorDropdown();

      forkJoin([
        industriesDropdown$,
        ExchangeDropdown$,
        SectorDropdown$,
      ]).subscribe({
        next: ([indusTriesResponse, exchangereponse, sectorResponse]: any) => {
          console.log('industriesDropdown: ', indusTriesResponse);
          console.log('ExchangeDropdown: ', exchangereponse);
          console.log('SectorDropdown ', sectorResponse);
          this.industries = indusTriesResponse;
          this.exchanges = exchangereponse;
          this.sectors = sectorResponse;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          reject(error);
        },
        complete: () => {
          this.tableLoading = false;
          resolve();
        },
      });
    });
  }


  filterSymbols(): void {
    const query = this.searchQuery.toLowerCase().trim();
    if (query) {
      this.allSymbols = this.allSymbols.filter(symbol =>
        symbol.symbol.toLowerCase().includes(query)
      );
    } else {
      // Reset the symbols array to show all symbols when search query is empty
      this.fetchAllSymbols(); // This will reload the symbols from the API
    }
  }

  fetchAllSymbols(): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}fetch_all_symbol`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          // console.log('Quiz Questions: ', response);
          this.allSymbols = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }

  submitAddForm() {
    const apiUrl = `${environment.API_URL}add_symbol`;
    const requestData = {
      ...this.addSymbolForm.value,
      user_id: localStorage.getItem('userId'),
    };
    console.log(requestData);
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchAllSymbols()
            .then(() => {
              this.message.success('Ticker added');
              this.addSymbolForm.reset();
              this.closeAddDrawer();
            })
            .catch((error) => {
              this.message.error('Error occurred, please try later');
              console.log(error);
            });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
  submitEditForm() {
    const apiUrl = `${environment.API_URL}edit_symbol`;

    const requestData = {
      ...this.editSymbolForm.value,
      user_id: localStorage.getItem('userId'),
    };
    console.log(requestData);
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchAllSymbols().then(() => {
            this.message.success('Ticker updated');
            this.editSymbolForm.reset();
            this.closeEditDrawer();
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
      },
    });
  }
  confirm(id: number): void {
    this.deleteRecord(id);
  }

  deleteRecord(id: number) {
    const apiUrl = `${environment.API_URL}delete_symbol`;

    const requestData = {
      symbol_id: [id],
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchAllSymbols().then(() => {
            this.message.success('Ticker deleted');
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
  cancel(): void {}
  closeEditDrawer(): void {
    this.editDrawer = false;
  }

  openAddDrawer(): void {
    this.addDrawer = true;
  }

  closeAddDrawer() {
    this.addDrawer = false;
  }

  currentEditQuizId?: number;
  openEditDrawer(symbolID: number): void {
    this.currentEditQuizId = symbolID;

    let courseDetails = this.allSymbols.find(
      (allSymbols) => allSymbols.symbol_id === symbolID
    );
    console.log(courseDetails);
    this.editSymbolForm.patchValue(courseDetails!);
    this.editDrawer = true;
  }
  sortByTicker = (a: any, b: any): number => {
    return (a.symbol || '').localeCompare(b.symbol || '');
  };

  sortByStatus = (a: any, b: any): number => {
    return a.active_status_id - b.active_status_id;
  };

  sortBySector = (a: any, b: any): number => {
    return (a.sector || '').localeCompare(b.sector || '');
  };

  sortByExchange = (a: any, b: any): number => {
    return (a.exchange || '').localeCompare(b.exchange || '');
  };

  sortByIndustry = (a: any, b: any): number => {
    return (a.industry || '').localeCompare(b.industry || '');
  };

  sortByCreatedOn = (a: any, b: any): number => {
    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  };

  sortByUpdatedOn = (a: any, b: any): number => {
    return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
  };
}
