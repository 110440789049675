<ng-container >
  <app-admin-layout>
      <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-admin-layout>
</ng-container>

<!-- <ng-template #authorLayout>
  <app-author-layout>
      <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-author-layout>
</ng-template> -->

<ng-template #content>
  <!-- Content specific to both admin and author layouts -->


  <div class="d-flex">
      <nz-breadcrumb>
          <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
          <nz-breadcrumb-item>Content Management</nz-breadcrumb-item>
          <nz-breadcrumb-item>Module</nz-breadcrumb-item>
      </nz-breadcrumb>
      <button class="ms-auto mb-2" nz-button nzType="primary" (click)="openAddDrawer()"><span nz-icon
              nzType="plus"></span>Add Module</button>
  </div>

<nz-card>

    <div class="table-responsive">

        <nz-table #categoryTable [nzData]="modules" nzTableLayout="fixed" [nzLoading]="tableLoading" nzShowSizeChanger>
            <thead>
                <tr>
                    <!-- <th>ID</th> -->
                    <th nzWidth="60px" nzAlign="center"></th>
                    <th>Parent Name</th>
                    <th>Description</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th nzWidth="100px" nzAlign="center">Action</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let category of categoryTable.data">

                    <tr (click)="openEditDrawer(category.module_id)">
                        <td nzAlign="center" [nzExpand]="expandSet.has(category.module_id)" (click)="$event.stopPropagation()"
                            [nzDisabled]="true" (nzExpandChange)="onExpandChange(category.module_id, $event)">
                        </td>
                        <td nzEllipsis>{{ category.module_name }}</td>
                        <td nzEllipsis>{{ category.description }}</td>
                        <td>{{ category.created_at | date: 'dd MMM y, h:mm a' }}</td>
                        <td>{{ category.updated_at | date: 'dd MMM y, h:mm a' }}</td>
                        <td nzAlign="center" (click)="$event.stopPropagation()">
                            <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                nzPopconfirmTitle="Are you sure you want to delete this record?"
                                (nzOnConfirm)="confirm(category.module_id)" (nzOnCancel)="cancel()">
                                <span nz-icon nzType="delete"></span>
                            </button>
                        </td>
                    </tr>
                    <!-- Child Category Section -->
                    <tr [nzExpand]="expandSet.has(category.module_id)">
                        <div (click)="openEditDrawer(category.module_id, child.module_id)" class="hstack gap-2 mb-1"
                            *ngFor="let child of category.child; let i = index;">
                            <span class="ps-1" style="width: 50px;">{{i+1}}.</span>
                            <span class="col-2">{{ child.module_name }}</span>
                            <nz-divider nzType="vertical"></nz-divider>
                            <nz-divider nzType="vertical"></nz-divider>
                            <span class="col-2">{{ child.description ? child.description: '--' }}</span>
                            <nz-divider nzType="vertical"></nz-divider>
                            <div class=" text-center" style="width: 100px;" (click)="$event.stopPropagation()">
                                <button nz-button nzSize="small" nzDanger nzShape="circle" nz-popconfirm
                                    nzPopconfirmTitle="Are you sure you want to delete this record?"
                                    (nzOnConfirm)="confirm(child.module_id)" (nzOnCancel)="cancel()">
                                    <span nz-icon nzType="delete"></span>
                                </button>
                            </div>
                        </div>
                        <span *ngIf="!category.child.length">No child category present!</span>
                    </tr>
                </ng-container>
            </tbody>
        </nz-table>
    </div>

    <nz-drawer [nzVisible]="editDrawer" nzTitle="Edit Modules" (nzOnClose)="closeEditDrawer()">
        <ng-container *nzDrawerContent>

            <form nz-form nzLayout="vertical" [formGroup]="editModulesForm" (ngSubmit)="submitEditForm()">
                <nz-form-item>
                    <nz-form-label>Module Name</nz-form-label>
                    <nz-form-control nzErrorTip="Category Name">
                        <nz-input-group>
                            <input type="text" nz-input formControlName="module_name" required placeholder="Name" />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>


                <nz-form-item>
                    <nz-form-label>Description</nz-form-label>
                    <nz-form-control nzHasFeedback nzErrorTip="Please write something here!">
                        <nz-textarea-count [nzMaxCharacterCount]="2000">
                            <textarea formControlName="description" nz-input rows="5"
                                placeholder="write your description here"></textarea>
                        </nz-textarea-count>
                    </nz-form-control>
                </nz-form-item>

                <button nz-button nzType="primary" type="submit">Submit</button>
            </form>

        </ng-container>
    </nz-drawer>


    <nz-drawer [nzVisible]="addDrawer" nzTitle="Add Modules" (nzOnClose)="closeAddDrawer()">
        <ng-container *nzDrawerContent>

            <form nz-form nzLayout="vertical" [formGroup]="editModulesForm" (ngSubmit)="submitAddForm()">
                <nz-form-item>
                    <nz-form-label nzRequired>Module Name</nz-form-label>
                    <nz-form-control nzErrorTip="module_name">
                        <nz-input-group>
                            <input type="text" nz-input formControlName="module_name" required placeholder="Name" />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label>Parent Module</nz-form-label>

                    <nz-select formControlName="parent_module" nzShowSearch nzAllowClear
                        nzPlaceHolder="Select Category">
                        <nz-option *ngFor="let category of parentModules" [nzLabel]="category.module_name"
                            [nzValue]="category.module_id"></nz-option>
                    </nz-select>

                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzRequired>Is Active?</nz-form-label>
                    <nz-radio-group formControlName="active_status_id">
                        <label nz-radio [nzValue]="1">Yes</label>
                        <label nz-radio [nzValue]="2">No</label>
                    </nz-radio-group>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label>Description</nz-form-label>
                    <nz-form-control nzHasFeedback nzErrorTip="Please write something here!">
                        <nz-textarea-count [nzMaxCharacterCount]="2000">
                            <textarea formControlName="description" nz-input rows="5"
                                placeholder="write your description here"></textarea>
                        </nz-textarea-count>
                    </nz-form-control>
                </nz-form-item>

                <button nz-button nzType="primary" type="submit">Submit</button>
            </form>

        </ng-container>
    </nz-drawer>
</nz-card>

</ng-template>