<app-main-fiminize-layout>
    <nz-breadcrumb>
        <nz-breadcrumb-item><a><span nz-icon nzType="home" nzTheme="outline"></span></a></nz-breadcrumb-item>
        <nz-breadcrumb-item routerLink="/discusstion">IdeaHub</nz-breadcrumb-item>
        <nz-breadcrumb-item>Rooms</nz-breadcrumb-item>
      </nz-breadcrumb>

      <div class="row">
        <h2 class="mt-2">#{{ topics[0]?.room_name  | titlecase}}</h2>
   
        <!-- <div class="col-md-3 my-3" *ngFor="let item of topics">
          <div class="card" [routerLink]="['/discussion-forum',item.topic_id]" style="width:100%; height: 100%; border-radius: 20px;">
            <img class="latest_article_image" alt="example" [src]="storageUrl + item.thumbnail_img" />
            <div class="card-body">
              <div class="title_text">
                <p style="margin-bottom: 0px; font-family: Heading;">{{item.topic_name | truncate:50 | titlecase}}</p>
              </div>
              <div class="description_text">
                <p style="margin-top: 0px;font-size: 14px;">{{item.description | truncate:80}}</p>
              </div>
           
            </div>
          </div>
        </div> -->
        <div class="col-md-4" *ngFor="let item of topics">
          <div [routerLink]="['/discussion-forum',item.topic_id]" class="card bg-white shadow-md rounded-lg my-4" style="height: 90%;">
           <div class="card-header">
            <div class="img-container">
              <img class="latest_article_image" alt="example" [src]="storageUrl + item.thumbnail_img" />
            </div>
           </div>
           <div class="card-body">
            <h1 class="text-overlay">{{ item.topic_name | truncate: 50 | titlecase }}</h1>
            <div class="me-auto">
              <i class="fas fa-eye"> 50</i>
            </div>
            <p class="text-gray-800 mt-4">
              {{item.description}}
            </p>
           </div>
          </div>
        </div>
      </div>
    
</app-main-fiminize-layout>
