<app-main-fiminize-layout>
    <nz-breadcrumb>
        <nz-breadcrumb-item><a><span nz-icon nzType="home" nzTheme="outline"></span></a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Article</nz-breadcrumb-item>
    </nz-breadcrumb>


    <div class="container mt-4">
      <div class="row">
        <div class="col-md-8 ">
          <h2 >{{userDetails.quote}}</h2>
          <div class="bordered-header d-flex align-items-center">
            <nz-page-header >
              <nz-avatar [nzSize]="64" nz-page-header-avatar nzSrc="https://avatars0.githubusercontent.com/u/22736418?s=88&v=4"></nz-avatar>
              <nz-page-header-title>{{userDetails.name}}</nz-page-header-title>
              <nz-page-header-subtitle>{{userDetails.username}}</nz-page-header-subtitle>
            
              <nz-page-header-content>
                <p>{{userDetails.bio}}</p>
                <div >
                  {{userDetails.followers}} Followers | 
                  {{userDetails.articles}} Articles | 
                  {{userDetails.blogs}} Blogs/Fans | 
                  {{userDetails.views}} Views
               
              </div>

              </nz-page-header-content>
           
            </nz-page-header>


          </div>
        </div>
      </div>
      
    
      <!-- Latest and Top Section -->
      <div class="row mt-5">
        <div class="col-md-8">
          <h4>Latest | Top</h4>
          <nz-row [nzGutter]="16">
            <nz-col *ngFor="let item of [1, 2, 3]" [nzSpan]="8">
              <nz-card [nzBordered]="false" class="latest-top-item bg-light" style="height: 150px;">
                <p>Card content here</p>
              </nz-card>
            </nz-col>
          </nz-row>
    
          <h4>Dost Diary Posts</h4>
          <div *ngFor="let post of posts">
            <nz-page-header class="bordered-header" [ngStyle]="{ 'background-color': post.is_featured_post === 1 ? '#FFF9EB' : 'inherit' }">
              <nz-page-header-content>
                <p nz-paragraph [innerHTML]="post.caption"></p>
                <nz-page-header-subtitle>{{ post.created_at | date: 'MMMM yyyy' }}</nz-page-header-subtitle>
                <nz-divider></nz-divider>
                <div class="d-flex align-items-center justify-content-between mt-1">
                  <div class="flex-grow-1 d-flex align-items-center">
                    <button nz-button nzShape="circle" nzType="default" (click)="likeButton(post)">
                      <i [ngClass]="post.like_count > 0 ? 'fa-solid fa-thumbs-up' : 'fa-regular fa-thumbs-up'"
                        [ngStyle]="{ color: post.like_count > 0 ? '#1890ff' : '' }"></i>
                    </button>
                    <span class="me-2"><b>{{ post.like_count }}</b></span>
                    <button nz-button nzType="text" nzShape="circle">
                      <i class="fa-solid fa-comment-dots" (click)="toggleCommentSection(post.diary_post_id)"></i>
                    </button>
                    <button nz-button nzType="text" (click)="openShareModal()" nzShape="circle">
                      <i class="fa-solid fa-share-nodes"></i>
                    </button>
                    <button nz-button nzType="text" nzShape="circle">
                      <i class="fa-solid fa-bookmark"></i>
                    </button>
                    <button nz-button nzType="text" nzShape="circle">
                      <i class="fa-solid fa-exclamation"></i>
                    </button>
                    <!-- Pushes the lock icon to the end -->
                    <button *ngIf="post.is_public" nz-button nzType="text" nzShape="circle" class="ms-auto">
                      <i class="fa-solid fa-lock"></i>
                    </button>
                  </div>
                </div>
                <!-- Comment section -->
                <div *ngIf="commentVisible[post.diary_post_id]">
                  <nz-comment nzAuthor="{{ post.created_by_name }}" nzDatetime="{{ post.created_at | date: 'short' }}">
                    <nz-avatar nz-comment-avatar nzIcon="user" nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"></nz-avatar>
                    <nz-comment-content>
                      <form [formGroup]="postForm">
                        <nz-input-group [nzSuffix]="suffixIconSearch">
                          <input type="text" nz-input formControlName="comment" placeholder="Enter post comment" />
                        </nz-input-group>
                        <ng-template #suffixIconSearch>
                          <button type="submit" nz-button nzType="dashed" nzShape="circle">
                            <span nz-icon nzType="send"></span>
                          </button>
                        </ng-template>
                      </form>
                    </nz-comment-content>
                  </nz-comment>
                </div>
              </nz-page-header-content>
            </nz-page-header>
          </div>
        </div>
    
        <!-- Right Column -->
        <div class="col-md-4">
          <h4>Dost Explains</h4>
          <nz-list>
            <nz-list-item *ngFor="let item of [1, 2, 3]" [ngStyle]="{'height': '40px'}">
              Explanation content
            </nz-list-item>
          </nz-list>
    
          <h4>Quiz Library</h4>
          <nz-list>
            <nz-list-item>- US Election 2024</nz-list-item>
            <nz-list-item>- BTC ETF</nz-list-item>
            <nz-list-item>- ETH ETF</nz-list-item>
          </nz-list>
    
          <h4>Dost Alerts</h4>
          <nz-list>
            <nz-list-item>- Alert 1</nz-list-item>
            <nz-list-item>- Alert 2</nz-list-item>
          </nz-list>
        </div>
      </div>
    </div>
    
    
</app-main-fiminize-layout>