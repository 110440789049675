import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
interface Discussion {
  id: number;
  message: string;
  name: string;
  replies_count: number;
  showInputField?: boolean; // Optional property to control input field visibility
  showComments?: boolean; // Optional property to control showing related comments
}
@Component({
  selector: 'app-dicussiion-forum',
  templateUrl: './dicussiion-forum.component.html',
  styleUrls: ['./dicussiion-forum.component.scss'],
})
export class DicussiionForumComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  topicName: string = '';
  visibleComments = 3;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private apiService: FetchApiService,
    private message: NzMessageService,
    private fb: FormBuilder
  ) {}
  @Output() refresh = new EventEmitter<void>();
  userID = localStorage.getItem('userId');
  showInputField: boolean = false;
  storageUrl = environment.STORAGE_URL;
  forumComments: any[] = [];
  discussions: Discussion[] = [];
  showComment: boolean = false;
  selectedFile: File | null = null;
  ngOnInit(): void {
    this.getAllDiscussion();
  }

  commentForm = new FormGroup({
    message: new FormControl('', Validators.required),
  });
  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0] ?? null;
  }

  uploadImage(): void {
    this.fileInput.nativeElement.click();
  }
  getAllDiscussion(): Promise<any> {
    this._activatedRoute.params.subscribe((params) => {
      console.log(params);
      const topicId = +params['id'];
      console.log(topicId);

      if (topicId) {
        this.apiService.fetchDiscussionForum(topicId).subscribe({
          next: (response: any) => {
            this.topicName = response.topic_name;
            console.log('Response: ', response.data);
            this.forumComments = response.discussions;
          },
          error: (error) => {
            console.error('Error: ', error);
          },
          complete: () => {},
        });
      } else {
        console.log(topicId, ' this data not get');
      }
    });

    return Promise.resolve(true);
  }
  loadMoreComments() {
  
    this.visibleComments += 3; 
}
  addComments(id?: number) {
    console.log('new');

    this._activatedRoute.params.subscribe((params) => {
      console.log(params);
      const roomId = +params['id'];
      console.log(roomId);
      let Obj = {
        topic_id: roomId,
        parent_comment_id: id ?? null,
        message: this.commentForm.value.message,
        commented_by: this.userID,
        image: null
      };
      console.log('OBJ', Obj);

      if (Obj.message !== null) {
        if (roomId && this.userID) {
          this.apiService.adddiscussionCommnets(Obj).subscribe({

            next: (res: any) => {
              this.message.success(res.message);
              this.getAllDiscussion();
              this.commentForm.reset();
              console.log("res",res);
            },
            error: (err: any) => {
              this.message.error("Comment Not Added");
              console.log("errror");
            },
          });
        }
      } else {
        console.log('please insert comments');
      }
    });
  }
  deleteBookmarkedArticle(id: number): void {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      this.message.error('User ID not found.');
      return;
    }

    const obj = { user_id: userId, artical_id: id };
    this.apiService.deleteBookmarkArticles(obj).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.message.success(response.message || 'Bookmark deleted successfully.');
        } else {
          this.message.error('Error occurred while deleting bookmark.');
        }
      },
      error: (error: any) => {
        this.message.error(error.error.message || 'An error occurred while deleting bookmark.');
      }
    });
  }
  addBookmarksComments(id: number): void {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      this.message.error('User ID not found.');
      return;
    }

    const obj = { user_id: userId, comment_id: id };
    this.apiService.addBookmarkDiscussionForumComment(obj).subscribe({
      next: (response: any) => {
        this.message.success(response.message);
        this.getAllDiscussion();
        this.refreshData();
      },
      error: (error: any) => {
        this.message.error(error.error.message || 'An error occurred while adding bookmark.');
      }
    });
  }
  
  refreshData(): void {
   
    this.refresh.emit();
  }
  toggleInputField(discussion: Discussion): void {
    discussion.showInputField = !discussion.showInputField;
  }
}
