import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hyphenatePipe'
})
export class HyphenatePipe implements PipeTransform {

  transform(input: string | null | undefined): string {
    if (!input) {
      return '';
    }
    return input.toLowerCase().split(' ').join('-');
  }

}