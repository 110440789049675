import { Component, Input } from '@angular/core';
import { Article } from 'src/app/Interfaces/Content';

@Component({
  selector: 'app-article-display-style-two',
  templateUrl: './article-display-style-two.component.html',
  styleUrls: ['./article-display-style-two.component.scss']
})
export class ArticleDisplayStyleTwoComponent {
  @Input() article!: Article;

}
