import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  currentYear: number = new Date().getFullYear();
  headerLogo: SafeUrl;
  currentRoute: string = '';

  constructor(private sanitizer: DomSanitizer) {
    this.headerLogo = this.sanitizer.bypassSecurityTrustResourceUrl('assets/InvestDostFull.png');
  }

  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),

  });

  submitForm() {

  }
}
