import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { forkJoin } from 'rxjs';
import { isFormValid, logFormData } from 'src/app/Functions/FormFunctions';
import { Course, Lecture, LectureContentType, LectureResource, Section } from 'src/app/Interfaces/Course';
import { Quiz } from 'src/app/Interfaces/Quiz';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lectures',
  templateUrl: './lectures.component.html',
  styleUrls: ['./lectures.component.scss']
})
export class LecturesComponent {

  courses: Course[] = [];
  lectureContentTypes: LectureContentType[] = [];

  sections: Section[] = [];
  lectures: Lecture[] = [];

  fileList: NzUploadFile[] = [];

  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  lectureResources: LectureResource[] = [];

  tableLoading: boolean = true;
  drawerLoading: boolean = false;

  formSubmitLoading: boolean = false;
  useAdminLayout: boolean = false;

  currentEditQuizId?: number;

  addDrawer: boolean = false;
  editDrawer: boolean = false;
  quizzes: Quiz[] = [];
  lectureResourceDrawer: boolean = false;

  addForm: string = 'addForm';
  editForm: string = 'editForm';
  roleId:any;
  addLectureForm: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    is_active: new FormControl(1),
    quiz_id: new FormControl(null, [Validators.required]),
    content_url: new FormControl({ value: '', disabled: true, }, [Validators.required]),
    content: new FormControl({ value: '', disabled: true, }, [Validators.required]),
    section_id: new FormControl(null, [Validators.required]),
    content_type_id: new FormControl(null, [Validators.required]),
    created_by: new FormControl(sessionStorage.getItem('userId'))
  });

  editLectureForm: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    lecture_id: new FormControl(null, [Validators.required]),
    quiz_id: new FormControl(null, [Validators.required]),
    content: new FormControl({ value: '', disabled: true, }, [Validators.required]),
    content_url: new FormControl({ value: '', disabled: true, }, [Validators.required]),
    is_active: new FormControl(null, [Validators.required]),
    section_id: new FormControl(null, [Validators.required]),
    content_type_id: new FormControl(null, [Validators.required]),
    updated_by: new FormControl(sessionStorage.getItem('userId'))
  });

  addLectureResourceForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    content_type: new FormControl(null, [Validators.required]),
    content_url: new FormControl({ value: '', disabled: true, }, [Validators.required]),
    created_by: new FormControl(sessionStorage.getItem('userId'))
  });


  selectedCourseId?: number;
  selectedSectionId?: number;

  // Used when we open Add Lecture Resource Drawer
  selectedLectureId?: number;

  storageUrl:string = environment.STORAGE_URL;
  
  constructor(private http: HttpClient, private message: NzMessageService, private fetchApiService: FetchApiService,) { }

  ngOnInit() {
    this.roleId = localStorage.getItem('role_id');
    this.useAdminLayout = this.roleId === '1' || this.roleId === '2';
   
    this.fetchInitialData();
    this.fetchQuiz();
  }

  fetchQuiz(): Promise<void> {
    this.tableLoading = true;
    return new Promise((resolve, reject) => {
      const courses$ = this.fetchApiService.getCourses();
      const quizzes$ = this.fetchApiService.getAllQuizzes();

      forkJoin([courses$, quizzes$]).subscribe({
        next: ([coursesResponse, quizzesResponse]: any) => {
          console.log('Courses: ', coursesResponse);
          console.log('Quizzes: ', quizzesResponse);
          this.courses = coursesResponse;
          this.quizzes = quizzesResponse.data;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          reject(error);
        },
        complete: () => {
          this.tableLoading = false;
          resolve();
        },
      });

    });
  }



  fetchInitialData(): Promise<void> {
    this.tableLoading = true;
    return new Promise((resolve, reject) => {
      const courses$ = this.fetchApiService.getCourses();
      const getLectureContentTypes$ = this.fetchApiService.getLectureContentTypes();
      

      forkJoin([courses$, getLectureContentTypes$]).subscribe({
        next: ([coursesResponse, getLectureContentTypesResponse]: any) => {
          console.log('Courses: ', coursesResponse);
          console.log('getLectureContentTypes: ', getLectureContentTypesResponse);

          this.courses = coursesResponse;
          this.lectureContentTypes = getLectureContentTypesResponse;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          reject(error);
        },
        complete: () => {
          this.tableLoading = false;
          resolve();
        },
      });

    });
  }

  fetchCourseSections(courseId: any = this.selectedCourseId): Promise<void> {
    console.log(courseId);
    this.tableLoading = true;

    return new Promise<void>((resolve, reject) => {
      this.fetchApiService.getSections(courseId).subscribe({
        next: (res: any) => {
          console.log('Sections: ', res);
          this.sections = res;
        },
        error: (error: any) => {
          console.log(error);
          this.tableLoading = false;
          reject(error); // Reject the promise on error
        },
        complete: () => {
          this.tableLoading = false;
          resolve(); // Resolve the promise on completion
        }
      });
    });
  }

  fetchLecturesOfSection(sectionId: any = this.selectedSectionId): Promise<void> {
    console.log(sectionId);
    this.tableLoading = true;

    return new Promise<void>((resolve, reject) => {
      this.fetchApiService.getLectures(sectionId).subscribe({
        next: (res: any) => {
          console.log('Lectures: ', res);
          this.lectures = res;
        },
        error: (error: any) => {
          console.log(error);
          this.tableLoading = false;
          reject(error); // Reject the promise on error
        },
        complete: () => {
          this.tableLoading = false;
          resolve(); // Resolve the promise on completion
        }
      });
    });
  }

  fetchLectureResources(lectureId: number) {
    console.log(lectureId);
    this.drawerLoading = true;

    return new Promise<void>((resolve, reject) => {
      this.fetchApiService.getLectureResources(lectureId).subscribe({
        next: (res: any) => {
          console.log('Lecture Resources: ', res);
          this.lectureResources = res;
        },
        error: (error: any) => {
          console.log(error);
          this.drawerLoading = false;
          reject(error); // Reject the promise on error
        },
        complete: () => {
          this.drawerLoading = false;
          resolve(); // Resolve the promise on completion
        }
      });
    });
  }

  openEditDrawer(lectureId: number): void {
    let lectureDetails = this.lectures.find(lecture => lecture.lecture_id === lectureId);
    this.onContentTypeChange(lectureDetails?.content_type_id, this.editForm);

    this.editLectureForm.patchValue({
      ...lectureDetails!,
    });

    console.log(this.editLectureForm.value);
    this.editDrawer = true;
  }

  closeEditDrawer(): void {
    this.editDrawer = false;
    this.currentEditQuizId = undefined;
  }

  openAddDrawer(): void {
    this.addDrawer = true;
    this.addLectureForm.patchValue({ section_id: this.selectedSectionId });
  }

  closeAddDrawer() {
    this.addDrawer = false;
  }

  cancel(): void {
  }

  confirm(id: number): void {
    this.deleteRecord(id);
  }

  deleteLectureResource(id: number): void {
    const apiUrl = `${environment.API_URL}delete_lecture_resource`;
    const requestData = {
      content_id: id,
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchLectureResources(this.selectedLectureId!).then(() => {
              this.message.success('Resource deleted');
            });
          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        }
      });
  };


  onContentTypeChange(selectedValue: any, formType: string): void {
    // console.log('Selected Content Type:', selectedValue, formType);

    const contentControl = formType === this.addForm ? this.addLectureForm.controls['content'] : this.editLectureForm.controls['content'];
    const contentUrlControl = formType === this.addForm ? this.addLectureForm.controls['content_url'] : this.editLectureForm.controls['content_url'];

    if (selectedValue === 1) {
      contentControl.enable();
      contentUrlControl.disable();
    } else {
      contentControl.disable();
      contentUrlControl.enable();
    }
  }

  onResourceTypeChange(selectedValue: number): void {
    const contentUrlControl = this.addLectureResourceForm.controls['content_url'];
    if (selectedValue === 1) {
      contentUrlControl.disable();
    } else {
      contentUrlControl.enable();
    }
  }


  openAddResourceDrawer(lectureId: number): void {
    this.lectureResourceDrawer = true;
    this.selectedLectureId = lectureId;
    this.fetchLectureResources(lectureId);
  };

  closeAddResourceDrawer(): void {
    this.lectureResourceDrawer = false;
  };

  submitAddForm() {
    if (isFormValid(this.addLectureForm)) {
      this.formSubmitLoading = true;
      const apiUrl = `${environment.API_URL}add_lecture`;
      const requestData = {
        ...this.addLectureForm.value,
      };

      console.log(requestData);
      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            console.log('Response: ', response);
            if (response.success) {
              this.fetchLecturesOfSection().then(() => {
                this.message.success('Lecture added');
                this.addLectureForm.reset();
                this.addLectureForm.patchValue({
                  is_active: 1,
                  created_by: sessionStorage.getItem('userId')
                });
                this.closeAddDrawer();
              });

            } else {
              this.message.error('Error occurred, please try later');
            }
            this.formSubmitLoading = false;
          },
          error: (error) => {
            console.error('Error: ', error);
            this.message.error('Error occurred, please try later');
            this.formSubmitLoading = false;
          }
        });
    }
  }

  submitEditForm() {
    if (isFormValid(this.editLectureForm)) {
      this.formSubmitLoading = true;
      const apiUrl = `${environment.API_URL}edit_lecture`;

      const requestData = {
        ...this.editLectureForm.value,
        updated_by: sessionStorage.getItem('userId')
      };

      console.log(requestData);
      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            console.log('Response: ', response);
            if (response.success) {
              this.fetchLecturesOfSection().then(() => {
                this.message.success('Lecture updated');
                this.editLectureForm.reset();
                this.closeEditDrawer();
              });
            } else {
              this.message.error('Error occurred, please try later');
            }
            this.formSubmitLoading = false;
          },
          error: (error) => {
            console.error('Error: ', error);
            this.formSubmitLoading = false;
          }
        });
    }
  }


  submitAddResourceForm() {
    if (isFormValid(this.addLectureResourceForm)) {
      this.drawerLoading = true;
      const apiUrl = `${environment.API_URL}add_lecture_resource`;

      // Create a FormData object
      const formData = new FormData();

      // Append form data values to FormData
      Object.keys(this.addLectureResourceForm.value).forEach(key => {
        formData.append(key, this.addLectureResourceForm.value[key]);
      });

      formData.append('lecture_id', this.selectedLectureId?.toString()!);

      if (this.fileList.length > 0) {
        formData.append('file', this.fileList[0] as any);
      }

      logFormData(formData);

      this.http.post(apiUrl, formData)
        .subscribe({
          next: (response: any) => {
            console.log('API Response:', response);
            if (response.success) {
              this.fetchLectureResources(this.selectedLectureId!).then(() => {
                this.message.success('Resource added');
                this.drawerLoading = false;
              });
            } else {
              this.message.error('Error occurred, please try later');
            }
          },
          error: (error) => {
            console.error('API Error:', error);
            this.message.error('Error occurred, please try later');
            this.drawerLoading = false;
          }
        });
    }
  }


  deleteRecord(id: number) {
    const apiUrl = `${environment.API_URL}delete_lecture`;
    const requestData = {
      lecture_id: id,
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchLecturesOfSection().then(() => {
              this.message.success('Lecture deleted');
            });
          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        }
      });
  }

  // Lecture Table Variables
  LectureTableChecked = false;
  LectureTableIndeterminate = false;
  setOfCheckedLectureTableIds = new Set<number>();

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  };

  onAllChecked(checked: boolean): void {
    const data = this.sections;
    data.forEach(({ section_id }: { section_id: number; }) => this.updateCheckedSet(section_id, checked));
  };

  updateCheckedSet(id: number, checked: boolean): void {
    const setOfCheckedIds = this.setOfCheckedLectureTableIds;
    if (checked) {
      setOfCheckedIds.add(id);
    } else {
      setOfCheckedIds.delete(id);
    }
  };

  refreshCheckedStatus(): void {
    const setOfCheckedIds = this.setOfCheckedLectureTableIds;
    var checked = this.LectureTableChecked;
    var indeterminate = this.LectureTableIndeterminate;

    const listOfEnabledData = this.sections.filter(({ is_active }) => is_active === 1);
    checked = listOfEnabledData.every(({ section_id }) => setOfCheckedIds.has(section_id));
    indeterminate = listOfEnabledData.some(({ section_id }) => setOfCheckedIds.has(section_id)) && !checked;
  }
}
