<div class="row mb-4">
    <div class="col-12 col-md-2">{{ article.publish_date | date: 'YYYY, MMMM dd' }}</div>
    <div class="col-12 col-md-6">
        <div [routerLink]="['/category_articles', article.category_string  | hyphenatePipe, article.category_id]"
            class="category mb-2 pointer">{{ article.category_string }}</div>
        <a [routerLink]="['/article', article.slug, article.id]" class="fw-semibold fs-5 text-black lh-1">{{
            article.title
            }}</a>
        <div [innerHTML]="removeTags(article.content)" class="light-text mb-3"></div>
        <div class="tags mb-4">
            <nz-tag class="rounded-1" *ngFor="let tag of article.tag_id">
                {{ tag }}
            </nz-tag>
        </div>

        <div class="hstack gap-2">
            <nz-avatar [routerLink]="['/author', article.created_by_string | hyphenatePipe, article.created_by]"
                class="pointer" nzSize="large" [nzText]="article.created_by_string.charAt(0).toUpperCase()"></nz-avatar>
            <div [routerLink]="['/author', article.created_by_string | hyphenatePipe, article.created_by]"
                class="pointer">
                <div class="small fw-bold light-text ">{{article.created_by_string}}</div>
                <div class="small fw-bold light-text">InvestDost</div>
            </div>
            <nz-divider nzType="vertical"></nz-divider>
            <div class="hstack gap-2">
                <span nz-icon nzType="like" nzTheme="outline" class="light-text me-1"></span>
                <span nz-icon nzType="comment" nzTheme="outline" class="light-text me-1"></span>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-2">
        <img [routerLink]="['/article', article.slug, article.id]" [nzDisablePreview]="true" nz-image
            [nzFallback]="fallbackImage" class="img-fluid light-shadow pointer" [nzSrc]="randomImageUrl"
            alt="Banner Image">
    </div>
</div>