import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { NzIconService } from 'ng-zorro-antd/icon';
import { NzMessageService } from 'ng-zorro-antd/message';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { sendOtp } from 'src/app/Interfaces/allForm';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
interface Tag {
  tag_id: number;
  tag_name: string;
}
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-main-fiminize-layout',
  templateUrl: './main-fiminize-layout.component.html',
  styleUrls: ['./main-fiminize-layout.component.scss']
})

export class MainFiminizeLayoutComponent implements OnInit{

  size: NzButtonSize = 'small'

  currentYear: number = new Date().getFullYear();
  passwordVisible = false;
  password?: string;
  @Input() isCollapsed: boolean = false;
  roleId =localStorage.getItem('role_id');
  popularTagList: Tag[] = [];

  storageUrl = environment.STORAGE_URL;
   apiUrl = `${environment.API_URL}`;

  loginForm = new FormGroup({
    username: new FormControl('', [
      Validators.required,
      Validators.maxLength(25),
      Validators.pattern('[a-z0-9!@#$%^&*()_+=?<>]*$'),
    ]),

    password: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),

    remember: new FormControl(false),
  } as { [key in keyof sendOtp]: any });

  addDrawer: boolean = false;
  viewDrawer: boolean = false;
  displayName: string | null = '';
  sidebarCollapseLogo: SafeUrl;
  sidebarExpandLogo: SafeUrl;

  constructor(private sanitizer: DomSanitizer, private message: NzMessageService, private router: Router,private http: HttpClient,private apiService: FetchApiService,private iconService: NzIconService) {
    // Create a safe URL for the image file
    this.iconService.fetchFromIconfont({
      scriptUrl: 'https://at.alicdn.com/t/font_8d5l8fzk5b87iudi.js'
    });
    this.sidebarCollapseLogo = this.sanitizer.bypassSecurityTrustResourceUrl('assets/app-icon.png');
    this.sidebarExpandLogo = this.sanitizer.bypassSecurityTrustResourceUrl('assets/InvestDostFull.png');
  }
  ngOnInit(): void {
    this.displayName = localStorage.getItem('displayName');
   this.fetchPopularTags();
  }

  fetchPopularTags(){
    this.apiService.fetchPouplarTags().subscribe({
      next:(response:any)=>{
        console.log('Response: ', response);
        this.popularTagList = Object.values(response.tags);
        
      },
      error:(error)=>{
        console.error('Error: ', error);
        
      },
      complete:()=>{
        
      }
    })
  }

  underDevelopmentMessage(): void {
    this.message.info('Under Development');
  }

  submitForm() {

      if (isFormValid(this.loginForm)) {
        const { username, password } = this.loginForm.value;
    

        const apiUrl = `${environment.API_URL}log_in`;
        console.log(username,password);
        

        try {
          this.http
            .post(apiUrl, { username, password })
            .subscribe({
              next: (response: any) => {
                console.log(response);
                if (response.success) {
                  this.message.success(response.message);
                  localStorage.setItem('role_id', response.role_id);
                  localStorage.setItem('userId', response.user_id);
                  localStorage.setItem('username', response.username);
                  localStorage.setItem('email', response.email);
                  localStorage.setItem('phone', response.phone);
                  localStorage.setItem('displayName', `${response.fname} ${response.lname}`);
                  localStorage.setItem('profile_pic', response.profile_pic);
                  localStorage.setItem('token', response.token);
                  localStorage.setItem('permission', response.permissionCodes);
                  localStorage.setItem('subscription_plan_id', response.subscription_plan_id);
                  localStorage.setItem('isLoggedIn', 'true');
                  this.router.navigateByUrl('/home-page');
                  window.location.reload();
                  this.closeAddDrawer();



                } else {
                  this.message.success(response.message);
                }
              },
              error: (error) => {
                console.error('Login API error:', error);
                this.message.error('Login failed');
              },

            });
        } catch (error) {
          console.error('Error in API call:', error);
          this.message.error('Login failed');
        }
      }
     else {
      this.message.success('The passwords do not match. Please try again.');
    }
  }





  logout() {

    localStorage.clear();
    localStorage.setItem('isLoggedIn', 'false');
    // this.router.navigate(['/']);
    this.router.navigateByUrl('/');
    window.location.reload();
  }


  openAddDrawer(): void {
    this.addDrawer = true;
  }

  closeAddDrawer() {
    this.addDrawer = false;
  }
}
