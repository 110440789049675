import { Component, Input, OnInit } from '@angular/core';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-all-courses',
  templateUrl: './all-courses.component.html',
  styleUrls: ['./all-courses.component.scss']
})
export class AllCoursesComponent implements OnInit{
  @Input() articleData: any[] = [];
  @Input() title: string = '';
  @Input() description: string = '';
  constructor(private fetchData:FetchApiService ){}
  ngOnInit(): void {
    this.fetchAllCourses()
  }
  coursesList:any=[];
  storageUrl = environment.STORAGE_URL;

  fetchAllCourses(): Promise<any> {
  
    this.fetchData.fetchAllcourses().subscribe
      ({
        next: (response: any) => {
          this.coursesList= response;
          console.log('Response: ', this.coursesList);
        },
        error: (error:any) => {
          console.error('Error: ', error);
        },
        complete:()=> {
       
        },
      });
    return Promise.resolve(true);
  }
}
