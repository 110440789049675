import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { forkJoin, interval } from 'rxjs';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { Question, Quiz, QuizCategory, QuizSubCategory } from 'src/app/Interfaces/Quiz';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quiz-categories',
  templateUrl: './quiz-categories.component.html',
  styleUrls: ['./quiz-categories.component.scss']
})
export class QuizCategoriesComponent {
  useAdminLayout: boolean = false; // Default to non-admin layout
  searchQuery: string = '';
  roleId:any;
  quizzes: Quiz[] = [];
  questions: Question[] = [];
  allcategories: any = [];
  treeSelectExpandKeys: string[] = [];
  categories: any = [];
  subCategories: any = [];
  quizLevel:any=[]
  tableLoading: boolean = true;
  formSubmitLoading: boolean = false;
  constructor(private http: HttpClient, private apiService: FetchApiService, private message: NzMessageService) { }

  ngOnInit() {
    this.roleId = localStorage.getItem('role_id');
    this.useAdminLayout = this.roleId === '1' || this.roleId === '2';
   this.fetchInitialData()
  }


  fetchInitialData(): Promise<void> {
    this.tableLoading = true;
    return new Promise((resolve, reject) => {
      const getCategories$ = this.apiService.getQuizCategories();
      const getSubCategories$ = this.apiService.getQuizSubCategories();
      const quizzes$ = this.apiService.getQuizOftheDay();
      const quizzesLevel$ = this.apiService.getQuizLevel();
      

      forkJoin([getCategories$, getSubCategories$, quizzes$,quizzesLevel$]).subscribe({
        next: ([categoriesResponse, subCategoriesResponse, quizzesResponse, quizLevel]: any) => {

          this.categories = categoriesResponse.data;
          this.subCategories = subCategoriesResponse.data;
          this.quizzes = quizzesResponse.data;
          this.quizLevel = quizLevel;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          reject(error);
        },
        complete: () => {
          this.tableLoading = false;
          resolve();
        },
      });

    });
  }
  searchQuizzes(): void {
    if (this.searchQuery.trim() === '') {

      this.fetchInitialData();
    } else {

      this.quizzes = this.quizzes.filter(quiz =>
        quiz.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  }
}
