import { Component, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { removeHtmlTags } from 'src/app/Functions/Sanitize';
import { Article } from 'src/app/Interfaces/Content';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {

  allPublishedArticles: Article[] = [];

  featuredArticles: Article[] = [];
  editorsPicksArticles: Article[] = [];
  trendingArticles: Article[] = [];
  latestArticles: Article[] = [];

  isLoading: boolean = true;

  @ViewChild('screen', { static: true }) screen: any;

  ngOnInit() {
    this.fetchInitialData();
    sessionStorage.setItem('isLoggedIn', 'false');
  }

  constructor(private sanitizer: DomSanitizer, private fetchApiService: FetchApiService) { }

  removeTags(content: string) {
    return removeHtmlTags(this.sanitizer, content);
  }

  // Define a BehaviorSubject to signal when all API calls are done
  private allApiCallsCompletedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);


  fetchInitialData() {
    this.isLoading = true;
    const allPublishedArticles$ = this.fetchApiService.getAllPublishedArticles();
    const featuredArticles$ = this.fetchApiService.getFeaturedArticles();
    const editorsPicksArticles$ = this.fetchApiService.getEditorsPicks();
    const trendingArticles$ = this.fetchApiService.getTrendingArticles();
    const latestArticles$ = this.fetchApiService.getLatestArticles();

    forkJoin([allPublishedArticles$, featuredArticles$, editorsPicksArticles$, trendingArticles$, latestArticles$]).subscribe((results) => {
      this.allPublishedArticles = results[0].data;
      this.featuredArticles = results[1].data;
      this.editorsPicksArticles = results[2].data;
      this.trendingArticles = results[3].data;
      this.latestArticles = results[4].data;
      console.log(results);
      this.isLoading = false;

      // All API calls have completed; signal that they are done
      this.allApiCallsCompletedSubject.next(true);
    });
  }

}
