<ng-container >
  <app-admin-layout>
      <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-admin-layout>
</ng-container>

<!-- <ng-template #authorLayout>
  <app-author-layout>
      <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-author-layout>
</ng-template> -->
<ng-template #content>
  <!-- Content specific to both admin and author layouts -->
  <div class="d-flex">
      <nz-breadcrumb>
          <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
          <nz-breadcrumb-item>Permissions</nz-breadcrumb-item>
          <nz-breadcrumb-item>Roles</nz-breadcrumb-item>
      </nz-breadcrumb>
      <button class="ms-auto mb-2"  nz-button nzType="primary"  (click)="addrole()"><span nz-icon
              nzType="plus" ></span>Add role</button>
  </div>
<div class="row">
  <div  class="col-md-{{ ShortLongTable ? 8 : 12 }}">
    <nz-card>
      <div class="table-responsive">
          <nz-table #articlesTable [nzData]="viewRoles" nzTableLayout="fixed" [nzLoading]="tableLoading"
              nzShowSizeChanger>
              <thead>
                  <tr>
                      <th>Role Name</th>
                      <th>Active</th>
                      <th>Description</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th>Created By</th>
                      <th>Updated By</th>
                      <th nzWidth="100px" nzAlign="center">Action</th> <!-- Add an Action column -->
                  </tr>
              </thead>
              <tbody>
                <!-- (click)="openEditDrawer(viewRoles.role_id)" -->
                  <tr *ngFor="let viewRoles of articlesTable.data"  (click)="editrole(viewRoles.role_id)">
                      <td nzEllipsis>{{ viewRoles.role_name }}</td>
                      <td nzEllipsis>{{ viewRoles.active_status_id === 1 ? 'Yes' : ' No' }}</td>
                      <td>{{ viewRoles.description }}</td>
                      <td>{{ viewRoles.created_at | date: 'yyyy-MM-dd' }}</td>
                      <td>{{ viewRoles.updated_at | date: 'yyyy-MM-dd' }}</td>
                      <td nzEllipsis>{{ viewRoles.created_by_string }}</td>
                      <td nzEllipsis>{{ viewRoles.updated_by_string }}</td>
                      <td nzAlign="center" (click)="$event.stopPropagation()">
                          <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                              nzPopconfirmTitle="Are you sure you want to delete this record?"
                              (nzOnConfirm)="confirm(viewRoles.role_id)" >
                              <span nz-icon nzType="delete"></span>
                          </button>
                      </td>
                  </tr>
              </tbody>
          </nz-table>
      </div>
  </nz-card>
  </div>
  <div  *ngIf="ShortLongTable" class="col-md-4">
    <nz-card>
      <ng-container >
        <nz-spin  class="position-absolute start-50 top-50" nzSimple></nz-spin>
        <form  [formGroup]="editRolesForm"  nz-form
            nzLayout="vertical">
            <p *ngIf="this._addrole" >Add role</p>
              <p *ngIf="this._editrole">Update role</p>
            <nz-card>
              <button *ngIf="this._addrole"  nz-button nzType="primary" type="submit" (click)="roleAdded()">Add role</button>
              <button *ngIf="this._editrole"  nz-button nzType="primary" type="submit" (click)="roleUpdated()">Update role</button>
              <button class="ms-3" nz-button nzType="default" type="button" (click)="closed()">Close
                  </button>
              <nz-divider></nz-divider>
              <nz-form-item>
                <nz-form-label nzRequired>Is Active?</nz-form-label>
                <nz-radio-group formControlName="active_status_id">
                    <label nz-radio [nzValue]="2">No</label>
                    <label nz-radio [nzValue]="1">Yes</label>
                </nz-radio-group>
            </nz-form-item>
              <nz-form-item>
                  <nz-form-label nzRequired>role Name</nz-form-label>
                  <nz-form-control>
                      <nz-input-group>
                          <input type="text" nz-input formControlName="role_name"
                              placeholder="Enter role Name" />
                      </nz-input-group>
                  </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                  <nz-form-label>Description</nz-form-label>
                  <nz-form-control>
                      <nz-input-group>
                          <textarea rows="5" nz-input formControlName="description"
                              placeholder="Enter Description"></textarea>
                      </nz-input-group>
                  </nz-form-control>
              </nz-form-item>
          </nz-card>
        </form>
    </ng-container>
    </nz-card>
  </div>
</div>

</ng-template>
