<div class="container d-flex flex-column align-items-center justify-content-center" style="height: 90vh;">
    <div class="card ">
        <div class="text-center">
            <img src="assets\InvestDostFull.png" alt="Brand Logo" class="mb-3" style="max-width: 100px;">
            <!-- Adjust max-width as needed -->
            <p>You need a password to view this site.</p>
            <nz-input-group [nzPrefix]="prefixIcon" [nzSuffix]="suffixIconSearch">
                <input
                  [(ngModel)]="password"
                  nzSize="large"
                  [type]="passwordVisible ? 'text' : 'password'"
                  nz-input
                  placeholder="Enter Password"
                />
              </nz-input-group>
              <ng-template #prefixIcon>
             <i class="fa-solid fa-lock"></i>
              </ng-template>
              <ng-template #suffixIconSearch>
                <span (click)="togglePasswordVisibility()" nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" nzTheme="outline"></span>
              </ng-template>
            <button nzShape="round" (click)="submitPassword()"><b>Submit</b></button>
        </div>
    </div>
</div>

<nz-footer>
    <div class="d-flex">
        <span>&#169; {{ currentYear }} Version 1.0.3 </span>
        <span class="ms-auto"> InvestDost | Development | Designed By
            <a class="ms-1 " target="_blank" rel="noopener noreferrer" href="https://portalwiz.com/">Portalwiz
                Technologies</a>
        </span>
    </div>
</nz-footer>