<ng-container [ngSwitch]="userRole">
    <ng-container *ngSwitchCase="'admin'">
        <app-admin-layout>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </app-admin-layout>
    </ng-container>
    <ng-container *ngSwitchCase="'author'">
        <app-author-layout>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </app-author-layout>
    </ng-container>
    <ng-container *ngSwitchCase="'reader'">
        <app-reader-layout>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </app-reader-layout>
    </ng-container>
    <ng-container *ngSwitchCase="'subscriber'">
        <app-subscriber-layout>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </app-subscriber-layout>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <app-home-page-layout>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </app-home-page-layout>
    </ng-container>
</ng-container>


<ng-template #content>
    <nz-spin [ngClass]="{'spinner-with-overlay': isLoading}" [nzSpinning]="isLoading" nzSize="large"></nz-spin>

    <nz-card>
        <div class="row">
            <div class="col-12">
                <nz-breadcrumb>
                    <nz-breadcrumb-item>
                        <a (click)="goBack()">Back</a>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>Category</nz-breadcrumb-item>
                    <nz-breadcrumb-item>{{categoryString| dehyphenate}}</nz-breadcrumb-item>
                </nz-breadcrumb>
                <nz-divider></nz-divider>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-9">

                <div class="row">
                    <app-section-heading title="Related Articles"></app-section-heading>


                    <ng-container *ngIf="categoryRelatedArticles.length === 0">
                        <nz-empty></nz-empty>
                    </ng-container>

                    <div class="col-12 col-md-3" *ngFor="let article of categoryRelatedArticles; let i = index;">
                        <app-article-display-style-one [article]="article"></app-article-display-style-one>
                    </div>
                </div>

            </div>

            <div class="col-12 col-md-3">

                <app-section-heading title="Editor's Picks"></app-section-heading>
                <ng-container *ngIf="isLoading">
                    <nz-empty></nz-empty>
                </ng-container>

                <nz-list>
                    <nz-list-item *ngFor="let article of editorsPicksArticles; let i = index;">
                        <app-article-display-style-two [article]="article"></app-article-display-style-two>
                    </nz-list-item>
                </nz-list>
            </div>
        </div>


    </nz-card>

</ng-template>