<ng-container>
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>

<ng-template #content>
    <div class="d-flex mb-2">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Course Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Courses</nz-breadcrumb-item>
        </nz-breadcrumb>
        <button class="ms-auto" nz-button nzType="primary" (click)="openAddDrawer()"><span nz-icon nzType="plus"></span>Add Course</button>
    </div>
    <div class="row">
        <div class="col-md-6 mb-3">
            <nz-input-group [nzSuffix]="suffixIconSearch">
                <input type="text" nz-input placeholder="Search Courses" [(ngModel)]="searchQuery"
                    (ngModelChange)="searchCourses()">
            </nz-input-group>
            <ng-template #suffixIconSearch>
                <span nz-icon nzType="search"></span>
            </ng-template>

        </div>
    </div>

    <nz-card>
        <div class="table-responsive">

            <nz-table #courseTable [nzData]="filteredCourses" nzTableLayout="fixed" [nzLoading]="tableLoading"
                nzShowSizeChanger>
                <thead>
                    <tr>
                        <th nzWidth="100px" nzAlign="center">Action</th>
                        <th>Sr.no</th>
                        <th [nzSortFn]="sortTitle">Title</th>
                        <th [nzSortFn]="sortCategory">Category</th>
                        <th [nzSortFn]="sortQuizName">Quiz Name</th>
                        <th [nzSortFn]="sortTickerName">Ticker Name</th>
                        <th [nzSortFn]="sortTags">Tags</th>
                        <th [nzSortFn]="sortStatus">Status</th>
                        <th [nzSortFn]="sortCreatedBy">Created By</th>
                        <th [nzSortFn]="sortCreatedAt">Created At</th>
                        <th [nzSortFn]="sortUpdatedAt">Updated At</th>
                        <th [nzSortFn]="sortUpdatedBy">Updated By</th>
                        <th nzWidth="100px" nzAlign="center">Action</th>
                      </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let course of courseTable.data;let i = index">

                        <tr (click)="openEditDrawer(course.course_id)">
                            <td nzAlign="center" (click)="$event.stopPropagation()">
                                <a [routerLink]="['/course',  'preview', course.course_slug, course.course_id]"
                                    target="_blank" nz-button nzType="default" nzShape="circle"
                                    nz-tooltip="Launch Preview" class="me-2" nzTooltipPlacement="right">
                                    <span nz-icon nzType="play-circle"></span>
                                </a>
                            </td>
                            <td>{{ i + 1 }}</td>
                            <td>{{ course.title }}</td>
                            <td>{{ course.category_name ? course.category_name: '--'}}</td>
                            <td>{{ course.quiz_name ? course.quiz_name: '--'}}</td>
                            <td>{{ course.symbol_name ? course.symbol_name: '--'}}</td>
                            <td>{{ course.tags }}</td>
                            <td>{{ course.is_active === 1? 'Active': 'Inactive' }}</td>
                            <td>{{ course.created_by_name ? course.created_by_name: '--' }}</td>
                            <td>{{ course.created_at | date: 'dd MMM yyyy' }}</td>
                            <td>{{ course.updated_at | date: 'dd MMM yyyy'}}</td>
                            <td>{{ course.updated_by_name }}</td>
                            <td nzAlign="center" (click)="$event.stopPropagation()">
                                <a [routerLink]="['/course-hierarchy', course.course_slug, course.course_id]"
                                    target="_blank" nz-button nzType="default" nzShape="circle"
                                    nz-tooltip="View Course Hierarchy" class="me-2" nzTooltipPlacement="left">
                                    <span nz-icon nzType="apartment"></span>
                                </a>

                                <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                    nzPopconfirmTitle="Are you sure you want to delete this record?"
                                    (nzOnConfirm)="confirm(course.course_id)" (nzOnCancel)="cancel()">
                                    <span nz-icon nzType="delete"></span>
                                </button>
                            </td>
                        </tr>

                    </ng-container>
                </tbody>
            </nz-table>

        </div>

        <nz-drawer nzWidth="50%" [nzVisible]="addDrawer" nzTitle="Add Course" (nzOnClose)="closeAddDrawer()"
            [nzFooter]="AddFormFooterTpl">
            <ng-container *nzDrawerContent>
                <form id="add-form" nz-form nzLayout="vertical" [formGroup]="addCourseForm"
                    (ngSubmit)="submitAddForm()">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label nzRequired>Title</nz-form-label>
                                <nz-form-control nzErrorTip="Enter Title">
                                    <nz-input-group>
                                        <input type="text" nz-input formControlName="title" required
                                            placeholder="Course title" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Select category</nz-form-label>
                                <nz-form-control nzErrorTip="Please select category!">
                                    <nz-tree-select [nzDefaultExpandAll]="true" nzShowSearch [nzAllowClear]="true"
                                        [nzMultiple]="true" [nzExpandedKeys]="treeSelectExpandKeys"
                                        [nzNodes]="allcategories" nzShowSearch nzPlaceHolder="Select Category"
                                        formControlName="category_id"></nz-tree-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                    <nz-form-label>Select Ticker</nz-form-label>
                                    <nz-select formControlName="symbol_id"
                                    nzMode="multiple" nzPlaceHolder="Please select">
                                        <nz-option *ngFor="let item of allSymbols"
                                            [nzValue]="item.symbol_id" [nzLabel]="item.symbol"></nz-option>
                                    </nz-select>
                            </nz-form-item>
                        </div>
                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Add a Main Quiz for this course <span nz-popover
                                        [nzPopoverOverlayStyle]="{width:'25rem'}" nzPopoverPlacement="topLeft"
                                        [nzPopoverContent]='contentTemplate' class="ms-2" nz-icon
                                        nzType="question-circle" nzTheme="outline"></span></nz-form-label>
                                <nz-select nzAllowClear class="w-100" nzShowSearch nzAllowClear
                                    nzPlaceHolder="Select Main Quiz" formControlName="quiz_id">
                                    <nz-option *ngFor="let quiz of quizzes" [nzLabel]="quiz.title"
                                        [nzValue]="quiz.id"></nz-option>
                                </nz-select>
                            </nz-form-item>
                            <ng-template #contentTemplate>
                                <span>This quiz serves as the <strong>Primary Assessment</strong> for learners who
                                    have a comprehensive
                                    understanding of the course. It can also be referred to as the <strong>Final
                                        Quiz</strong> for
                                    the course.</span>
                            </ng-template>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Tags <span nz-popover [nzPopoverOverlayStyle]="{width:'25rem'}"
                                        nzPopoverPlacement="topLeft"
                                        nzPopoverContent="You can add multiple tags by typing a tag and pressing the 'Enter' key."
                                        class="ms-2" nz-icon nzType="question-circle"
                                        nzTheme="outline"></span></nz-form-label>

                                <nz-select nzMode="tags"
                                    nzPlaceHolder="To add tags, simply type a tag and press 'Enter'."
                                    formControlName="tags">
                                </nz-select>
                            </nz-form-item>
                        </div>

                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Course status?</nz-form-label>
                                <nz-radio-group formControlName="is_active">
                                    <label nz-radio [nzValue]="1">Active</label>
                                    <label nz-radio [nzValue]="0">Inactive</label>
                                </nz-radio-group>
                            </nz-form-item>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Description</nz-form-label>
                                <nz-form-control nzHasFeedback nzErrorTip="Please write something here!">
                                    <nz-textarea-count [nzMaxCharacterCount]="5000">
                                        <textarea formControlName="description" nz-input rows="5"
                                            placeholder="Write your description here"></textarea>
                                    </nz-textarea-count>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Prerequisites</nz-form-label>
                                <nz-form-control nzHasFeedback nzErrorTip="Please write something here!">
                                    <nz-textarea-count [nzMaxCharacterCount]="5000">
                                        <textarea formControlName="prerequisites" nz-input rows="5"
                                            placeholder="Write prerequisites here"></textarea>
                                    </nz-textarea-count>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                    </div>
                </form>
            </ng-container>

            <ng-template #AddFormFooterTpl>
                <div style="float: right">
                    <button nz-button style="margin-right: 8px;" (click)="closeAddDrawer()">Cancel</button>
                    <button nz-button nzType="primary" [nzLoading]="formSubmitLoading" form="add-form"
                        type="submit">Submit</button>
                </div>
            </ng-template>
        </nz-drawer>

        <nz-drawer nzWidth="50%" [nzVisible]="editDrawer" nzTitle="Edit Course" (nzOnClose)="closeEditDrawer()"
            [nzFooter]="EditFormFooterTpl">
            <ng-container *nzDrawerContent>
                <form id="add-form" nz-form nzLayout="vertical" [formGroup]="editCourseForm"
                 >
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label nzRequired>Title</nz-form-label>
                                <nz-form-control nzErrorTip="Enter Title">
                                    <nz-input-group>
                                        <input type="text" nz-input formControlName="title" required
                                            placeholder="Course title" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div class="col-12 col-md-6">
                            <nz-form-control nzErrorTip="Please select category!">
                                <nz-form-label>Select category</nz-form-label>
                                <nz-tree-select [nzDefaultExpandAll]="true" nzShowSearch [nzAllowClear]="true"
                                    [nzMultiple]="true" [nzExpandedKeys]="treeSelectExpandKeys"
                                    [nzNodes]="allcategories" nzShowSearch nzPlaceHolder="Select Category"
                                    formControlName="category_id"></nz-tree-select>
                            </nz-form-control>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Select Ticker</nz-form-label>
                                <nz-select formControlName="symbol_id"
                                nzMode="multiple" nzPlaceHolder="Please select">
                                    <nz-option *ngFor="let item of allSymbols"
                                        [nzValue]="item.symbol_id" [nzLabel]="item.symbol"></nz-option>
                                </nz-select>
                        </nz-form-item>
                        </div>
                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Add a Main Quiz for this course <span nz-popover
                                        [nzPopoverOverlayStyle]="{width:'25rem'}" nzPopoverPlacement="topLeft"
                                        [nzPopoverContent]='contentTemplate' class="ms-2" nz-icon
                                        nzType="question-circle" nzTheme="outline"></span></nz-form-label>
                                <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Main Quiz"
                                    formControlName="quiz_id">
                                    <nz-option *ngFor="let quiz of quizzes" [nzLabel]="quiz.title"
                                        [nzValue]="quiz.id"></nz-option>
                                </nz-select>
                            </nz-form-item>
                            <ng-template #contentTemplate>
                                <span>This quiz serves as the <strong>Primary Assessment</strong> for learners who
                                    have a comprehensive
                                    understanding of the course. It can also be referred to as the <strong>Final
                                        Quiz</strong> for
                                    the course.</span>
                            </ng-template>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Tags <span nz-popover [nzPopoverOverlayStyle]="{width:'25rem'}"
                                        nzPopoverPlacement="topLeft"
                                        nzPopoverContent="You can add multiple tags by typing a tag and pressing the 'Enter' key."
                                        class="ms-2" nz-icon nzType="question-circle"
                                        nzTheme="outline"></span></nz-form-label>

                                <nz-select nzMode="tags"
                                    nzPlaceHolder="To add tags, simply type a tag and press 'Enter'."
                                    formControlName="tags">
                                </nz-select>
                            </nz-form-item>
                        </div>

                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Course status?</nz-form-label>
                                <nz-radio-group formControlName="is_active">
                                    <label nz-radio [nzValue]="1">Active</label>
                                    <label nz-radio [nzValue]="0">Inactive</label>
                                </nz-radio-group>
                            </nz-form-item>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Description</nz-form-label>
                                <nz-form-control nzHasFeedback nzErrorTip="Please write something here!">
                                    <nz-textarea-count [nzMaxCharacterCount]="5000">
                                        <textarea formControlName="description" nz-input rows="5"
                                            placeholder="Write your description here"></textarea>
                                    </nz-textarea-count>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Prerequisites</nz-form-label>
                                <nz-form-control nzHasFeedback nzErrorTip="Please write something here!">
                                    <nz-textarea-count [nzMaxCharacterCount]="5000">
                                        <textarea formControlName="prerequisites" nz-input rows="5"
                                            placeholder="Write prerequisites here"></textarea>
                                    </nz-textarea-count>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                    </div>
                </form>
            </ng-container>
            <ng-template #EditFormFooterTpl>
                <div style="float: right">
                    <button nz-button style="margin-right: 8px;" (click)="closeEditDrawer()">Cancel</button>
                    <button (click)="submitEditForm()" nz-button nzType="primary" [nzLoading]="formSubmitLoading" form="edit-form"
                        type="submit">Submit</button>
                </div>
            </ng-template>
        </nz-drawer>
    </nz-card>

</ng-template>