import { Component } from '@angular/core';

@Component({
  selector: 'app-course-hierarchy',
  templateUrl: './course-hierarchy.component.html',
  styleUrls: ['./course-hierarchy.component.scss']
})
export class CourseHierarchyComponent {

}
