<div class="vstack mb-3">
  <img [routerLink]="['/article', article.slug, article.id]" [nzDisablePreview]="true" nz-image
    class="img-fluid light-shadow pointer" *ngIf="showBannerImage" [nzSrc]="randomImageUrl" alt="Banner Image"
    [nzFallback]="fallbackImage">
  <a class="text-black fw-semibold mt-3 mb-2" [routerLink]="['/article', article.slug, article.id]"
    [ngClass]="{'fs-3': centerAlign, 'text-center': centerAlign}">{{
    article.title
    }}</a>
  <div [routerLink]="['/author', article.created_by_string | hyphenatePipe, article.created_by]"
    class="small fw-semibold pointer" [ngClass]="{'text-center': centerAlign}">{{ article.created_by_string }},
    InvestDost
  </div>
</div>
