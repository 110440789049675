import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dost-portfolio',
  templateUrl: './dost-portfolio.component.html',
  styleUrls: ['./dost-portfolio.component.scss']
})
export class DostPortfolioComponent {
  userId = localStorage.getItem('userId');
  sentiments: any[] = [];
  risks: any[] = [];
  horizons: any[] = [];
  trades: any[] = [];
  tickers: any[] = [];
  watchLists: any[] = [];
  watchlistTickers: any[] = [];
  drawerVisible = false;
  addTickerForm: FormGroup;

  constructor(
    private message: NzMessageService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private drawerService: NzDrawerService,
    private fb: FormBuilder
  ) {
    this.addTickerForm = this.fb.group({
      symbol_id: [null, [Validators.required]],
      sentiment_id: [null, [Validators.required]],
      risk_id: [null, [Validators.required]],
      horizon_id: [null, [Validators.required]],
      trade_id: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.fetchWatchLists();
  }

  fetchWatchLists() {
    forkJoin([
      this.http.get(`${environment.API_URL}sentiment_dropdown`),
      this.http.get(`${environment.API_URL}risk_dropdown`),
      this.http.get(`${environment.API_URL}horizon_dropdown`),
      this.http.get(`${environment.API_URL}trade_dropdown`),
      this.http.get(`${environment.API_URL}ticker_dropdown`),
      this.http.post(`${environment.API_URL}fetch_watch_lists`, { user_id: this.userId })
    ]).subscribe(
      ([
        sentiments,
        risks,
        horizons,
        trades,
        tickers,
        watchLists
      ]) => {
        this.sentiments = sentiments as any[];
        this.risks = risks as any[];
        this.horizons = horizons as any[];
        this.trades = trades as any[];
        this.tickers = tickers as any[];
        this.watchLists = watchLists as any[];

        if (this.watchLists.length > 0) {
          this.fetchWatchlistTickers(this.watchLists[0].watch_list_id);
        }
      },
      error => this.message.error('Failed to fetch data.')
    );
  }

  fetchWatchlistTickers(watchListId: number) {
    this.http.post(`${environment.API_URL}fetch_watchlist_tickers`, { watch_list_id: watchListId })
      .subscribe(
        (data: any) => {
          this.watchlistTickers = data;
        },
        error => this.message.error('Failed to fetch watchlist tickers.')
      );
  }

  openDrawer(): void {
    this.drawerVisible = true;
  }

  closeDrawer(): void {
    this.drawerVisible = false;
    this.addTickerForm.reset();
  }

  addTicker(): void {
    if (this.addTickerForm.valid) {
      const newTicker = {
        user_id: this.userId, 
        watch_list_id: 1, 
        symbol_id: this.addTickerForm.value.symbol_id,
        sentiment_id: this.addTickerForm.value.sentiment_id,
        date: new Date().toISOString().split('T')[0], 
        risk_id: this.addTickerForm.value.risk_id,
        horizon_id: this.addTickerForm.value.horizon_id,
        trade_id: this.addTickerForm.value.trade_id,
      };
  
      // Log the data for debugging purposes
      console.log('New Ticker:', newTicker);
  
      // Make the POST request to the add_watch_list API
      this.http.post(`${environment.API_URL}add_watch_list`, newTicker).subscribe({
        next: (response) => {
          console.log('API Response:', response);
          this.message.success('Ticker added successfully!');
          this.closeDrawer(); // Close the drawer after successful addition
          // Optionally, fetch the updated watchlist or refresh the table data
          this.fetchWatchLists();
        },
        error: (error) => {
          console.error('Error adding ticker:', error);
          this.message.error('Failed to add the ticker. Please try again.');
        }
      });
    } else {
      // Show error if form is not valid
      this.message.error('Please fill in all required fields.');
    }
  }
}
