import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {Observable, Subject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class FetchApiService {

  constructor(private http: HttpClient) { }
  baseUrl: string = `${environment.API_URL}`;

  fetchPouplarTags(){
    return this.http.get(`${this.baseUrl}get_popular_tags`);
  }
  // ===============================================
  // Article Module
  //fetch  ideahub All dropdowns Articles

  fetchDropdowns(): Observable<any> {
    return this.http.get(`${this.baseUrl}fetch_ideahub_dropdown`);
  }
  fetchAllMarkupLines(): Observable<any> {
    return this.http.get(`${this.baseUrl}fetch_markup_line`);
  }

  getCategories() {
    return this.http.get(`${this.baseUrl}fetch_categories`);
  }
  getArticleFilters(){
    return this.http.get(`${this.baseUrl}fetch_filter_setup`);
  }

  getTags() {
    return this.http.get(`${this.baseUrl}fetch_tag`);
  }

  getAllPublishedArticles(obj:any | string = sessionStorage.getItem('userId')!): Observable<any> {
    return this.http.post(`${this.baseUrl}articles`, { user_id: obj });
  }
  likeArticle(obj:any ): Observable<any> {
    return this.http.post(`${this.baseUrl}like_count`, obj);
  }
  getTrendingArticles(obj:any | string = sessionStorage.getItem('userId')!): Observable<any> {
    return this.http.post(`${this.baseUrl}trending_articles`, { user_id: obj });
  }

  getLatestArticles(obj?:any): Observable<any> {
    return this.http.post(`${this.baseUrl}latest_articles`,  { user_id: obj });
  }

  getEditorsPicks(obj:any | string = sessionStorage.getItem('userId')!): Observable<any> {
    return this.http.post(`${this.baseUrl}editors_picks`, { user_id: obj });
  }

  getFeaturedArticles(obj:any | string = sessionStorage.getItem('userId')!): Observable<any> {
    return this.http.post(`${this.baseUrl}featured_articles`, { user_id: obj });
  }
  getsponseredArticles(obj:any | string = sessionStorage.getItem('userId')!): Observable<any> {
    return this.http.post(`${this.baseUrl}get_sponsored_articles`, { user_id: obj });
  }
  fetchBookmarArticles(obj:any | string = sessionStorage.getItem('userId')!): Observable<any> {
    return this.http.post(`${this.baseUrl}fetch_user_bookmarks`, { user_id: obj });
  }
  deleteBookmarkArticles(obj:any): Observable<any> {
    return this.http.post(`${this.baseUrl}delete_bookmark`, obj);
  }
  //Room and Discussion Forum
  fetchAllgetRooms(){
    return this.http.get(`${this.baseUrl}fetch_room`);
  }
  fetchRoomTopics(obj:any){
    return this.http.post(`${this.baseUrl}fetch_room_topic_per_room`, { room_id: obj });
  }
  fetchDiscussionForum(obj:any){
    return this.http.post(`${this.baseUrl}fetch_all_discussion_forum_comments`, { topic_id: obj });

  }
  adddiscussionCommnets(obj:any){
    return this.http.post(`${this.baseUrl}add_discussion_forum_comment `, obj);
  }
  addRoom(obj:any){
    return this.http.post(`${this.baseUrl}add_room`, obj);
  }

  //lookUps
  getIndustries() {
    return this.http.get(`${this.baseUrl}fetch_all_industry`);
  }
  getExchanges(): Observable<any> {
    return this.http.get(`${this.baseUrl}fetch_all_exchange`);
  }

  getSectors(): Observable<any> {
    return this.http.get(`${this.baseUrl}fetch_all_sector`);
  }
  // ===============================================
  // Quiz Module

  getQuizDetails(id: string | number) {
    return this.http.get(`${this.baseUrl}quiz/${id}`);
  }

  getAllQuizzes() {
    return this.http.get(`${this.baseUrl}fetch_quizzes`);
  }
  getQuizOftheDay() {
    return this.http.get(`${this.baseUrl}quiz_of_the_day`);
  }

  getQuizCategories() {
    return this.http.get(`${this.baseUrl}quiz_categories`);
  }

  getQuizSubCategories() {
    return this.http.get(`${this.baseUrl}quiz_sub_categories`);
  }
  getQuizLevel() {
    return this.http.get(`${this.baseUrl}quiz_level_dropdown`);
  }
  // ===============================================
  // Questions Module

//dostDiary
getTasks() {
  return this.http.get(`${this.baseUrl}show_tasks`);
}
fetchPosts(userId: number) {
  return this.http.post(`${this.baseUrl}fetch_post`, { user_id: userId });
}



//Symbols
getIndustryDropdown() {
  return this.http.get(`${this.baseUrl}industry_dropdown`);
}
getExchangeDropdown() {
  return this.http.get(`${this.baseUrl}exchange_dropdown`);
}
getSectorDropdown() {
  return this.http.get(`${this.baseUrl}sector_dropdown`);
}



  getQuestionTypes() {
    return this.http.get(`${this.baseUrl}question_types`);
  }

  getAllQuestions() {
    return this.http.get(`${this.baseUrl}quiz_questions`);
  }

  getQuestionDetails(id: string | number) {
    return this.http.get(`${this.baseUrl}fetch_quiz_question/${id}`);
  }
  getAllQuizQuestions(obj:any){
    return this.http.post(`${this.baseUrl}fetch_quiz` ,obj);
  }
  //popularTags
  fetchPopularTags(id: number | string) {
    return this.http.post(`${this.baseUrl}tag_articles`, { tag_id: id });
  }
  //Bookmark Articles
  addBookmarkArticles(obj:any){
    return this.http.post(`${this.baseUrl}add_bookmark` ,obj);
  }
  //Bookmark forum comments
  addBookmarkDiscussionForumComment(obj:any){
    return this.http.post(`${this.baseUrl}add_forum_comment_bookmark` ,obj);
  }
  fetchBookmarkDiscussionForumComment(obj:any | string = sessionStorage.getItem('userId')!): Observable<any> {
    return this.http.post(`${this.baseUrl}fetch_forum_comment_bookmarks`, { user_id: obj });
  }
  // ===============================================
  // Course Module

  getCourses() {
    return this.http.get(`${this.baseUrl}fetch_courses`);
  }

  getCoursesSlugs(id: number | string = sessionStorage.getItem('userId')!) {
    return this.http.post(`${this.baseUrl}user_courses`, { user_id: id });
  }


  getCourseSlugs(id: number | string = sessionStorage.getItem('userId')!) {
    return this.http.post(`${this.baseUrl}user-courses`, { instructor_id: id });
  }

  getUserCourseCategories(id: number | string = sessionStorage.getItem('userId')!) {
    return this.http.post(`${this.baseUrl}fetch_user_course_categories`, { user_id: id });
  }

  getUserCourseSubCategories(id: number | string = sessionStorage.getItem('userId')!) {
    return this.http.post(`${this.baseUrl}fetch_user_course_sub_categories`, { user_id: id });
  }
  fetchArticleComments(articleId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}fetch_article_comment`, {article_id:articleId});
  }
  addArticleComment(obj: any): Observable<any> {
    return this.http.post(`${this.baseUrl}add_article_comment`,obj);
  }
  deleteArticleComment(commentId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}delete_article_comment`, { comment_id: commentId });
  }
  // ===============================================
  // Section Module

  getSections(id: number | string) {
    return this.http.post(`${this.baseUrl}fetch_sections`, { course_id: id });
  }

  // ===============================================
  // Lectures Module

  getLectures(id: number | string) {
    return this.http.post(`${this.baseUrl}fetch_lectures`, { section_id: id });
  }

  getLectureContentTypes() {
    return this.http.get(`${this.baseUrl}lecture_content_types`);
  }

  getLectureResources(id: number | string) {
    return this.http.post(`${this.baseUrl}fetch_lecture_resources`, { lecture_id: id });
  }


  // getLeaderBoard(id: number | string = sessionStorage.getItem('userId')!){
  //   return this.http.post(`${this.baseUrl}fetch_top_ranks`, { user_id: id });
  // }
  fetchleaderBoard(id: number | string = sessionStorage.getItem('userId')!){
    return this.http.post(`${this.baseUrl}fetch_top_ranks`, { user_id: id });
  }


  fetchAllarticles(id: number | string) {
    return this.http.post(`${this.baseUrl}category_articles`, { category_id: id });
  }

  
  fetchTagArticles(Tag: string ) {
    return this.http.post(`${this.baseUrl}fetch_articles_by_tag
`, { tag_id: Tag });
  }
  getdisplayCategories() {
    return this.http.get(`${this.baseUrl}fetch_parent_and_sub_category`);
  }
  fetchSingleProfileArticle(id: number | string){
    return this.http.post(`${this.baseUrl}fetch_single_author_profile`, { author_slug: id });
  }
  fetchAllcourses(){
    return this.http.get(`${this.baseUrl}fetch_all_courses`);
  }
}
