<app-author-layout>
    <nz-breadcrumb>
        <nz-breadcrumb-item>Home</nz-breadcrumb-item>
        <nz-breadcrumb-item>Dashboard</nz-breadcrumb-item>
    </nz-breadcrumb>

    <!-- <nz-card nzTitle="Admin Panel" class="mb-3">
        Admin must log in to access the Admin Panel.
        Admin is the owner of the platform and has full control over it.
        The Admin Dashboard, provides an overview of platform details.
    </nz-card> -->
    <div class="row mb-3">
        <div class="col-3">
            <nz-card>
                <nz-statistic [nzValue]="(50 | number)!" [nzTitle]="'Total Articles'"></nz-statistic>
            </nz-card>
        </div>

        <div class="col-3">
            <nz-card>
                <nz-statistic [nzValue]="(540 | number)!" [nzTitle]="'My Subscribers'"></nz-statistic>
            </nz-card>
        </div>
    </div>
</app-author-layout>