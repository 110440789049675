import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fetch-article',
  templateUrl: './fetch-article.component.html',
  styleUrls: ['./fetch-article.component.scss']
})
export class FetchArticleComponent {

  constructor(private http: HttpClient,private _activatedRoute: ActivatedRoute, private apiService: FetchApiService, private message: NzMessageService){

  }
tableLoading: boolean = true;
  articles: any[] = [];
categoryID :number | undefined;
storageUrl = environment.STORAGE_URL;

ngOnInit(): void {
  
this.fetchInitialData();


  }
  fetchInitialData(): Promise<any> {
    this._activatedRoute.params.subscribe((params) => {
      console.log(params);
      const categoryID = +params['categoryId'];
      console.log(categoryID);

      if(categoryID){
        this.apiService.fetchAllarticles(categoryID).subscribe({
          next: (response: any) => {
            console.log('Response: ', response.data);
            this.articles = response.data;
          },
          error: (error) => {
            console.error('Error: ', error);
            this.tableLoading = false;
          }, complete: () => {
            this.tableLoading = false;
          }
        });
       
      }
      else{
        console.log(categoryID  , " this data not get");
        
      }

     
    });

    return Promise.resolve(true);
  }

}
