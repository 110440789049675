import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class FormDataService {

  appendObjectValuesToFormData(formData: FormData, objectData: any): FormData {
    for (const key in objectData) {
      if (objectData.hasOwnProperty(key)) {
        formData.append(key, objectData[key] ?? '');
      }
    }
    return formData;
  }

}