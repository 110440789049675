<app-main-fiminize-layout>
    <nz-breadcrumb>
        <nz-breadcrumb-item><a><span nz-icon nzType="home" nzTheme="outline"></span></a></nz-breadcrumb-item>
        <nz-breadcrumb-item>My Bookmark's</nz-breadcrumb-item>
    </nz-breadcrumb>
    <h1> Articles</h1>
    <div >
        <h2>Your saved articles</h2>
        <div class="row">
            <div class="col-md-8">
                <div class="row" *ngIf="loggedIn; else loginPrompt">
                    <div class="col-md-4 my-3" *ngFor="let item of bookmakrArticles">
                        <div class="card" style="width:100%; height: 100%; border-radius: 20px;">
                            <img class="latest_article_image" alt="example"
                                [src]="storageUrl + item.featured_img_path" />
                            <div class="card-body">
                                <span
                                    class="inline-flex p-1 items-center rounded-md bg-blue-50 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">{{item.category_string}}</span>
                                <div class="title_text">
                                    <p style="margin-bottom: 0px; font-family: Heading;">{{item.title | truncate:50 |
                                        titlecase}}
                                    </p>
                                </div>
                                <div class="description_text">
                                    <p style="margin-top: 0px;font-size: 14px;">{{item.meta_description | truncate:80}}
                                    </p>
                                </div>
                                <div class="card-footer">
                                    <div class="d-flex bd-highlight mb-3">
                                        <div class="p-2 bd-highlight">
                                            <h5>{{item.created_by_string}}</h5>
                                        </div>
                                        <div class="ms-auto p-2 bd-highlight">

                                            <button *ngIf="item.bookmark_status === 1"
                                                (click)="$event.stopPropagation();">
                                                <i (click)="deleteBookmarkedArticle(item.id)"
                                                    class="fa-solid fa-bookmark"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="bookmakrArticles.length === 0">
                        <nz-empty [nzNotFoundContent]=""></nz-empty>
                    </div>
                </div>

            </div>
            <div class="col-md-4">
                <h2> Explore more topics</h2>
                <div class="d-flex flex-wrap">
                    <div class="tag">portfolio construction</div>
                    <div class="tag">financials</div>
                    <div class="tag">retail</div>
                    <div class="tag">hardware</div>
                    <div class="tag">central banks</div>
                    <div class="tag">cloud</div>
                    <div class="tag">ads</div>
                    <div class="tag">commodities</div>
                    <div class="tag">banks</div>
                    <div class="tag">crypto</div>
                </div>
                <div class="mobile-img">
                    <img src="assets\mobile image.webp" alt="">
                    <div class="mobile-view">
                        <h3>Download the InvestDost app to gain access to audio</h3>
                        <p>1000+ stories, insights, deep-dives, interviews, podcasts, and more.
                            Anytime, anywhere.</p>
                    </div>
                    
                </div>

            </div>

        </div>
    </div>



    <ng-template #loginPrompt>
        <div class="row">
            <div class="col-md-7">
                <h2>Login required</h2>
                <img src="assets\bookmark_images.webp" alt="" style="height: 12rem; width: 10rem;">
                <p><i>You need to log in to view your bookmarked content.</i></p>
            </div>
            <div class="col-md-5">

            </div>
        </div>
    </ng-template>
    <div #loginPrompt>
        <h1>Comments</h1>
        <div *ngFor="let discussion of bookmarkComments" nz-card class="mt-3 my-2">
            <div class="bg-gray-50 p-3 rounded-md">
                <nz-card-meta [nzAvatar]="avatarTemplate" nzTitle="{{ discussion.name }}"
                    nzDescription="{{ discussion.message }}"></nz-card-meta>
                <ng-template #avatarTemplate>
                    <nz-avatar [nzSize]="40" [nzSrc]="storageUrl + '/' + discussion.profile_pic"
                        [nzShape]="'circle'"></nz-avatar>
                </ng-template>
                <!-- <div class="my-2">
                <nz-input-group [nzSuffix]="suffixIconSearch">
                    <input  formControlName="message" type="text" nz-input
                        placeholder="Add a new comment..." />
                </nz-input-group>
                <ng-template #suffixIconSearch>
                    <button type="button" nzType="primary" (click)="addComments(discussion.id)"
                         nzSearch><span nz-icon nzType="send"></span></button>
                </ng-template>

                <button nzType="default" typ="button" (click)="toggleInputField(discussion)">
                    <i class="fa-regular fa-xl fa-comment"></i>
                    {{ discussion.replies_count }}
                </button>
            </div> -->
            </div>
        </div>
    </div>
</app-main-fiminize-layout>