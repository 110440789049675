import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-author-details',
  templateUrl: './author-details.component.html',
  styleUrls: ['./author-details.component.scss']
})
export class AuthorDetailsComponent implements OnInit{
  storageUrl = environment.STORAGE_URL;
  profileData: any;
  authorSlug:any;
  isModalVisible = false;
  postForm: FormGroup;
  commentVisible: { [key: number]: boolean } = {};

  userID = localStorage.getItem('userId');
  posts: any[] = [];
  userDetails: any = {};
  dostUserID: string | null = null;
  constructor(
    private http: HttpClient,
    private apiService: FetchApiService,
    private message: NzMessageService,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
  ) {
    this.postForm = new FormGroup({
      comment: new FormControl('', [Validators.required]),
    });
  }
  ngOnInit(): void {
    this.dostUserID = this.activatedRoute.snapshot.paramMap.get('id');
    this.fetchUserDetails();
  }

  fetchUserDetails(): void {
    if (this.dostUserID) {
      const requestData = {
        user_id: this.userID,
      };

  this.http.post(`${environment.API_URL}fetch_user_profile`, requestData).subscribe({
        next: (response: any) => {
          this.userDetails = response;
          this.posts = response.posts;
        },
        error: (error) => {
          console.error('Error fetching user details:', error);
          this.message.error('Failed to fetch user details');
        },
      });
    } else {
      this.message.error('User ID is missing');
    }
  }
  likeButton(post: any): void {
    const apiUrl = `${environment.API_URL}like_post`;
    const requestData = {
      post_id: post.diary_post_id,
      like_status: post.like_count ? 0 : 1,
      user_id: this.userID,
    };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.message.success('Like updated');
        this.fetchUserDetails();
      },
      error: (error) => {
        console.error('Error:', error);
        this.message.error('Failed to update like status');
      },
    });


  }
  toggleCommentSection(postId: number): void {
    this.commentVisible[postId] = !this.commentVisible[postId];
  }
  openShareModal(): void {
    this.isModalVisible = true;
  }
}
