import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Category, ParentCategory } from 'src/app/Interfaces/Content';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';

@Component({
  selector: 'app-all-categories',
  templateUrl: './all-categories.component.html',
  styleUrls: ['./all-categories.component.scss']
})
export class AllCategoriesComponent implements OnInit{
constructor(private http: HttpClient, private apiService: FetchApiService, private message: NzMessageService){

}
tableLoading: boolean = true;
parentCategories: ParentCategory[] = [];
ngOnInit(): void {
    this.fetchInitialData();
  }
  fetchInitialData(): Promise<any> {
    this.apiService.getdisplayCategories().subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        this.parentCategories = response;
      },
      error: (error) => {
        console.error('Error: ', error);
        this.tableLoading = false;
      }, complete: () => {
        this.tableLoading = false;
      }
    });
    return Promise.resolve(true);
  }

}
