import { Component } from '@angular/core';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})
export class CommunityComponent {
  communityNotes = [
    { title: 'Good growth story', description: 'BSE has great infrastructure to benefit from...' },
    { title: 'R&D investment', description: 'BSE might need to invest in R&D to stay competitive...' },
    { title: 'Strong results', description: 'BSE has shown good results and consistent growth...' }
  ];

  similarStocks = ['Stock A', 'Stock B', 'Stock C'];

  faqs = [
    { question: 'What is BSE?', answer: 'BSE stands for Bombay Stock Exchange.' },
    { question: 'How does BSE work?', answer: 'BSE facilitates the trading of stocks.' },
    { question: 'What are the trading hours?', answer: 'BSE is open from 9 AM to 3:30 PM.' }
  ];
}
