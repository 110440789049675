<ng-container>
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>

<ng-template #content>
    <div class="d-flex">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Content Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Look-ups</nz-breadcrumb-item>
        </nz-breadcrumb>

    </div>
    <nz-card>
        <nz-tabset>
            <nz-tab nzTitle="Industries">
                <div class="row mb-3">
                    <div class="col-md-6">
                        <button nz-button nzType="primary" (click)="openDrawer('industry')">Add Industry</button>
                    </div>
                    <div class="col-md-6">
                        <div class="search-container">
                            <nz-input-group [nzSuffix]="industrySearchIcon">
                                <input nz-input placeholder="Search Industries" [(ngModel)]="industrySearchText"
                                    (ngModelChange)="filterData('industry')" />
                            </nz-input-group>
                            <ng-template #industrySearchIcon>
                                <i nz-icon nzType="search"></i>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <nz-table #industryTable [nzData]="filteredIndustries" [nzBordered]="true">
                    <thead>
                        <tr>
                            <th nzWidth="5%">Serial No.</th>
                            <th nzWidth="10%" [nzSortFn]="sortByIndustry">Industry</th>
                            <th nzWidth="10%" [nzSortFn]="sortByIndustryCreatedBy">Created By</th>
                            <th nzWidth="10%" [nzSortFn]="sortByIndustryCreatedAt">Created At</th>
                            <th nzWidth="10%" [nzSortFn]="sortByIndustryCreatedAt">Updated By</th>
                            <th nzWidth="10%" [nzSortFn]="sortByIndustryCreatedAt">Updated At</th>

                            <th nzWidth="10%">Action</th>
                          </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let industry of industryTable.data; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ industry.industry }}</td>
                            <td>{{ industry.created_by_name }}</td>
                            <td>{{ industry.created_at |  date: 'dd MMM yyyy'  }}</td>
                            <td>{{ industry.updated_by_name }}</td>
                            <td>{{ industry.updated_at  | date: 'dd MMM yyyy' }}</td>
                            <td nzAlign="center" (click)="$event.stopPropagation()">
                                <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                    nzPopconfirmTitle="Are you sure you want to delete this record?"
                                    (nzOnConfirm)="confirm(industry.industry_id, 'industry')">
                                    <span nz-icon nzType="delete"></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>

            <nz-tab nzTitle="Exchanges">
                <div class="row mb-3">
                    <div class="col-md-6">
                        <button nz-button nzType="primary" (click)="openDrawer('exchange')">Add Exchange</button>
                    </div>
                    <div class="col-md-6">
                        <div class="search-container">
                            <nz-input-group [nzSuffix]="exchangeSearchIcon">
                                <input nz-input placeholder="Search Exchanges" [(ngModel)]="exchangeSearchText"
                                    (ngModelChange)="filterData('exchange')" />
                            </nz-input-group>
                            <ng-template #exchangeSearchIcon>
                                <i nz-icon nzType="search"></i>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <nz-table #exchangeTable [nzData]="filteredExchanges" [nzBordered]="true">
                    <thead>
                        <tr>
                            <th nzWidth="5%">Serial No.</th>
                            <th nzWidth="10%" [nzSortFn]="sortByExchange">Exchange</th>
                            <th nzWidth="10%" [nzSortFn]="sortByExchangeCreatedBy">Created By</th>
                            <th nzWidth="10%" [nzSortFn]="sortByExchangeCreatedAt">Created At</th>
                            <th nzWidth="10%" [nzSortFn]="sortByExchangeCreatedBy">Updated By</th>
                            <th nzWidth="10%" [nzSortFn]="sortByExchangeCreatedAt">Updated At</th>
                            <th nzWidth="10%">Action</th>
                          </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let exchange of exchangeTable.data; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ exchange.exchange }}</td>
                            <td>{{ exchange.created_by_name }}</td>
                            <td>{{ exchange.created_at| date: 'dd MMM yyyy' }}</td>
                            <td>{{ exchange.updated_by_name }}</td>
                            <td>{{ exchange.updated_at  | date: 'dd MMM yyyy' }}</td>
                            <td nzAlign="center" (click)="$event.stopPropagation()">
                                <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                    nzPopconfirmTitle="Are you sure you want to delete this record?"
                                    (nzOnConfirm)="confirm(exchange.exchange_id, 'exchange')">
                                    <span nz-icon nzType="delete"></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>

            <nz-tab nzTitle="Sectors">
                <div class="row mb-3">
                    <div class="col-md-6">
                        <button nz-button nzType="primary" (click)="openDrawer('sector')">Add Sector</button>
                    </div>
                    <div class="col-md-6">
                        <div class="search-container">
                            <nz-input-group [nzSuffix]="sectorSearchIcon">
                                <input nz-input placeholder="Search Sectors" [(ngModel)]="sectorSearchText"
                                    (ngModelChange)="filterData('sector')" />
                            </nz-input-group>
                            <ng-template #sectorSearchIcon>
                                <i nz-icon nzType="search"></i>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <nz-table #sectorTable [nzData]="filteredSectors" [nzBordered]="true">
                    <thead>
                        <tr>
                            <th nzWidth="5%">Serial No.</th>
                            <th nzWidth="10%" [nzSortFn]="sortBySector">Sector</th>
                            <th nzWidth="10%" [nzSortFn]="sortBySectorCreatedBy">Created By</th>
                            <th nzWidth="10%" [nzSortFn]="sortBySectorCreatedAt">Created At</th>
                            <th nzWidth="10%" [nzSortFn]="sortByIndustryCreatedAt">Created At</th>
                            <th nzWidth="10%" [nzSortFn]="sortByIndustryCreatedAt">Updated By</th>
                            <th nzWidth="10%" [nzSortFn]="sortByIndustryCreatedAt">Updated At</th>
                            <th nzWidth="10%">Action</th>
                          </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let sector of sectorTable.data; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ sector.sector }}</td>
                            <td>{{ sector.created_by_name }}</td>
                            <td>{{ sector.created_at | date: 'dd MMM yyyy'  }}</td>
                            <td>{{ sector.updated_by_name }}</td>
                            <td>{{ sector.updated_at  | date: 'dd MMM yyyy' }}</td>
                            <td nzAlign="center" (click)="$event.stopPropagation()">
                                <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                    nzPopconfirmTitle="Are you sure you want to delete this record?"
                                    (nzOnConfirm)="confirm(sector.sector_id, 'sector')">
                                    <span nz-icon nzType="delete"></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </nz-table>
            </nz-tab>
        </nz-tabset>
    </nz-card>
    <nz-drawer [nzVisible]="isDrawerVisible"
        [nzTitle]="drawerType === 'industry' ? 'Add Industry' : drawerType === 'exchange' ? 'Add Exchange' : 'Add Sector'"
        [nzWidth]="400" (nzOnClose)="closeDrawer()">
        <ng-container *nzDrawerContent>
            <form [formGroup]="getFormGroup()">
                <ng-container [ngSwitch]="drawerType">
                    <div *ngSwitchCase="'industry'">
                        <label for="industry">Enter Industry</label>
                        <input id="industry" formControlName="industry" nz-input />
                    </div>
                    <div *ngSwitchCase="'exchange'">
                        <label for="exchange"> Enter Exchange</label>
                        <input id="exchange" formControlName="exchange" nz-input />
                    </div>
                    <div *ngSwitchCase="'sector'">
                        <label for="sector">Enter Sector</label>
                        <input id="sector" formControlName="sector" nz-input />
                    </div>
                </ng-container>
                <button class="mt-3" nz-button nzType="primary" [disabled]="getFormGroup().invalid" (click)="handleSave()">Add {{drawerType}}</button>
            </form>
        </ng-container>
      
    </nz-drawer>
</ng-template>