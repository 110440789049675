import { Component } from '@angular/core';

@Component({
  selector: 'app-attend-quiz',
  templateUrl: './attend-quiz.component.html',
  styleUrls: ['./attend-quiz.component.scss']
})
export class AttendQuizComponent {

}
