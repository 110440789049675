import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { isFormValid } from 'src/app/Functions/FormFunctions';

import { AllPermission, AllRoles, AllUsers,  allModule,  userRoles } from 'src/app/Interfaces/modules';
import { PermissionService } from 'src/app/Services/Permission/permission.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-role-set-up',
  templateUrl: './user-role-set-up.component.html',
  styleUrls: ['./user-role-set-up.component.scss']
})
export class UserRoleSetUpComponent {
  useAdminLayout: boolean = false; // Default to non-admin layout
  viewUserRoles: userRoles[] = [];
  tableLoading: boolean = true;
  editFormLoading: boolean = false;
  ShortLongTable: boolean = false;
  _addrole: boolean = false;
  _editrole: boolean = false;
  pipe = new DatePipe('en-US');

  allUser:AllUsers[]=[];
  AllRoles:AllRoles[]=[];
  AllPermission:AllPermission[]=[];
  allModule:allModule[]=[];
  roleId:any;



  constructor(private http: HttpClient, private message: NzMessageService, private permissionApi:PermissionService) {}

  ngOnInit() {
    this.roleId = localStorage.getItem('role_id');
    this.useAdminLayout = this.roleId === '1' || this.roleId === '2';
  
    this.fetchInitialData();
    this.getUserDropdown();
    this.getRolesDropdown();
    this.getPermissionsDropdown();
  }

  fetchInitialData(): Promise<any> {
    const apiUrl = `${environment.API_URL}fetch_all_user_roles`;

    const requestData = {
      account_id: sessionStorage.getItem('account_id'),
    };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('All roles: ', response);
        this.viewUserRoles = response;
      },
      error: (error) => {
        console.error('Error: ', error);
        this.tableLoading = false;
      },
      complete: () => {
        this.tableLoading = false;
      },
    });
    return Promise.resolve(true);
  }

  editUserRolesForm = new FormGroup({
    account_id: new FormControl(null),
    role_id: new FormControl(null),
    user_role_id: new FormControl(null),
    user_id:new FormControl(''),
    username: new FormControl(''),
    active_status_id: new FormControl(null),
    valid_from :new FormControl(),
    valid_till :new FormControl(),
    created_at: new FormControl(),
    updated_at: new FormControl(),
    created_by_string: new FormControl(),
    updated_by_string: new FormControl(),
  });

  editrole(role_id: number) {
    this.ShortLongTable = true;
    this._editrole = true;
    this._addrole = false;
    this.getArticleDetails(role_id);
  }
  roleUpdated() {
    if (isFormValid(this.editUserRolesForm)) {
      // Handle form submission, e.g., send data to the server
      const apiUrl = `${environment.API_URL}update_user_role`;
      const requestData = {
        account_id: this.editUserRolesForm.value.account_id,
        role_id: this.editUserRolesForm.value.role_id,
        user_id: this.editUserRolesForm.value.user_id,
        user_role_id: this.editUserRolesForm.value.user_role_id,
        valid_from: this.editUserRolesForm.value.valid_from,
        valid_till: this.editUserRolesForm.value.valid_till,
        active_status_id: this.editUserRolesForm.value.active_status_id,
        updated_by: localStorage.getItem('userId'),
      };

      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          console.log('Update Response: ', response);
          if (response.success) {
            this.fetchInitialData()
              .then(() => {
                const message = 'role Updated';
                this.message.success(message);
              })
              .catch(() => {
                this.message.success('Error Occurred');
              });
          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
        },
        complete:()=> {

        },
      });
    }
  }

  addrole() {
    this.ShortLongTable = true;
    this._addrole = true;
    this._editrole = false;
  }


  roleAdded() {
    if (isFormValid(this.editUserRolesForm)) {


      const apiUrl = `${environment.API_URL}assign_role_to_user`;
      const requestData = {
        user_id: this.editUserRolesForm.value.user_id,
        role_id: this.editUserRolesForm.value.role_id,
        valid_from:   this.pipe.transform(this.editUserRolesForm.value.valid_from, 'YYYY-MM-dd'  ),
        valid_till:   this.pipe.transform(this.editUserRolesForm.value.valid_till, 'YYYY-MM-dd'  ),
        account_id: this.editUserRolesForm.value.account_id,
        active_status_id: this.editUserRolesForm.value.active_status_id,
        created_by: localStorage.getItem('userId'),

        // role_name: this.editUserRolesForm.value.role_name,
        // username: this.editUserRolesForm.value.username,

      };

      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          console.log('Update Response: ', response);
          if (response.success) {
            this.fetchInitialData()
              .then(() =>
                this.getArticleDetails(
                  this.editUserRolesForm.controls['role_id'].value!
                )
              )
              .then(() => {
                const message = 'role Updated';
                this.message.success(message);
              })
              .catch(() => {
                this.message.success('Error Occurred');
              });
          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
        },
      });
    }
  }
  closed() {
    (this.ShortLongTable = false), this.editUserRolesForm.reset();
  }

  getArticleDetails(user_id: number): Promise<void> {
    const apiUrl = `${environment.API_URL}fetch_assigned_role_to_single_user`;
    const requestData =
    {
      account_id:null,
      user_role_id:user_id
  };
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          console.log('role Details: ', response[0]);

          this.editUserRolesForm.patchValue(response[0]);
          return resolve();
        },
        error: (error) => {
          console.error('Error: ', error);
          return reject();
        },
        complete: () => {
          this.editFormLoading = false;
        },
      });
    });
  }

  getModuleDropdown(){
    this.permissionApi.getModuleDropdown().subscribe({
      next: (response: any) => {
        console.log('Module Details: ', response);
        this.allModule =response
       },
      error: (error) => {
        console.error('Error: ', error);

      },
      complete: () => {
        this.editFormLoading = false;
      },
    });

  }

  getUserDropdown(){
    this.permissionApi.getUserDropdown().subscribe({
      next: (response: any) => {
        console.log('Users Details: ', response);
        this.allUser =response


      },
      error: (error) => {
        console.error('Error: ', error);

      },
      complete: () => {
        this.editFormLoading = false;
      },
    });

  }
  getRolesDropdown(){
    this.permissionApi.getRoleDropdown().subscribe({
      next: (response: any) => {
        console.log('Roles Details: ', response);
        this.AllRoles =response


      },
      error: (error) => {
        console.error('Error: ', error);

      },
      complete: () => {
        this.editFormLoading = false;
      },
    });

  }
  getPermissionsDropdown(){
    this.permissionApi.getPermissionDropdown().subscribe({
      next: (response: any) => {
        console.log('Permissions Details: ', response);
        this.AllPermission =response


      },
      error: (error) => {
        console.error('Error: ', error);

      },
      complete: () => {
        this.editFormLoading = false;
      },
    });

  }



  confirm(id: number): void {
    this.deleteRecord(id);
  }

  deleteRecord(role_id: number) {
    const apiUrl = `${environment.API_URL}delete_assigned_role`;

    const requestData = {
      user_role_id: role_id,
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchInitialData().then(() => {
            this.message.success('role deleted');
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
}

// onSubmit() {
//   if (isFormValid(this.editUserRolesForm)) {
//     // Handle form submission, e.g., send data to the server
//     const apiUrl = `${environment.API_URL}edit_artical`;

//     const requestData = {
//       ...this.editUserRolesForm.value,
//       role_id: this.editUserRolesForm.controls['role_id'].value,
//       updated_by: sessionStorage.getItem('userId'),
//     };

//     this.http.post(apiUrl, requestData)
//       .subscribe({
//         next: (response: any) => {
//           console.log('Update Response: ', response);
//           if (response.success) {
//             this.fetchInitialData()
//               .then(() => this.getArticleDetails(this.editUserRolesForm.controls['role_id'].value!))
//               .then(() => {
//                 const message = 'role Updated';
//                 this.message.success(message);
//               })
//               .catch(() => {
//                 this.message.success('Error Occurred');
//               });
//           } else {
//             this.message.error('Error occurred, please try later');
//           }
//         },
//         error: (error) => {
//           console.error('Error: ', error);
//         }
//       });
//   }
// }
