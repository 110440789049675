import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bookmark-articles',
  templateUrl: './bookmark-articles.component.html',
  styleUrls: ['./bookmark-articles.component.scss']
})
export class BookmarkArticlesComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private _activatedRoute: ActivatedRoute,
    private apiService: FetchApiService,
    private message: NzMessageService
  ) {}
  storageUrl = environment.STORAGE_URL;
  userId = localStorage.getItem('userId');
  loggedIn: boolean = !!this.userId;
  bookmakrArticles:any[]=[]
  bookmarkComments:any[]=[]
  ngOnInit(): void {
    this.fetchAllBookmarkArticles();
    this.fetchAllBookmarsCommentForum();
  }
  fetchAllBookmarkArticles(){
    let UserID=this.userId
    this.apiService.fetchBookmarArticles(UserID).subscribe({
      next: (response: any) => {
        console.log('Response: ', response.data);
        this.bookmakrArticles = response.data;
      },
      error: (error) => {
        console.error('Error: ', error);
      },
      complete: () => {},
    });
  }
  fetchAllBookmarsCommentForum(){
    let UserID=this.userId
    this.apiService.fetchBookmarkDiscussionForumComment(UserID).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        this.bookmarkComments = response;
        
      },
      error: (error) => {
        console.error('Error: ', error);
      },
      complete: () => {},
    });
  }
  deleteBookmarkedArticle( id: number){
    let obj = {
      user_id: localStorage.getItem('userId'),
      artical_id: id,
    };
    console.log('obj: ', obj);
    this.apiService.deleteBookmarkArticles(obj).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchAllBookmarkArticles()
          this.message.success(response.message);

        } else {
          this.message.error('Error occurred, please try later');
        }
      },
    })
  }
  confirm(id: number): void {
    this.deleteBookmarkedArticle(id);
  }
  cancel(): void {
  }

}
