<app-main-fiminize-layout>
  <nz-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="/">
        <span nz-icon nzType="home" nzTheme="outline"></span>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>DostPortfolio</nz-breadcrumb-item>
  </nz-breadcrumb>
  <nz-card *ngIf="watchlistTickers.length > 0">
    <button nz-button nzType="primary" (click)="openDrawer()">Add</button>
    <nz-table #basicTable [nzData]="watchlistTickers" [nzBordered]="true">
      <thead>
        <tr>
          <th nzWidth="120px">Ticker</th>
          <th nzWidth="120px">Date</th>
          <th nzWidth="120px">Sentiment</th>
          <th nzWidth="120px">Risk</th>
          <th nzWidth="120px">Horizon</th>
          <th nzWidth="120px">Trade</th>
          <th nzWidth="180px">Review Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ticker of basicTable.data">
          <td>{{ ticker.ticker_name }}</td>
          <td>{{ ticker.date }}</td>
          <td>{{ ticker.sentiment_name }}</td>
          <td>{{ ticker.risk_name }}</td>
          <td>{{ ticker.horizon_name }}</td>
          <td>{{ ticker.trade_name }}</td>
          <td>{{ ticker.review_date }}</td>
        </tr>
      </tbody>
    </nz-table>
  </nz-card>
  <nz-drawer [nzVisible]="drawerVisible" [nzTitle]="'Add New Ticker'" [nzWidth]="'400px'" (nzOnClose)="closeDrawer()">
    <ng-container *nzDrawerContent>
        <!-- Content inside the drawer -->
    <form [formGroup]="addTickerForm" (ngSubmit)="addTicker()">
      <div class="row">
        <div class="col-md-6">
          <nz-form-item>
            <nz-form-label>Symbol</nz-form-label>
            <nz-form-control>
              <nz-select formControlName="symbol_id" placeholder="Select symbol">
                <nz-option *ngFor="let ticker of tickers" [nzValue]="ticker.symbol_id"
                  [nzLabel]="ticker.symbol"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md-6">
          <nz-form-item>
            <nz-form-label>Sentiment</nz-form-label>
            <nz-form-control>
              <nz-select formControlName="sentiment_id" placeholder="Select sentiment">
                <nz-option *ngFor="let sentiment of sentiments" [nzValue]="sentiment.sentiment_id"
                  [nzLabel]="sentiment.sentiment"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <nz-form-item>
            <nz-form-label>Risk</nz-form-label>
            <nz-form-control >
              <nz-select formControlName="risk_id" placeholder="Select risk">
                <nz-option *ngFor="let risk of risks" [nzValue]="risk.risk_id" [nzLabel]="risk.risk"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-md-6">
          <nz-form-item>
            <nz-form-label>Horizon</nz-form-label>
            <nz-form-control >
              <nz-select formControlName="horizon_id" placeholder="Select horizon">
                <nz-option *ngFor="let horizon of horizons" [nzValue]="horizon.horizon_id"
                  [nzLabel]="horizon.horizon"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
     
   
     
      <nz-form-item>
        <nz-form-label>Trade</nz-form-label>
        <nz-form-control >
          <nz-select formControlName="trade_id" placeholder="Select trade">
            <nz-option *ngFor="let trade of trades" [nzValue]="trade.trade_id" [nzLabel]="trade.trade"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control  [nzOffset]="6">
          <button nz-button nzType="primary" [disabled]="!addTickerForm.valid">Add Ticker</button>
        </nz-form-control>
      </nz-form-item>
    </form>
    </ng-container>
  </nz-drawer>
</app-main-fiminize-layout>