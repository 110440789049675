<ng-container *ngIf="useAdminLayout">
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>

<!-- <ng-template #authorLayout>
    <app-author-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-author-layout>
</ng-template> -->


<ng-template #content>
    <!-- Content specific to both admin and author layouts -->


    <div class="d-flex">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Quiz Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Quiz of the day</nz-breadcrumb-item>
        </nz-breadcrumb>

    </div>
    <div class="row">
        <div class="col-md-6 mb-3">
            <nz-input-group [nzSuffix]="suffixIconSearch">
                <input type="text" nz-input placeholder="Search Quizzes" [(ngModel)]="searchQuery" (ngModelChange)="searchQuizzes()">
              </nz-input-group>
              <ng-template #suffixIconSearch>
                <span nz-icon nzType="search"></span>
              </ng-template>

        </div>
    </div>
    <nz-card>
        <div class="table-responsive">

            <nz-table #quizTable [nzData]="quizzes" nzTableLayout="fixed" [nzLoading]="tableLoading" nzShowSizeChanger>
                <thead>
                    <tr>
                        <th nzWidth="100px" nzAlign="center">Action</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Quiz Level</th>
                        <th>Category</th>
                        <th>Valid From</th>
                        <th>Valid To</th>
                        <th>Created By</th>
                        <th>Updated By</th>
                        <th nzWidth="100px" nzAlign="center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let quiz of quizTable.data">

                        <tr >
                            <td nzAlign="center" (click)="$event.stopPropagation()">
                                <a [routerLink]="['/attend-quiz']" target="_blank" nz-button nzType="default"
                                    nzShape="circle" nz-tooltip="Launch Preview" class="me-2"
                                    nzTooltipPlacement="right">
                                    <span nz-icon nzType="play-circle"></span>
                                </a>
                            </td>
                            <td>{{ quiz.title }}</td>
                            <td nzEllipsis>{{ quiz.description ? quiz.description: '--' }}</td>
                            <td>{{ quiz.is_active === 1 ? 'Active': 'Inactive' }}</td>
                            <td>{{ quiz.quiz_level ? quiz.quiz_level: '--'}}</td>
                            <td>{{ quiz.category_string ? quiz.category_string: '--'}}</td>
                            <td>{{ quiz.valid_from | date: 'dd MMM y, h:mm a' }}</td>
                            <td>{{ quiz.valid_to | date: 'dd MMM y, h:mm a' }}</td>
                            <td>{{ quiz.created_by_string ?  quiz.created_by_string:  '--'}}</td>

                            <td>{{ quiz.updated_by_string ?  quiz.updated_by_string: '--' }}</td>

                            <td nzAlign="center" (click)="$event.stopPropagation()">
                                <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                    nzPopconfirmTitle="Are you sure you want to delete this record?"
                                 >
                                    <span nz-icon nzType="delete"></span>
                                </button>
                            </td>
                        </tr>

                    </ng-container>
                </tbody>
            </nz-table>
        </div>


    </nz-card>

</ng-template>