import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { Category } from 'src/app/Interfaces/Content';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})

export class CategoriesComponent {
  categories: Category[] = [];

  searchQuery: string = '';
  useAdminLayout: boolean = false; // Default to non-admin layout
  addCategoryForm: FormGroup;
  editCategoryForm: FormGroup;
  roleId:any;
  tableLoading: boolean = true;
  constructor(private http: HttpClient, private apiService: FetchApiService, private message: NzMessageService) {
    this.addCategoryForm = new FormGroup({
      // Define your form control with validation
      category_name: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      parent_category_id: new FormControl(''),
    });

    this.editCategoryForm = new FormGroup({
      // Define your form control with validation
      id: new FormControl(''),
      category_name: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      parent_category_id: new FormControl(''),
    });
  }

  ngOnInit() {
    this.roleId = localStorage.getItem('role_id');
    this.useAdminLayout = this.roleId === '1' || this.roleId === '2';
    // Set useAdminLayout based on the 'role' value
    this.fetchInitialData();

  }

  filterSymbols(): void {
    const query = this.searchQuery.toLowerCase().trim();
    if (query) {
      this.categories = this.categories.filter(category =>
        category.category_name.toLowerCase().includes(query)
      );
    } else {
      this.fetchInitialData(); // Reset to fetch all categories when query is empty
    }
  }
  fetchInitialData(): Promise<any> {
    this.apiService.getCategories().subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        this.categories = response.data;
      },
      error: (error) => {
        console.error('Error: ', error);
        this.tableLoading = false;
      }, complete: () => {
        this.tableLoading = false;
      }
    });
    return Promise.resolve(true);
  }

  expandSet = new Set<number>();
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  addDrawer: boolean = false;
  editDrawer: boolean = false;

  openEditDrawer(parentCategoryId: number, childCategoryId?: number): void {
    var categoryDetails;
    if (childCategoryId) {
      // Its Child Category
      let filter1 = this.categories.find(category => category.id === parentCategoryId)!;
      categoryDetails = filter1.child.find(child => child.id === childCategoryId);
    } else {
      // Its Parent Category
      categoryDetails = this.categories.find(category => category.id === parentCategoryId);
    }
    this.editCategoryForm.patchValue(categoryDetails!);
    console.log(this.editCategoryForm.value);
    this.editDrawer = true;
  }

  closeEditDrawer(): void {
    this.editDrawer = false;
  }

  openAddDrawer(): void {
    this.addDrawer = true;
  }

  closeAddDrawer() {
    this.addDrawer = false;
  }

  cancel(): void {
  }

  confirm(id: number): void {
    this.deleteCategory(id);
  }

  submitAddForm() {
    if (isFormValid(this.addCategoryForm)) {
      const apiUrl = `${environment.API_URL}add_category`;
      const requestData = {
        user_id: localStorage.getItem('userId'),
        ...this.addCategoryForm.value
      };
      console.log(requestData);
      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            console.log('Response: ', response);
            if (response.success) {
              this.fetchInitialData().then(() => {
                this.message.success('Category added');
                this.addCategoryForm.reset();
                this.closeAddDrawer();
              });

            } else {
              this.message.error('Error occurred, please try later');
            }
          },
          error: (error) => {
            console.error('Error: ', error);
            this.message.error('Error occurred, please try later');
          }
        });
    }
  }
 
  submitEditForm() {
    if (isFormValid(this.editCategoryForm)) {
      const apiUrl = `${environment.API_URL}edit_category`;
      const requestData = {
        updated_by: localStorage.getItem('userId'),
        category_id: this.editCategoryForm.controls['id'].value,
        ...this.editCategoryForm.value
      };
      console.log(requestData);
      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            console.log('Response: ', response);
            if (response.success) {
              this.fetchInitialData().then(() => {
                this.message.success('Category updated');
                this.editCategoryForm.reset();
                this.closeEditDrawer();
              });
            } else {
              this.message.error('Error occurred, please try later');
            }
          },
          error: (error) => {
            console.error('Error: ', error);
          }
        });
    }
  }

  deleteCategory(id: number) {
    const apiUrl = `${environment.API_URL}delete_category`;

    const requestData = {
      category_id: id,
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchInitialData().then(() => {
              this.message.success('Category deleted');
            });

          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        }
      });
  }
  sortByName = (a: any, b: any): number => {
    return a.category_name.localeCompare(b.category_name);
};

sortByDescription = (a: any, b: any): number => {
    return (a.description || '').localeCompare(b.description || '');
};

sortByDate = (a: any, b: any): number => {
    return new Date(a).getTime() - new Date(b).getTime();
};
}

