import { Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-author-layout',
  templateUrl: './author-layout.component.html',
  styleUrls: ['./author-layout.component.scss']
})
export class AuthorLayoutComponent {

  isCollapsed: boolean = true;
  currentYear: number = new Date().getFullYear();

  sidebarCollapseLogo: SafeUrl;
  sidebarExpandLogo: SafeUrl;

  constructor(private sanitizer: DomSanitizer, private message: NzMessageService, private router: Router) {
    // Create a safe URL for the image file
    this.sidebarCollapseLogo = this.sanitizer.bypassSecurityTrustResourceUrl('assets/app-icon.png');
    this.sidebarExpandLogo = this.sanitizer.bypassSecurityTrustResourceUrl('assets/InvestDostFull.png');
  }
  underDevelopmentMessage(): void {
    this.message.info('Under Development');
  }


  logout() {
    sessionStorage.clear();
    sessionStorage.setItem('isLoggedIn', 'false');
    this.router.navigate(['/']);
  }
}
