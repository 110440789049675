<ng-container *ngIf="useAdminLayout; else authorLayout">
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>

<ng-template #authorLayout>
    <app-author-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-author-layout>
</ng-template>

<ng-template #content>
    <!-- Content specific to both admin and author layouts -->
    <div class="d-flex">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Messages</nz-breadcrumb-item>
            <!-- <nz-breadcrumb-item>Add Article</nz-breadcrumb-item> -->
        </nz-breadcrumb>
        <button class="ms-auto mb-2" nz-button nzType="default" (click)="openAddDrawer()"><span nz-icon
                nzType="plus"></span>Compose Message</button>
    </div>

    <div class="table-responsive">
        <nz-table #messagesTable [nzData]="messages" nzTableLayout="fixed" [nzLoading]="tableLoading" nzShowSizeChanger>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Sender</th>
                    <th>Receiver</th>
                    <th>Content</th>
                    <th>Timestamp</th>
                    <th nzWidth="100px" nzAlign="center">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let message of messagesTable.data" (click)="openViewDrawer(message.id)">
                    <td>{{ message.id }}</td>
                    <td nzEllipsis>{{ message.sender }}</td>
                    <td nzEllipsis>{{ message.receiver }}</td>
                    <td nzEllipsis>{{ message.content }}</td>
                    <td>{{ message.timestamp | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
                    <td nzAlign="center" (click)="$event.stopPropagation()">
                        <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                            nzPopconfirmTitle="Are you sure you want to delete this record?" (nzOnConfirm)="confirm()"
                            (nzOnCancel)="cancel()">
                            <span nz-icon nzType="delete"></span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </div>

    <nz-drawer [nzVisible]="viewDrawer" nzWidth="40%" nzTitle="View Message" (nzOnClose)="closeViewDrawer()">
        <ng-container *nzDrawerContent>
            <form [formGroup]="addMessageForm" (ngSubmit)="onReplySubmit()" nz-form nzLayout="vertical">

                <nz-form-item>
                    <!-- <nz-form-label nzRequired>Message</nz-form-label> -->
                    <nz-form-control nzHasFeedback message="Enter your messag here">
                        <nz-textarea-count [nzMaxCharacterCount]="2000">
                            <textarea formControlName="content" nz-input rows="3"
                                placeholder="write your content here"></textarea>
                        </nz-textarea-count>
                    </nz-form-control>
                </nz-form-item>

                <button nz-button nzType="primary">Send</button>

            </form>

        </ng-container>
    </nz-drawer>

    <nz-drawer [nzVisible]="addDrawer" nzWidth="40%" nzTitle="Send Message" (nzOnClose)="closeAddDrawer()">
        <ng-container *nzDrawerContent>
            <form [formGroup]="replyForm" (ngSubmit)="onNewMessageSubmit()" nz-form nzLayout="vertical">


                <nz-form-item>
                    <nz-form-label>Category</nz-form-label>

                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Category">
                        <nz-option nzLabel="Finance" nzValue="Finance"></nz-option>
                        <nz-option nzLabel="Technology" nzValue="Technology"></nz-option>
                        <nz-option nzLabel="Health" nzValue="Health"></nz-option>
                        <nz-option nzLabel="Travel" nzValue="Travel"></nz-option>
                        <nz-option nzLabel="Food" nzValue="Food"></nz-option>
                    </nz-select>

                </nz-form-item>

                <nz-form-item>
                    <!-- <nz-form-label nzRequired>Message</nz-form-label> -->
                    <nz-form-control nzHasFeedback message="Enter your messag here">
                        <nz-textarea-count [nzMaxCharacterCount]="2000">
                            <textarea formControlName="content" nz-input rows="3"
                                placeholder="write your content here"></textarea>
                        </nz-textarea-count>
                    </nz-form-control>
                </nz-form-item>

                <button nz-button nzType="primary">Send</button>


            </form>

        </ng-container>
    </nz-drawer>

</ng-template>
