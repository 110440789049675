<app-admin-layout>
  <nz-breadcrumb>
    <nz-breadcrumb-item><a routerLink="/">Home</a></nz-breadcrumb-item>
    <nz-breadcrumb-item><a routerLink="/all-Categories">Categories</a></nz-breadcrumb-item>
    <nz-breadcrumb-item>Articles</nz-breadcrumb-item>
  </nz-breadcrumb>
  <h2 class="mt-2">{{ articles[0]?.category_string }}</h2>

  <div class="row">
    <div class="col-md-3 my-3" *ngFor="let item of articles">
      <div class="card" style="width:100%; height: 100%; border-radius: 20px;">
        <img class="latest_article_image" alt="example" [src]="storageUrl + item.featured_img_path" />
        <div class="card-body">
          <span
            class="inline-flex p-1 items-center rounded-md bg-blue-50 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">{{item.category_string}}</span>
          <div class="title_text">
            <p style="margin-bottom: 0px; font-family: Heading;">{{item.title | truncate:50 | titlecase}}
            </p>
          </div>
          <div class="description_text">
            <p style="margin-top: 0px;font-size: 14px;">{{item.meta_description | truncate:80}}</p>
          </div>
          <div class="card-footer">
            <div class="d-flex bd-highlight mb-3">
              <div class="p-2 bd-highlight">
                <h5>{{item.created_by_string}}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!articles || articles.length === 0">
    <nz-empty nzNotFoundImage="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
      [nzNotFoundContent]="contentTpl">
      <ng-template #contentTpl>
        <span>
          No Data
          <a routerLink="/all-Categories">All Categories</a>
        </span>
      </ng-template>

    </nz-empty>
  </div>

</app-admin-layout>