import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterComments'
})
export class FilterCommentsPipe implements PipeTransform {

  transform(comments: any[], diaryPostId: number): any[] {
    if (!comments || diaryPostId === undefined || diaryPostId === null) {
      return comments;
    }
    return comments.filter(comment => comment.diary_post_id === diaryPostId);
  }
}