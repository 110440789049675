<app-main-fiminize-layout>
  <div class="marquee-track">
<div *ngIf="markupLines.length > 0">
  <marquee class="p-1"  behavior="scroll" direction="left" style="background-color: #edf2f4; border-radius: 10px;">
    <ng-container *ngFor="let markupLine of markupLines; let i = index">
      <span style="display: inline-block; width: 10px; text-align: center;">&#8226;</span>
      <span [style.color]="getColor(i)">
        <b (click)="navigateToArticle(markupLine.article_id)">{{ markupLine.markup_line }}</b>
      </span>
      &nbsp;&nbsp;&nbsp;&nbsp;
    </ng-container>
  </marquee>
</div>
<div *ngIf="markupLines.length === 0">
  No markup lines available.
</div>

  </div>



  <nz-card class="mb-4 promo-banner">
    <div class="row">
      <div class="p-4">
        <h3>InvestDost - The premier social network where finance meets community.</h3>
        <p style="color: white; font-size: 1rem; "> Dive into a world of real-time market analysis and peer-to-peer
          knowledge exchange, all within a secure and engaging platform. We bridge the gap between expert insights and
          everyday decision-making, creating a vibrant ecosystem for investors, professionals, and enthusiasts to
          connect, learn, and grow.</p>
        <div class="hstack gap-3">
          <button [routerLink]="['/register']" id="signupButton" nz-button nzSize="large" nzType="primary">Find Out
            More</button>
          <button [routerLink]="['/login']" nz-button nzSize="large" nzType="text">SIGN IN</button>
        </div>
      </div>
    </div>
  </nz-card>


  <div class="row">
    <h2>Latest Story</h2>
    <app-article-card-design [articleData]="latestArticleData" 
      description="Stay informed with recent market news" [showSlideButtons]="true" (slideLeftEvent)="slideLeft()"
      (slideRightEvent)="slideRight()" (refresh)="fetchPopularArticles()" >
    </app-article-card-design>
  </div>

  <div class="row ">
    <h2>🔥InvestDost+ Insights</h2>
    <app-article-card-design class="bg-light" [articleData]="revLatestArticleData"
      description="Quality research from pro investors" [showSlideButtons]="true" (slideLeftEvent)="slideLeft()"
      (slideRightEvent)="slideRight()" (refresh)="revFetchInitialData()">
    </app-article-card-design>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="row">
          <div class="col-md-6">
            <div class="iQqhWs">
              <div class="htgHRO">
                <img src="assets\image (1).png" alt="">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="m-4">
              <h1 class="mb-2">
                Elevate Your Investing IQ
              </h1>
              <p>Unlock the potential of the financial markets with the help of DostExplains. From novice to expert
                investors, these comprehensive guides are designed to enhance your understanding, refine your
                strategies, and empower your investment decisions with knowledge.</p>
              <div class="report-button">
                <a href="">Try Now</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card" style="background-color: #FFF9EB;">
        <div class="row">
          <div class="col-md-6">
            <div class="iQqhWs">
              <div class="htgHRO">
                <img src="assets\image (1).png" alt="">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="m-4 heading">
              <h1 class="mb-2">
                Sharpen Your Skills, Earn Rewards
              </h1>
              <p>Engage with our content, the interactive platform where knowledge pays off. Put your investment savvy
                to the test, climb the leaderboard, and earn accolades as you compete with peers in our educational
                ecosystem for top honours and rewards</p>
              <div class="report-button">
                <a href="">Unlock Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="row">
    <h2>Must Read</h2>
    <app-article-card-design [articleData]="editerPics"
      description="Top picks carefully chosen so you can learn & action." [showSlideButtons]="true"
      (slideLeftEvent)="slideLeft()" (slideRightEvent)="slideRight()" (refresh)="fetchEditerPics()">
    </app-article-card-design>
  </div>
  <div class="row">
    <h2>Popular</h2>
    <app-article-card-design [articleData]="trendingArticles" 
      description="Find out what everyone is interested in" [showSlideButtons]="true" (slideLeftEvent)="slideLeft()"
      (slideRightEvent)="slideRight()" (refresh)="fetchTrendingArticles()"></app-article-card-design>

  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="card" style="background-color: #010100e5; min-height: 25rem;">
        <h1 class=""> Author Spotlight</h1>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card" style="background-color: #d9d4c9;min-height: 25rem">
        <h1 > Trending on IdeaHub</h1>
      </div>
    </div>

  </div>
  <div class="row">
    <div class="second-section py-10">
      <h3>EXPLORE MORE FROM INVESTDOST</h3>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div routerLink="/all-Categories"
          class="bg-light border rounded p-4 shadow transition-transform transform hover:-translate-y-2 hover:shadow-lg">
          <div class="flex items-center mb-2">
            <img class="icons" src="assets\icons\Intelligence.png" alt="" class="w-6 h-6 mr-2">
            <h2>INTELLIGENCE</h2>
          </div>
          <p>Dive into expert analysis and data-driven insights curated to empower investors with a comprehensive suite
            of knowledge to inform and enhance your investment strategy.
          </p>
        </div>
        <div routerLink="/discusstion"
          class="bg-light border rounded p-4 shadow transition-transform transform hover:-translate-y-2 hover:shadow-lg">
          <div class="flex items-center mb-2">
            <img class="icons" src="assets\icons\ideahub_PNG.png" alt="" class="w-6 h-6 mr-2">
            <h2>IDEAHUB</h2>
          </div>
          <p>The dynamic forum where investors converge to spark innovation and share actionable investment ideas.
            Whether you're looking to brainstorm with investors of all levels or seek feedback on your latest investment
            thesis, IdeaHub is your go-to platform for collective intelligence.</p>
        </div>
        <div routerLink="/quizzes"
          class="bg-light border rounded p-4 shadow transition-transform transform hover:-translate-y-2 hover:shadow-lg">
          <div class="flex items-center mb-2 hover-container">
            <img class="icons" src="assets\icons\compass.png" alt="" class="w-6 h-6 mr-2">
            <h2>EXPLORE</h2>
          </div>
          <p>Find streamlined access points to the most critical and valuable content, handpicked to enhance your
            investment decisions and market understanding.</p>
        </div>
        <div routerLink="/bookmar-articles"
          class="bg-light border rounded p-4 shadow transition-transform transform hover:-translate-y-2 hover:shadow-lg">
          <div class="flex items-center mb-2">
            <img class="icons" src="assets\icons\Bookmark-PNG.png" alt="" class="w-6 h-6 mr-2">
            <h2>BOOKMARK</h2>
          </div>
          <p> Keep track of all the content that catches your eye in one place</p>
        </div>
        <div routerLink="/leaderBoard"
          class="bg-light border rounded p-4 shadow transition-transform transform hover:-translate-y-2 hover:shadow-lg">
          <div class="flex items-center mb-2">
            <img class="icons" src="assets\icons\diary.png" alt="" class="w-6 h-6 mr-2">
            <h2>DostDiary </h2>
          </div>
          <p> Unlock the power of reflection and organization with your personal DostDiary to record investment ideas,
            track market insights, and chronicle your investment journey for strategic growth which you can share or
            keep private. </p>
        </div>
        <div
          class="border rounded p-4 shadow bg-black text-white transition-transform transform hover:-translate-y-2 hover:shadow-lg">
          <h2 class="text-xl text-light font-semibold">Download the App</h2>
          <p class="text-light">Find out why the smartest investors trust Finimize to help them save time.</p>
        </div>
      </div>
    </div>
  </div>
</app-main-fiminize-layout>