<ng-container *ngIf="useAdminLayout; else authorLayout">
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>

<ng-template #authorLayout>
    <app-author-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-author-layout>
</ng-template>

<ng-template #content>
    <!-- Content specific to both admin and author layouts -->


    <div class="d-flex">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Content Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Manage Drafts</nz-breadcrumb-item>
        </nz-breadcrumb>
        <button class="ms-auto mb-2" routerLink="/articles" nz-button nzType="default"><span nz-icon
                nzType="file-done"></span>Published Articles</button>
        <button class="ms-3 mb-2" (click)="addArticleID()"  nz-button nzType="primary"><span nz-icon
                nzType="plus"></span>Add Article</button>
    </div>

    <nz-card>
        <div class="table-responsive">
            <nz-table #articlesTable [nzData]="draftArticles" nzTableLayout="fixed" [nzLoading]="tableLoading"
                nzShowSizeChanger>
                <thead>
                    <tr>
                        <!-- <th>ID</th> -->
                        <th>Title</th>
                        <th>InvestDost+</th>
                        <th>Category</th>
                        <th>Tag(s)</th>
                        <th>Publication On</th>
                        <th>Updated On</th>
                        <th nzWidth="100px" nzAlign="center">Action</th> <!-- Add an Action column -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let article of articlesTable.data" (click)="openEditDrawer(article.id)">
                        <!-- <td>{{ article.id }}</td> -->
                        <td nzEllipsis>{{ article.title }}</td>
                        <td nzEllipsis>{{ article.is_free_artical === 0 ? 'No' : ' Yes' }}</td>
                        <td>{{ article.category_string }}</td>
                        <td nzEllipsis>{{ article.tag_id }}</td>
                        <td>{{ article.publish_date | date: 'yyyy-MM-dd' }}</td>
                        <td>{{ article.updated_at | date: 'yyyy-MM-dd' }}</td>
                        <td nzAlign="center" (click)="$event.stopPropagation()">

                            <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                nzPopconfirmTitle="Are you sure you want to delete this record?"
                                (nzOnConfirm)="confirm(article.id)" (nzOnCancel)="cancel()">
                                <span nz-icon nzType="delete"></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>

        <nz-drawer [nzVisible]="editDrawer" nzWidth="95%" nzTitle="Edit Article" (nzOnClose)="closeEditDrawer()">
            <ng-container *nzDrawerContent>
                <form [formGroup]="editDraftArticleForm" (ngSubmit)="onSubmit(0)" nz-form nzLayout="vertical">

                    <div class="row">
                        <div class="col-md-8">
                            <nz-card>
                                <nz-row [nzGutter]="5" class="mb-2">
                                    <nz-col class="mt-1">
                                        <nz-form-label nzRequired>Title</nz-form-label>
                                    </nz-col>
                                    <nz-col nzSpan="22">
                                        <nz-form-control nzErrorTip="Please enter title">
                                            <nz-input-group>
                                                <input type="text" nz-input formControlName="title" required
                                                    placeholder="Title" />
                                            </nz-input-group>
                                        </nz-form-control>
                                    </nz-col>
                                </nz-row>
                                <div *ngFor="let editor of Content; let i = index" class="editor-container">
                                    <nz-row [nzGutter]="5">
                                        <nz-col nzSpan="2.5" *ngIf="i!==0">
                                            <nz-form-label class="mt-1 fw-bold">Section Type</nz-form-label>

                                        </nz-col>
                                        <nz-col nzSpan="10">
                                            <nz-select *ngIf="i!==0" nzPlaceHolder="Select Section Type"
                                                [(ngModel)]="editor.section_type_id"
                                                [ngModelOptions]="{ standalone: true }" style="width: 50%;">
                                                <nz-option *ngFor="let type of sectionTypes"
                                                    [nzValue]="type.section_type_id"
                                                    [nzLabel]="type.section_type"></nz-option>
                                            </nz-select>
                                        </nz-col>
                                        <!-- <nz-col nzSpan="9" class="text-end">
                                            <button nz-button nzType="primary" (click)="addEditor(editor)">Update</button>
                                        </nz-col> -->
                                        <nz-col *ngIf="i!==0" nzSpan="11" class="text-end">
                                            <!-- Added a new nz-col for the Delete button with nzSpan="2" -->
                                            <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                                (nzOnConfirm)="delelteArticle(editor.artical_section_id)"
                                                nzPopconfirmTitle="Are you sure you want to delete this record?"
                                                (nzOnCancel)="cancel()">
                                                <span nz-icon nzType="delete"></span>
                                            </button> 
                                        </nz-col>
                                    </nz-row>



                                    <nz-form-item class="mt-2">
                                        <nz-form-control nzErrorTip="Please write something here!">
                                            <quill-editor class="resizable-quill-editor" [modules]="editorOptions"
                                                [sanitize]="false" name="section_content"
                                                [(ngModel)]="editor.section_content"
                                                [ngModelOptions]="{ standalone: true }"
                                                placeholder="Enter your content here..."
                                                style="width: 100%; height: 200px;"></quill-editor>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>

                                <div class="div text-end">
                                    <button nz-button nzType="primary" type="button"
                                        (click)="addRichTextEditor()">Add</button>
                                </div>
                                <!-- <nz-form-item>
                                    <nz-form-label nzRequired>Content</nz-form-label>
                                    <nz-form-control nzErrorTip="Please write something here!">
                                        <quill-editor [modules]="editorOptions" [sanitize]="false" formControlName="content"
                                            placeholder="Enter your content here..."
                                            style="width: 100%; height: 400px; margin-bottom: 3rem;"></quill-editor>
                                    </nz-form-control>
                                </nz-form-item> -->

                            </nz-card>
                        </div>
                        <div class="col-md-4">
                            <nz-card>
                                <button nz-button nzType="primary" type="submit">Submit</button>
                                <button class="ms-3" nz-button nzType="default" type="button">Save
                                    As Draft</button>
                                <nz-divider></nz-divider>
                                <nz-divider></nz-divider>

                                <nz-form-item>
                                    <nz-form-label>Is this for InvestDost+?</nz-form-label>
                                    <nz-radio-group formControlName="is_free_artical">
                                        <label nz-radio [nzValue]="FreeArticle">Yes</label>
                                        <label nz-radio [nzValue]="PaidArticle">No</label>
                                    </nz-radio-group>

                                </nz-form-item>


                                <nz-form-item>
                                    <nz-form-label nzRequired>Category</nz-form-label>
                                    <nz-form-control nzErrorTip="Please select category!"
                                        [nzExtra]="selectedCategoryHint">
                                        <nz-tree-select [nzExpandedKeys]="treeSelectExpandKeys" [nzNodes]="categories"
                                            nzShowSearch nzPlaceHolder="Select Category"
                                            formControlName="category_id"></nz-tree-select>

                                        <ng-template #selectedCategoryHint>
                                            <span class="ps-2">
                                                {{editDraftArticleForm.controls['parent_category_string'].value ?
                                                editDraftArticleForm.controls['parent_category_string'].value +
                                                (editDraftArticleForm.controls['category_string'].value ? ' / ' +
                                                editDraftArticleForm.controls['category_string'].value : '') :
                                                editDraftArticleForm.controls['category_string'].value}}</span>
                                        </ng-template>
                                    </nz-form-control>
                                </nz-form-item>


                                <nz-form-item>
                                    <nz-form-label>Tags</nz-form-label>

                                    <nz-select nzMode="tags"
                                        nzPlaceHolder="To add tags, simply type a tag and press 'Enter'."
                                        formControlName="tag_id">
                                    </nz-select>
                                </nz-form-item>


                                <nz-form-item *ngIf="featuredImagePresent">
                                    <nz-form-label>Featured Image</nz-form-label>
                                    <div class="border p-2 d-flex justify-content-between align-items-center">
                                        <img style="cursor: pointer;" nz-image width="50px" height="50px"
                                            [nzSrc]="featuredImagePath" alt="Uploaded Image" />
                                        <button (click)="removeSelectedImage()" nz-button nzDanger nzType="dashed"
                                            nzShape="circle"><span nz-icon nzType="delete"></span></button>
                                    </div>
                                </nz-form-item>

                                <nz-form-item *ngIf="!featuredImagePresent">
                                    <nz-form-label>Upload Featured Image</nz-form-label>
                                    <label *ngIf="!showImage" for="fileInput" class="file-upload-container">
                                        <input type="file" (change)="onFileSelected($event)" accept="image/*"
                                            id="fileInput" #fileInput style="display: none;" />
                                        <div *ngIf="!selectedFile">
                                            <span>Upload Image</span>
                                        </div>

                                    </label>
                                    <div *ngIf="showImage"
                                        class="border p-2 d-flex justify-content-between align-items-center">
                                        <img style="cursor: pointer;" nz-image width="50px" height="50px"
                                            [nzSrc]="imageSrc" alt="Uploaded Image" />
                                        <span>{{selectedFile?.['name']}}</span>
                                        <button (click)="removeSelectedImage()" nz-button nzDanger nzType="dashed"
                                            nzShape="circle"><span nz-icon nzType="delete"></span></button>
                                    </div>

                                </nz-form-item>

                                <nz-form-item>
                                    <nz-form-label>Slug</nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>
                                            <input type="text" nz-input formControlName="slug"
                                                placeholder="enter-title-slug" />
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>

                                <nz-form-item>
                                    <nz-form-label>Meta Description</nz-form-label>
                                    <nz-form-control>
                                        <nz-input-group>

                                            <textarea rows="5" nz-input formControlName="meta_description"
                                                placeholder="Enter Meta description"></textarea>
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                                <div class="row">
                                    <div class="col-md-6">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-select formControlName="post_type"
                                                    nzPlaceHolder="Select a Post Type">
                                                    <nz-option *ngFor="let item of postTypes"
                                                        [nzValue]="item.ideahub_dropdown_id"
                                                        [nzLabel]="item.sub_name"></nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>

                                    <!-- <div class="col-md-6">
                                      <nz-form-item>
                                        <nz-form-control>
                                          <nz-select formControlName="asset_class" nzPlaceHolder="Select an Asset Class">
                                            <nz-option *ngFor="let item of assetClasses" [nzValue]="item.ideahub_dropdown_id" [nzLabel]="item.sub_name"></nz-option>
                                          </nz-select>
                                        </nz-form-control>
                                      </nz-form-item>
                                    </div> -->

                                    <div class="col-md-6">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-select formControlName="sentiments"
                                                    nzPlaceHolder="Select a Sentiment">
                                                    <nz-option *ngFor="let item of sentiments"
                                                        [nzValue]="item.ideahub_dropdown_id"
                                                        [nzLabel]="item.sub_name"></nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>

                                    <div class="col-md-6">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-select formControlName="holding_period"
                                                    nzPlaceHolder="Select a Holding Period">
                                                    <nz-option *ngFor="let item of holdingPeriods"
                                                        [nzValue]="item.ideahub_dropdown_id"
                                                        [nzLabel]="item.sub_name"></nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>

                                    <div class="col-md-6">
                                        <nz-form-item>
                                            <nz-form-control>
                                                <nz-select formControlName="risk" nzPlaceHolder="Select a Risk">
                                                    <nz-option *ngFor="let item of risks"
                                                        [nzValue]="item.ideahub_dropdown_id"
                                                        [nzLabel]="item.sub_name"></nz-option>
                                                </nz-select>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>

                                </div>
                            </nz-card>
                        </div>
                    </div>
                </form>
            </ng-container>
        </nz-drawer>
    </nz-card>
</ng-template>