<nz-layout style="min-height: 100vh;">
  <nz-layout>
    <nz-header>
      <div class="container-fluid d-flex align-items-center px-3">
        <span>
          <img routerLink="/home-page" class="me-5 header-logo pointer" [src]="sidebarExpandLogo" alt="Invest Dost Logo">
        </span>

        <ul nz-menu nzMode="horizontal" class="ms-auto me-3">

          <li nz-menu-item>
            Leaderboard
          </li>

          <li nz-submenu nzTitle="Categories">
            <ul>
              <li nz-menu-group nzTitle="Banking">
                <ul>
                  <li nz-menu-item>Savings Accounts</li>
                  <li nz-menu-item>Loans</li>
                </ul>
              </li>
              <li nz-menu-group nzTitle="Investments">
                <ul>
                  <li nz-menu-item>Stocks</li>
                  <li nz-menu-item>Mutual Funds</li>
                </ul>
              </li>
            </ul>
          </li>

        </ul>
<!-- 
        <button nz-button nz-dropdown [nzDropdownMenu]="languageDropDownMenu">
          Language
          <img class="ms-2" height="21px" width="21px" src="assets/language-switch.png" />
        </button> -->

        <!-- <nz-dropdown-menu #languageDropDownMenu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item>English</li>
            <li nz-menu-item>French</li>
            <li nz-menu-item>Dutch</li>
          </ul>
        </nz-dropdown-menu> -->

        <!-- <div class="ms-3 hstack gap-2">
          <button nz-button nzType="default" routerLink="/login">Login</button>
          <button nz-button nzType="primary" routerLink="/register">Sign Up</button>
        </div> -->
      </div>

    </nz-header>
    <nz-content class="py-3 border-bottom">
      <div class="container-fluid">
        <ng-content></ng-content>
      </div>
    </nz-content>
    <nz-footer>
      <div class="d-flex">
        <span>&#169; {{ currentYear }} Version 1.0.3 </span>
        <span class="ms-auto"> InvestDost | Development | Designed By
          <a class="ms-1 " target="_blank" rel="noopener noreferrer" href="https://portalwiz.com/">Portalwiz
            Technologies</a>
        </span>
      </div>
    </nz-footer>
  </nz-layout>
</nz-layout>