import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { forkJoin } from 'rxjs';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { Quiz } from 'src/app/Interfaces/Quiz';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';
import { Course } from '../../../../../Interfaces/Course';
import { convertToCategoryTree } from 'src/app/Functions/arrayFunctions';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent {

  

  searchQuery: string = '';
  courses: Course[] = [];
  filteredCourses: Course[] = [];
    courseCategories: any[] = [];
  courseSubCategories: any[] = [];
  treeSelectExpandKeys: string[] = [];
  tableLoading: boolean = true;
  formSubmitLoading: boolean = false;
  allcategories: any[] = [];  // Initialize as an array
  allSymbols: any[] = [];     // Initialize as an array
  quizzes: Quiz[] = [];
  useAdminLayout: boolean = false; 
  currentEditQuizId?: number;

  addDrawer: boolean = false;
  editDrawer: boolean = false;
  roleId:any;

  constructor( private apiService: FetchApiService,private http: HttpClient, private message: NzMessageService, private fetchApiService: FetchApiService) { }

  ngOnInit() {
    this.roleId = localStorage.getItem('role_id');
    this.useAdminLayout = this.roleId === '1' || this.roleId === '2';
    this.fetchInitialData();
    this.fetchDropDowns();
    this.fetchAllSymbols();
  }

  addCourseForm: FormGroup = new FormGroup({
      title:  new FormControl(''),
      category_id: new FormControl(''),
      symbol_id: new FormControl([]),
      quiz_id: new FormControl(''),
      tags: new FormControl([]),
      is_active:  new FormControl(''),
      description:  new FormControl(''),
      prerequisites:  new FormControl(''),
      created_by: new FormControl(localStorage.getItem('userId')),
  });

  editCourseForm: FormGroup = new FormGroup({
    course_id: new FormControl(''),
    title:  new FormControl(''),
    category_id: new FormControl(''),
    symbol_id: new FormControl(''),
    quiz_id: new FormControl(''),
    tags: new FormControl([]),
    is_active:  new FormControl(''),
    description:  new FormControl(''),
    prerequisites:  new FormControl(''),
    updated_by: new FormControl(localStorage.getItem('userId')),
  });



  fetchDropDowns(): Promise<any> {
    this.apiService.getCategories().subscribe({
      next: (response: any) => {
        console.log('Category Dropdown: ', response);
        const treeData = convertToCategoryTree(response.data);
        console.log("treedata",treeData);
        this.allcategories = treeData;
      },
      error: (error) => {
        console.error('Error: ', error);
      },
    });
    return Promise.resolve(true);
  }
  fetchAllSymbols(): Promise<void> {
    this.tableLoading = true;
    const apiUrl = `${environment.API_URL}symbol_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          this.allSymbols = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }


  searchCourses(): void {
    const query = this.searchQuery.toLowerCase();
    if (query) {
      this.filteredCourses = this.courses.filter(article =>
        article.title.toLowerCase().includes(query)
      );
    } else {
      this.filteredCourses = this.courses; 
    }
  }
  fetchInitialData(): Promise<void> {
    this.tableLoading = true;
    return new Promise((resolve, reject) => {
      const courses$ = this.fetchApiService.getCourses();
      const quizzes$ = this.fetchApiService.getAllQuizzes();
      const courseCategories$ = this.fetchApiService.getUserCourseCategories();
      const courseSubCategories$ = this.fetchApiService.getUserCourseSubCategories();

      forkJoin([courses$, courseCategories$, courseSubCategories$, quizzes$]).subscribe({
        next: ([coursesResponse, categoriesResponse, subCategoriesResponse, quizzesResponse]: any) => {
          console.log('Courses: ', coursesResponse);
          console.log('Course Categories: ', categoriesResponse);
          console.log('Course Sub Categories: ', subCategoriesResponse);
          console.log('Quizzes: ', quizzesResponse);
          this.courses = coursesResponse;
          this.courseCategories = categoriesResponse;
          this.courseSubCategories = subCategoriesResponse;
          this.quizzes = quizzesResponse.data;
          this.filteredCourses = this.courses;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          reject(error);
        },
        complete: () => {
          this.tableLoading = false;
          resolve();
        },
      });

    });
  }

  fetchCourses(): Promise<void> {
    this.tableLoading = true;
    return new Promise((resolve, reject) => {
      const courses$ = this.fetchApiService.getCourses();

      forkJoin([courses$]).subscribe({
        next: ([coursesResponse]: any) => {
          console.log('Courses: ', coursesResponse);
          this.courses = coursesResponse;
          this.filteredCourses = [...this.courses]; 
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          reject(error);
        },
        complete: () => {
          this.tableLoading = false;
          resolve();
        },
      });

    });
  }

  openEditDrawer(courseId: number): void {
    this.currentEditQuizId = courseId;

    let courseDetails = this.courses.find(course => course.course_id === courseId);
    console.log(courseDetails);
    this.editCourseForm.patchValue(courseDetails!);
    this.editDrawer = true;
  }

  closeEditDrawer(): void {
    this.editDrawer = false;
    this.currentEditQuizId = undefined;
  }

  openAddDrawer(): void {
    this.addDrawer = true;
  }

  closeAddDrawer() {
    this.addDrawer = false;
  }

  cancel(): void {
  }

  confirm(id: number): void {
    this.deleteRecord(id);
  }

  submitAddForm() {
    this.formSubmitLoading = true;
    const apiUrl = `${environment.API_URL}add_course`;

    const requestData = {
      ...this.addCourseForm.value,
    };
    console.log(requestData);
    console.log(requestData);
    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchCourses().then(() => {
              this.message.success('Course added');
              this.addCourseForm.reset();
              this.addCourseForm.patchValue({
                is_active: true,
                created_by: sessionStorage.getItem('userId')
              });
              this.closeAddDrawer();
            }).catch((error) => {
              this.message.error('Error occurred, please try later');
              console.log(error);
            });
          } else {
            this.message.error('Error occurred, please try later');
          }
          this.formSubmitLoading = false;

        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
          this.formSubmitLoading = false;
        }
      });
  }

  submitEditForm() {
    this.formSubmitLoading = true;
    const apiUrl = `${environment.API_URL}edit_course`;
    const requestData = {
      ...this.editCourseForm.value,
      updated_by: localStorage.getItem('userId')
    };
    console.log(requestData);
    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchCourses().then(() => {
              this.message.success('Course updated');
              this.editCourseForm.reset();
              this.closeEditDrawer();
            });
          } else {
            this.message.error('Error occurred, please try later');
          }
          this.formSubmitLoading = false;

        },
        error: (error) => {
          console.error('Error: ', error);
          this.formSubmitLoading = false;

        }
      });
  }

  deleteRecord(id: number) {
    const apiUrl = `${environment.API_URL}delete_course`;

    const requestData = {
      course_id: id,
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchInitialData().then(() => {
              this.message.success('Course deleted');
            });

          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        }
      });
  }
  sortTitle = (a: any, b: any) => a.title.localeCompare(b.title);
sortCategory = (a: any, b: any) => a.category_name.localeCompare(b.category_name);
sortQuizName = (a: any, b: any) => a.quiz_name.localeCompare(b.quiz_name);
sortTickerName = (a: any, b: any) => a.symbol_name.localeCompare(b.symbol_name);
sortTags = (a: any, b: any) => a.tags.localeCompare(b.tags);
sortStatus = (a: any, b: any) => a.is_active - b.is_active;
sortCreatedBy = (a: any, b: any) => a.created_by_name.localeCompare(b.created_by_name);
sortCreatedAt = (a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
sortUpdatedAt = (a: any, b: any) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
sortUpdatedBy = (a: any, b: any) => a.updated_by_name.localeCompare(b.updated_by_name);

}
