import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Message } from 'src/app/Interfaces/Message';


@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent {
  useAdminLayout: boolean = false; // Default to non-admin layout
  tableLoading: boolean = true;
  roleId:any;
  ngOnInit() {
  
    this.roleId = localStorage.getItem('role_id');
    this.useAdminLayout = this.roleId === '1' || this.roleId === '2';
    setTimeout(() => {
      this.tableLoading = false;
    }, 3000);
  }

  messages: Message[] = [
    {
      "id": 1,
      "sender": "User1",
      "receiver": "User2",
      "subject": "Meeting Reminder",
      "content": "Don't forget the meeting tomorrow at 2 PM.",
      "timestamp": new Date("2023-10-13")
    },
    {
      "id": 2,
      "sender": "User2",
      "receiver": "User1",
      "subject": "Re: Meeting Reminder",
      "content": "I'll be there. Thanks for the reminder.",
      "timestamp": new Date("2023-10-13")
    },
    {
      "id": 3,
      "sender": "User3",
      "receiver": "User1",
      "subject": "Project Update",
      "content": "Here's the latest project update.",
      "timestamp": new Date("2023-10-14")
    },
    {
      "id": 4,
      "sender": "User1",
      "receiver": "User3",
      "subject": "Re: Project Update",
      "content": "Thanks for the update. Looks good!",
      "timestamp": new Date("2023-10-14")
    },
    {
      "id": 5,
      "sender": "User4",
      "receiver": "User1",
      "subject": "Feedback Request",
      "content": "We'd like to hear your feedback on our service.",
      "timestamp": new Date("2023-10-15")
    }
  ];


  addMessageForm = new FormGroup({
    receiver: new FormControl('', [Validators.required]),
    content: new FormControl('', [Validators.required]),
  });

  replyForm = new FormGroup({
    receiver: new FormControl('', [Validators.required]),
    content: new FormControl('', [Validators.required]),
  });

  addDrawer: boolean = false;
  viewDrawer: boolean = false;

  openViewDrawer(id: number): void {
    this.viewDrawer = true;
  }

  closeViewDrawer(): void {
    this.viewDrawer = false;
  }

  openAddDrawer(): void {
    this.addDrawer = true;
  }

  closeAddDrawer() {
    this.addDrawer = false;
  }

  cancel(): void {
  }

  confirm(): void {
  }

  onReplySubmit() {
  }

  onNewMessageSubmit() {

  }
}
