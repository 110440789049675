import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { removeHtmlTags } from 'src/app/Functions/Sanitize';
import { Article } from 'src/app/Interfaces/Content';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-article-display-style-three',
  templateUrl: './article-display-style-three.component.html',
  styleUrls: ['./article-display-style-three.component.scss']
})
export class ArticleDisplayStyleThreeComponent {
  @Input() article!: Article;
  randomImageUrl: string = '';
  fallbackImage: string = 'assets/fallback.webp';

  populateRandomImageUrls() {
    const randomIndex = Math.floor(Math.random() * 8); // 8 images preset in dummy-images folder
    // this.randomImageUrl = `assets/dummy-images/dumyimg${randomIndex + 1}.jpg`;
    this.randomImageUrl = `${environment.STORAGE_URL}${this.article.featured_img_path}`;

  }
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.populateRandomImageUrls();
  }


  removeTags(content: string) {
    return removeHtmlTags(this.sanitizer, content);
  }
}
