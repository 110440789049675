import { Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-home-page-layout',
  templateUrl: './home-page-layout.component.html',
  styleUrls: ['./home-page-layout.component.scss']
})
export class HomePageLayoutComponent {
  currentYear: number = new Date().getFullYear();

  sidebarExpandLogo: SafeUrl;
  constructor(private sanitizer: DomSanitizer, private message: NzMessageService, private route: ActivatedRoute) {
    // Create a safe URL for the image file
    this.sidebarExpandLogo = this.sanitizer.bypassSecurityTrustResourceUrl('assets/InvestDostFull.png');
  }

  underDevelopmentMessage(): void {
    this.message.info('Under Development');
  }
}
