<ng-container>
  <app-admin-layout>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-admin-layout>
</ng-container>
<ng-template #content>

    <nz-breadcrumb>
      <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
      <nz-breadcrumb-item>Content Management</nz-breadcrumb-item>
      <nz-breadcrumb-item>leaderBoardPoint</nz-breadcrumb-item>
    </nz-breadcrumb>
  
    <div class="d-flex align-items-center mb-2 justify-content-between">
      <div class="d-flex align-items-center">
        <nz-input-group nzPrefixIcon="search">
          <input nz-input placeholder="Search Activity" [(ngModel)]="searchTerm" (ngModelChange)="onSearch()" />
        </nz-input-group>
        <div class="mb-3 ms-3" *ngIf="searchTerm">
          <span><i>Showing {{ filteredLeaderboardPoints.length }} of {{ leaderboardPoints.length }} Activity</i></span>
        </div>
      </div>
      <button nz-button nzType="primary" (click)="toggleDrawer('add')">
        <span nz-icon nzType="plus"></span>Add LeaderPoints
      </button>
    </div>
  
  <nz-card>
    <div class="table-responsive">
      <nz-table #basicTable [nzData]="filteredLeaderboardPoints">
        <thead>
          <tr>
            <th nzWidth="50px" [nzSortFn]="sortByIndex">Sr. No.</th>
            <th nzWidth="100px" [nzSortFn]="sortByType">Type</th>
            <th nzWidth="200px" [nzSortFn]="sortByActivity">Activity</th>
            <th nzWidth="100px" [nzSortFn]="sortByPoints">Points</th>
            <th nzWidth="300px" [nzSortFn]="sortByDescription">Description</th>
            <th nzWidth="100px" [nzSortFn]="sortByMaxPerDay">Max Per Day</th>
            <th nzWidth="100px" [nzSortFn]="sortByStatus">Status</th>
            <th nzWidth="200px" [nzSortFn]="sortByCreatedBy">Created By</th>
            <th nzWidth="200px" [nzSortFn]="sortByCreatedAt">Created At</th>
            <th nzWidth="200px" [nzSortFn]="sortByUpdatedBy">Updated By</th>
            <th nzWidth="200px" [nzSortFn]="sortByUpdatedAt">Updated At</th>
            <th nzWidth="100px" nzAlign="center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let point of filteredLeaderboardPoints;let i = index" (click)="toggleDrawer('edit', point)">
            <td>{{ i + 1 }}</td>
            <td>{{ point.leaderboard_type }}</td>
            <td>{{ point.activity_name }}</td>
            <td>{{ point.points_allotted }}</td>
            <td>{{ point.description }}</td>
            <td>{{ point.max_per_day ? point.max_per_day : '--'}}</td>
            <td>{{ point.active_status_id === 1 ? 'Active' : 'Inactive'}}</td>
            <td>{{ point.created_by_string }}</td>
            <td>{{ point.created_at | date: 'short' }}</td>
            <td>{{ point.updated_by_string ? point.updated_by_string : '--'}}</td>
            <td>{{ point.updated_at | date: 'short' }}</td>
            <td nzAlign="center" (click)="$event.stopPropagation()">
              <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                nzPopconfirmTitle="Are you sure you want to delete this record?"
                (nzOnConfirm)="deleteRecord(point.leaderboard_point_id)" (nzOnCancel)="cancel()">
                <span nz-icon nzType="delete"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </nz-card>
  <nz-drawer [(nzVisible)]="isDrawerVisible" [nzTitle]="drawerTitle" nzPlacement="right" (nzOnClose)="closeDrawer()"
    [nzWidth]="400">
    <ng-container *nzDrawerContent>
      <form [formGroup]="currentForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="activity_name">Activity Name</label>
          <input nz-input formControlName="activity_name" placeholder="Enter activity name" />
        </div>
        <div class="form-group">
          <label for="points_allotted">Points Allotted</label>
          <input nz-input formControlName="points_allotted" type="number" placeholder="Enter points" />
        </div>
        <nz-form-item>
          <nz-form-label>Symbol status?</nz-form-label>
          <nz-radio-group formControlName="active_status_id">
            <label nz-radio [nzValue]="1">Active</label>
            <label nz-radio [nzValue]="2">Inactive</label>
          </nz-radio-group>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label>Select Type</nz-form-label>
          <nz-select class="w-100" formControlName="leaderboard_type_id" nzPlaceHolder="Select a leaderboard type">
            <nz-option *ngFor="let type of leaderboardTypes" [nzValue]="type.leaderboard_type_id" [nzLabel]="type.leaderboard_type">
            </nz-option>
          </nz-select>
      </nz-form-item>
        <div class="form-group">
          <label for="description">Description</label>
          <textarea nz-input formControlName="description" placeholder="Enter description"></textarea>
        </div>
        <button nz-button nzType="primary" >Submit</button>
      </form>
    </ng-container>
  </nz-drawer>

</ng-template>