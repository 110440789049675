import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-marque',
  templateUrl: './marque.component.html',
  styleUrls: ['./marque.component.scss']
})
export class MarqueComponent implements OnInit{
  markupLines: any[] = [];
  articles: any[] = [];
  filteredMarkupLines: any[] = [];
  addDrawer: boolean = false;
  editDrawer: boolean = false;
  searchQuery: string = '';
  constructor(
    private fetchData: FetchApiService,
    private _activatedRoute: ActivatedRoute,
    private message: NzMessageService,
    private http: HttpClient,
  ) {}

  markupForm  = new FormGroup({
    markup_line_id: new FormControl(null),
    markup_line: new FormControl('', Validators.required),
    active_status_id: new FormControl(null),
    sequence: new FormControl(null),
    artical_id: new FormControl(null), 
    created_at: new FormControl({value: '', disabled: true}),
    updated_at: new FormControl({value: '', disabled: true})

  });

  ngOnInit(): void {
    this.fetchAllMarque();
    this.loadArticles();
  }
 
  fetchAllMarque(): Promise<any> {
    const apiUrl = `${environment.API_URL}fetch_all_markup_line`;
    this.http.get(apiUrl).subscribe({
      next: (response: any) => {
        this.markupLines = response;  
      },
      error: (error: any) => {
        console.error('Error: ', error);
      },
      complete: () => {
     
      },
    });
    return Promise.resolve(true);
  }
  loadArticles(): Promise<any> {
    const apiUrl = `${environment.API_URL}article_dropdown`;
    this.http.get(apiUrl).subscribe({
      next: (response: any) => {
        this.articles = response;
      },
      error: (error: any) => {
        console.error('Error: ', error);
      },
      complete: () => {
     
      },
    });
    return Promise.resolve(true);
  }

  filterMarkupLines(): void {
    const query = this.searchQuery.toLowerCase().trim();
    if (query) {
      this.markupLines = this.markupLines.filter(marqueLine =>
        marqueLine.markup_line.toLowerCase().includes(query)
      );
    } else {
      this.fetchAllMarque(); // Reset to fetch all categories when query is empty
    }
  }

  submitAddForm() {
    const apiUrl = `${environment.API_URL}add_markup`;
    const requestData = {

      user_id: localStorage.getItem('userId'),
      role_id: localStorage.getItem('role_id'),
      artical_id: this.markupForm.get('artical_id')?.value,
      markup_line: this.markupForm.get('markup_line')?.value,
      active_status_id: this.markupForm.get('active_status_id')?.value,
      sequence: this.markupForm.get('sequence')?.value,
    };
    console.log(requestData);
    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchAllMarque().then(() => {
              this.message.success('Marque added');
              this.markupForm.reset();
              this.closeAddDrawer();
            });

          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        }
      });
  }
  populateForm(markupLine: any): void {
    this.markupForm.patchValue({
      markup_line_id: markupLine.markup_line_id,
      markup_line: markupLine.markup_line,
      artical_id: markupLine.artical_id,
      sequence: markupLine.sequence,
      active_status_id: markupLine.active_status_id,
    });
  }
  fetchSingleMarkupLine(markqueID: number,): void {
    const apiUrl = `${environment.API_URL}fetch_single_markup_line`;
    const requestData = { markup_line_id: markqueID };
    this.http.post<any>(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Fetch Single Markup Line Response:', response);
        if (response && response.length > 0) {
          const markupLine = response[0];
          this.populateForm(markupLine);
          this.editDrawer = true; // Open edit drawer after fetching data
        } else {
          this.message.error('Markup line not found.');
        }
      },
      error: (error: any) => {
        console.error('Error fetching markup line:', error);
        this.message.error('An error occurred while fetching markup line.');
      }
    });
  }
  confirm(id: number): void {
    this.deleteCategory(id);
  }
  deleteCategory(id: number) {
    const apiUrl = `${environment.API_URL}delete_markup_line`;

    const requestData = {
      markup_line_id: id,
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchAllMarque().then(() => {
              this.message.success('Marque deleted');
            });

          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        }
      });
  }
  cancel(): void {
  }
  openAddDrawer(): void {
    this.addDrawer = true;
  }
  submitEditForm(): void {
    const apiUrl = `${environment.API_URL}edit_markup`;
    const requestData = {
      markup_line_id: this.markupForm.get('markup_line_id')?.value,
      artical_id: this.markupForm.get('artical_id')?.value,
      user_id: localStorage.getItem('userId'),
      markup_line: this.markupForm.get('markup_line')?.value,
      active_status_id: this.markupForm.get('active_status_id')?.value,
      sequence: this.markupForm.get('sequence')?.value,
      updated_by: localStorage.getItem('userId')
    };
  
    this.http.post<any>(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Edit Markup Response:', response);
        if (response.success) {
          this.fetchAllMarque(); // Refresh markup lines list
          this.message.success('Markup line updated');
          this.closeEditDrawer();
        } else {
          this.message.error('Error occurred while updating markup line');
        }
      },
      error: (error: any) => {
        console.error('Error editing markup line:', error);
        this.message.error('An error occurred while updating markup line');
      }
    });
  }
  
  closeAddDrawer() {
    this.addDrawer = false;
  }
  closeEditDrawer(): void {
    this.editDrawer = false;
  }
  sortByMarqueeLine = (a: any, b: any): number => {
    return (a.markup_line || '').localeCompare(b.markup_line || '');
  };
  sortByArticle = (a: any, b: any): number => {
    return (a.artical_name || '').localeCompare(b.artical_name || '');
  };

  sortByStatus = (a: any, b: any): number => {
    return (a.status === 'Active' ? 1 : 0) - (b.status === 'Active' ? 1 : 0);
  };

  sortBySequence = (a: any, b: any): number => {
    return a.sequence - b.sequence;
  };

  sortByDate = (a: any, b: any): number => {
    return new Date(a).getTime() - new Date(b).getTime();
  };

}
