<ng-container [ngSwitch]="userRole">
    <ng-container *ngSwitchCase="'admin'">
        <app-admin-layout>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </app-admin-layout>
    </ng-container>
    <ng-container *ngSwitchCase="'author'">
        <app-author-layout>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </app-author-layout>
    </ng-container>
    <ng-container *ngSwitchCase="'reader'">
        <app-reader-layout>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </app-reader-layout>
    </ng-container>
    <ng-container *ngSwitchCase="'subscriber'">
        <app-subscriber-layout>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </app-subscriber-layout>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <app-home-page-layout>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </app-home-page-layout>
    </ng-container>
</ng-container>


<ng-template #content>
    <div class="page-content">
        <nz-spin [ngClass]="{'spinner-with-overlay': isLoading}" [nzSpinning]="isLoading" nzSize="large"></nz-spin>

        <div class="row">
            <div class="col-12 col-md-9">
                <ng-container *ngIf="isLoading">
                    <nz-empty></nz-empty>
                </ng-container>

                <img *ngIf="!isLoading" class="img-fluid light-shadow article-image" nz-image [nzSrc]="randomImageUrl"
                    [alt]="mainArticle.title" style="max-height: 300px; max-width: 100%;"
                    [nzFallback]="fallbackImage" />

                <nz-breadcrumb class="ps-0 my-3">
                    <nz-breadcrumb-item>Category</nz-breadcrumb-item>
                    <nz-breadcrumb-item>
                        <ng-container *ngFor="let category of mainArticle.categoryArray">
                            <a [routerLink]="['/article-category', 'category', category.category_id]">
                                <nz-tag [nzColor]="'#2db7f5'" class="breadcrumb-tag">{{ category.category_name }}</nz-tag>
                            </a>
                        </ng-container>
                    </nz-breadcrumb-item>
                </nz-breadcrumb>
                <nz-card-meta [nzTitle]="mainArticle.title"></nz-card-meta>

                <nz-divider></nz-divider>
                <div class="text-center" *ngIf="mainArticle.article_view">
                    <ng-container>
                        <h2 class="mb-3" style="font-size: 24px;">Please log in to access the article.</h2>
                        <button nz-button nzType="primary" routerLink="/home-page">Login</button>
                    </ng-container>
                </div>

                <div *ngFor="let editor of Content; let i = index" class="editor-container">
                    <h1 *ngIf="editor.section_type !== 'Normal'" class="mt-2">
                        <b>
                            <ng-container *ngIf="editor.section_type !== 'Normal'; else noIcon">
                                <ng-container [ngSwitch]="editor.section_type">
                                    <span *ngSwitchCase="'DostTips'"
                                        nz-tooltip="A pointer to make sure you are headed in the right direction and understand what to look out for when related to the topic.">
                                        <i nz-icon nzType="exclamation-circle" class="icon"></i>
                                    </span>
                                    <span *ngSwitchCase="'DostSecret'"
                                        nz-tooltip="A piece of information with a value component that you may not have previously known and required in-depth analysis to uncover.">
                                        <i nz-icon nzType="exclamation-circle" class="icon"></i>
                                    </span>
                                    <span *ngSwitchCase="'DostSolution'"
                                        nz-tooltip="This is to develop an upper hand for the topic discussed and make sure you know how to get started.">
                                        <i nz-icon nzType="exclamation-circle" class="icon"></i>
                                    </span>
                                    <span *ngSwitchCase="'DostInsights'"
                                        nz-tooltip="An element aimed to give you extra knowledge on the topic backed by real-world experience.">
                                        <i nz-icon nzType="exclamation-circle" class="icon"></i>
                                    </span>
                                    <span *ngSwitchCase="'DostFact'"
                                        nz-tooltip="A quick fact about the topic in the article so you have more in-depth details which you can use to build your own judgment.">
                                        <i nz-icon nzType="exclamation-circle" class="icon"></i>
                                    </span>
                                    <span *ngSwitchDefault>
                                        <i nz-icon nzType="exclamation-circle" class="icon"></i>
                                    </span>
                                </ng-container>
                            </ng-container>
                            {{editor.section_type}}
                            <ng-container *ngIf="editor.symbolArray && editor.symbolArray.length > 0">
                                <span class="symbol-container" *ngFor="let sym of editor.symbolArray">
                                    <nz-tag [routerLink]="['/article-category', 'tickers', sym.symbol_id]" [nzColor]="'#2db7f5'" class="symbol-item" nz-tooltip [nzTooltipTitle]="sym.symbol_name">
                                        {{ sym.symbol }}
                                    </nz-tag>
                                </span>
                            </ng-container>
                        </b>
                    </h1>
                    <ng-template #noIcon></ng-template>
                    <div class="section-content" [ngClass]="editor.section_type === 'Normal' ? '' : 'dost'">
                        <ng-container *ngIf="editor.section_type === 'Normal' || roleID === '1'; else notNormalContent">
                            <div [innerHTML]="editor.section_content" (click)="onLinkClick($event)"></div>
                        </ng-container>
                        <ng-template #notNormalContent>
                            <div class="button-container">
                                <button class="custom-button primary" routerLink="/start-free-trial">Start Free
                                    Trial</button>
                                <button class="custom-button secondary" routerLink="/subscription">Subscription</button>
                            </div>
                        </ng-template>
                    </div>
                </div>


                <nz-modal [(nzVisible)]="isCourseModalVisible" (nzOnCancel)="isCourseModalVisible = false" nzCentered>
                    <ng-container *nzModalContent>
                        <div [innerHTML]="modalHtmlContent"></div>
                        <nz-spin [nzSpinning]="isCourseModalLoading">


                            <div *ngIf="modalCourseLectureData?.content_type_id === 1" class="ql-editor p-0"
                                style="height: 60vh;" [innerHTML]="modalCourseLectureData?.content">
                            </div>

                            <div *ngIf="modalCourseLectureData?.content_type_id === 2 && !isCourseModalLoading"
                                class=" ratio ratio-21x9" style="height: 60vh;">
                                <iframe [src]="sanitizeURL(modalCourseLectureData?.content_url!)" title="Lecture player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen>
                                </iframe>
                            </div>


                            <nz-result nzStatus="success"
                                *ngIf="(currentLectureNumber !==1) && !modalCourseLectureData && !isCourseModalLoading"
                                nzTitle="Congratulations! You've successfully completed the course.">
                            </nz-result>


                            <nz-result nzStatus="info"
                                *ngIf="(currentLectureNumber ===1) &&!modalCourseLectureData && !isCourseModalLoading"
                                nzTitle="Oops!, it seems there are currently no lectures available for this course.">
                            </nz-result>

                        </nz-spin>
                    </ng-container>
                    <ng-template [nzModalTitle]>
                        <div class="vstack">
                            <p>{{modalCourseData?.title}}</p>
                            <span
                                *ngIf="!isCourseModalLoading && modalCourseLectureData">{{modalCourseData?.courseSections[0]?.title}}:
                                <span class="small">{{modalCourseLectureData.title}}</span> </span>
                        </div>
                    </ng-template>

                    <ng-template [nzModalFooter]>
                        <div class="d-flex">
                            <a nz-tooltip nzTooltipTitle="Open Course" class="me-auto" nz-button nzType="primary"
                                [routerLink]="['/course',  'preview', modalCourseSlug,]" target="_blank">
                                <span nz-icon nzType="play-circle" nzTheme="outline"></span>
                            </a>
                            <button nz-button nzType="primary" type="button" [disabled]="currentLectureNumber === 1"
                                (click)="handlePreviousLectureClick()"> <span nz-icon nzType="left"></span> Previous
                                Lecture</button>
                            <button nz-button nzType="primary" type="button" [disabled]="!modalCourseLectureData"
                                (click)="handleNextLectureClick()">Next Lecture
                                <span nz-icon nzType="right"></span></button>
                        </div>
                    </ng-template>
                </nz-modal>
                <nz-divider></nz-divider>

                <div class="d-flex align-items-center mb-3 flex-wrap">
                    <nz-avatar nz-comment-avatar nzIcon="user" style="cursor: pointer;"
                        class="d-flex justify-content-center align-items-center"></nz-avatar>
                    <a [routerLink]="['/author-details', mainArticle.id]" class="ms-3">
                        <nz-card-meta [nzTitle]="mainArticle.author_name"></nz-card-meta>
                    </a>

                    <div class="d-flex align-items-center ms-auto">
                        <div class="me-3">
                            <button nz-button nzShape="circle" nzType="default">
                                <span nz-icon nzType="exclamation" nzTheme="outline"></span>
                            </button>

                        </div>
                        <div class="me-3">
                            <button nz-button nzShape="circle" nzType="default" (click)="showModal('share')">
                                <span nzShape="circle" nz-icon nzType="share-alt" nzTheme="outline"></span>
                            </button>

                        </div>

                        <div class="me-3">
                            <ng-container *ngFor="let tag of mainArticle.tagArray">
                                <a [routerLink]="['/article-category', 'tags', tag.tag_id]">
                                    <nz-tag [nzColor]="'#2db7f5'" class="breadcrumb-tag">{{ tag.tag_name }}</nz-tag>
                                </a>
                            </ng-container>
                        </div>

                        <div class="like-container d-flex align-items-center">

                            <div class="ms-auto p-2 bd-highlight">
                                <button nz-button nzShape="circle" nzType="default"
                                    (click)="likeButton(mainArticle.like_status === 0 || mainArticle.like_status === null ? 1 : 0)">
                                    <i [ngClass]="mainArticle.like_status === 1 ? 'fa-solid fa-thumbs-up' : 'fa-regular fa-thumbs-up'"
                                        [ngStyle]="{ color: mainArticle.like_status === 1 ? '#1890ff' : '' }"></i>
                                </button>
                                <span class="me-2"><b>{{ mainArticle.like_count }}</b></span>
                            </div>

                            <div class="ms-auto p-2 bd-highlight">
                                <button *ngIf="mainArticle.bookmark_status === 0">

                                    <i (click)="addBookmarks(mainArticle.id)" class="fa-regular fa-bookmark"></i>
                                </button>

                                <button *ngIf="mainArticle.bookmark_status === 1">
                                    <i (click)="deleteBookmarkedArticle(mainArticle.id)"
                                        class="fa-solid fa-bookmark"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="mainArticle.quiz_id">

                    <button nz-button [nzType]="'primary'" (click)="fetchQuizQuestions(mainArticle.quiz_id)">
                        <span>Attend Quiz</span>
                    </button>
                </div>

                <nz-comment class="w-75">
                    <nz-avatar class="d-flex justify-content-center align-items-center" nz-comment-avatar
                        nzIcon="user"></nz-avatar>
                    <nz-comment-content class="d-flex align-items-center">
                        <form [formGroup]="commentForm" class="flex-grow-1 me-3">
                            <textarea (input)="updateCharacterCount()" formControlName="comment_text" nz-input rows="2"
                                placeholder="Write a comment..." class="form-control"></textarea>
                            <span *ngIf="characterCount > 0">{{ characterCount }} / 500</span>
                        </form>
                        <button nz-button type="button" (click)="addComment()" nzType="primary"
                            [disabled]="!commentForm.valid" class="btn btn-primary">Add Comment</button>
                    </nz-comment-content>

                    <div *ngIf="comments.length > 0 && roleID">
                        <div *ngFor="let comment of comments" style="display: flex; align-items: center;" class="mb-3">
                            <div style="flex-grow: 1;">
                                <p><strong>{{ comment.user_name }}</strong> ({{ comment.created_at | date: 'short' }})
                                </p>
                                <p>{{ comment.comment_text }}</p>
                            </div>

                            <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm nzSize="small"
                                (nzOnConfirm)="deleteArticleComment(comment.comment_id)"
                                nzPopconfirmTitle="Are you sure you want to delete this record?"
                                (nzOnCancel)="cancel()">
                                <span nz-icon nzType="delete"></span>
                            </button>

                        </div>
                    </div>
                    <div *ngIf="comments.length === 0">
                        <p>No comments available.</p>
                    </div>
                </nz-comment>
            </div>
            <div class="col-12 col-md-3">
                <app-section-heading title="Editor's Picks"></app-section-heading>
                <ng-container *ngIf="isLoading">
                    <nz-empty></nz-empty>
                </ng-container>

                <nz-list>
                    <nz-list-item *ngFor="let article of editorsPicksArticles; let i = index;">
                        <app-article-display-style-two [article]="article"></app-article-display-style-two>
                    </nz-list-item>
                </nz-list>
            </div>
        </div>
    </div>
  
    <nz-modal [(nzVisible)]="isVisible" [nzTitle]="modalTitle" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
        <ng-container *nzModalContent>
            <!-- Quiz Content -->
            <ng-container *ngIf="modalType === 'quiz'">
                <div class="d-flex justify-content-end align-items-center mb-3">
                    <span class="badge bg-dark text-white p-2 rounded">
                        {{ remainingTime }} seconds
                    </span>
                </div>
                <ng-container *ngIf="quizQuestions?.length; else noQuestions">
                    <div class="row">
                        <div class="col">
                            <h1><b>{{ quizQuestions[currentQuestionIndex].question_text }}</b></h1>
                        </div>
                        <div class="col-auto ml-auto">
                            Points: {{ quizQuestions[currentQuestionIndex].points }}
                        </div>
                    </div>
                    <ul class="list-group">
                        <li *ngFor="let option of quizQuestions[currentQuestionIndex].options">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" (change)="saveData($event)"
                                    name="option_{{ quizQuestions[currentQuestionIndex].id }}"
                                    [value]="option.option_text" id="option_{{ option.id }}">
                                <label class="form-check-label" for="option_{{ option.id }}">{{
                                    option.option_text }}</label>
                            </div>

                        </li>
                    </ul>


                    <div class="navigation-buttons mt-3">
                        <button nz-button nzType="primary" nzShape="round" (click)="previousQuestion()"
                            [disabled]="currentQuestionIndex === 0">
                            <span nz-icon nzType="left"></span>
                        </button>
                        <button nz-button nzType="primary" nzShape="round" (click)="nextQuestion()"
                            [disabled]="currentQuestionIndex >= quizQuestions.length - 1">
                            <span nz-icon nzType="right"></span>
                        </button>
                    </div>
                </ng-container>
                <ng-template #noQuestions>
                    <p>No quiz questions available.</p>
                </ng-template>
            </ng-container>

            <!-- Share Content -->
            <ng-container *ngIf="modalType === 'share'">
                <div class="social-media-dialog">
                    <button nz-button nzType="default" nzShape="circle">
                        <span nz-icon nzType="facebook" nzTheme="fill"></span>
                    </button>
                    <button nz-button nzType="default" nzShape="circle">
                        <span nz-icon nzType="twitter" nzTheme="outline"></span>
                    </button>
                    <button nz-button nzType="default" nzShape="circle">
                        <span nz-icon nzType="linkedin" nzTheme="outline"></span>
                    </button>
                    <button nz-button nzType="default" nzShape="circle">
                        <span nz-icon nzType="whats-app" nzTheme="outline"></span>
                    </button>
                </div>
            </ng-container>
        </ng-container>
    </nz-modal>
</ng-template>