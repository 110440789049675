<ng-container>
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>

<!-- <ng-container >
    <app-author-layout>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-author-layout>
  </ng-container> -->

<ng-template #content>
    <!-- Content specific to both admin and author layouts -->

    <div class="d-flex">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Course Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Course Categories</nz-breadcrumb-item>
        </nz-breadcrumb>
    </div>

    <nz-card>

        <!-- <nz-tabset>

            <nz-tab nzTitle="Categories">

                <div class="row mb-3">
                    <div class="col-auto ms-auto">
                        <button nz-button nzType="primary" (click)="openAddDrawer(CATEGORY)">
                            <span nz-icon nzType="plus"></span>
                            Add Category
                        </button>
                    </div>
                </div>

                <div class="table-responsive">

                    <nz-table #categoryTable [nzData]="categories" nzTableLayout="fixed" [nzLoading]="tableLoading"
                        nzShowSizeChanger>
                        <thead>
                            <tr>
                                <th>Category Name</th>
                                <th>Created At</th>
                                <th nzWidth="100px" nzAlign="center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let category of categoryTable.data">

                                <tr (click)="openEditDrawer(category.id, CATEGORY)">
                                    <td>{{ category.category }}</td>

                                    <td>{{ category.created_at | date: 'dd MMM y, h:mm a' }}</td>
                                    <td nzAlign="center" (click)="$event.stopPropagation()">
                                        <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                            nzPopconfirmTitle="Are you sure you want to delete this record?"
                                            (nzOnConfirm)="confirm(category.id, CATEGORY)" (nzOnCancel)="cancel()">
                                            <span nz-icon nzType="delete"></span>
                                        </button>
                                    </td>
                                </tr>

                            </ng-container>
                        </tbody>
                    </nz-table>
                </div>

            </nz-tab>

            <nz-tab nzTitle="Sub Categories">

                <div class="row mb-3">
                    <div class="col-auto ms-auto">
                        <button nz-button nzType="primary" (click)="openAddDrawer(SUB_CATEGORY)">
                            <span nz-icon nzType="plus"></span>
                            Add Sub Category
                        </button>
                    </div>
                </div>

                <div class="table-responsive">

                    <nz-table #subCategoryTable [nzData]="subCategories" nzTableLayout="fixed"
                        [nzLoading]="tableLoading" nzShowSizeChanger>
                        <thead>
                            <tr>
                                <th>Sub Category Name</th>
                                <th>Created At</th>
                                <th nzWidth="100px" nzAlign="center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let subCategory of subCategoryTable.data">

                                <tr (click)="openEditDrawer(subCategory.id, SUB_CATEGORY)">
                                    <td>{{ subCategory.sub_category_name }}</td>

                                    <td>{{ subCategory.created_at | date: 'dd MMM y, h:mm a' }}</td>
                                    <td nzAlign="center" (click)="$event.stopPropagation()">
                                        <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                            nzPopconfirmTitle="Are you sure you want to delete this record?"
                                            (nzOnConfirm)="confirm(subCategory.id, SUB_CATEGORY)"
                                            (nzOnCancel)="cancel()">
                                            <span nz-icon nzType="delete"></span>
                                        </button>
                                    </td>
                                </tr>

                            </ng-container>
                        </tbody>
                    </nz-table>
                </div>

            </nz-tab>

        </nz-tabset> -->

        <div class="row">
            <div class="col-md-6">

                <div class="row mb-3">
                    <div class="col-auto">
                        <button nz-button nzType="primary" (click)="openAddDrawer(CATEGORY)">
                            <span nz-icon nzType="plus"></span>
                            Add Category
                        </button>
                    </div>
                </div>

                <div class="table-responsive">

                    <nz-table #categoryTable [nzData]="categories" nzTableLayout="fixed" [nzLoading]="tableLoading"
                        nzShowSizeChanger>
                        <thead>
                            <tr>
                                <th>Category Name</th>
                                <th nzWidth="100px" nzAlign="center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let category of categoryTable.data">

                                <tr (click)="openEditDrawer(category.category_id, CATEGORY)">
                                    <td>{{ category.category }}</td>
                                    <td nzAlign="center" (click)="$event.stopPropagation()">
                                        <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                            nzPopconfirmTitle="Are you sure you want to delete this record?"
                                            (nzOnConfirm)="confirm(category.category_id, CATEGORY)"
                                            (nzOnCancel)="cancel()">
                                            <span nz-icon nzType="delete"></span>
                                        </button>
                                    </td>
                                </tr>

                            </ng-container>
                        </tbody>
                    </nz-table>
                </div>

            </div>

            <div class="col-md-6">
                <div class="row mb-3">
                    <div class="col-auto">
                        <button nz-button nzType="primary" (click)="openAddDrawer(SUB_CATEGORY)">
                            <span nz-icon nzType="plus"></span>
                            Add Sub Category
                        </button>
                    </div>
                </div>

                <div class="table-responsive">

                    <nz-table #subCategoryTable [nzData]="subCategories" nzTableLayout="fixed"
                        [nzLoading]="tableLoading" nzShowSizeChanger>
                        <thead>
                            <tr>
                                <th>Sub Category Name</th>
                                <th nzWidth="100px" nzAlign="center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let subCategory of subCategoryTable.data">

                                <tr (click)="openEditDrawer(subCategory.sub_category_id, SUB_CATEGORY)">
                                    <td>{{ subCategory.sub_category_name }}</td>
                                    <td nzAlign="center" (click)="$event.stopPropagation()">
                                        <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                            nzPopconfirmTitle="Are you sure you want to delete this record?"
                                            (nzOnConfirm)="confirm(subCategory.sub_category_id, SUB_CATEGORY)"
                                            (nzOnCancel)="cancel()">
                                            <span nz-icon nzType="delete"></span>
                                        </button>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </nz-table>
                </div>
            </div>
        </div>


        <nz-drawer [nzVisible]="addDrawer" nzTitle="Add Category" (nzOnClose)="closeAddDrawer()">
            <ng-container *nzDrawerContent>

                <form *ngIf="Drawer_Opened_For === CATEGORY" nz-form nzLayout="vertical" [formGroup]="addCategoryForm"
                    (ngSubmit)="submitAddForm(CATEGORY)">
                    <div class="row">
                        <div class="col-12">
                            <nz-form-item>
                                <nz-form-label nzRequired>Category Name</nz-form-label>
                                <nz-form-control nzErrorTip="Enter Category Name">
                                    <nz-input-group>
                                        <input type="text" nz-input formControlName="category" required
                                            placeholder="Category Name" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <button nz-button nzType="primary" type="submit">Submit</button>
                </form>

                <form *ngIf="Drawer_Opened_For === SUB_CATEGORY" nz-form nzLayout="vertical"
                    [formGroup]="addSubCategoryForm" (ngSubmit)="submitAddForm(SUB_CATEGORY)">
                    <div class="row">
                        <div class="col-12">
                            <nz-form-item>
                                <nz-form-label nzRequired>Sub Category Name</nz-form-label>
                                <nz-form-control nzErrorTip="Enter Sub Category Name">
                                    <nz-input-group>
                                        <input type="text" nz-input formControlName="sub_category_name" required
                                            placeholder="Sub Category Name" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <button nz-button nzType="primary" type="submit">Submit</button>
                </form>


            </ng-container>
        </nz-drawer>

        <nz-drawer [nzVisible]="editDrawer" nzTitle="Edit Category" (nzOnClose)="closeEditDrawer()">
            <ng-container *nzDrawerContent>

                <form *ngIf="Drawer_Opened_For === CATEGORY" nz-form nzLayout="vertical" [formGroup]="editCategoryForm"
                    (ngSubmit)="submitEditForm(CATEGORY)">
                    <div class="row">
                        <div class="col-12 ">
                            <nz-form-item>
                                <nz-form-label nzRequired>Category Name</nz-form-label>
                                <nz-form-control nzErrorTip="Enter Category Name">
                                    <nz-input-group>
                                        <input type="text" nz-input formControlName="category" required
                                            placeholder="Category Name" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <button nz-button nzType="primary" type="submit">Update</button>
                </form>

                <form *ngIf="Drawer_Opened_For === SUB_CATEGORY" nz-form nzLayout="vertical"
                    [formGroup]="editSubCategoryForm" (ngSubmit)="submitEditForm(SUB_CATEGORY)">
                    <div class="row">
                        <div class="col-12 ">
                            <nz-form-item>
                                <nz-form-label nzRequired>Sub Category Name</nz-form-label>
                                <nz-form-control nzErrorTip="Enter Sub Category Name">
                                    <nz-input-group>
                                        <input type="text" nz-input formControlName="sub_category_name" required
                                            placeholder="Sub Category Name" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <button nz-button nzType="primary" type="submit">Update</button>
                </form>
            </ng-container>
        </nz-drawer>
    </nz-card>
</ng-template>