import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  constructor(private http: HttpClient) { }
  baseUrl: string = `${environment.API_URL}`;




  getModuleDropdown(): Observable<any> {
    return this.http.get(`${this.baseUrl}fetch_all_modules`,);
  }
  getUserDropdown(): Observable<any> {
    return this.http.post(`${this.baseUrl}users_dropdown`, {account_id: null});
  }
  getRoleDropdown(): Observable<any> {
    return this.http.post(`${this.baseUrl}roles_dropdown`, {account_id: null});
  }
  getPermissionDropdown(): Observable<any> {
    return this.http.get(`${this.baseUrl}permissions_dropdown`);
  }

  getTrendingArticles(): Observable<any> {
    return this.http.get(`${this.baseUrl}trending_articles`);
  }



}
