import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-intelligence',
  templateUrl: './intelligence.component.html',
  styleUrls: ['./intelligence.component.scss']
})
export class IntelligenceComponent implements OnInit{
  constructor(
    private fetchData: FetchApiService,
    private _activatedRoute: ActivatedRoute,
    private apiService:FetchApiService
  ) {}
  ngOnInit(): void {
   this.fetchlatestArticles();
   this.loadIdeaHubTypes();
  }
 
  storageUrl = environment.STORAGE_URL;
  userId = localStorage.getItem('userId');
  @ViewChild('leftButton') leftButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('rightButton') rightButton!: ElementRef<HTMLButtonElement>;

  @Output() slideLeftEvent = new EventEmitter<void>();
  @Output() slideRightEvent = new EventEmitter<void>();

  slideLeft(): void {
    this.slideLeftEvent.emit();
  }

  slideRight(): void {
    this.slideRightEvent.emit();
  }


  postTypes: any= [];
  sentiments: any= [];
  holdingPeriods: any= [];
  risks: any= [];
  loadIdeaHubTypes(){
    this.apiService.fetchDropdowns().subscribe(response => {
      this.postTypes = response["Post Type"];
      this.sentiments = response["Sentiment"];
      this.holdingPeriods = response["Holding Period"];
      this.risks = response["Risk"];

    });
  }

  editArticleForm = new FormGroup({
    user_id: new FormControl(localStorage.getItem('userId')),
    holding_period:new FormControl(null),
    risk:new FormControl(null),
    sentiments:new FormControl(null),
    post_type:new FormControl(null),
  });

  clear(){
    this.editArticleForm.reset();
  }

  loadingData: boolean = false;
  latestArticleData: any = [];
  
  fetchlatestArticles(): Promise<any> {
  this.loadingData = true;
//  let UserID=this.userId


 let obj={
 ...this.editArticleForm.value
 }
    this.fetchData.getLatestArticles(obj).subscribe({
      next: (response: any) => {
        console.log('Response: ', response.data);
        this.latestArticleData = response.data;
      },
      error: (error) => {
        console.error('Error: ', error);
      },
      complete: () => {},
    });
    return Promise.resolve(true);
  }
}
