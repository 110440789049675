// import { Component, OnInit } from '@angular/core';

// export interface ItemData {
//   id: number
//   question_text: string
//   question_image: string
//   evaluation_type: string
//   quiz_level: string
//   categories: string
//   question_type_string: string,
 
// }

// @Component({
//   selector: 'app-question-table',
//   templateUrl: './question-table.component.html',
//   styleUrls: ['./question-table.component.scss']
// })
// export class QuestionTableComponent implements OnInit {

//   cancel(): void {}

//   checked = false;
//   indeterminate = false;
//   listOfCurrentPageData: readonly any[] = [];
//   listOfData: readonly any[] = [];
//   setOfCheckedId = new Set<number>();
//     selectedItemsArray:any[]=[];

//   updateCheckedSet(id: number, checked: boolean): void {
//     if (checked) {
//       this.setOfCheckedId.add(id);
//     } else {
//       this.setOfCheckedId.delete(id);
//     }
//     this.updateSelectedItems();
//   }

//   onItemChecked(id: number, checked: boolean): void {
//     this.updateCheckedSet(id, checked);
//     this.refreshCheckedStatus();
//   }

//   onAllChecked(value: boolean): void {
//     this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
//     this.refreshCheckedStatus();
//   }

//   onCurrentPageDataChange($event: readonly ItemData[]): void {
//     this.listOfCurrentPageData = $event;
//     this.refreshCheckedStatus();
//   }

//   refreshCheckedStatus(): void {
//     this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
//     this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
//   }

//   updateSelectedItems(): void {
//     // Convert the Set to an array
//     this.selectedItemsArray = Array.from(this.setOfCheckedId);
//     console.log('Selected Items:', this.selectedItemsArray);
//   }

//   ngOnInit(): void {
//     this.listOfData = new Array(200).fill(0).map((_, index) => ({
//       id: index,
//       name: `Edward King ${index}`,
//       age: 32,
//       address: `London, Park Lane no. ${index}`
//     }));
//   }

// }


import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { moveItemInArray, CdkDragDrop ,transferArrayItem} from '@angular/cdk/drag-drop';

export interface ItemData {
  id: number;
  question_text: string;
  question_image: string;
  evaluation_type: string;
  quiz_level: string;
  categories: string;
  question_type_string: string;
}

@Component({
  selector: 'app-question-table',
  templateUrl: './question-table.component.html',
  styleUrls: ['./question-table.component.scss'],
})
export class QuestionTableComponent implements OnInit {
  // @Input() listOfData: readonly any[] = [];
  @Input() refreshFunction: () => void = () => {};
  @Input() deleteButtonConfig: { text: string; tooltip: string } = {
    text: '',
    tooltip: '',
  };
  @Input() addButtonConfig: { text: string; tooltip: string } = {
    text: '',
    tooltip: '',
  };

  @Output() deleteAction = new EventEmitter<number[]>();
  @Output() addAction = new EventEmitter<void>();

  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly ItemData[] = [];
  setOfCheckedId = new Set<number>();
  selectedItemsArray: number[] = [];

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
    this.updateSelectedItems();
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach((item) =>
      this.updateCheckedSet(item.id, value)
    );
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly ItemData[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every((item) =>
      this.setOfCheckedId.has(item.id)
    );
    this.indeterminate =
      this.listOfCurrentPageData.some((item) =>
        this.setOfCheckedId.has(item.id)
      ) && !this.checked;
  }

  updateSelectedItems(): void {
    this.selectedItemsArray = Array.from(this.setOfCheckedId);
    console.log('Selected Items:', this.selectedItemsArray);
  }

  handleDelete(): void {
    this.deleteAction.emit(this.selectedItemsArray);
  }

  handleAdd(): void {
    this.addAction.emit();
  }

  ngOnInit(): void {
    // Initialize the table with default data or remove if using @Input() data directly
  }
 
}
