import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  MinLengthValidator,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { sendOtp } from 'src/app/Interfaces/allForm';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  currentYear: number = new Date().getFullYear();
  headerLogo: SafeUrl;
  currentRoute: string = '';
  passwordVisible = false;
  password?: string;
  roleId:any;

  sendFourDigitOtp: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private http: HttpClient,
    private message: NzMessageService
  ) {
    this.headerLogo = this.sanitizer.bypassSecurityTrustResourceUrl(
      'assets/InvestDostFull.png'
    );
  }

  sendOtp = new FormGroup({
    username: new FormControl('', [
      Validators.required,
      Validators.maxLength(25),
      Validators.pattern('[a-z0-9!@#$%^&*()_+=?<>]*$'),
    ]),

    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
    confirm_password: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
    role_id: new FormControl(3, [Validators.required]),
    remember: new FormControl(false),
  } as { [key in keyof sendOtp]: any });

  otpSubmitForm = new FormGroup({
    username: new FormControl(localStorage.getItem('username')),
    email: new FormControl(localStorage.getItem('email')),
    password: new FormControl(localStorage.getItem('password')),
    role_id: new FormControl(3, [Validators.required]),
    otp: new FormControl(null, [Validators.required]),
    remember: new FormControl(false),
  } as { [key in keyof sendOtp]: any });

  loginButtonDisable: boolean = false;
  sendOtpToUser() {
    if (this.sendOtp.controls['password'].value ===this.sendOtp.controls['confirm_password'].value) {
      if (isFormValid(this.sendOtp)) {
        this.loginButtonDisable = true;
        const { username, password, role_id, email } = this.sendOtp.value;
       

        const apiUrl = `${environment.API_URL}register_as_user`;

        try {
          this.http
            .post(apiUrl, { username, password, role_id, email })
            .subscribe({
              next: (response: any) => {
                console.log(response);
                if (response.success) {

                  localStorage.setItem('role_id', role_id)
                  localStorage.setItem('isLoggedIn', 'true')
                  localStorage.setItem('email', email)
                  localStorage.setItem('password', password)
                  localStorage.setItem('username', username)

                  this.message.success(response.message);
                  this.sendFourDigitOtp = true;
                } else {
                  this.message.success(response.message);
                  // this.message.error('Incorrect OTP Please Try Again ');
                }
              },
              error: (error) => {
                console.error('Login API error:', error);
                this.message.error('Login failed');
              },
            });
        } catch (error) {
          console.error('Error in API call:', error);
          this.message.error('Login failed');
        }
      }
    } else {
      this.message.success('The passwords do not match. Please try again.');
    }
  }

  submitForm() {
    if (isFormValid(this.otpSubmitForm)) {

      let obj={
        username:  localStorage.getItem('username'),
        password:  localStorage.getItem('password'),
        role_id:   localStorage.getItem('role_id'),
        email:     localStorage.getItem('email'),
        otp:      this.otpSubmitForm.controls['otp'].value
      }
      // const { username, password, role_id, email, otp } = this.otpSubmitForm.value;

      const apiUrl = `${environment.API_URL}verify_email_otp_for_user `;
      // console.log("login data",username,password,role_id,email,otp);
      console.log(obj);
      

      try {
        this.http
          .post(apiUrl, obj)
          .subscribe({
            next: (response: any) => {
              console.log(response);
              if (response.success) {
                localStorage.setItem('token', response.token)
                localStorage.setItem('userId', response.user_id)


                this.message.success('Login Successful ');
               this.roleId  = localStorage.getItem('role_id');
                debugger
                switch (this.roleId) {

                  
                  case '1':
                    ///super-admin
                    this.router.navigate(['/admin_dashboard']);
                    sessionStorage.setItem('userId', response.data.user_id);
                    sessionStorage.setItem(
                      'displayName',
                      `${response.data.fname} ${response.data.lname}`
                    );
                    break;

                  case '2':
                    //admin
                    this.router.navigate(['/admin_dashboard']);
                    sessionStorage.setItem('userId', response.data.user_id);
                    sessionStorage.setItem('displayName', `${response.data.fname} ${response.data.lname}`);
                    break;

                  case '3':
                    //user
                    this.router.navigate(['/']);
                    sessionStorage.setItem('userId', response.user_id);
                    sessionStorage.setItem(  'displayName', `${response.data.fname} ${response.data.lname}`
                    );
                    break;

                  case '4':
                    //author
                    this.router.navigate(['/author_dashboard']);
                    sessionStorage.setItem('userId', response.data.user_id);
                    sessionStorage.setItem('displayName', 'Neeraj Author');
                    break;

                  // case 'subscriber':
                  //   this.router.navigate(['/subscriber_homepage']);
                  //   break;

                  // case 'reader':
                  //   this.router.navigate(['/reader_homepage']);
                  //   break;

                  default:
                    // Handle the default case, or perform a default action
                    break;
                }
              } else {
                this.message.error('Login failed');
              }
            },
            error: (error) => {
              console.error('Login API error:', error);
              this.message.error('Login failed');
            },
          });
      } catch (error) {
        console.error('Error in API call:', error);
        this.message.error('Login failed');
      }
    }
  }

  getErrorMessage(controlName: string): string {
    const control = this.sendOtp.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) {
        return 'This field is required';
      }
      if (control.errors['minlength']) {
        return `This field must be at least ${control.errors['minlength'].requiredLength} characters long`;
      }
      if (control.errors['maxlength']) {
        return `This field cannot exceed ${control.errors['maxlength'].requiredLength} characters`;
      }
      if (control.errors['pattern']) {
        return 'Invalid input format';
      }
      // Add more error checks for other validation types as needed
    }
    return '';
  }
}
