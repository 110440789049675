import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Article } from 'src/app/Interfaces/Content';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-category-articles',
  templateUrl: './category-articles.component.html',
  styleUrls: ['./category-articles.component.scss']
})
export class CategoryArticlesComponent {
  userRole: string = ''; // Replace with the actual user's role


  isLoading: boolean = true;
  constructor(private http: HttpClient, private route: ActivatedRoute, private location: Location, private sanitizer: DomSanitizer, private fetchApiService: FetchApiService) { }
  editorsPicksArticles: Article[] = [];
  categoryRelatedArticles: Article[] = [];
  categoryString: string = '';

  ngOnInit() {
    const role = sessionStorage.getItem('role');
    this.userRole = role ?? '';

    this.route.params.subscribe((params) => {
      this.categoryString = params['category'];
      let articleId = +params['id']; // Convert the parameter to a number
      console.log(articleId);
      this.fetchInitialData(articleId);
      this.fetchOtherData();
    });
  }

  fetchInitialData(id: number): Promise<any> {
    this.isLoading = true;
    const apiUrl = `${environment.API_URL}category_articles/${id}`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl)
        .subscribe({
          next: (response: any) => {
            console.log(response.data);
            this.categoryRelatedArticles = response.data;

            resolve(true);
            this.isLoading = false;
          },
          error: (error) => {
            console.error('Error: ', error);
            this.isLoading = false;
            reject();
          }
        });
    });

  }

  fetchOtherData() {
    this.isLoading = true;
    const editorsPicksArticles$ = this.fetchApiService.getEditorsPicks();
    forkJoin([editorsPicksArticles$]).subscribe((results) => {
      this.editorsPicksArticles = results[0].data;
      this.isLoading = false;
      // console.log(results);
    });
  }




  goBack() {
    this.location.back();
  }

}
