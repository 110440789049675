<app-main-fiminize-layout>
  <nz-breadcrumb>
    <nz-breadcrumb-item><a><span nz-icon nzType="home" nzTheme="outline"></span></a></nz-breadcrumb-item>
    <nz-breadcrumb-item>IdeaHub</nz-breadcrumb-item>
  </nz-breadcrumb>
  <div class="d-flex bd-highlight">
    <div class="p-2 flex-grow-1 bd-highlight">
      <h2>Rooms</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4" *ngFor="let item of allRooms">
      <div [routerLink]="['/room-ropics',item.room_id]" class="card bg-white shadow-md rounded-lg my-4" style="height: 90%;">
       <div class="card-header">
        <div class="img-container">
          <img class="latest_article_image" alt="example" [src]="storageUrl + item.thumbnail_img" />
        </div>
       </div>
       <div class="card-body">
        <h1 class="text-overlay">{{ item.room_name | truncate: 50 | titlecase }}</h1>

        <div class="me-auto">
          <i class="fas fa-eye"> 50</i>
        </div>
        <p class="text-gray-800 mt-4">
          {{item.description}}
        </p>
       </div>
      </div>
    </div>
  </div>

</app-main-fiminize-layout>