// Function to shuffle an array (Fisher-Yates shuffle)
export function shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

export function convertToModuleTree(sourceData: any[]): any[] {
    return sourceData.map(item => {
        const treeNode: any = {
            title: item.module_name,
            key: item.module_id,
        };

        if (item.child && item.child.length > 0) {
            treeNode.children = convertToModuleTree(item.child);
        } else {
            treeNode.isLeaf = true;
        }
        return treeNode;
    });
}