<ng-container>
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>

<ng-template #content>
    <div class="d-flex">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Content Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Tags</nz-breadcrumb-item>
        </nz-breadcrumb>
        <button class="ms-auto mb-2" nz-button nzType="primary" (click)="openAddDrawer()"><span nz-icon
                nzType="plus"></span>Add Tag</button>
    </div>

    <nz-card>
        <div class="mb-3">
            <nz-input-group [nzSuffix]="searchIcon">
              <input nz-input placeholder="Search Tag Name" [(ngModel)]="searchQuery" (ngModelChange)="filterTags()" />
              <ng-template #searchIcon>
                <i nz-icon nzType="search"></i>
              </ng-template>
            </nz-input-group>
          </div>
        <div class="table-responsive">
            <nz-table #tagsTable [nzData]="filteredTags" nzTableLayout="fixed" [nzLoading]="tableLoading" nzShowSizeChanger>
                <thead>
                    <tr>
                        <th  nzWidth="5%">Sr.No</th>
                        <th [nzSortFn]="sortByName">Name</th>
                        <th [nzSortFn]="sortByCreatedAt">Created At</th>
                        <th [nzSortFn]="sortByUpdatedAt">Created By</th>
                        <th [nzSortFn]="sortByCreatedAt"> Updated By</th>
                        <th [nzSortFn]="sortByUpdatedAt">Updated at</th>
                        <th nzWidth="5%" [nzSortFn]="sortByApproved">Approved</th>
                        <th nzWidth="100px" nzAlign="center">Action</th>
                      </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let tag of tagsTable.data ; let i = index" (click)="openEditDrawer(tag.tag_id)">
                        <td>{{i+1}}</td>
                        <td>{{ tag.tag_name }}</td>
                        <td>{{ tag.created_at | date: 'dd MMM y, h:mm a' }}</td>
                        <td>{{ tag.created_by_name }}</td>
                        <td>{{ tag.updated_by_name }}</td>
                        <td>{{ tag.updated_at | date: 'dd MMM y, h:mm a' }}</td>
                 
                      <td (click)="$event.stopPropagation()">
                        <div>
                            <input [(ngModel)]="tag.is_approved" (ngModelChange)="updateApprovalStatus(tag.tag_id, $event)"  class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                          </div>
                      </td>
                        <td (click)="$event.stopPropagation()">
                            <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                nzPopconfirmTitle="Are you sure you want to delete this record?"
                                (nzOnConfirm)="confirm(tag.tag_id)" (nzOnCancel)="cancel()">
                                <span nz-icon nzType="delete"></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>

        <nz-drawer [nzVisible]="editDrawer" nzTitle="Edit Tag" (nzOnClose)="closeEditDrawer()">
            <ng-container *nzDrawerContent>

                <form nz-form nzLayout="vertical" [formGroup]="editTagForm" (ngSubmit)="submitEditForm()">
                    <nz-form-item>
                        <nz-form-label nzRequired>Tag Name</nz-form-label>
                        <nz-form-control message="Tag Name">
                            <nz-input-group>
                                <input type="text" nz-input formControlName="tag_name" required
                                    placeholder="Tag Name" />
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <button nz-button nzType="primary" type="submit">Submit</button>
                </form>

            </ng-container>
        </nz-drawer>

        <nz-drawer [nzVisible]="addDrawer" nzTitle="Add Tag" (nzOnClose)="closeAddDrawer()">
            <ng-container *nzDrawerContent>

                <form nz-form nzLayout="vertical" [formGroup]="addTagForm" (ngSubmit)="submitAddForm()">
                    <nz-form-item>
                        <nz-form-label nzRequired>Tag Name</nz-form-label>
                        <nz-form-control message="Tag Name">
                            <nz-input-group>
                                <input type="text" nz-input formControlName="tag_name" required
                                    placeholder="Tag Name" />
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <button nz-button nzType="primary" type="submit">Submit</button>
                </form>
            </ng-container>
        </nz-drawer>
    </nz-card>
</ng-template>