import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-small-section-heading',
  templateUrl: './small-section-heading.component.html',
  styleUrls: ['./small-section-heading.component.scss']
})
export class SmallSectionHeadingComponent {
  @Input() title: string = '';
  @Input() showDivider: boolean = true;
}
