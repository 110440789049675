import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-course-preview',
  templateUrl: './course-preview.component.html',
  styleUrls: ['./course-preview.component.scss']
})
export class CoursePreviewComponent {
  sidebarExpandLogo: SafeUrl;
  isSidebarCollapsed = false;
  quizQuestions: any[] = [];
  slug: string | null = null;
  courseId: string | null = null;
  currentLectureNumber: number = 1;
  currentQuestionIndex: number = 0;
  isVisible = false;
  loading: boolean = false;
  storageUrl: string = environment.STORAGE_URL;
  courseData: any;

  noDataToDisplay: boolean = true;
  displayLectureData: any;
  displayLectureDataType: number | null = null;
videoVissible: boolean=true

  constructor(   private fetchApiService: FetchApiService,private sanitizer: DomSanitizer, private http: HttpClient, private apiService: FetchApiService, private message: NzMessageService, private router: Router, private route: ActivatedRoute) {
    // Create a safe URL for the image file
    this.sidebarExpandLogo = this.sanitizer.bypassSecurityTrustResourceUrl('assets/InvestDostFull.png');
  }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      console.log(paramMap);
      this.slug = paramMap.get('slug');
      this.courseId = paramMap.get('course_id');
      this.fetchInitialData();
      console.log(this.slug, this.courseId);
    });
  }

  fetchInitialData(): Promise<any> {
    this.loading = true;
    const apiUrl = `${environment.API_URL}course_data`;

    const requestData = {
      use_slug: this.courseId === null ? true : false,
      course_slug: this.slug,
      course_id: this.courseId
    };

    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Course Data: ', response);
          this.courseData = response[0];
          this.updateNoDataToDisplay();
        },
        error: (error) => {
          console.error('Error: ', error);
          this.loading = false;
        }, complete: () => {
          this.loading = false;
        }
      });
    return Promise.resolve(true);
  }
  fetchQuizQuestions(quizId: number): void {
    debugger
    this.videoVissible=false;

    const requestData = { quiz_id: quizId };
console.log("quiz id is ", requestData);

    this.fetchApiService.getAllQuizQuestions(requestData).subscribe(
      (response: any) => {
        console.log('Quiz Questions:', response);
        this.quizQuestions = response;
        this.currentQuestionIndex = 0;
      },
      (error) => {
        console.error('Error fetching quiz questions:', error);
      }
    );
    console.log('Quizz ID:', quizId);
    this.isVisible = true;
  }
  saveData(event:any){
    console.log('hi')
  }
  nextQuestion(): void {
    if (this.currentQuestionIndex < this.quizQuestions.length - 1) {
      this.currentQuestionIndex++;
    }
  }
  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }
  previousQuestion(): void {
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
    }
  }
  // Function to check and update noDataToDisplay
  updateNoDataToDisplay(courseData: any = this.courseData) {
    console.log(courseData);
    // Check if courseSections is empty or the first object's lectures property is empty
    if (courseData.courseSections.length === 0 || courseData.courseSections[0].lectures.length === 0) {
      this.noDataToDisplay = true;
    } else {
      this.noDataToDisplay = false;
      var firstLectureData = courseData.courseSections[0].lectures[0];
      this.displayLectureDataType = firstLectureData.content_type_id;
      if (this.displayLectureDataType === 1) { // Text Data
        this.displayLectureData = firstLectureData.content;
      } else { // Video Data
        this.displayLectureData = firstLectureData.content_url;
      }
    }
  }

  changeLecture(lectureData: any) {
    console.log(lectureData);
    this.noDataToDisplay = false;
    this.displayLectureDataType = lectureData.content_type_id;
    if (this.displayLectureDataType === 1) { // Text Data
      this.displayLectureData = lectureData.content;
    } else { // Video Data
      this.displayLectureData = lectureData.content_url;
    }
  }

  // Function to sanitize a URL
  sanitizeURL(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  tempCourseData = {
    "courseTitle": 'Mastering Finance: From Basics to Advanced Concepts',
    "courseSections": [
      {
        "id": 1,
        "sectionTitle": "Introduction to Finance",
        "lectures": [
          {
            "id": 1, "title": "What is Finance?", "resources": [
              { "type": "downloadable", "data": "https://example.com/downloadable_file1.pdf", "title": "Intro Slides PDF" },
              { "type": "external_link", "data": "https://example.com/additional_reading1", "title": "Additional Reading" }
            ]
          },
          {
            "id": 2, "title": "Importance of Financial Knowledge", "resources": [
              { "type": "downloadable", "data": "https://example.com/downloadable_file2.pdf", "title": "Importance PDF" },
              { "type": "external_link", "data": "https://example.com/additional_reading2", "title": "Further Reading" }
            ]
          },
          {
            "id": 3, "title": "Overview of Financial Markets", "resources": [
              { "type": "downloadable", "data": "https://example.com/downloadable_file3.pdf", "title": "Market Overview PDF" },
              { "type": "external_link", "data": "https://example.com/additional_reading3", "title": "Extended Reading" }
            ]
          }
        ]
      },
      {
        "id": 2,
        "sectionTitle": "Fundamentals of Accounting",
        "lectures": [
          { "id": 4, "title": "Basics of Accounting", "resources": [] },
          {
            "id": 5, "title": "Financial Statements", "resources": [
              { "type": "downloadable", "data": "https://example.com/downloadable_file4.pdf", "title": "Financial Statements Guide" },
              { "type": "external_link", "data": "https://example.com/additional_reading4", "title": "Supplementary Reading" }
            ]
          },
          { "id": 6, "title": "Understanding Balance Sheets", "resources": [] }
        ]
      },
      {
        "id": 3,
        "sectionTitle": "Principles of Investments",
        "lectures": [
          {
            "id": 7, "title": "Introduction to Investments", "resources": [
              { "type": "downloadable", "data": "https://example.com/downloadable_file7.pdf", "title": "Investments Guide PDF" },
              { "type": "external_link", "data": "https://example.com/additional_reading7", "title": "Additional Reading on Investments" }
            ]
          },
          {
            "id": 8, "title": "Risk and Return", "resources": [
              { "type": "downloadable", "data": "https://example.com/downloadable_file8.pdf", "title": "Risk and Return Analysis" },
              { "type": "external_link", "data": "https://example.com/additional_reading8", "title": "Extended Reading on Risk" }
            ]
          },
          { "id": 9, "title": "Investment Strategies", "resources": [] }
        ]
      },
      {
        "id": 4,
        "sectionTitle": "Financial Planning",
        "lectures": [
          { "id": 10, "title": "Personal Financial Planning", "resources": [] },
          {
            "id": 11, "title": "Budgeting and Saving", "resources": [
              { "type": "downloadable", "data": "https://example.com/downloadable_file11.pdf", "title": "Budgeting Guide PDF" },
              { "type": "external_link", "data": "https://example.com/additional_reading11", "title": "Supplementary Budgeting Reading" }
            ]
          },
          { "id": 12, "title": "Retirement Planning", "resources": [] }
        ]
      },
      {
        "id": 5,
        "sectionTitle": "Banking and Financial Institutions",
        "lectures": [
          { "id": 13, "title": "Functions of Banks", "resources": [] },
          { "id": 14, "title": "Types of Financial Institutions", "resources": [] },
          {
            "id": 15, "title": "Banking Services", "resources": [
              { "type": "downloadable", "data": "https://example.com/downloadable_file15.pdf", "title": "Banking Services Guide" },
              { "type": "external_link", "data": "https://example.com/additional_reading15", "title": "Extended Reading on Banking Services" }
            ]
          }
        ]
      },
      {
        "id": 6,
        "sectionTitle": "Corporate Finance Basics",
        "lectures": [
          { "id": 16, "title": "Overview of Corporate Finance", "resources": [] },
          {
            "id": 17, "title": "Capital Budgeting", "resources": [
              { "type": "downloadable", "data": "https://example.com/downloadable_file17.pdf", "title": "Capital Budgeting PDF" },
              { "type": "external_link", "data": "https://example.com/additional_reading17", "title": "Further Reading on Capital Budgeting" }
            ]
          },
          {
            "id": 18, "title": "Financial Ratios and Analysis", "resources": [
              { "type": "downloadable", "data": "https://example.com/downloadable_file18.pdf", "title": "Financial Ratios Guide" },
              { "type": "external_link", "data": "https://example.com/additional_reading18", "title": "Extended Reading on Financial Ratios" }
            ]
          }
        ]
      },
      {
        "id": 7,
        "sectionTitle": "Investment Vehicles",
        "lectures": [
          {
            "id": 19, "title": "Stocks and Equities", "resources": [
              { "type": "downloadable", "data": "https://example.com/downloadable_file19.pdf", "title": "Stocks Guide PDF" },
              { "type": "external_link", "data": "https://example.com/additional_reading19", "title": "Extended Reading on Stocks" }
            ]
          },
          {
            "id": 20, "title": "Bonds and Fixed Income", "resources": [
              { "type": "downloadable", "data": "https://example.com/downloadable_file20.pdf", "title": "Bonds Guide PDF" },
              { "type": "external_link", "data": "https://example.com/additional_reading20", "title": "Extended Reading on Bonds" }
            ]
          },
          {
            "id": 21, "title": "Mutual Funds and ETFs", "resources": [
              { "type": "downloadable", "data": "https://example.com/downloadable_file21.pdf", "title": "Mutual Funds Guide PDF" },
              { "type": "external_link", "data": "https://example.com/additional_reading21", "title": "Extended Reading on Mutual Funds" }
            ]
          }
        ]
      },
      {
        "id": 8,
        "sectionTitle": "Financial Markets",
        "lectures": [
          { "id": 22, "title": "Stock Markets", "resources": [] },
          { "id": 23, "title": "Bond Markets", "resources": [] },
          { "id": 24, "title": "Forex Markets", "resources": [] }
        ]
      },
      {
        "id": 9,
        "sectionTitle": "International Finance",
        "lectures": [
          { "id": 25, "title": "Global Financial Markets", "resources": [] },
          {
            "id": 26, "title": "Foreign Exchange Rates", "resources": [
              { "type": "downloadable", "data": "https://example.com/downloadable_file26.pdf", "title": "Forex Rates Guide PDF" },
              { "type": "external_link", "data": "https://example.com/additional_reading26", "title": "Extended Reading on Forex Rates" }
            ]
          },
          {
            "id": 27, "title": "International Investment", "resources": [
              { "type": "downloadable", "data": "https://example.com/downloadable_file27.pdf", "title": "International Investment PDF" },
              { "type": "external_link", "data": "https://example.com/additional_reading27", "title": "Further Reading on International Investment" }
            ]
          }
        ]
      },
      {
        "id": 10,
        "sectionTitle": "Risk Management in Finance",
        "lectures": [
          { "id": 28, "title": "Types of Financial Risks", "resources": [] },
          {
            "id": 29, "title": "Hedging and Risk Mitigation", "resources": [
              { "type": "downloadable", "data": "https://example.com/downloadable_file29.pdf", "title": "Risk Mitigation Guide PDF" },
              { "type": "external_link", "data": "https://example.com/additional_reading29", "title": "Extended Reading on Risk Mitigation" }
            ]
          },
          { "id": 30, "title": "Case Studies in Risk Management", "resources": [] }
        ]
      }
    ]
  };

  toggleSidebar() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
  }

  closeWindow() {
    window.close();
  }

}
