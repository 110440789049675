import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-leader-board-point',
  templateUrl: './leader-board-point.component.html',
  styleUrls: ['./leader-board-point.component.scss']
})
export class LeaderBoardPointComponent implements OnInit{
  leaderboardPoints: any[] = [];
  filteredLeaderboardPoints: any[] = [];
  searchTerm: string = '';
  selectedPoint: any = null; 
  leaderboardTypes:any[]=[]
  isDrawerVisible = false;
  drawerType: 'add' | 'edit' = 'add'; 
  drawerTitle = 'Add Leader Points';
  currentForm: FormGroup = new FormGroup({}); 
  constructor(
    private fetchData: FetchApiService,
    private _activatedRoute: ActivatedRoute,
    private message: NzMessageService,
    private http: HttpClient,
  ) {}
  ngOnInit(): void {
   this.fetchAllLeaderboardPoints();
   this.fetchLeaderboardTypes();
  }


  addLeaderPoints = new FormGroup({
    leaderboard_point_id: new FormControl(''),
    leaderboard_type_id: new FormControl('', Validators.required),
    activity_name: new FormControl('', Validators.required),
    points_allotted: new FormControl('', [Validators.required, Validators.min(1)]),
    description: new FormControl(''),
    active_status_id: new FormControl(),
    created_by: new FormControl(localStorage.getItem('userId')),
  });

  editLeaderPoints = new FormGroup({
        leaderboard_point_id: new FormControl(''),
    leaderboard_type_id: new FormControl('', Validators.required),
    activity_name: new FormControl('', Validators.required),
    points_allotted: new FormControl('', [Validators.required, Validators.min(1)]),
    description: new FormControl(''),
    active_status_id: new FormControl(),
  });
  toggleDrawer(type: 'add' | 'edit', point?: any): void {
    this.drawerType = type;
    this.drawerTitle = type === 'edit' ? 'Edit Leaderboard Point' : 'Add Leader Points';
    this.currentForm = type === 'edit' ? this.editLeaderPoints : this.addLeaderPoints;
    this.isDrawerVisible = !this.isDrawerVisible;
  
    if (type === 'edit' && point) {
      this.selectedPoint = point;
      this.editLeaderPoints.patchValue({
         leaderboard_point_id: point. leaderboard_point_id,
        leaderboard_type_id: point.leaderboard_type_id,
        activity_name: point.activity_name,
        points_allotted: point.points_allotted,
        description: point.description,
        active_status_id: point.active_status_id,
      });
    } else if (type === 'add') {
      this.addLeaderPoints.reset({
        created_by: localStorage.getItem('userId'),
      });
      this.editLeaderPoints.reset();// Clear selected point for 'add' mode
    } else {
      this.editLeaderPoints.reset();// Clear selected point if closing the drawer
    }
  }

  closeDrawer(): void {
    this.isDrawerVisible = false;
    this.selectedPoint = null; // Clear selected point when closing
  }
  fetchAllLeaderboardPoints(): Promise<any> {
    const apiUrl = `${environment.API_URL}fetch_all_leaderboard_points`;
    this.http.get(apiUrl).subscribe({
      next: (response: any) => {
        this.leaderboardPoints = response;  
        this.filteredLeaderboardPoints = [...this.leaderboardPoints];
      },
      error: (error: any) => {
        console.error('Error: ', error);
      },
      complete: () => {
      },
    });
    return Promise.resolve(true);
  }

  fetchLeaderboardTypes(): Promise<any> {
    const apiUrl = `${environment.API_URL}dropdown_for_leaderboard_type`;
    this.http.get(apiUrl).subscribe({
      next: (response: any) => {
        this.leaderboardTypes = response;  
      },
      error: (error: any) => {
        console.error('Error: ', error);
      },
      complete: () => {
      },
    });
    return Promise.resolve(true);
  }

  onSubmit(): void {
    if (this.currentForm === this.addLeaderPoints && this.addLeaderPoints.valid) {
      const payload = this.addLeaderPoints.value;
      this.http.post(`${environment.API_URL}add_leaderboard_point`, payload).subscribe({
        next: (response) => {
          this.message.success('Leaderboard point added successfully!');
          this.closeDrawer();
          this.fetchAllLeaderboardPoints();
        },
        error: (error) => {
          this.message.error('Failed to add leaderboard point.');
          console.error('Error: ', error);
        },
      });
    } else if (this.currentForm === this.editLeaderPoints && this.editLeaderPoints.valid) {
      const payload = {
        ...this.editLeaderPoints.value,
        updated_by: localStorage.getItem('userId'),
      };
      this.http.post(`${environment.API_URL}update_leaderboard_point`, payload).subscribe({
        next: (response) => {
          this.message.success('Leaderboard point updated successfully!');
          this.closeDrawer();
          this.fetchAllLeaderboardPoints();
        },
        error: (error) => {
          this.message.error('Failed to update leaderboard point.');
          console.error('Error: ', error);
        },
      });
    } else {
      this.message.error('Please fill in all required fields.');
    }
  }














  onSearch(): void { 
    if (this.searchTerm) {
      this.filteredLeaderboardPoints = this.leaderboardPoints.filter(point =>
        point.activity_name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    } else {
      this.filteredLeaderboardPoints = [...this.leaderboardPoints];
    }
  }
  deleteRecord(ID: number) {
    const apiUrl = `${environment.API_URL}delete_leaderboard_point`;

    const requestData = {
      leaderboard_point_id: ID,
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchAllLeaderboardPoints().then(() => {
            this.message.success('leaderboard point deleted');
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }

  cancel(): void {
  }
  sortByIndex = (a: any, b: any): number => a.index - b.index;
  sortByType = (a: any, b: any): number => a.leaderboard_type.localeCompare(b.leaderboard_type);
  sortByActivity = (a: any, b: any): number => a.activity_name.localeCompare(b.activity_name);
  sortByPoints = (a: any, b: any): number => a.points_allotted - b.points_allotted;
  sortByDescription = (a: any, b: any): number => a.description.localeCompare(b.description);
  sortByMaxPerDay = (a: any, b: any): number => (a.max_per_day || 0) - (b.max_per_day || 0);
  sortByStatus = (a: any, b: any): number => a.active_status_id - b.active_status_id;
  sortByCreatedBy = (a: any, b: any): number => a.created_by_string.localeCompare(b.created_by_string);
  sortByCreatedAt = (a: any, b: any): number => new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  sortByUpdatedBy = (a: any, b: any): number => a.updated_by_string?.localeCompare(b.updated_by_string) || 0;
  sortByUpdatedAt = (a: any, b: any): number => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
}
