import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { formatBytes } from 'src/app/Functions/formatBytes';
import { convertToCategoryTree } from 'src/app/Functions/arrayFunctions';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-add-article',
  templateUrl: './add-article.component.html',
  styleUrls: ['./add-article.component.scss']
})
export class AddArticleComponent {

  useAdminLayout: boolean = false; // Default to non-admin layout
  tags: any = [];
  categories: any = [];
  coursesSlugs: any = [];
  postTypes: any= [];
  assetClasses: any= [];
  sentiments: any= [];
  holdingPeriods: any= [];
  risks: any= [];

  FreeArticle = 1;
  PaidArticle = 0;

  selectedCourseSlug: string | null = null;

  isImageInstructionsModalVisible = false;
  getCourseLinkModalVisible = false;

  ngOnInit() {
    this.fetchSectionType();
    // Get the 'role' value from session storage
    const role = sessionStorage.getItem('role');
    // Set useAdminLayout based on the 'role' value
    this.useAdminLayout = role === 'admin';
    this.fetchInitialData();
    this.loadIdeaHubTypes();
  }

  constructor(private http: HttpClient, private apiService: FetchApiService, private message: NzMessageService, private router: Router, private clipboard: Clipboard) { }


  fetchInitialData(): Promise<void> {
    // this.tableLoading = true;
    return new Promise((resolve, reject) => {
      const coursesSlugs$ = this.apiService.getCoursesSlugs();
      const categories$ = this.apiService.getCategories();

      forkJoin([coursesSlugs$, categories$]).subscribe({
        next: ([coursesSlugsResponse, categoriesResponse]: any) => {
          console.log('Courses Slugs: ', coursesSlugsResponse);
          console.log('Categories: ', categoriesResponse);
          this.coursesSlugs = coursesSlugsResponse;
          const treeData = convertToCategoryTree(categoriesResponse.data);
          this.categories = treeData;
        },
        error: (error) => {
          console.error('Error: ', error);
          // this.tableLoading = false;
          reject(error);
        },
        complete: () => {
          // this.tableLoading = false;
          resolve();
        },
      });

    });
  }
  loadIdeaHubTypes(){
    this.apiService.fetchDropdowns().subscribe(response => {
      this.postTypes = response["Post Type"];
      this.assetClasses = response["Asset Class"];
      this.sentiments = response["Sentiment"];
      this.holdingPeriods = response["Holding Period"];
      this.risks = response["Risk"];

    });
  }


  copySlugToClipboard() {
    console.log(this.selectedCourseSlug);
    this.clipboard.copy(`${environment.WEB_URL}course/${this.selectedCourseSlug!}/preview`);
  }

  articleForm = new FormGroup({
    title: new FormControl('', Validators.required),
    content: new FormControl([], Validators.required),
    author_id: new FormControl(localStorage.getItem('userId')),
    category_id: new FormControl('', Validators.required),
    tag_id: new FormControl([]),
    slug: new FormControl('', Validators.required),
    meta_description: new FormControl(''),
    is_free_artical: new FormControl(1),
    holding_period:new FormControl(null),
    risk:new FormControl(null),
    sentiments:new FormControl(null),
    asset_class:new FormControl(null),
    post_type:new FormControl(null),
  });

  onSubmit(isPublished: number) {
    debugger
    if (isFormValid(this.articleForm)) {
      // Handle form submission, e.g., send data to the server
      const apiUrl = `${environment.API_URL}add_artical`;

      const requestData = {
        ...this.articleForm.value,
        is_published: isPublished,
      };

      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            console.log('Add Response: ', response);
            if (response.success) {
              this.uploadFeaturedImage(response.id).then(() => {
                if (isPublished) {
                  this.message.success('Article Published!');
                  this.router.navigate(['/articles']);
                }
                else {
                  this.message.success('Saved As Draft');
                  this.router.navigate(['/draft_articles']);
                }
              }).catch(() => {
                this.message.error('Error occurred, please try later');
              });
            } else {
              this.message.error('Error occurred, please try later');
            }
          },
          error: (error) => {
            console.error('Data Add Error: ', error);
          }
        });
    }

  }
  tableLoading:boolean=false;
  sectionTypes : any = [];
  fetchSectionType(): Promise<any> {
    const apiUrl = `${environment.API_URL}fetch_section_type`;
    this.http.get(apiUrl).subscribe({
      next: (response: any) => {
        console.log('API Response: ', response); // Log the API response
        if (Array.isArray(response) && response.length > 0) {
          this.sectionTypes = response;
        } else {
          console.log('Empty or invalid response data.');
        }
      },
      error: (error) => {
        console.log('editor Type: ', error);
        console.error('Error: ', error);
        this.tableLoading = false;
      },
      complete: () => {
        this.sectionTypes 
        this.tableLoading = false;
      },
    });
    return Promise.resolve(true);
  }

  Content: any[] = [
   { content: '', content_type: 'normal', content_type_id: 1 }

  ];
  addRichTextEditor() {
    const Content = { content: '', content_type: null, content_type_id: '' };
    this.Content.push(Content);
  }

  uploadFeaturedImage(articleId: string): Promise<boolean> {
    if (this.selectedFile) {
      const imageUrl = `${environment.API_URL}update_featured_image`; // Replace with the actual URL for image upload
      const formData = new FormData();
      formData.append('image', this.selectedFile as Blob, this.selectedFile.name);
      formData.append('article_id', articleId);

      return new Promise((resolve, reject) => {
        this.http.post(imageUrl, formData)
          .subscribe({
            next: (response: any) => {
              console.log('Image Upload Response: ', response);
              if (response.success) {
                return resolve(true);
              } else {
                return reject(false);
              }
            },
            error: (error) => {
              console.error('Image Upload Error: ', error);
              return reject(false);
            }
          });
      });

    } else {
      return Promise.resolve(true);
    }
  }

  // File Upload

  selectedFile: File | null = null;
  showImage: boolean = false;
  imageSrc: string = '';

  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;


  onFileSelected(event: any): void {
    const file = event.target.files[0];
  
    if (file) {
      // Check file extension
      const maxSizeInBytes = 1024 * 1024; // 1MB
      const minWidth = 640;
      const aspectRatio = 16 / 9;
  
      if (file.size > maxSizeInBytes) {
        this.message.error(`File size exceeds the limit (1MB). Current size: ${formatBytes(file.size)}. Please choose a smaller file.`);
        return;
      }
  
      const img = new Image();
      img.src = URL.createObjectURL(file);
  
      img.onload = () => {
        if (img.width < minWidth || Math.abs((img.width / img.height) - aspectRatio) > 0.01) {
          this.message.error(`Image dimensions do not meet the requirements (16:9, minimum width: 640 pixels). Current dimensions: ${img.width}x${img.height}.`);
        } else {
          // Convert image to WebP
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          if (ctx) {
            ctx.drawImage(img, 0, 0, img.width, img.height);
            canvas.toBlob((blob) => {
              if (blob) {
                this.selectedFile = new File([blob], file.name.replace(/\.[^.]+$/, ".webp"), { type: 'image/webp' });
                this.viewImage();
              } else {
                this.message.error('Conversion to WebP failed.');
              }
            }, 'image/webp');
          } else {
            this.message.error('Canvas context not available.');
          }
        }
      };
  
      img.onerror = () => {
        this.message.error('Error loading image.');
      };
    }
  }
  
  viewImage(): void {
    if (this.selectedFile) {
      this.showImage = true;
      // Assuming you want to display the image as a base64 data URL
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }
  editorOptions = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      // [{ 'font': [] }],                              // remove font dropdown
      [{ 'align': [] }],
// remove formatting button

      ['link', 'image', 'video']                         // link and image, video
    ]
  };
}
