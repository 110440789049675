<nz-layout>
    <nz-header class="container-fluid d-flex align-items-center px-3">
        <img routerLink="/home-page" class="preview-expand-logo" [src]="sidebarExpandLogo" alt="Invest Dost Logo">
        <nz-divider nzType="vertical" class="mx-4 h-50" style="border-color: #3e4143;"></nz-divider>
        <span class="course-heading me-auto">{{courseData?.title}}</span>
        <button nz-button nzType="default" class="bg-transparent text-white" (click)="closeWindow()">CLOSE</button>

    </nz-header>
    <nz-layout>

        <nz-content style="min-height: 100vh;" class="bg-white">
            <nz-breadcrumb>
                <nz-breadcrumb-item><a routerLink="/"><span nz-icon nzType="home"
                            nzTheme="outline"></span></a></nz-breadcrumb-item>
                <nz-breadcrumb-item routerLink="/all-courses">All Courses</nz-breadcrumb-item>
                <nz-breadcrumb-item>Course Preview</nz-breadcrumb-item>
            </nz-breadcrumb>
            <div class="container-fluid">
                <div class="row">
                    <!-- No Data to display -->
                    <div *ngIf="noDataToDisplay" class="col-12 d-flex align-items-center screen justify-content-center"
                        style="height: 60vh;">
                        <h5 class="text-white">No data available to show.</h5>
                    </div>

                    <!-- Text Format Display -->
                    <div *ngIf="!noDataToDisplay && (displayLectureDataType ===1)"
                        class="col-12 ql-editor border border-bottom" style="height: 60vh;"
                        [innerHTML]="displayLectureData">
                    </div>

                    <!-- Video Format Display -->
                    <div *ngIf="!noDataToDisplay && (displayLectureDataType ===2)" class="col-12 ratio ratio-21x9"
                        style="height: 60vh;">

                        <iframe *ngIf="videoVissible" [src]="sanitizeURL(displayLectureData)" title="Lecture player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen>
                        </iframe>

                        <div class="quizz-vissible" *ngIf="!videoVissible">
                            <div *ngIf="quizQuestions && quizQuestions.length > 0">
                                <div *ngIf="quizQuestions[currentQuestionIndex]">
                                    <div class="row">
                                        <div class="col">
                                            <h1><b>{{ quizQuestions[currentQuestionIndex].question_text }}</b></h1>
                                        </div>
                                        <div class="col-auto ml-auto">
                                            Points: {{ quizQuestions[currentQuestionIndex].points }}
                                        </div>
                                    </div>
                                    <ul class="list-group">
                                        <li *ngFor="let option of quizQuestions[currentQuestionIndex].options">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" (change)="saveData($event)"
                                                    name="option_{{ quizQuestions[currentQuestionIndex].id }}"
                                                    [value]="option.option_text" id="option_{{ option.id }}">
                                                <label class="form-check-label" for="option_{{ option.id }}">{{
                                                    option.option_text }}</label>
                                            </div>

                                        </li>
                                    </ul>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-6 text-start">
                                        <button nzTheme="outline" type="button" [disabled]="currentQuestionIndex === 0"
                                            (click)="previousQuestion()" nz-button nzType="primary"
                                            nzShape="round"><span nz-icon nzType="left"></span></button>

                                    </div>
                                    <div class="col-md-6 text-end">
                                        <button nzTheme="outline" type="button"
                                            [disabled]="currentQuestionIndex >= quizQuestions.length - 1"
                                            (click)="nextQuestion()" nz-button nzType="primary" nzShape="round"><span
                                                nz-icon nzType="right"></span></button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!quizQuestions || quizQuestions.length === 0">
                                <p>No quiz questions available.</p>
                            </div>
                        </div>

                    </div>
                    <ng-container *nzModalContent>
                        <div *ngIf="quizQuestions && quizQuestions.length > 0">
                            <div *ngIf="quizQuestions[currentQuestionIndex]">
                                <div class="row">
                                    <div class="col">
                                        <h1><b>{{ quizQuestions[currentQuestionIndex].question_text }}</b></h1>
                                    </div>
                                    <div class="col-auto ml-auto">
                                        Points: {{ quizQuestions[currentQuestionIndex].points }}
                                    </div>
                                </div>
                                <ul class="list-group">
                                    <li *ngFor="let option of quizQuestions[currentQuestionIndex].options">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" (change)="saveData($event)"
                                                name="option_{{ quizQuestions[currentQuestionIndex].id }}"
                                                [value]="option.option_text" id="option_{{ option.id }}">
                                            <label class="form-check-label" for="option_{{ option.id }}">{{
                                                option.option_text }}</label>
                                        </div>

                                    </li>
                                </ul>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6 text-start">
                                    <button nzTheme="outline" type="button" [disabled]="currentQuestionIndex === 0"
                                        (click)="previousQuestion()" nz-button nzType="primary" nzShape="round"><span
                                            nz-icon nzType="left"></span></button>

                                </div>
                                <div class="col-md-6 text-end">
                                    <button nzTheme="outline" type="button"
                                        [disabled]="currentQuestionIndex >= quizQuestions.length - 1"
                                        (click)="nextQuestion()" nz-button nzType="primary" nzShape="round"><span
                                            nz-icon nzType="right"></span></button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!quizQuestions || quizQuestions.length === 0">
                            <p>No quiz questions available.</p>
                        </div>
                    </ng-container>
                    <!-- =========================================== -->
                    <!-- Lecture Details & Other Bottom Tabs -->
                    <div class="col-12 px-4 py-3">
                        <nz-tabset [nzTabBarGutter]="5">
                            <nz-tab [nzTitle]="searchTitleTemplate">
                                <ng-template #searchTitleTemplate>
                                    <span nz-icon nzType="search" class="mx-auto px-2"></span>
                                </ng-template>

                                <nz-empty></nz-empty>
                            </nz-tab>
                            <nz-tab nzTitle="Overview">
                                <nz-empty></nz-empty>
                            </nz-tab>
                            <nz-tab nzTitle="Notes">
                                <nz-empty></nz-empty>
                            </nz-tab>
                            <nz-tab nzTitle="Reviews">
                                <nz-empty></nz-empty>
                            </nz-tab>
                            <nz-tab nzTitle="Announcements">
                                <nz-empty></nz-empty>
                            </nz-tab>
                        </nz-tabset>
                    </div>
                </div>
            </div>
        </nz-content>

        <button nz-button (click)="toggleSidebar()" *ngIf="isSidebarCollapsed" class="open-sidebar-button"
            nzSize="large"><span nz-icon nzType="arrow-left"></span></button>

        <nz-sider class="sidebar-body border border-start" [(nzCollapsed)]="isSidebarCollapsed" nzCollapsible
            [nzReverseArrow]="true" [nzTrigger]="null" [nzCollapsedWidth]="0" nzWidth="25%" nzTheme="light">


            <div class="sidebar-header ps-4 pe-2 py-3 border-bottom">
                <h6 class="m-0 fw-bold">Course content</h6>
                <button nz-button (click)="toggleSidebar()" nzType="text">
                    <span nz-icon nzType="close"></span>
                </button>
            </div>

            <div class="sidebar-scroll-content ">
                <ul nz-menu nzMode="inline">
                    <nz-alert *ngIf="courseData?.courseSections.length === 0" nzType="info"
                        nzMessage="Oops! No Sections Found." nzShowIcon class="p-3"></nz-alert>
                    <ng-container *ngFor="let section of courseData?.courseSections; let sectionIndex = index">
                        <ng-template #sectionTitleTemplate>
                            <p class="fw-bold text-wrap  text-break m-0 lh-base" style="max-width: 85%;">
                                {{ 'Section ' + (sectionIndex+1) + ': ' + section.title }}
                            </p>
                        </ng-template>
                        <li class="border-bottom border" nz-submenu [nzTitle]="sectionTitleTemplate">
                            <ul>
                                <nz-alert *ngIf="section.lectures.length === 0" nzType="info"
                                    nzMessage="Oops! No Lectures Found." nzShowIcon class="p-3"></nz-alert>

                                <ng-container *ngFor="let lecture of section.lectures; let lectureIndex = index">
                                    <li nz-menu-item class="h-auto border-top mb-1 ps-4"
                                        (click)="changeLecture(lecture)">
                                        <div class="vstack w-100">
                                            <p class="m-0 text-wrap text-break">
                                                <span class="me-2" nz-icon
                                                    [nzType]="lecture.content_type_id === 1 ?'file-text':'video-camera'"
                                                    nzTheme="outline">
                                                </span>
                                                {{ (lectureIndex+1) + '. ' + lecture.title }}
                                            </p>
                                            <div *ngIf="lecture.quiz_id" class="d-flex justify-content-end">
                                                <button (click)="fetchQuizQuestions(lecture.quiz_id)"
                                                    class="bg-transparent text-black" nz-button nz-dropdown>
                                                    <span nz-icon nzType="question-circle"></span>
                                                    Attend Quiz
                                                </button>

                                            </div>

                                            <div class="d-flex justify-content-end"
                                                *ngIf="lecture.resources && lecture.resources.length > 0">
                                                <button class="bg-transparent text-black" nz-button nz-dropdown
                                                    [nzDropdownMenu]="resourceDropDownMenu"
                                                    (click)="$event.stopPropagation()">
                                                    <span nz-icon nzType="folder-open"></span>
                                                    Resources
                                                </button>

                                                <nz-dropdown-menu #resourceDropDownMenu="nzDropdownMenu">
                                                    <ul nz-menu class="border">
                                                        <ng-container *ngFor="let resource of lecture.resources">
                                                            <li nz-menu-item>
                                                                <a class="text-black"
                                                                    *ngIf="resource.content_type === 1"
                                                                    [href]="storageUrl + resource.content_url"
                                                                    download="{{ resource.name }}" target="_blank">
                                                                    <span class="me-3" nz-icon nzType="download"></span>
                                                                    {{ resource.name }}
                                                                </a>
                                                                <a class="text-black"
                                                                    *ngIf="resource.content_type === 2"
                                                                    [href]="resource.content_url" target="_blank"
                                                                    rel="noopener">
                                                                    <span class="me-3" nz-icon nzType="link"></span>
                                                                    {{ resource.name }}
                                                                </a>
                                                            </li>
                                                        </ng-container>
                                                    </ul>
                                                </nz-dropdown-menu>
                                            </div>
                                        </div>
                                    </li>
                                </ng-container>
                            </ul>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </nz-sider>
    </nz-layout>
</nz-layout>