import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { convertToCategoryTree } from 'src/app/Functions/arrayFunctions';
import { DraftArticle } from 'src/app/Interfaces/Content';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-draft-articles',
  templateUrl: './draft-articles.component.html',
  styleUrls: ['./draft-articles.component.scss']
})
export class DraftArticlesComponent {

  useAdminLayout: boolean = false; // Default to non-admin layout
  draftArticles: DraftArticle[] = [];
  tags: any = [];
  categories: any = [];
  dropdownData: any= [];
  postTypes: any= [];
  assetClasses: any= [];
  Content: any[] = [];
  sentiments: any= [];
  holdingPeriods: any= [];
  risks: any= [];
  FreeArticle = 1;
  PaidArticle = 0;
  treeSelectExpandKeys = [];
  editDrawer: boolean = false;

  editFormLoading: boolean = false;
  tableLoading: boolean = true;

  featuredImagePresent: boolean = false;
  featuredImagePath: string = '';

  ngOnInit() {
    // Get the 'role' value from session storage
    const role = sessionStorage.getItem('role');
    // Set useAdminLayout based on the 'role' value
    this.useAdminLayout = role === 'admin';
    this.fetchInitialData();
    this.loadIdeaHubTypes();
    this.fetchSectionType();
    this.fetchDropDowns();
  }

  constructor(private http: HttpClient, private apiService: FetchApiService, private message: NzMessageService) { }
  fetchSectionType(): Promise<any> {
    const apiUrl = `${environment.API_URL}fetch_section_type`;
    this.http.get(apiUrl).subscribe({
      next: (response: any) => {
        console.log('API Response: ', response); // Log the API response
        if (Array.isArray(response) && response.length > 0) {
          this.sectionTypes = response;
        } else {
          console.log('Empty or invalid response data.');
        }
      },
      error: (error) => {
        console.log('editor Type: ', error);
        console.error('Error: ', error);
        this.tableLoading = false;
      },
      complete: () => {
        this.sectionTypes 
        this.tableLoading = false;
      },
    });
    return Promise.resolve(true);
  }
  fetchInitialData(): Promise<any> {
    const apiUrl = `${environment.API_URL}my_articles`;

    const requestData = {
      author_id: sessionStorage.getItem('userId'),
      is_published: 0
    };

    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('All Articles: ', response);
          this.draftArticles = response.data;
        },
        error: (error) => {
          console.error('Error: ', error);
         this.tableLoading = false;
        }, complete: () => {
          this.tableLoading = false;
        }
      });
    return Promise.resolve(true);

  }
  addRichTextEditor() {
    const Content = { content: '', content_type: null, content_type_id: '' };
    this.Content.push(Content);
  }
  fetchDropDowns(): Promise<any> {
    // this.apiService.getTags().subscribe({
    //   next: (response: any) => {
    //     // console.log('Tag DropDown: ', response);
    //     this.tags = response.data;
    //   },
    //   error: (error) => {
    //     console.error('Error: ', error);
    //   }
    // });
    this.apiService.getCategories().subscribe({
      next: (response: any) => {
        // console.log('Category DropDown: ', response);
        const treeData = convertToCategoryTree(response.data);
        this.categories = treeData;
      },
      error: (error) => {
        console.error('Error: ', error);
      }
    });
    return Promise.resolve(true);
  }

  editDraftArticleForm = new FormGroup({
    id: new FormControl(null),
    title: new FormControl('', Validators.required),
    content: new FormControl('', Validators.required),
    author_id: new FormControl(sessionStorage.getItem('userId')),
    category_id: new FormControl('', Validators.required),
    parent_category_string: new FormControl(null),
    category_string: new FormControl(''),
    tag_id: new FormControl([]),
    slug: new FormControl(''),
    meta_description: new FormControl(''),
    is_free_artical: new FormControl(null),
  });

  onSubmit(isPublished: number) {
    if (isFormValid(this.editDraftArticleForm)) {
      // Handle form submission, e.g., send data to the server
      const apiUrl = `${environment.API_URL}edit_artical`;
      const requestData = {
        ...this.editDraftArticleForm.value,
        is_published: isPublished,
        artical_id: this.editDraftArticleForm.controls['id'].value,
        updated_by: sessionStorage.getItem('userId'),
      };

      // console.log(requestData);

      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            console.log('Update Response: ', response);
            if (response.success) {
              this.uploadFeaturedImage(this.editDraftArticleForm.controls['id'].value!)
                .then(() => this.fetchInitialData())
                .then(() => this.getArticleDetails(this.editDraftArticleForm.controls['id'].value!))
                .then(() => {
                  const message = !isPublished ? 'Article Updated' : 'Article Updated and Published';
                  this.message.success(message);
                })
                .catch(() => {
                  this.message.success('Error Occurred');
                });
            } else {
              this.message.error('Error occurred, please try later');
            }
          },
          error: (error) => {
            console.error('Error: ', error);
          }
        });
    }
  }
  delelteArticle(id: number) : void {
    this.deleteEditArticle(id);
  }
  deleteEditArticle(id: number){
    const apiUrl = `${environment.API_URL}delete_artical_section`;
    const requestData = {
      artical_section_id: [id],
    };
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchInitialData().then(() => {
            this.message.success('Article deleted');
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
  uploadFeaturedImage(articleId: string): Promise<boolean> {
    if (this.selectedFile) {
      const imageUrl = `${environment.API_URL}update_featured_image`; // Replace with the actual URL for image upload
      const formData = new FormData();
      formData.append('image', this.selectedFile as Blob, this.selectedFile.name);
      formData.append('article_id', articleId);

      return new Promise((resolve, reject) => {
        this.http.post(imageUrl, formData)
          .subscribe({
            next: (response: any) => {
              console.log('Image Upload Response: ', response);
              if (response.success) {
                return resolve(true);
              } else {
                return reject(false);
              }
            },
            error: (error) => {
              console.error('Image Upload Error: ', error);
              return reject(false);
            }
          });
      });

    } else {
      return Promise.resolve(true);
    }
  }


  openEditDrawer(id: number): void {
    this.editFormLoading = true;
    this.editDrawer = true;
    this.getArticleDetails(id);
  };

  getArticleDetails(id: number): Promise<void> {
    const apiUrl = `${environment.API_URL}fetch_single_artical`;
    const requestData = { artical_id: id };
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            console.log('Article Details: ', response);
            if (response.article.featured_img_path) {
              this.featuredImagePath = `${environment.STORAGE_URL}${response.article.featured_img_path}`;
              console.log(this.featuredImagePath);
              this.featuredImagePresent = true;
            }
            else
              this.featuredImagePresent = false;

            this.editDraftArticleForm.patchValue(response.article);
            return resolve();
          },
          error: (error) => {
            console.error('Error: ', error);
            return reject();
          }, complete: () => {
            this.editFormLoading = false;
            // console.log(this.editDraftArticleForm.value);
          }
        });
    });
  }


  closeEditDrawer(): void {
    this.editDrawer = false;
  }

  cancel(): void {
  }

  sectionTypes : any = [];
  confirm(id: number): void {
    this.deleteRecord(id);
  }
  addArticleID(){
    const apiUrl = `${environment.API_URL}add_artical`;
    const requestData = {
      author_id: localStorage.getItem('userId'),
    };
    this.http.post(apiUrl , requestData).subscribe({
       next: async (response: any) => {
        console.log('API Response: ', response); // Log the API response
        if(response.success){
        await  this.openEditDrawer(response.id);
        } else{
          console.error('article ID Not Present: ',);
 
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.tableLoading = false;
      },
      complete: () => {
        this.sectionTypes 
        this.tableLoading = false;
      },
    });
    return Promise.resolve(true);
   
    
  }
  deleteRecord(id: number) {
    const apiUrl = `${environment.API_URL}delete_artical`;

    const requestData = {
      artical_id: id,
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchInitialData().then(() => {
              this.message.success('Article deleted');
            });

          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        }
      });
  }


  // File Upload

  selectedFile: File | null = null;
  showImage: boolean = false;
  imageSrc: string = '';

  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      this.showImage = true;
      // Assuming you want to display the image as a base64 data URL
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }

  removeSelectedImage() {
    this.featuredImagePresent = false;
    this.featuredImagePath = '';
    this.selectedFile = null;
    this.showImage = false;
    this.imageSrc = '';
  }

  loadIdeaHubTypes(){
    this.apiService.fetchDropdowns().subscribe(response => {
      this.postTypes = response["Post Type"];
      this.assetClasses = response["Asset Class"];
      this.sentiments = response["Sentiment"];
      this.holdingPeriods = response["Holding Period"];
      this.risks = response["Risk"];

    });
  }
  editorOptions = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      // [{ 'font': [] }],                              // remove font dropdown
      [{ 'align': [] }],
// remove formatting button

      ['link', 'image', 'video']                         // link and image, video
    ]
  };

}
