import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { formatBytes } from 'src/app/Functions/formatBytes';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss']
})
export class DiscussionComponent implements OnInit{
  constructor(
    private http: HttpClient,
    private _activatedRoute: ActivatedRoute,
    private apiService: FetchApiService,
    private message: NzMessageService
  ) {}
  imageSrc: string = '';
  storageUrl = environment.STORAGE_URL;
  allRooms:any[]=[];
  selectedFile: File | null = null;
  isVisible = false;
  showImage: boolean = false;
  isImageInstructionsModalVisible = false;
  addRoomForm = new FormGroup({
    room_name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    thumbnail_img: new FormControl('', Validators.required),
  });

  ngOnInit(): void {
    this.getAllrooms()
  }

  getAllrooms(){
    this.apiService.fetchAllgetRooms().subscribe({
      next: (res: any) => {
        this.allRooms = res
        console.log(res)
        },
      error: (err: any) => {
        console.log(err)

      },
    })
  }

  // onSubmit() {
  //   if (this.addRoomForm.valid) {
  //     const formData = new FormData();
  //     formData.append('thumbnail_img', this.selectedFile as Blob, this.selectedFile.name);
  //     formData.append('room_name', this.addRoomForm.value.room_name);
  //     formData.append('description', this.addRoomForm.value.description);

  //     console.log('Form Data:', formData);

  //     this.apiService.addRoom(formData)
  //       .subscribe({
  //         next: (response: any) => {
  //           console.log('Add Response:', response);
  //           this.message.success('Room added successfully!');
  //         },
  //         error: (error) => {
  //           console.error('Data Add Error:', error);
  //           this.message.error('Error occurred, please try again.');
  //         }
  //       });
  //   } else {
  //     this.message.error('Please fill in all required fields.');
  //   }
  // }
  onSubmit() {
    if (this.addRoomForm.valid) {
      const formData = new FormData();
  
      const roomName = this.addRoomForm.get('room_name')?.value;
      const description = this.addRoomForm.get('description')?.value;
  
      if (roomName !== null && roomName !== undefined) {
        formData.append('room_name', roomName);
      }
  
      if (description !== null && description !== undefined) {
        formData.append('description', description);
      }
  
      if (this.selectedFile) {
        formData.append('thumbnail_img', this.selectedFile as Blob, this.selectedFile.name);
      }
  
      console.log('Form Data:', formData);
  
      // Continue with your API call using formData
    } else {
      this.message.error('Please fill in all required fields.');
    }
  }
  
  uploadFeaturedImage(articleId: string): Promise<boolean> {
    if (this.selectedFile) {
      const imageUrl = `${environment.API_URL}update_featured_image`; 
      const formData = new FormData();
      formData.append('thumbnail_img', this.selectedFile as Blob, this.selectedFile.name);
      return new Promise((resolve, reject) => {
        this.http.post(imageUrl, formData)
          .subscribe({
            next: (response: any) => {
              console.log('Image Upload Response: ', response);
              if (response.success) {
                return resolve(true);
              } else {
                return reject(false);
              }
            },
            error: (error) => {
              console.error('Image Upload Error: ', error);
              return reject(false);
            }
          });
      });

    } else {
      return Promise.resolve(true);
    }
  }
  onFileSelected(event: any): void {
    const file = event.target.files[0];

    if (file) {
      const isWebP = file.type === 'image/webp';
      if (!isWebP) {
        this.message.error('Please choose a WebP image.');
        return;
      }
      // Perform size and aspect ratio validation
      const maxSizeInBytes = 1024 * 1024; // 1MB
      const minWidth = 640;
      const aspectRatio = 16 / 9;

      if (file.size > maxSizeInBytes) {
        this.message.error(`File size exceeds the limit (1MB). Current size: ${formatBytes(file.size)}. Please choose a smaller file.`);
        return;
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        if (img.width < minWidth || Math.abs((img.width / img.height) - aspectRatio) > 0.01) {
          this.message.error(`Image dimensions do not meet the requirements (16:9, minimum width: 640 pixels). Current dimensions: ${img.width}x${img.height}.`);
        } else {
          this.selectedFile = file;
        
        }
      };
    }
  }
  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }
  showModal(): void {
    this.isVisible = true;
  }
}
