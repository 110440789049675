import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';
interface AuthorAnswer {
  user_id: number;
  role_id: number;
  author_answer_id: number;
  question_type_id: number;
  question_id: number;
  answer: string | null;
  option_id: number | null;
  created_by: number;
  created_at: string;
  updated_by: number | null;
  updated_at: string;
  author_name: string;
  author_username: string;
  author_question: string;
}
@Component({
  selector: 'app-author-management',
  templateUrl: './author-management.component.html',
  styleUrls: ['./author-management.component.scss']
})
export class AuthorManagementComponent implements OnInit{
  authorsData: AuthorAnswer[] = [];
  filteredAuthorsData: AuthorAnswer[] = [];
  searchText: string = '';
  constructor(private http: HttpClient, private apiService: FetchApiService, private message: NzMessageService) {
   
  }
  ngOnInit(): void {
    this.fetchAuthorsData();
  }
  fetchAuthorsData(): Promise<void> {
   
    const apiUrl = `${environment.API_URL}fetch_author_answer`;
  
    return new Promise<void>((resolve, reject) => {
      const obj={
        user_id:localStorage.getItem('userId')
      }
      this.http.post(apiUrl,obj).subscribe({
        next: (response:any) => {
          this.authorsData = response;
          this.filteredAuthorsData = response;
        },
        error: (error) => {
          console.error('Error: ', error);
         
          reject(); // reject without parameters for general errors
        },
        complete: () => {
         
          resolve(); // resolve when completed
        }
      });
    });
  }
  filterData(): void {
    if (this.searchText) {
      this.filteredAuthorsData = this.authorsData.filter(author =>
        author.author_question.toLowerCase().includes(this.searchText.toLowerCase())
      );
    } else {
      this.filteredAuthorsData = this.authorsData;
    }
  }
}
