


import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { isFormValid } from 'src/app/Functions/FormFunctions';

import { Roles } from 'src/app/Interfaces/modules';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-all-role',
  templateUrl: './all-role.component.html',
  styleUrls: ['./all-role.component.scss']
})
export class AllRoleComponent {
  useAdminLayout: boolean = false; // Default to non-admin layout
  viewRoles: Roles[] = [];
  tableLoading: boolean = true;
  editFormLoading: boolean = false;
  ShortLongTable: boolean = false;
  _addrole: boolean = false;
  _editrole: boolean = false;

  roleId:any;

  constructor(private http: HttpClient, private message: NzMessageService) {}

  ngOnInit() {
    this.roleId = localStorage.getItem('role_id');
    this.useAdminLayout = this.roleId === '1' || this.roleId === '2';
    this.fetchInitialData();
  }

  fetchInitialData(): Promise<any> {
    const apiUrl = `${environment.API_URL}fetch_all_roles`;

    const requestData = {
      account_id: sessionStorage.getItem('account_id'),
    };

    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('All roles: ', response);
        this.viewRoles = response;
      },
      error: (error) => {
        console.error('Error: ', error);
        this.tableLoading = false;
      },
      complete: () => {
        this.tableLoading = false;
      },
    });
    return Promise.resolve(true);
  }

  editRolesForm = new FormGroup({
    account_id: new FormControl(null),
    role_id: new FormControl(null),
    role_name: new FormControl('', Validators.required),
    description: new FormControl(''),
    active_status_id: new FormControl(null),
    created_at: new FormControl(),
    updated_at: new FormControl(),
    created_by_string: new FormControl(),
    updated_by_string: new FormControl(),
  });

  editrole(role_id: number) {
    this.ShortLongTable = true;
    this._editrole = true;
    this._addrole = false;
    this.getArticleDetails(role_id);
  }
  roleUpdated() {
    if (isFormValid(this.editRolesForm)) {
      // Handle form submission, e.g., send data to the server
      const apiUrl = `${environment.API_URL}update_role`;
      const requestData = {
        account_id: this.editRolesForm.value.account_id,
        role_name: this.editRolesForm.value.role_name,
        role_id: this.editRolesForm.value.role_id,
        description: this.editRolesForm.value.description,
        active_status_id: this.editRolesForm.value.active_status_id,
        updated_by: localStorage.getItem('userId'),
      };

      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          console.log('Update Response: ', response);
          if (response.success) {
            this.fetchInitialData()
              .then(() => {
                const message = 'role Updated';
                this.message.success(message);
              })
              .catch(() => {
                this.message.success('Error Occurred');
              });
          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
        },
        complete:()=> {

        },
      });
    }
  }

  addrole() {
    this.ShortLongTable = true;
    this._addrole = true;
    this._editrole = false;
  }
  roleAdded() {
    if (isFormValid(this.editRolesForm)) {
      // Handle form submission, e.g., send data to the server
      const apiUrl = `${environment.API_URL}add_role`;
      const requestData = {
        role_id:   localStorage.getItem('role_id'),
        account_id: this.editRolesForm.value.account_id,
        role_name: this.editRolesForm.value.role_name,
        description: this.editRolesForm.value.description,
        active_status_id: this.editRolesForm.value.active_status_id,
        created_by: localStorage.getItem('userId'),
      };

      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          console.log('Update Response: ', response);
          if (response.success) {
            this.fetchInitialData()
              .then(() =>
                this.getArticleDetails(
                  this.editRolesForm.controls['role_id'].value!
                )
              )
              .then(() => {
                const message = 'role Updated';
                this.message.success(message);
              })
              .catch(() => {
                this.message.success('Error Occurred');
              });
          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
        },
      });
    }
  }
  closed() {
    (this.ShortLongTable = false), this.editRolesForm.reset();
  }

  getArticleDetails(role_id: number): Promise<void> {
    const apiUrl = `${environment.API_URL}fetch_single_role`;
    const requestData = { role_id: role_id };
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData).subscribe({
        next: (response: any) => {
          console.log('role Details: ', response[0]);

          this.editRolesForm.patchValue(response[0]);
          return resolve();
        },
        error: (error) => {
          console.error('Error: ', error);
          return reject();
        },
        complete: () => {
          this.editFormLoading = false;
        },
      });
    });
  }



  confirm(id: number): void {
    this.deleteRecord(id);
  }

  deleteRecord(role_id: number) {
    const apiUrl = `${environment.API_URL}delete_role`;

    const requestData = {
      role_id: role_id,
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        console.log('Response: ', response);
        if (response.success) {
          this.fetchInitialData().then(() => {
            this.message.success('role deleted');
          });
        } else {
          this.message.error('Error occurred, please try later');
        }
      },
      error: (error) => {
        console.error('Error: ', error);
        this.message.error('Error occurred, please try later');
      },
    });
  }
}

// onSubmit() {
//   if (isFormValid(this.editRolesForm)) {
//     // Handle form submission, e.g., send data to the server
//     const apiUrl = `${environment.API_URL}edit_artical`;

//     const requestData = {
//       ...this.editRolesForm.value,
//       role_id: this.editRolesForm.controls['role_id'].value,
//       updated_by: sessionStorage.getItem('userId'),
//     };

//     this.http.post(apiUrl, requestData)
//       .subscribe({
//         next: (response: any) => {
//           console.log('Update Response: ', response);
//           if (response.success) {
//             this.fetchInitialData()
//               .then(() => this.getArticleDetails(this.editRolesForm.controls['role_id'].value!))
//               .then(() => {
//                 const message = 'role Updated';
//                 this.message.success(message);
//               })
//               .catch(() => {
//                 this.message.success('Error Occurred');
//               });
//           } else {
//             this.message.error('Error occurred, please try later');
//           }
//         },
//         error: (error) => {
//           console.error('Error: ', error);
//         }
//       });
//   }
// }
