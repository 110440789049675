<ng-container >
  <app-admin-layout>
      <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-admin-layout>
</ng-container>

<!-- <ng-template #authorLayout>
  <app-author-layout>
      <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-author-layout>
</ng-template> -->
<ng-template #content>
  <!-- Content specific to both admin and author layouts -->
  <div class="d-flex">
    <nz-breadcrumb>
      <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
      <nz-breadcrumb-item>Permissions</nz-breadcrumb-item>
      <nz-breadcrumb-item>Role Permission Setup</nz-breadcrumb-item>
    </nz-breadcrumb>
    <button class="ms-auto mb-2" nz-button nzType="primary" (click)="addrole()"><span nz-icon nzType="plus"></span>Add
      Role Permission</button>
  </div>
  <div class="row">
    <div class="col-md-{{ ShortLongTable ? 8 : 12 }}">
      <nz-card>
        <div class="table-responsive">
          <nz-table #articlesTable [nzData]="viewRolePermission" nzTableLayout="fixed" [nzLoading]="tableLoading"
            nzShowSizeChanger>
            <thead>
              <tr>
                <th>Permission Name</th>
                <th>Role Name</th>
                <th>Active</th>
                <th>valid_till</th>
                <th>valid_from</th>
                <th>Created At</th>
                
                <th>Created By</th>
                <th>Updated At</th>
                <th>Updated By</th>
                <th nzWidth="100px" nzAlign="center">Action</th> <!-- Add an Action column -->
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let viewRolePermission of articlesTable.data" (click)="editrole(viewRolePermission.role_permission_id)">

                <td>{{ viewRolePermission.permission_name }}</td>
                <td nzEllipsis>{{ viewRolePermission.role_name }}</td>
                <td nzEllipsis>{{ viewRolePermission.active_status_id === 1 ? 'Yes' : ' No' }}</td>
                <td>{{ viewRolePermission.valid_from }}</td>
                <td>{{ viewRolePermission.valid_till }}</td>
                <td>{{ viewRolePermission.created_at | date: 'yyyy-MM-dd' }}</td>
                <td nzEllipsis>{{ viewRolePermission.created_by_string }}</td>
                <td>{{ viewRolePermission.updated_at | date: 'yyyy-MM-dd' }}</td>
                <td nzEllipsis>{{ viewRolePermission.updated_by_string}}</td>
                <td nzAlign="center" (click)="$event.stopPropagation()">
                  <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                    nzPopconfirmTitle="Are you sure you want to delete this record?"
                    (nzOnConfirm)="confirm(viewRolePermission.role_permission_id)">
                    <span nz-icon nzType="delete"></span>
                  </button>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-card>
    </div>
    <div *ngIf="ShortLongTable" class="col-md-4">
      <nz-card>
        <ng-container>
          <nz-spin class="position-absolute start-50 top-50" nzSimple></nz-spin>
          <form [formGroup]="editRolePermissionForm" nz-form nzLayout="vertical">
            <p *ngIf="this._addrole">Add Role Permission</p>
            <p *ngIf="this._editrole">Update Role Permission</p>
            <nz-card>
              <button *ngIf="this._addrole" nz-button nzType="primary" type="submit" (click)="roleAdded()">Add Role
                Permission</button>
              <button *ngIf="this._editrole" nz-button nzType="primary" type="submit" (click)="roleUpdated()">Update
                Role Permission</button>
              <button class="ms-3" nz-button nzType="default" type="button" (click)="closed()">Close
              </button>
              <nz-divider></nz-divider>
              <nz-form-item>
                <nz-form-label nzRequired>Is Active?</nz-form-label>
                <nz-radio-group formControlName="active_status_id">
                  <label nz-radio [nzValue]="1">Yes</label>
                  <label nz-radio [nzValue]="2">No</label>
                 
                </nz-radio-group>
              </nz-form-item>
              <div class="row">
                <div class="col-md-12">
                  <nz-form-item>
                    <nz-form-label>Role</nz-form-label>
                    <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Roles"
                      formControlName="role_id">
                      <nz-option *ngFor="let roles of AllRoles" [nzLabel]="roles.role_name"
                        [nzValue]="roles.role_id"></nz-option>
                    </nz-select>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label>Permission</nz-form-label>
                    <nz-select nzMode="multiple" nzPlaceHolder="Please select" nzAllowClear [nzShowArrow]="true"
                      formControlName="permission_id">
                      <nz-option nzMode="multiple" *ngFor="let permission of AllPermission"
                        [nzLabel]="permission.permission_name" [nzValue]="permission.permission_id"></nz-option>
                    </nz-select>
                  </nz-form-item>
                </div>
                <div class="col-md-6">
                  <nz-form-item>
                    <nz-form-label>Valid From</nz-form-label>
                    <nz-date-picker formControlName="valid_from"></nz-date-picker>
                  </nz-form-item>
                </div>
                <div class="col-md-6">
                  <nz-form-item>
                    <nz-form-label>Valid Till</nz-form-label>
                    <nz-date-picker formControlName="valid_till"></nz-date-picker>
                  </nz-form-item>
                </div>
              </div>
            </nz-card>
          </form>
        </ng-container>
      </nz-card>
    </div>
  </div>

</ng-template>
