<app-main-fiminize-layout>
    <nz-breadcrumb>
        <nz-breadcrumb-item>
          <a routerLink="/">
            <span nz-icon nzType="home" nzTheme="outline"></span>
          </a>
        </nz-breadcrumb-item>
        <nz-breadcrumb-item>Community</nz-breadcrumb-item>
      </nz-breadcrumb>
      <div class="container mt-4">
        <!-- Header Section -->
        <div class="row mb-4">
          <div class="col-12">
            <h2>Community Notes</h2>
          </div>
        </div>
      
        <!-- Community Notes Section -->
        <div class="row">
          <div class="col-9">
            <nz-list [nzDataSource]="communityNotes" nzBordered nzSize="large">
              <nz-list-item *ngFor="let note of communityNotes">
                <nz-list-item-meta
                  nzTitle="{{ note.title }}"
                  nzDescription="{{ note.description }}"
                ></nz-list-item-meta>
              </nz-list-item>
            </nz-list>
          </div>
      
          <!-- Sidebar Section -->
          <div class="col-3">
            <div class="mb-4">
              <button nz-button nzType="primary" class="w-100">+ Add Note</button>
            </div>
            <div class="card">
              <div class="card-header">
                <h5>Similar Stocks</h5>
              </div>
              <div class="card-body">
                <ul>
                  <li *ngFor="let stock of similarStocks">{{ stock }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      
        <!-- FAQ and Ask Section -->
        <div class="row mt-5">
          <!-- FAQ Section -->
          <div class="col-8">
            <h4>Frequently Asked Questions</h4>
            <div class="faq-item" *ngFor="let faq of faqs; let i = index">
              <h5>{{ i + 1 }}. {{ faq.question }}</h5>
              <p>{{ faq.answer }}</p>
            </div>
          </div>
      
          <!-- Ask Section -->
          <div class="col-4">
            <h4>Ask</h4>
            <div class="mb-3">
              <input nz-input placeholder="Ask a question" class="w-100" />
            </div>
            <button nz-button nzType="primary" class="w-100">Submit</button>
          </div>
        </div>
      </div>
      
</app-main-fiminize-layout>
