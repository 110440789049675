<nz-layout style="min-height: 100vh;"> 
  <nz-sider  [nzWidth]="290" nzCollapsible [(nzCollapsed)]="isCollapsed" (nzCollapsedChange)="isCollapsed = $event" [nzTrigger]="null">

    <div class="mx-auto d-flex align-items-center bg-white px-4 " style="height: 64px;" routerLink="/home-page">
      <img class="sidebar-collapse-logo" *ngIf="isCollapsed" [src]="sidebarCollapseLogo" alt="Invest Dost Logo" >
      <img class="sidebar-expand-logo" *ngIf="!isCollapsed" [src]="sidebarExpandLogo" alt="Invest Dost Logo">
    </div>

    <ul nz-menu  nzMode="inline">

      <li *ngIf="roleId === '1' || roleId === '2' || roleId === '4'"   routerLink="/admin_dashboard" nz-menu-item [nzTooltipTitle]="isCollapsed ? 'Dashboard': null"
        nzTooltipPlacement="right" nz-tooltip>
        <span nz-icon nzType="dashboard"></span>
        <span>Dashboard</span>
      </li>

      <li *ngIf="roleId === '1' || roleId === '2' || roleId === '4'"  routerLink="/messages" nz-menu-item [nzTooltipTitle]="isCollapsed ? 'Messages': null"
        nzTooltipPlacement="right" nz-tooltip>
        <span nz-icon nzType="message"></span>
        <span>Messages</span>
      </li>

      <li *ngIf="hasPermission('ShowContentManagement')"  nz-submenu nzTitle="Content Management" nzIcon="form">
        <ul>
          <li *ngIf="isCollapsed" nz-menu-group nzTitle="Article Management">
            <nz-divider *ngIf="isCollapsed" class="border-white"></nz-divider>
            <li  *ngIf="roleId === '1' || roleId === '2' " routerLink="/articles-filter-setup" nz-menu-item>Filters</li>

          <li  routerLink="/articles" (click)="keepSidebarOpen($event)" nz-menu-item>Articles</li>

          <li  *ngIf="roleId === '1' || roleId === '2' " routerLink="/categories" nz-menu-item>Categories</li>
          <li *ngIf="roleId === '1' || roleId === '2' "  routerLink="/marque" nz-menu-item>Marquee</li>
          <li *ngIf="roleId === '1' || roleId === '2' "  routerLink="/symbols" nz-menu-item>Ticker</li>
          <li *ngIf="roleId === '1' || roleId === '2' "  routerLink="/tags" nz-menu-item>Tags</li>
          <li *ngIf="roleId === '1' || roleId === '2' "  routerLink="/look-ups" nz-menu-item>LookUps</li>
          <li *ngIf="roleId === '1' || roleId === '2' "  routerLink="/leaderBoardPoint" nz-menu-item>Leaderboard Point</li>
        </ul>
      </li>



      <li *ngIf="roleId === '1' || roleId === '2' "  nz-submenu nzTitle="User Management" nzIcon="team">
        <ul>
          <li *ngIf="isCollapsed" nz-menu-group nzTitle="User Management">
            <nz-divider *ngIf="isCollapsed" class="border-white"></nz-divider>
          <li nz-menu-item>Manage Users</li>
          <li nz-menu-item  routerLink="/author-Management">Manage Authors</li>
          <!-- <li nz-menu-item>Permissions</li> -->
        </ul>
      </li>

      <li *ngIf="roleId === '1' || roleId === '2' "  nz-submenu nzTitle="Subscription Management" nzIcon="crown">
        <ul>
          <li *ngIf="isCollapsed" nz-menu-group nzTitle="Subscription Management">
            <nz-divider *ngIf="isCollapsed" class="border-white"></nz-divider>
          <li nz-menu-item>Manage Subscribers</li>
          <li nz-menu-item>Subscription Plans</li>
        </ul>
      </li>
      
      
      <li *ngIf="hasPermission('ShowPermissions')"  nz-submenu nzTitle="Permissions" nzIcon="slack">
        <ul>
          <li *ngIf="isCollapsed" nz-menu-group nzTitle="Permissions">
            <nz-divider *ngIf="isCollapsed" class="border-white"></nz-divider>
          <li routerLink="/module" nz-menu-item>Modules</li>
          <li  routerLink="/role" nz-menu-item>Roles</li>
          <li routerLink="/permission" nz-menu-item>Permissions</li>
          <li routerLink="/user-role" nz-menu-item>User Roles</li>
          <li nz-menu-item  routerLink="/role-permission">Role Permissions</li>

        </ul>
      </li>


 
      <li   *ngIf="hasPermission('ShowQuizManagement')"  nz-submenu nzTitle="Quiz Management" nzIcon="calculator">
        <ul>
          <li *ngIf="isCollapsed" nz-menu-group nzTitle="Quiz Management">
            <nz-divider *ngIf="isCollapsed" class="border-white"></nz-divider>
          <li routerLink="/quizzes" nz-menu-item>Quizzes</li>
          <li routerLink="/quiz_questions" nz-menu-item>Questions</li>
          <li routerLink="/quiz_categories" nz-menu-item>Quiz of the day</li>
        </ul>
      </li>

      <li   *ngIf="hasPermission('ShowCourseManagement')" nz-submenu nzTitle="Course Management" nzIcon="read">
        <ul>
          <li *ngIf="isCollapsed" nz-menu-group nzTitle="Course Management">
            <nz-divider *ngIf="isCollapsed" class="border-white"></nz-divider>
          <li routerLink="/courses" nz-menu-item>Courses</li>
          <li routerLink="/sections" nz-menu-item>Sections</li>
          <li routerLink="/lectures" nz-menu-item>Lectures</li>
        </ul>
      </li>

      <!--
      <li nz-submenu nzTitle="Settings" nzIcon="setting">
        <ul>
          <li nz-menu-item>General</li>
          <li nz-menu-item>Security</li>
          <li nz-menu-item>SEO</li>
          <li nz-menu-item>Appearance</li>
          <li nz-menu-item>Plugins</li>
        </ul>
      </li> -->

      <li *ngIf="roleId === '1' || roleId === '2' " nz-submenu nzTitle="Data Management" nzIcon="cloud-server">
        <ul>
          <li *ngIf="isCollapsed" nz-menu-group nzTitle="Data Management">
            <nz-divider *ngIf="isCollapsed" class="border-white"></nz-divider>
          <li nz-menu-item>Database</li>
          <!-- <li nz-menu-item>Backups</li>
          <li nz-menu-item>Import/Export</li> -->
          <!-- <li nz-menu-item>Appearance</li> -->
          <!-- <li nz-menu-item>Plugins</li> -->
        </ul>
      </li>

      <!-- <li nz-menu-item>
        <span nz-icon nzType="zhihu"></span>
        <span>Language Settings</span>
      </li> -->

      <li *ngIf="roleId === '1' || roleId === '2' " nz-submenu nzTitle="Logs" nzIcon="file-text">
        <ul>
          <li *ngIf="isCollapsed" nz-menu-group nzTitle="Logs">
            <nz-divider *ngIf="isCollapsed" class="border-white"></nz-divider>
          <li nz-menu-item>Error Logs</li>
          <li nz-menu-item>Access Logs</li>
        </ul>
      </li>

      <li *ngIf="roleId === '1' || roleId === '2' || roleId === '4'" nz-submenu nzTitle="Help & Support" nzIcon="customer-service">
        <ul>
          <li *ngIf="isCollapsed" nz-menu-group nzTitle="Help & Support">
            <nz-divider *ngIf="isCollapsed" class="border-white"></nz-divider>
          <li nz-menu-item>Documentation</li>
          <li nz-menu-item>FAQs</li>
          <li nz-menu-item>Contact Support</li>
        </ul>
      </li>
    </ul>
  </nz-sider>

  <nz-layout>
    <nz-header>
      
      <div class="container-fluid d-flex align-items-center">
        <!-- <span class="trigger" nz-icon  [nzType]="isCollapsed ? 'right' : 'left'"
          (click)="isCollapsed = !isCollapsed"></span> -->

          <span class="trigger p-2" (click)="isCollapsed = !isCollapsed">
            <i class="fa-solid" [ngClass]="{'fa-chevron-right': isCollapsed, 'fa-chevron-left': !isCollapsed}"></i>
          </span>
          <div class="ms-auto">
            <button class="ms-2" *ngIf="roleId === '1' || roleId === '2' || roleId === '4'" nz-button nzType="default"
              [routerLink]="['/admin_dashboard']">Dashboard</button>
            <!-- <button class="ms-3 " *ngIf=" roleId === '1' || roleId === '2' || roleId === '4'" nz-button nzType="default"
              [routerLink]="['/author_dashboard']">Author Dashboard</button> -->
            <button (click)="openAddDrawer()"
              *ngIf="!( roleId === '1' || roleId === '2' || roleId === '3' || roleId === '4')" nz-button nzShape="round">
              <span> <i class="fa-solid fa-user"></i> Log In</span>
            </button>
            <b class="ms-2">{{ displayName }}</b>
            <button class="me-5" nz-button nzShape="round"
              *ngIf="!( roleId === '1' || roleId === '2' || roleId === '3' || roleId === '4')" nz-button nzType="default"
              [routerLink]="['/login']"><span>Sign Up</span></button>
  
            <!-- <button class="ms-2"  *ngIf="!( roleId === '1' || roleId === '2' || roleId === '3' || roleId === '4')" nz-button nzType="default" (click)="openAddDrawer()" >Login</button> -->
  
            <!-- <button  nz-button nzTrigger="click" nz-dropdown [nzDropdownMenu]="languageDropDownMenu">
      Language
      <img class="ms-2" height="21px" width="21px" src="assets/language-switch.png" />
    </button> -->
            <!-- <button class="ms-2" nz-button nzType="default" (click)="openAddDrawer()"><span nz-icon
      nzType="plus"></span>Login</button> -->
  
  
  
          </div>
          
        <!-- <ul nz-menu nzMode="horizontal">
          <li nzMatchRouter routerLink="/admin_dashboard" nz-menu-item>Admin Panel</li>
          <li nzMatchRouter routerLink="/author_dashboard" nz-menu-item>Author Panel</li>
          <li nzMatchRouter routerLink="/subscriber_homepage" nz-menu-item nz-tooltip nzTooltipPlacement="bottom"
            nzTooltipTitle="Logged-In user (with Subscription)">
            Subscriber
          </li>
          <li nzMatchRouter routerLink="/reader_homepage" nz-menu-item nz-tooltip nzTooltipPlacement="bottom"
            nzTooltipTitle="Logged-In user">Reader</li>
        </ul> -->

        <!-- <button class="ms-auto" nz-button nzTrigger="click" nz-dropdown [nzDropdownMenu]="languageDropDownMenu">
          Language
          <img class="ms-2" height="21px" width="21px" src="assets/language-switch.png" />
        </button> -->



        <!-- <nz-dropdown-menu #languageDropDownMenu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item>English</li>
            <li nz-menu-item>French</li>
            <li nz-menu-item>Dutch</li>
          </ul>
        </nz-dropdown-menu> -->


        <!-- <nz-avatar nz-dropdown [nzDropdownMenu]="avatarDropDownMenu"
          class="ms-3 d-flex justify-content-center align-items-center" [nzSize]="40" nzIcon="user"></nz-avatar> -->
          <nz-avatar nz-dropdown [nzDropdownMenu]="avatarDropDownMenu"
          *ngIf="( roleId === '1' || roleId === '2' || roleId === '3' || roleId === '4')"
          class="me-5 ms-3 d-flex justify-content-center align-items-center" [nzSize]="40" nzIcon="user"></nz-avatar>

        <nz-dropdown-menu #avatarDropDownMenu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item (click)="underDevelopmentMessage()"><span nz-icon nzType="setting" nzTheme="outline"
                class="me-3"></span> Account Settings</li>
            <li nz-menu-item (click)="underDevelopmentMessage()"><span nz-icon nzType="user" nzTheme="outline"
                class="me-3"></span> My Profile</li>
            <!-- <li nz-menu-item><span nz-icon nzType="crown" nzTheme="outline" class="me-3"></span>Get Subscription</li> -->
            <nz-divider class="m-0"></nz-divider>
            <li nz-menu-item (click)="logout()"><span nz-icon nzType="logout" nzTheme="outline"
                class="me-3"></span>Logout
            </li>
            <nz-divider class="m-0"></nz-divider>

            <li nz-menu-item (click)="null">
         
            </li>

          </ul>
        </nz-dropdown-menu>
      </div>

    </nz-header>
    <nz-content class="border-bottom" style="padding-right: 3rem;padding-left: 3rem;padding-top: 2rem;">
      <div class="container-fluid">
        <ng-content></ng-content>
      </div>
    </nz-content>
    <nz-footer>
      <div class="d-flex">
        <span>&#169;  {{ currentYear }} Version 1.0.3 </span>
        <span class="ms-auto"> InvestDost | Development | Designed By
          <a class="ms-1 " target="_blank" rel="noopener noreferrer" href="https://portalwiz.com/">Portalwiz
            Technologies</a>
        </span>
      </div>
    </nz-footer>
  </nz-layout>
</nz-layout>
