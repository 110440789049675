import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  userDetails: any = {};
  posts: any[] = [];
  dostUserID: string | null = null;
  userID = localStorage.getItem('userId');
  postForm: FormGroup;
  commentVisible: { [key: number]: boolean } = {};
  isModalVisible = false;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private apiService: FetchApiService,
    private message: NzMessageService,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
  ) {
    this.postForm = new FormGroup({
      comment: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.dostUserID = this.activatedRoute.snapshot.paramMap.get('id');
    this.fetchUserDetails();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  fetchUserDetails(): void {
    if (this.dostUserID) {
      const requestData = {
        user_id: this.dostUserID,
        auth_user_id: this.userID,
      };

      const subscription = this.http.post(`${environment.API_URL}fetch_user_profile`, requestData).subscribe({
        next: (response: any) => {
          this.userDetails = response;
          this.posts = response.posts;
        },
        error: (error) => {
          console.error('Error fetching user details:', error);
          this.message.error('Failed to fetch user details');
        },
      });

      this.subscriptions.add(subscription);
    } else {
      this.message.error('User ID is missing');
    }
  }

  likeButton(post: any): void {
    const apiUrl = `${environment.API_URL}like_post`;
    const requestData = {
      post_id: post.diary_post_id,
      like_status: post.like_count ? 0 : 1,
      user_id: this.userID,
    };

    const subscription = this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.message.success('Like updated');
        this.fetchUserDetails();
      },
      error: (error) => {
        console.error('Error:', error);
        this.message.error('Failed to update like status');
      },
    });

    this.subscriptions.add(subscription);
  }

  toggleFollow(): void {
    const apiUrl = `${environment.API_URL}follow_user`;
    const requestData = {
      follower_id: this.userID,
      followed_id: this.dostUserID,
    };
    const subscription = this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.message.success(this.userDetails.already_following ? 'Unfollowed successfully' : 'Followed successfully');
        this.userDetails.already_following = !this.userDetails.already_following;
        this.userDetails.followers += this.userDetails.already_following ? 1 : -1;
      },
      error: (error) => {
        console.error('Error:', error);
        this.message.error('Failed to update follow status');
      },
    });

    this.subscriptions.add(subscription);
  }
  notifyMe(): void {
    const apiUrl = `${environment.API_URL}notify_me`;
    const requestData = {
      user_id: this.userID,
      notified_user_id: this.dostUserID
    };
    const subscription = this.http.post(apiUrl, requestData).subscribe({
      next: (response: any) => {
        this.message.success('You have been notified successfully.');
        this.userDetails.already_notified = !this.userDetails.already_notified;
       
            },
      error: (error) => {
        console.error('Error:', error);
        this.message.error('Failed to update follow status');
      },
    });
  }

  toggleCommentSection(postId: number): void {
    this.commentVisible[postId] = !this.commentVisible[postId];
  }

  openShareModal(): void {
    this.isModalVisible = true;
  }

  share(platform: string): void {
    this.message.success(`Sharing on ${platform}`);
  }

  handleCancel(): void {
    this.isModalVisible = false;
  }

  onCommentSubmit(): void {
    if (this.postForm.valid) {
      const comment = this.postForm.value.comment;
      // Handle the comment submission logic here
      console.log('Comment:', comment);
    } else {
      this.message.error('Please enter a comment.');
    }
  }
}
