import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-category-tags-articles',
  templateUrl: './category-tags-articles.component.html',
  styleUrls: ['./category-tags-articles.component.scss']
})
export class CategoryTagsArticlesComponent implements OnInit {
  type: string = '';
  id: number = 0;
  articles: any[] = [];
  categoryOrSymbolName: string = '';
  isFollowed: boolean = false; 

  storageUrl = environment.STORAGE_URL;
  authors: string[] = []; // Placeholder for authors array
  similarTags: string[] = []; // Placeholder for similar tags
  relatedStocks: string[] = []; // Placeholder for related stocks
  userId = localStorage.getItem('userId');
  @ViewChild('leftButton') leftButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('rightButton') rightButton!: ElementRef<HTMLButtonElement>;

  @Output() slideLeftEvent = new EventEmitter<void>();
  @Output() slideRightEvent = new EventEmitter<void>();

  slideLeft(): void {
    this.slideLeftEvent.emit();
  }

  slideRight(): void {
    this.slideRightEvent.emit();
  }
  constructor(
    private message: NzMessageService,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.type = params.get('type') || ''; 
      this.id = +(params.get('id') || 0); 
      this.fetchArticles();
    });
  }

  toggleFollow() {
    const apiUrl = `${environment.API_URL}follow_user`;
    const requestBody: any = {
      follower_id: this.userId,
    };
  
    // Set request body based on type
    if (this.type === 'category') {
      requestBody.category_id = this.id; 
    } else if (this.type === 'tickers') {
      requestBody.symbol_id = this.id;
    } else if (this.type === 'tags') {
      requestBody.tag_id = this.id;
    } else {
      this.message.error('Invalid type for following.');
      return;
    }
    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        this.isFollowed = !this.isFollowed;
        this.message.success(`${this.isFollowed ? 'Followed' : 'Unfollowed'} ${this.categoryOrSymbolName} successfully.`);
      },
      error: (err) => {
        this.message.error(`Failed to ${this.isFollowed ? 'unfollow' : 'follow'} ${this.categoryOrSymbolName}.`);
      }
    });
  }
  
  
  
  fetchArticles() {
    let apiUrl = '';
    let requestBody: any = {
      auth_user_id: this.userId
    };
    if (this.type === 'category') {
      apiUrl = `${environment.API_URL}category_articles`;
      requestBody.category_id = this.id;
    } else if (this.type === 'tickers') {
      apiUrl = `${environment.API_URL}fetch_articles_by_symbol`;
      requestBody.symbol_id = this.id;
    } else if (this.type === 'tags') {
      apiUrl = `${environment.API_URL}fetch_articles_by_tag`;
      requestBody.tag_id = this.id;
    } else {
      this.message.error('Invalid type for fetching articles.');
      return;
    }
    this.http.post(apiUrl, requestBody).subscribe({
      next: (response: any) => {
        if (this.type === 'category') {
          this.categoryOrSymbolName = response.Category;
        } else if (this.type === 'tickers') {
          this.categoryOrSymbolName = response.Symbol;
        } else if (this.type === 'tags') {
          this.categoryOrSymbolName = response.tag;
        }
  
        this.articles = response.articles;
        this.message.success(`Articles for the ${this.type} "${this.categoryOrSymbolName}"`);
      },
      error: (err) => {
        this.message.error(`Failed to fetch articles for the selected ${this.type}.`);
      },
      complete: () => {
        console.log(`${this.type} articles`);
      }
    });
  }
  
}
