import { Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-subscriber-layout',
  templateUrl: './subscriber-layout.component.html',
  styleUrls: ['./subscriber-layout.component.scss']
})
export class SubscriberLayoutComponent {
  currentYear: number = new Date().getFullYear();

  sidebarExpandLogo: SafeUrl;

  constructor(private sanitizer: DomSanitizer, private message: NzMessageService, private router: Router) {
    // Create a safe URL for the image file
    this.sidebarExpandLogo = this.sanitizer.bypassSecurityTrustResourceUrl('assets/InvestDostFull.png');
  }

  underDevelopmentMessage(): void {
    this.message.info('Under Development');
  }



  logout() {
    sessionStorage.clear();
    sessionStorage.setItem('isLoggedIn', 'false');
    this.router.navigate(['/']);
  }
}
