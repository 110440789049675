import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit{
  isCollapsed: boolean = true;
  currentYear: number = new Date().getFullYear();
  displayName: string | null = '';
  sidebarCollapseLogo: SafeUrl;
  sidebarExpandLogo: SafeUrl;
  addDrawer: boolean = false;
  roleId =localStorage.getItem('role_id');
  permissionData = localStorage.getItem('permission');

  hasPermission(permission: string): boolean {
    return this.permissionData!.includes(permission);
  }


  constructor(private sanitizer: DomSanitizer, private message: NzMessageService, private router: Router) {
    // Create a safe URL for the image file
    this.sidebarCollapseLogo = this.sanitizer.bypassSecurityTrustResourceUrl('assets/app-icon.png');
    this.sidebarExpandLogo = this.sanitizer.bypassSecurityTrustResourceUrl('assets/InvestDostFull.png');
  }
  ngOnInit(): void {
    this.displayName = localStorage.getItem('displayName');
  }
  keepSidebarOpen(event: MouseEvent) {
    // Prevent the click event from causing the sidebar to collapse
    event.stopPropagation();
  }
  underDevelopmentMessage(): void {
    this.message.info('Under Development');
  }
  openAddDrawer(): void {
    this.addDrawer = true;
  }
  logout() {
    sessionStorage.clear();
    sessionStorage.setItem('isLoggedIn', 'false');
    this.router.navigate(['/home-page']);
  }
}
