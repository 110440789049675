import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-author-sign-up',
  templateUrl: './author-sign-up.component.html',
  styleUrls: ['./author-sign-up.component.scss'],
})
export class AuthorSignUpComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  authorOnboarding: any[] = [];
  isAnimationTriggered = false;
  showMoreInfo: boolean = false;
  showQuestions: boolean = false;
  i: number = 0;

  constructor(private http: HttpClient, private message: NzMessageService,  private router: Router, private modal: NzModalService ) {}
  email: string = '';

  ngOnInit(): void {
    this.fetchauthorOnboarding();
  }

  fetchauthorOnboarding() {
    const requestData = {
      user_id: localStorage.getItem('userId'),
    };
    const apiUrl = `${environment.API_URL}fetch_author_question`;
    this.http.post(apiUrl,requestData).subscribe({
      next: (response: any) => {
        this.authorOnboarding = response;
      },
      error: (error) => {
        console.error('Error: ', error);
      },
    });
  }
  increaseIndex(option: any, answer: any): void {

    const userId = localStorage.getItem('userId');
    const obj = {
      user_id: userId,
      question_id: option.author_question_id,
      question_type_id: option.question_type_id,
      answer: answer,
    };
    this.http
      .post(`${environment.API_URL}start_author_onboard`, obj)
      .subscribe({
        next: (res: any) => {
          if (
            res.success &&
            this.i < (this.authorOnboarding?.length ?? 0) - 1
          ) {
            this.i++;
            this.triggerAnimation();
          }
        },
        error: (error) => {
          console.error('Error: ', error);
        },
      });
  }

  private triggerAnimation() {
    this.isAnimationTriggered = true;
    setTimeout(() => {
      this.isAnimationTriggered = false;
    }, 500); // Match the duration of your CSS animation
  }
  decreaseIndex(): void {
    if (this.i > 0) {
      this.i--;
      this.triggerAnimation();
    }
  }

  

  showMoreInfoSection(): void {
    this.showMoreInfo = true;
  }

  continueToQuestions(): void {
    this.showMoreInfo = false;
    this.showQuestions = true;
  }
  submitForm(): void {
    this.message.success('Thank You ');
    this.router.navigate(['/home-page']);
  }
  confirmSkip(): void {
    this.modal.confirm({
      nzTitle: 'Are you sure you want to skip this question?',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOnOk: () => this.navigateToHomePage(),
      nzCancelText: 'No'
    });
  }
  navigateToHomePage(): void {
    this.router.navigate(['/home-page']);
  }

}
