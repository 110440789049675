<app-reader-layout>
    <div class="row">
        <div class="col-md-9">
            <!-- <nz-card nzTitle="Logged-In Reader">
                Readers are users who can view content for free on the Home Page. They can create an account and log in
                without
                needing a paid subscription.
                <br />Readers with a free account receive certain benefits, although these benefits are not as extensive
                as
                those of subscribers.
                <br /> These benefits may include access to enhanced content, personalized recommendations, or an
                improved
                user
                experience.
            </nz-card> -->

            <div class="row g-3">

                <div class="col-12 col-md-4" *ngFor="let article of articles; let i = index;">
                    <nz-card nzHoverable [nzCover]="coverTemplate"
                        [routerLink]="['/article', article.slug, article.id]">
                        <nz-card-meta [nzTitle]="article.title" [nzDescription]="descriptionTemplate"></nz-card-meta>
                        <ng-template #coverTemplate>
                            <img alt="featured-image" [src]="randomImageUrls[i]" />
                        </ng-template>

                        <ng-template #descriptionTemplate>
                            <div [innerHTML]="removeTags(article.content)"></div>
                        </ng-template>
                    </nz-card>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <nz-alert class="mb-3" nzType="info" nzMessage="Subscribe Now!" nzDescription="Subscribe to Invest Dost for exclusive
                financial insights, expert content, and premium features. Unlock your financial potential today!"
                nzShowIcon></nz-alert>

            <nz-card nzTitle="Top Picks" class="mb-3">
                <!-- <nz-skeleton [nzParagraph]="{ rows: 2 }"></nz-skeleton> -->

                <nz-list>
                    <nz-list-item *ngFor="let article of topPicks; let i = index;">
                        <a [routerLink]="['/article', article.slug, article.id]">{{ article.title }}</a>
                    </nz-list-item>
                </nz-list>


            </nz-card>

            <nz-card nzTitle=" Trending">
                <!-- <nz-skeleton [nzParagraph]="{ rows: 4 }"></nz-skeleton> -->

                <nz-list>
                    <nz-list-item *ngFor="let article of trending; let i = index;">
                        <a [routerLink]="['/article', article.slug, article.id]">{{ article.title }}</a>
                    </nz-list-item>
                </nz-list>
            </nz-card>



        </div>

    </div>

</app-reader-layout>