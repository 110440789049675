<!-- <app-admin-layout> -->
  <app-main-fiminize-layout>
  <nz-breadcrumb>
    <nz-breadcrumb-item><a><span nz-icon nzType="home" nzTheme="outline"></span></a></nz-breadcrumb-item>
    <nz-breadcrumb-item>Categories</nz-breadcrumb-item>
    <nz-breadcrumb-item>All Categories</nz-breadcrumb-item>
  </nz-breadcrumb>

<div class="container-fluid">
  <div class="row ">
    <div class="col-md-3 my-1" *ngFor="let category of parentCategories">
      <nz-card nzTitle="{{ category.category_name }}"  style="height: 300px;" >
        <ul class="list-group ms-3" *ngIf="category.children.length > 0; else noChildCategories">
          <li class="custom-list-item " *ngFor="let childCategory of category.children">
            <a  href="" [routerLink]="['/articles', childCategory.category_id]">{{ childCategory.category_name }}</a>
          </li>
        </ul>
        <ng-template #noChildCategories>
          <nz-tag [nzColor]="'#f50'">No Sub Sategories Available.</nz-tag>
        </ng-template>
  
      </nz-card>
      
    </div>
  </div>
</div>
</app-main-fiminize-layout>
<!-- </app-admin-layout> -->