import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { forkJoin } from 'rxjs';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { CourseCategory, CourseSubCategory } from 'src/app/Interfaces/Course';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-course-categories',
  templateUrl: './course-categories.component.html',
  styleUrls: ['./course-categories.component.scss']
})

export class CourseCategoriesComponent {

  vissibleLayout: boolean = false; // Default to non-admin layout
  categories: CourseCategory[] = [];
  subCategories: CourseSubCategory[] = [];

  tableLoading: boolean = false;

  CATEGORY: string = 'category';
  SUB_CATEGORY: string = 'sub-category';
   roleID = sessionStorage.getItem('role_id');

  Drawer_Opened_For: string | null = null;

  constructor(private http: HttpClient, private apiService: FetchApiService, private message: NzMessageService) { }

  ngOnInit() {
   
 
    this.fetchInitialData();
  }

  fetchInitialData(): Promise<void> {
    this.tableLoading = true;
    return new Promise((resolve, reject) => {
      const getCategories$ = this.apiService.getUserCourseCategories();
      const getSubCategories$ = this.apiService.getUserCourseSubCategories();

      forkJoin([getCategories$, getSubCategories$]).subscribe({
        next: ([categoriesResponse, subCategoriesResponse]: any) => {
          console.log('Course Categories: ', categoriesResponse);
          console.log('Course Sub Categories: ', subCategoriesResponse);
          this.categories = categoriesResponse;
          this.subCategories = subCategoriesResponse;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          reject(error);
        },
        complete: () => {
          this.tableLoading = false;
          resolve();
        },
      });

    });
  }


  // Category Add & Edit FormGroups
  addCategoryForm: FormGroup = new FormGroup({
    category: new FormControl('', [Validators.required]),
    created_by: new FormControl(sessionStorage.getItem('userId'))
  });

  editCategoryForm: FormGroup = new FormGroup({
    category_id: new FormControl(''),
    category: new FormControl('', [Validators.required]),
    updated_by: new FormControl(sessionStorage.getItem('userId'))
  });

  // Sub Category Add & Edit FormGroups
  addSubCategoryForm: FormGroup = new FormGroup({
    sub_category_name: new FormControl('', [Validators.required]),
    created_by: new FormControl(sessionStorage.getItem('userId'))
  });

  editSubCategoryForm: FormGroup = new FormGroup({
    sub_category_id: new FormControl(''),
    sub_category_name: new FormControl('', [Validators.required]),
    updated_by: new FormControl(sessionStorage.getItem('userId'))
  });

  // ==================================================================
  addDrawer: boolean = false;
  editDrawer: boolean = false;

  openEditDrawer(categoryId: number, categoryType: string): void {

    if (categoryType === this.CATEGORY) {
      let courseDetails = this.categories.find(category => category.category_id === categoryId);
      this.editCategoryForm.patchValue({ ...courseDetails!, lk_category_id: courseDetails!.category_id });
      console.log(this.editCategoryForm.value);
    } else {
      let courseDetails = this.subCategories.find(category => category.sub_category_id === categoryId);
      this.editSubCategoryForm.patchValue({ ...courseDetails!, lk_sub_category_id: courseDetails!.sub_category_id });
      console.log(this.editSubCategoryForm.value);
    }
    this.Drawer_Opened_For = categoryType;
    this.editDrawer = true;
  };

  closeEditDrawer(): void {
    this.editDrawer = false;
    this.Drawer_Opened_For = null;
  };

  openAddDrawer(categoryType: string): void {
    this.addDrawer = true;
    this.Drawer_Opened_For = categoryType;
  };

  closeAddDrawer() {
    this.addDrawer = false;
    this.Drawer_Opened_For = null;
  }

  cancel(): void {
  };

  confirm(id: number, categoryType: string): void {
    this.deleteCategory(id, categoryType);
  };

  submitAddForm(categoryType: string) {
    var requestData = {};
    var apiUrl = '';

    if (categoryType === this.CATEGORY) {
      if (isFormValid(this.addCategoryForm)) {
        apiUrl = `${environment.API_URL}add_course_category`;
        requestData = {
          ...this.addCategoryForm.value,
        };
        console.log(requestData);
      } else return;
    } else {
      if (isFormValid(this.addSubCategoryForm)) {
        apiUrl = `${environment.API_URL}add_course_sub_category`;
        requestData = {
          ...this.addSubCategoryForm.value,
        };
        console.log(requestData);

      } else return;
    }

    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchInitialData().then(() => {
              if (categoryType === this.CATEGORY)
                this.message.success('Category added');
              else
                this.message.success('Sub-Category added');

              this.addCategoryForm.reset();
              this.addSubCategoryForm.reset();
              this.addCategoryForm.patchValue({ category_name: '', created_by: sessionStorage.getItem('userId') });
              this.addSubCategoryForm.patchValue({ sub_category_name: '', created_by: sessionStorage.getItem('userId') });
              this.closeAddDrawer();
            });

          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        }
      });
  }

  submitEditForm(categoryType: string) {
    var requestData = {};
    var apiUrl = '';
    if (categoryType === this.CATEGORY) {
      if (isFormValid(this.editCategoryForm)) {
        apiUrl = `${environment.API_URL}edit_course_category`;
        requestData = {
          ...this.editCategoryForm.value,
          updated_by: sessionStorage.getItem('userId')
        };
        console.log(requestData);
      } else return;
    } else {
      if (isFormValid(this.editSubCategoryForm)) {
        apiUrl = `${environment.API_URL}edit_course_sub_category`;
        requestData = {
          ...this.editSubCategoryForm.value,
          updated_by: sessionStorage.getItem('userId')
        };
        console.log(requestData);

      } else return;
    }

    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchInitialData().then(() => {
              this.message.success('Category updated');
              this.closeAddDrawer();
            });

          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        }
      });
  }

  deleteCategory(id: number, categoryType: string) {
    var requestData = {};
    var apiUrl = '';

    if (categoryType === this.CATEGORY) {
      apiUrl = `${environment.API_URL}delete_course_categories`;
      requestData = { category_id: id };
      console.log(requestData);
    } else {
      apiUrl = `${environment.API_URL}delete_course_sub_category`;
      requestData = { sub_category_id: id };
      console.log(requestData);
    }

    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchInitialData().then(() => {
              this.message.success('Category deleted');
            });

          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        }
      });
  }

}
