import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dehyphenate'
})
export class DeHyphenatePipe implements PipeTransform {

  transform(input: string | null | undefined): string {
    if (!input) {
      return '';
    }

    // Split the input by hyphens, capitalize the first letter of each word, and join with spaces
    return input.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
}
