<app-main-fiminize-layout>
    <nz-breadcrumb>
        <nz-breadcrumb-item><a routerLink="/"><span nz-icon nzType="home"
                    nzTheme="outline"></span></a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a routerLink="/room-topics">IdeaHub</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a>Rooms</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a>Topics</a></nz-breadcrumb-item>

    </nz-breadcrumb>
    <h2 class="mt-2">#{{ topicName | titlecase}}</h2>
    <div class="container mt-5">
        <form action="" [formGroup]="commentForm">
            <!-- Add new comments -->
            <nz-input-group [nzSuffix]="suffixIconSearch">
                <input  formControlName="message" type="text" nz-input placeholder="Add New Post" />
            </nz-input-group>
            <ng-template #suffixIconSearch>
                <button type="button" nzType="primary" (click)="addComments()"
                     nzSearch><span nz-icon nzType="send"></span></button>
                     <button type="button" nzType="primary" (click)="uploadImage()"
                     nzSearch><span nz-icon nzType="upload"></span></button>
                     <input type="file" #fileInput (change)="onFileSelected($event)" style="display: none;" />

            </ng-template>
            <!-- All comments -->
            <div *ngFor="let discussion of forumComments" nz-card class="mt-3 my-2 bg-gray-100 p-3 rounded-md">
                <div >
                    <nz-card-meta [nzAvatar]="avatarTemplate" nzTitle="{{ discussion.name }}"
                        nzDescription="{{ discussion.message }}"></nz-card-meta>
                    <ng-template #avatarTemplate>
                        <nz-avatar [nzSize]="40" [nzSrc]="storageUrl + '/' + discussion.profile_pic"
                            [nzShape]="'circle'"></nz-avatar>
                    </ng-template>
                 
                </div>
                <div class="m-3">
                    <nz-input-group [nzSuffix]="suffixIconSearch">
                        <input  formControlName="message" type="text" nz-input
                            placeholder="Add a new comment..." />
                    </nz-input-group>
                    <ng-template #suffixIconSearch>
                        <button type="button" nzType="primary" (click)="addComments(discussion.id)"
                             nzSearch><span nz-icon nzType="send"></span></button>
                    </ng-template>
                    <div class="d-flex justify-content-start mt-2">
                        <button nzType="default" typ="button" (click)="toggleInputField(discussion)">
                            <i class="fa-regular fa-xl fa-comment"></i>
                            {{ discussion.replies_count }}
                        </button>
                        <div class="ms-3">
                            <button *ngIf="discussion.bookmark_status === 0" (click)="$event.stopPropagation();">
    
                                <i (click)="addBookmarksComments(discussion.id)" class="fa-regular fa-bookmark"></i>
                            </button>
        
                            <button *ngIf="discussion.bookmark_status === 1" (click)="$event.stopPropagation();">
                                <i (click)="deleteBookmarkedArticle(discussion.id)" class="fa-solid fa-bookmark"></i>
                            </button>
                        </div>
                    </div>
                </div>
              
            
                <div *ngIf="discussion.showInputField" class="nested-comments-section rounded-md">
                    <!-- Display nested comments here -->
                    <div *ngFor="let nestedComment of discussion.children.slice(0, visibleComments)" nz-card
                        class="mt-3">
                        <nz-card-meta [nzAvatar]="avatarTemplate" nzTitle="{{ nestedComment.name }}"
                            nzDescription="{{ nestedComment.message }}"></nz-card-meta>
                        <ng-template #avatarTemplate>
                            <nz-avatar [nzSize]="30" [nzSrc]="storageUrl + '/' + discussion.profile_pic"
                                [nzShape]="'circle'"></nz-avatar>
                        </ng-template>
                        <button nzType="button" type="button" (click)="toggleInputField(nestedComment)">
                            <i class="fa-solid fa-lg fa-reply"></i> {{nestedComment.replies_count}}
                        </button>
                        <div *ngIf="nestedComment.showInputField" class="mt-2">
                            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                                <input formControlName="message" type="text" nz-input placeholder="Add New Comment" />
                            </nz-input-group>
                            <ng-template #suffixIconButton>
                                <button  nzTooltipTitle="Add Comment" nz-tooltip
                                    [nzTooltipPlacement]="['bottomRight']" nz-button nzType="primary" type="button"
                                    (click)="addComments(nestedComment.id)" nzSearch>
                                    <span nz-icon nzType="send"></span>
                                </button>
                            </ng-template>
                        </div>
                        <div *ngIf="nestedComment.showComments">
                            <div *ngFor="let comment of nestedComment.comments" class="nested-comment">
                              <!-- Display nested comment content here -->
                              <p>{{ comment.message }}</p>
                              <!-- Add more elements as needed -->
                            </div>
                          </div>
                    </div>

                    <a nzType="primary" (click)="loadMoreComments()"
                        *ngIf="discussion.children.length > visibleComments" nz-button 
                        nzType="link">View More...</a>
                </div>
            </div>
        </form>
    </div>
</app-main-fiminize-layout>