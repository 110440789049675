<nz-layout style="min-height: 100vh;" class="d-flex">
    <nz-layout>
        <nz-header>
            <div class="container d-flex">
                <span>
                    <img routerLink="/" class="me-5 header-logo pointer" [src]="headerLogo" alt="Invest Dost Logo">
                </span>
            </div>
        </nz-header>

        <div class="container bg-white p-5 m-3 mx-auto flex-grow-1 ">

            <div class="row">
                <div class="col-md-6 px-3">

                </div>
                <div class="col-md-6 px-3">
                  <form nz-form nzLayout="vertical" [formGroup]="sendOtp" (ngSubmit)="sendOtpToUser()">

                    <div class="addUsers" *ngIf="!sendFourDigitOtp">
                      <nz-form-item>
                        <nz-form-label nzRequired nzFor="username">Username  <span class="mx-2" nz-icon nz-tooltip nzTooltipTitle="Ex: all letters in lowercase,(!@#$%^&*()_+=?<>]*$) this special characters are allowed, numbers are allowed, Space are not allowed" nzType="info-circle"></span></nz-form-label>

                        <nz-form-control nzSpan="15">
                            <nz-input-group nzPrefixIcon="user">
                                <input type="text" nz-input formControlName="username" placeholder="Username" />
                            </nz-input-group>
                            <div *ngIf="sendOtp.get('username')?.invalid && sendOtp.get('username')?.touched" style="color: red;">
                              {{ getErrorMessage('username') }}
                            </div>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="email">Email</nz-form-label>

                      <nz-form-control nzSpan="15" >
                          <nz-input-group nzPrefixIcon="user"  >
                              <input type="email" nz-input formControlName="email" placeholder="email" />
                          </nz-input-group>
                          <div *ngIf="sendOtp.get('email')?.invalid && sendOtp.get('email')?.touched" style="color: red;">
                            {{ getErrorMessage('email') }}
                          </div>
                      </nz-form-control>
                  </nz-form-item>
                    <nz-form-item>
                        <nz-form-label nzRequired nzFor="password">Password <span class="mx-2" nz-icon nz-tooltip nzTooltipTitle="Ex: all letters in lowercase,(!@#$%^&*()_+=?<>]*$) this special char" nzType="info-circle"></span></nz-form-label>
                        <nz-form-control nzSpan="15" >
                            <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                                <input [type]="passwordVisible ? 'text' : 'password'" nz-input
                                    formControlName="password" placeholder="Password" />
                            </nz-input-group>
                            <div *ngIf="sendOtp.get('password')?.invalid && sendOtp.get('password')?.touched" style="color: red;">
                              {{ getErrorMessage('password') }}
                            </div>
                        </nz-form-control>
                        <ng-template #suffixTemplate>
                            <span
                              nz-icon
                              [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                              (click)="passwordVisible = !passwordVisible"
                            ></span>
                          </ng-template>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="password">Confirm Password</nz-form-label>
                      <nz-form-control nzSpan="15"  nzStatus="error">
                          <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                              <input [type]="passwordVisible ? 'text' : 'password'" nz-input
                                  formControlName="confirm_password" placeholder="Confirm Password" />
                          </nz-input-group>
                          <div *ngIf="sendOtp.get('confirm_password')?.invalid && sendOtp.get('confirm_password')?.touched" style="color: red;">
                            {{ getErrorMessage('confirm_password') }}
                          </div>

                      </nz-form-control>
                      <ng-template #suffixTemplate>
                          <span
                            nz-icon
                            [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                            (click)="passwordVisible = !passwordVisible"
                          ></span>
                        </ng-template>
                  </nz-form-item>
                    <div nz-row class="mb-4">
                        <div nz-col [nzSpan]="7">
                            <label nz-checkbox formControlName="remember">
                                <span>Remember me</span>
                            </label>
                        </div>
                        <div nz-col [nzSpan]="8">
                            <a class="float-end">Forgot password?</a>
                        </div>
                    </div>
                    <button nz-button nzType="primary" type="submit" [disabled]="!sendOtp.valid || loginButtonDisable">register now!</button>
                         <!-- <p class="mt-3"> Or
                        <a class="ps-0" nz-button nzType="link" routerLink="/register">register now!</a>
                    </p> -->
                    </div>
                </form>

                <form nz-form nzLayout="vertical" [formGroup]="otpSubmitForm" (ngSubmit)="submitForm()">
                  <div class="SendOtp"      *ngIf="sendFourDigitOtp">
                    <nz-form-item>
                      <nz-form-label nzRequired nzFor="OTP">OTP </nz-form-label>

                      <nz-input-number-group  nzCompact>
                        <nz-input-number formControlName="otp"   [nzMin]="1000" [nzMax]="9999" style="width: calc(100% - 40%)"></nz-input-number>
                        <button nz-button nzType="primary">Submit</button>
                      </nz-input-number-group>
                  </nz-form-item>
                  <div>
                    <button nz-button nzType="primary" nzGhost (click)="sendOtpToUser()">Resend OTP</button>
                    <p> you have get OTP on This mail  {{this.otpSubmitForm.controls['email'].value}}</p>
                  </div>
                  </div>
                </form>
                </div>
            </div>
        </div>

        <nz-footer class="border-top">
            <div class="d-flex">
                <span>&#169; {{ currentYear }} Version 1.0.3 </span>
                <span class="ms-auto"> InvestDost | Development | Designed By
                    <a class="ms-1 " target="_blank" rel="noopener noreferrer" href="https://portalwiz.com/">Portalwiz
                        Technologies</a>
                </span>
            </div>
        </nz-footer>
    </nz-layout>
</nz-layout>
