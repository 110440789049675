<div class="full-page bg-dark">
  <div class="container center-content">
    <div *ngIf="!showMoreInfo && !showQuestions" class="text-center mb-5">
      <img src="assets/app-icon-white-bg.png" alt="Steering Wheel" class="mb-3 logo">
      <h3 class="mb-3">Are you a finance influencer, enthusiast or professional wanting to monetize your analysis and research?</h3>
      <button class="btn btn-light btn-lg mb-3" (click)="showMoreInfoSection()">Learn more</button>
      <p><span nz-icon nzType="field-time" nzTheme="outline"></span> Takes 45 sec</p>
    </div>

    <div *ngIf="showMoreInfo && !showQuestions" class="text-center mb-5">
      <h3 class="mb-3"><i class="fas fa-quote-left"></i> InvestDost is your social platform built for the finance professional, influencer, and those who want to earn via the InvestDost Author program.</h3>
      <h4 class="mb-3">Those who are selected will have access to <strong>grow and manage their audience</strong> all while getting the InvestDost+ membership, for free.</h4>
      <button class="btn btn-light btn-lg mb-3" (click)="continueToQuestions()">Continue</button>
    </div>

    <div *ngIf="showQuestions" class="form-container">
      <div class="d-flex justify-content-end mb-3">
        <button nz-button nzSize="small"  type="button" (click)="confirmSkip()">
          <span nz-icon nzType="logout" nzTheme="outline"></span>
        </button>
      </div>
      <form [ngClass]="{'fade-in': isAnimationTriggered, 'slide-in': isAnimationTriggered}">
        <ng-container *ngIf="authorOnboarding.length > 0">
          <h3 class="mb-4">{{ authorOnboarding[i].author_question }}</h3>

          <!-- Short answer -->
          <input *ngIf="authorOnboarding[i].question_type_id === 1" [(ngModel)]="authorOnboarding[i].answer" type="text" name="answer{{i}}" class="form-control mb-4" placeholder="Enter your answer" required>

          <!-- Radio Options (Type 2 & 3) -->
          <ng-container *ngIf="authorOnboarding[i].question_type_id === 2 || authorOnboarding[i].question_type_id === 3">
            <nz-radio-group [(ngModel)]="authorOnboarding[i].answer" class="mb-4" name="radioGroup{{i}}">
              <ng-container *ngFor="let item of authorOnboarding[i].options">
                <label nz-radio nzValue="{{item.author_option_id}}" style="color: azure;">{{item.options}}</label>
              </ng-container>
            </nz-radio-group>
          </ng-container>

          <!-- Social Media Inputs -->
          <div class="social-inputs mb-4" *ngIf="authorOnboarding[i].question_type_id === 4">
            <div class="d-flex align-items-center mb-3">
              <i class="fa-brands fa-instagram fa-2xl text-white"></i>
              <input [(ngModel)]="authorOnboarding[i].instagram" type="text" class="form-control ms-3" name="instagramHandle{{i}}" placeholder="Instagram handle">
            </div>
            <div class="d-flex align-items-center mb-3">
              <i class="fa-brands fa-linkedin fa-2xl text-white"></i>
              <input [(ngModel)]="authorOnboarding[i].linkedin" type="text" class="form-control ms-3" name="linkedinHandle{{i}}" placeholder="LinkedIn profile">
            </div>
            <div class="d-flex align-items-center mb-3">
              <i class="fa-brands fa-youtube fa-2xl text-white"></i>
              <input [(ngModel)]="authorOnboarding[i].youtube" type="text" class="form-control ms-3" name="youtubeHandle{{i}}" placeholder="YouTube channel">
            </div>
            <div class="d-flex align-items-center mb-3">
              <i class="fa-brands fa-twitter fa-2xl text-white"></i>
              <input [(ngModel)]="authorOnboarding[i].twitter" type="text" class="form-control ms-3" name="twitterHandle{{i}}" placeholder="Twitter handle">
            </div>
          </div>
          <ng-container *ngIf="authorOnboarding[i].question_type_id === 5">
            <nz-radio-group [(ngModel)]="authorOnboarding[i].answer" class="mb-4" name="radioGroup{{i}}">
              <ng-container *ngFor="let item of authorOnboarding[i].options">
                <label nz-radio nzValue="{{item.author_option_id}}" style="color: azure;">{{item.options}}</label>
              </ng-container>
            </nz-radio-group>
          </ng-container>
          <!-- Long answer (Textarea) -->
          <textarea *ngIf="authorOnboarding[i].question_type_id === 6" [(ngModel)]="authorOnboarding[i].answer" rows="4" class="form-control mb-4" placeholder="Write your article here..."></textarea>

          <!-- Navigation Buttons -->
          <div class="d-flex justify-content-between">
            <button type="button" class="btn btn-secondary" (click)="decreaseIndex()">
              <span nz-icon nzType="arrow-left" nzTheme="outline"></span>
            </button>
         
            <button type="button" class="btn" 
            [ngClass]="i < authorOnboarding.length - 1 ? 'btn-primary' : 'btn-success'" 
            (click)="i < authorOnboarding.length - 1 ? increaseIndex(authorOnboarding[i], authorOnboarding[i].answer) : submitForm()">
      <span nz-icon [nzType]="i < authorOnboarding.length - 1 ? 'arrow-right' : 'check'"></span>
      {{ i < authorOnboarding.length - 1 ? '' : 'Submit' }}
    </button>
 
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</div>
