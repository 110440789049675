import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Article } from 'src/app/Interfaces/Content';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-author-profile',
  templateUrl: './author-profile.component.html',
  styleUrls: ['./author-profile.component.scss']
})
export class AuthorProfileComponent {
  userRole: string = ''; // Replace with the actual user's role
  isLoading: boolean = false;
  constructor(private http: HttpClient, private route: ActivatedRoute, private location: Location, private sanitizer: DomSanitizer, private fetchApiService: FetchApiService) { }
  editorsPicksArticles: Article[] = [];
  articles: Article[] = [];
  authorString: string = '';

  goBack() {
    this.location.back();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.authorString = params['author'];
      let authorId = +params['id']; // Convert the parameter to a number
      console.log(authorId);
      this.fetchInitialData(authorId);
      this.fetchOtherData();
    });
  }

  fetchInitialData(authorId: any): Promise<any> {
    const apiUrl = `${environment.API_URL}my_articles`;

    const requestData = {
      author_id: authorId,
      is_published: 1
    };

    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('All Articles: ', response);
          this.articles = response.data;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.isLoading = false;
        }, complete: () => {
          this.isLoading = false;
        }
      });
    return Promise.resolve(true);
  }


  fetchOtherData() {
    this.isLoading = true;
    const editorsPicksArticles$ = this.fetchApiService.getEditorsPicks();
    forkJoin([editorsPicksArticles$]).subscribe((results) => {
      this.editorsPicksArticles = results[0].data;
      this.isLoading = false;
      // console.log(results);
    });
  }
}
