
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-room-topics',
  templateUrl: './room-topics.component.html',
  styleUrls: ['./room-topics.component.scss']
})
export class RoomTopicsComponent implements OnInit{
  constructor(
    private _activatedRoute: ActivatedRoute,
    private apiService: FetchApiService,
    private message: NzMessageService
  ) {}
 
  storageUrl = environment.STORAGE_URL;
  topics: any[] = [];
  ngOnInit(): void {
    this.getTopics();
  }

  getTopics(): Promise<any> {
    this._activatedRoute.params.subscribe((params) => {
      console.log(params);
      const roomId = +params['id'];
      console.log(roomId);

      if (roomId) {
        this.apiService.fetchRoomTopics(roomId).subscribe({
          next: (response: any) => {
            console.log('Response: ', response.data);
            this.topics = response;
          },
          error: (error) => {
            console.error('Error: ', error);
          },
          complete: () => {},
        });
      } else {
        console.log(roomId, ' this data not get');
      }
    });

    return Promise.resolve(true);
  }

}
