import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-article-tag',
  templateUrl: './article-tag.component.html',
  styleUrls: ['./article-tag.component.scss'],
})
export class ArticleTagComponent implements OnInit {
  constructor(
    private _activatedRoute: ActivatedRoute,
    private apiService: FetchApiService,
    private message: NzMessageService
  ) {}
  ngOnInit(): void {
    this.fetchTagArticle();
  }
  tagArticles: any[] = [];
  Tag: any;
  tableLoading: boolean = false;


  storageUrl = environment.STORAGE_URL;
  userId = localStorage.getItem('userId');
  @ViewChild('leftButton') leftButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('rightButton') rightButton!: ElementRef<HTMLButtonElement>;

  @Output() slideLeftEvent = new EventEmitter<void>();
  @Output() slideRightEvent = new EventEmitter<void>();

  slideLeft(): void {
    this.slideLeftEvent.emit();
  }

  slideRight(): void {
    this.slideRightEvent.emit();
  }
  fetchTagArticle(): Promise<any> {
    this._activatedRoute.params.subscribe((params) => {
      console.log(params);
      this.Tag = params['tag'];
      console.log(this.Tag);

      if (this.Tag) {
        this.apiService.fetchTagArticles(this.Tag).subscribe({
          next: (response: any) => {
            console.log('Response: ', response.articles);
            this.tagArticles = response.articles;
            
          },
          error: (error) => {
            console.error('Error: ', error);
            this.tableLoading = false;
          },
          complete: () => {
            this.tableLoading = false;
          },
        });
      } else {
        console.log(this.Tag, ' this data not get');
      }
    });

    return Promise.resolve(true);
  }
}
