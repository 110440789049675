<ng-container *ngIf="useAdminLayout; else authorLayout">
    <app-admin-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-admin-layout>
</ng-container>

<ng-template #authorLayout>
    <app-author-layout>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </app-author-layout>
</ng-template>

<ng-template #content>
    <!-- Content specific to both admin and author layouts -->
    <div class="d-flex mb-2">
        <nz-breadcrumb>
            <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Course Management</nz-breadcrumb-item>
            <nz-breadcrumb-item>Sections</nz-breadcrumb-item>
        </nz-breadcrumb>

    </div>

    <nz-card>

        <div class="col-12 d-flex mb-3">
            <nz-form-label class="col-auto">Choose Course</nz-form-label>

            <nz-select class="col-3" nzShowSearch nzAllowClear nzPlaceHolder="Select Course"
                (ngModelChange)="fetchCourseSections($event)" [(ngModel)]="selectedCourseId">
                <nz-option *ngFor="let course of courses" [nzLabel]="course.title"
                    [nzValue]="course.course_id"></nz-option>
            </nz-select>



            <button class="ms-auto col-auto" nz-button nzType="primary" (click)="openAddDrawer()"
                [disabled]="!selectedCourseId">
                <span nz-icon nzType="plus"></span>Add Section
            </button>
        </div>


        <div class="table-responsive">
            <nz-table #sectionTable [nzData]="sections" nzTableLayout="fixed" [nzLoading]="tableLoading"
                nzShowSizeChanger>
                <thead>
                    <tr>
                        <th [nzChecked]="sectionTableChecked" [nzIndeterminate]="sectionTableIndeterminate"
                            nzLabel="Select all" (nzCheckedChange)="onAllChecked($event)" width="5%"></th>
                        <th>Title</th>
                        <th>Created On</th>
                        <th>Updated On</th>
                        <th nzWidth="100px" nzAlign="center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let section of sectionTable.data">

                        <!-- Main Table -->
                        <tr (click)="openEditDrawer(section.section_id)">
                            <td [nzChecked]="setOfCheckedSectionTableIds.has(section.section_id)"
                                (click)="$event.stopPropagation()"
                                (nzCheckedChange)="onItemChecked(section.section_id, $event)"></td>
                            <td>{{ section.title }}</td>
                            <td>{{ section.created_at | date: 'dd MMM y, h:mm a' }}</td>
                            <td>{{ section.updated_at | date: 'dd MMM y, h:mm a' }}</td>

                            <td nzAlign="center" (click)="$event.stopPropagation()">
                                <button nz-button nzType="default" nzDanger nzShape="circle" nz-popconfirm
                                    nzPopconfirmTitle="Are you sure you want to delete this record?"
                                    (nzOnConfirm)="confirm(section.section_id)" (nzOnCancel)="cancel()">
                                    <span nz-icon nzType="delete"></span>
                                </button>
                            </td>
                        </tr>

                    </ng-container>
                </tbody>
            </nz-table>
        </div>

        <nz-drawer nzSize="large" [nzVisible]="addDrawer" nzTitle="Add Section" (nzOnClose)="closeAddDrawer()"
            [nzFooter]="AddFormFooterTpl">
            <ng-container *nzDrawerContent>

                <form id="add-form" nz-form nzLayout="vertical" [formGroup]="addSectionForm"
                    (ngSubmit)="submitAddForm()">
                    <div class="row">

                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label nzRequired>Title</nz-form-label>
                                <nz-form-control nzErrorTip="Enter Title">
                                    <nz-input-group>
                                        <input type="text" nz-input formControlName="title" required
                                            placeholder="Section title" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label nzRequired>Under Course</nz-form-label>
                                <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Course"
                                    formControlName="course_id">
                                    <nz-option *ngFor="let course of courses" [nzLabel]="course.title"
                                        [nzValue]="course.course_id"></nz-option>
                                </nz-select>
                            </nz-form-item>
                        </div>

                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Description</nz-form-label>
                                <nz-form-control nzHasFeedback nzErrorTip="Please write something here!">
                                    <nz-textarea-count [nzMaxCharacterCount]="1000">
                                        <textarea formControlName="description" nz-input rows="2"
                                            placeholder="Write your description here"></textarea>
                                    </nz-textarea-count>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Add a Main Quiz for this section <span nz-popover
                                        [nzPopoverOverlayStyle]="{width:'25rem'}" nzPopoverPlacement="topLeft"
                                        [nzPopoverContent]='contentTemplate' class="ms-2" nz-icon
                                        nzType="question-circle" nzTheme="outline"></span></nz-form-label>
                                <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Main Quiz"
                                    formControlName="quiz_id">
                                    <nz-option *ngFor="let quiz of quizzes" [nzLabel]="quiz.title"
                                        [nzValue]="quiz.id"></nz-option>
                                </nz-select>
                            </nz-form-item>
                            <ng-template #contentTemplate>
                                <span>This quiz serves as the <strong>Primary Assessment</strong> for learners who
                                    have a comprehensive understanding of this section. It can also be referred to as
                                    the <strong>Final Quiz</strong> for this section.</span>
                            </ng-template>
                        </div>

                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Section status?</nz-form-label>
                                <nz-radio-group formControlName="is_active">
                                    <label nz-radio [nzValue]="1">Active</label>
                                    <label nz-radio [nzValue]="0">Inactive</label>
                                </nz-radio-group>
                            </nz-form-item>
                        </div>

                    </div>

                </form>

            </ng-container>

            <ng-template #AddFormFooterTpl>
                <div style="float: right">
                    <button nz-button style="margin-right: 8px;" (click)="closeAddDrawer()">Cancel</button>
                    <button nz-button nzType="primary" [nzLoading]="formSubmitLoading" form="add-form"
                        type="submit">Submit</button>
                </div>
            </ng-template>
        </nz-drawer>

        <nz-drawer nzSize="large" [nzVisible]="editDrawer" nzTitle="Edit Section" (nzOnClose)="closeEditDrawer()"
            [nzFooter]="EditFormFooterTpl">
            <ng-container *nzDrawerContent>

                <form id="edit-form" class="mb-4" nz-form nzLayout="vertical" [formGroup]="editSectionForm"
                    (ngSubmit)="submitEditForm()">

                    <div class="row">


                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label nzRequired>Title</nz-form-label>
                                <nz-form-control nzErrorTip="Enter Title">
                                    <nz-input-group>
                                        <input type="text" nz-input formControlName="title" required
                                            placeholder="Section title" />
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label nzRequired>Under Course</nz-form-label>
                                <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Course"
                                    formControlName="course_id">
                                    <nz-option *ngFor="let course of courses" [nzLabel]="course.title"
                                        [nzValue]="course.course_id"></nz-option>
                                </nz-select>
                            </nz-form-item>
                        </div>

                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Description</nz-form-label>
                                <nz-form-control nzHasFeedback nzErrorTip="Please write something here!">
                                    <nz-textarea-count [nzMaxCharacterCount]="1000">
                                        <textarea formControlName="description" nz-input rows="2"
                                            placeholder="Write your description here"></textarea>
                                    </nz-textarea-count>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Add a Main Quiz for this section <span nz-popover
                                        [nzPopoverOverlayStyle]="{width:'25rem'}" nzPopoverPlacement="topLeft"
                                        [nzPopoverContent]='contentTemplate' class="ms-2" nz-icon
                                        nzType="question-circle" nzTheme="outline"></span></nz-form-label>
                                <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Main Quiz"
                                    formControlName="quiz_id">
                                    <nz-option *ngFor="let quiz of quizzes" [nzLabel]="quiz.title"
                                        [nzValue]="quiz.id"></nz-option>
                                </nz-select>
                            </nz-form-item>
                            <ng-template #contentTemplate>
                                <span>This quiz serves as the <strong>Primary Assessment</strong> for learners who
                                    have a comprehensive understanding of this section. It can also be referred to as
                                    the <strong>Final Quiz</strong> for this section.</span>
                            </ng-template>
                        </div>


                        <div class="col-12 col-md-6">
                            <nz-form-item>
                                <nz-form-label>Section status?</nz-form-label>
                                <nz-radio-group formControlName="is_active">
                                    <label nz-radio [nzValue]="1">Active</label>
                                    <label nz-radio [nzValue]="0">Inactive</label>
                                </nz-radio-group>

                            </nz-form-item>
                        </div>

                    </div>
                </form>

            </ng-container>
            <ng-template #EditFormFooterTpl>
                <div style="float: right">
                    <button nz-button style="margin-right: 8px;" (click)="closeEditDrawer()">Cancel</button>
                    <button nz-button nzType="primary" [nzLoading]="formSubmitLoading" form="edit-form"
                        type="submit">Submit</button>
                </div>
            </ng-template>
        </nz-drawer>

    </nz-card>
</ng-template>