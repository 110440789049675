import { DatePipe, Time } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { forkJoin } from 'rxjs';
import { convertToCategoryTree } from 'src/app/Functions/arrayFunctions';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { Question, Quiz } from 'src/app/Interfaces/Quiz';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { Router } from '@angular/router';
@Component({
  selector: 'app-quizzes',
  templateUrl: './quizzes.component.html',
  styleUrls: ['./quizzes.component.scss']
})
export class QuizzesComponent {
  roleId:any;
  searchQuery: string = '';
  quizzes: Quiz[] = [];
  filteredQuizzes: Quiz[] = [];
  questions: Question[] = [];
  allcategories: any = [];
  allSymbols: any[] = [];    
  useAdminLayout: boolean = false; 
  treeSelectExpandKeys: string[] = [];
  categories: any = [];
  subCategories: any = [];
  quizLevel:any=[]
  tableLoading: boolean = true;
  formSubmitLoading: boolean = false;

  addQuizForm: FormGroup = new FormGroup({
    // Define your form control with validation
    title: new FormControl('', [Validators.required]),
    description: new FormControl(''),
   quiz_level_id: new FormControl(''),
   category_id: new FormControl(''),
    is_active: new FormControl(1),
    symbol_id: new FormControl([]),
    valid_date_range: new FormControl([]),
    valid_from: new FormControl(null),
    valid_to: new FormControl(null),
    bonus_points: new FormControl(null),
    no_of_days: new FormControl(null),
    time: new FormControl(null),
    max_attempt: new FormControl(null),
    category: new FormControl(null),
    sub_category: new FormControl(null),
  });

  editQuizForm: FormGroup = new FormGroup({
    // Define your form control with validation
    quiz_id: new FormControl(''),
    title: new FormControl('', [Validators.required]),
    description: new FormControl(''),
   quiz_level_id: new FormControl(''),
   category_id: new FormControl(''),
    is_active: new FormControl(null),
    symbol_id: new FormControl([]),
    valid_date_range: new FormControl([]),
    valid_from: new FormControl(null),
    valid_to: new FormControl(null),
    category: new FormControl(null),
    sub_category: new FormControl(null),
    updated_by: new FormControl(sessionStorage.getItem('userId'))
  });

  // This will store the id Quiz Currently Open in the Edit Drawer
  currentEditQuizId?: number;

  quizQuestions: any = [];

  constructor(private http: HttpClient,private router: Router, private message: NzMessageService, private apiService: FetchApiService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.roleId = localStorage.getItem('role_id');
    this.useAdminLayout = this.roleId === '1' || this.roleId === '2';
    this.fetchInitialData();
    this.fetchDropDowns();
    this.fetchAllSymbols();
    this.apiService.getAllQuestions().subscribe({
      next: (res: any) => {
        this.questions = res.data.filter((x: any) => x.is_active === 1);
      }
    });
  }
  fetchDropDowns(): Promise<any> {
    this.apiService.getCategories().subscribe({
      next: (response: any) => {
        console.log('Category Dropdown: ', response);
        const treeData = convertToCategoryTree(response.data);
        console.log("treedata",treeData);
        this.allcategories = treeData;
      },
      error: (error) => {
        console.error('Error: ', error);
      },
    });
    return Promise.resolve(true);
  }
  
  searchQuizzes(): void {
    const trimmedQuery = this.searchQuery.trim().toLowerCase();

    if (trimmedQuery === '') {
      this.filteredQuizzes = this.quizzes; // Reset to all quizzes if query is empty
    } else {
      this.filteredQuizzes = this.quizzes.filter(quiz =>
        quiz.title.toLowerCase().includes(trimmedQuery)
      );
    }
  }
  sortByTitle = (a: any, b: any) => a.title.localeCompare(b.title);
  sortByDescription = (a: any, b: any) => a.description.localeCompare(b.description);
  sortByStatus = (a: any, b: any) => a.status.localeCompare(b.status);
  sortByQuizLevel = (a: any, b: any) => a.quizLevel.localeCompare(b.quizLevel);
  sortByCategory = (a: any, b: any) => a.category.localeCompare(b.category);
  sortByValidFrom = (a: any, b: any) => new Date(a.validFrom).getTime() - new Date(b.validFrom).getTime();
  sortByValidTo = (a: any, b: any) => new Date(a.validTo).getTime() - new Date(b.validTo).getTime();
  sortByCreatedAt = (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  sortByUpdatedAt = (a: any, b: any) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
  sortByCreatedBy = (a: any, b: any) => a.createdBy.localeCompare(b.createdBy);
  sortByUpdatedBy = (a: any, b: any) => a.updatedBy.localeCompare(b.updatedBy);



  fetchInitialData(): Promise<void> {
    this.tableLoading = true;
    return new Promise((resolve, reject) => {
      const getCategories$ = this.apiService.getQuizCategories();
      const getSubCategories$ = this.apiService.getQuizSubCategories();
      const quizzes$ = this.apiService.getAllQuizzes();
      const quizzesLevel$ = this.apiService.getQuizLevel();
      

      forkJoin([getCategories$, getSubCategories$, quizzes$,quizzesLevel$]).subscribe({
        next: ([categoriesResponse, subCategoriesResponse, quizzesResponse, quizLevel]: any) => {

          this.categories = categoriesResponse.data;
          this.subCategories = subCategoriesResponse.data;
          this.quizzes = quizzesResponse.data;
          console.log(   this.quizzes );
          this.quizLevel = quizLevel;
          this.filteredQuizzes = this.quizzes;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          reject(error);
        },
        complete: () => {
          this.tableLoading = false;
          resolve();
        },
      });

    });
  }

  fetchQuizQuestions(id: number): Promise<void> {
    this.tableLoading = true;
    // console.log(id);
    const apiUrl = `${environment.API_URL}fetch_all_quiz_question`;
    var requestData = { "quiz_id": id };

    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            // console.log('Quiz Questions: ', response);
            this.quizQuestions = response;
          },
          error: (error) => {
            console.error('Error: ', error);
            this.tableLoading = false;
            return reject();
          }, complete: () => {
            this.tableLoading = false;
            return resolve();
          }
        });
    });
  }

  addDrawer: boolean = false;
  editDrawer: boolean = false;
  
  openEditDrawer(quizId: number): void {
    this.fetchQuizQuestions(quizId);
    this.currentEditQuizId = quizId;

    const apiUrl = `${environment.API_URL}fetch_quiz`;
    var requestData = { "quiz_id": quizId };

    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Quiz Details: ', response);
        },
        error: (error) => {
          console.error('Error: ', error);
        }
      });

    let quizDetails = this.quizzes.find(quiz => quiz.id === quizId);
    console.log(quizDetails);
    let rangeArray = [];
    if (quizDetails!.valid_from != null) {
      rangeArray.push(quizDetails!.valid_from);
    }
    if (quizDetails!.valid_to != null) {
      rangeArray.push(quizDetails!.valid_to);
    }

    this.editQuizForm.patchValue({
      ...quizDetails!,
      quiz_id: quizId,
      valid_date_range: rangeArray
    });
    this.editDrawer = true;
  }

  closeEditDrawer(): void {
    this.editDrawer = false;
    this.currentEditQuizId = undefined;
    this.setOfCheckedQuizQuestionsTableIds.clear();
  }

  openAddDrawer(): void {
    this.addDrawer = true;
  }

  closeAddDrawer() {
    this.addDrawer = false;
  }

  cancel(): void {
  }

  confirm(id: number): void {
    this.deleteRecord(id);
  }

  submitAddForm() {
    if (isFormValid(this.addQuizForm)) {
      this.formSubmitLoading = true;
      const apiUrl = `${environment.API_URL}add_quiz`;
      let start_date = this.addQuizForm.controls['valid_date_range'].value[0];
      let end_date = this.addQuizForm.controls['valid_date_range'].value[1];
      const time = this.addQuizForm.controls['time'].value;
      const requestData = {
        ...this.addQuizForm.value,
        user_id: localStorage.getItem('userId'),
        valid_from: this.datePipe.transform(start_date, 'yyyy-MM-dd HH:mm:ss'),
        valid_to: this.datePipe.transform(end_date, 'yyyy-MM-dd HH:mm:ss'),
        time: this.datePipe.transform(time, 'HH:mm:ss'), 
      };

      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            if (response.success) {
              this.fetchInitialData().then(() => {
                this.message.success('Quiz added');
                this.addQuizForm.reset();
                this.addQuizForm.patchValue({
                  is_active: 1,
                  created_by: sessionStorage.getItem('userId')
                });
                this.closeAddDrawer();
              });

            } else {
              this.message.error('Error occurred, please try later');
            }
            this.formSubmitLoading = false;

          },
          error: (error) => {
            console.error('Error: ', error);
            this.message.error('Error occurred, please try later');
            this.formSubmitLoading = false;
          }
        });
    }
  }

  submitEditForm() {
    if (isFormValid(this.editQuizForm)) {
      this.formSubmitLoading = true;

      const apiUrl = `${environment.API_URL}edit_quiz`;

      let start_date = this.editQuizForm.controls['valid_date_range'].value[0];
      let end_date = this.editQuizForm.controls['valid_date_range'].value[1];

      const requestData = {
        ...this.editQuizForm.value,
        valid_from: this.datePipe.transform(start_date, 'yyyy-MM-dd HH:mm:ss'),
        valid_to: this.datePipe.transform(end_date, 'yyyy-MM-dd HH:mm:ss'),
        user_id: localStorage.getItem('userId'),
      };
      // console.log(requestData);
      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            // console.log('Response: ', response);
            if (response.success) {
              this.fetchInitialData().then(() => {
                this.message.success('Quiz updated');
                this.editQuizForm.reset();
                this.closeEditDrawer();
              });
            } else {
              this.message.error('Error occurred, please try later');
            }
            this.formSubmitLoading = false;

          },
          error: (error) => {
            console.error('Error: ', error);
            this.formSubmitLoading = false;

          }
        });
    }
  }

  deleteRecord(id: number) {
    const apiUrl = `${environment.API_URL}delete_quiz`;

    const requestData = {
      quiz_id: id,
    };

    // console.log(requestData);
    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          // console.log('Response: ', response);
          if (response.success) {
            this.fetchInitialData().then(() => {
              this.message.success('Quiz deleted');
            });

          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        }
      });
  }

  onEditQuizValidDateRangeCalendarChange(result: Array<Date | null>): void {
    // console.log('onCalendarChange', result);
    if (result.length) {
      this.editQuizForm.patchValue({
        valid_date_range: result
      });
    }
  }
  fetchAllSymbols(): Promise<void> {
    this.tableLoading = true;
    const apiUrl = `${environment.API_URL}symbol_dropdown`;
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl).subscribe({
        next: (response: any) => {
          this.allSymbols = response;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          return reject();
        },
        complete: () => {
          this.tableLoading = false;
          return resolve();
        },
      });
    });
  }

  // =======================================================================================

  // Add Questions to Quiz Part

  // Quiz Table Variables
  QuizTable: number = 0;
  quizTableChecked = false;
  quizTableIndeterminate = false;

  // Question Table Variables
  QuestionTable: number = 1;
  questionTableChecked = false;
  questionTableIndeterminate = false;

  // Quiz Questions Table Variables
  QuizQuestionTable: number = 2;
  quizQuestionsTableChecked = false;
  quizQuestionsTableIndeterminate = false;

  // Set of Checked Ids of Quiz, Question, QuizQuestions Tables
  setOfCheckedQuizTableIds = new Set<number>();
  setOfCheckedQuestionsTableIds = new Set<number>();
  setOfCheckedQuizQuestionsTableIds = new Set<number>();

  addQuestionToQuizDrawer = false;

  openAddQuestionsToQuizDialog() {
    this.setOfCheckedQuestionsTableIds.clear();
    this.addQuestionToQuizDrawer = true;
  }

  closeAddQuestionToQuizDrawer() {
    this.addQuestionToQuizDrawer = false;
  }

  onItemChecked(forTable: number, id: number, checked: boolean): void {
    this.updateCheckedSet(forTable, id, checked);
    // this.refreshCheckedStatus(forTable);
  }

  onAllChecked(forTable: number, checked: boolean): void {
    const tables = {
      [this.QuizTable]: this.quizzes,
      [this.QuestionTable]: this.questions,
      [this.QuizQuestionTable]: this.quizQuestions,
    };
    const data = tables[forTable];

    data.forEach(({ id }: { id: number; }) => this.updateCheckedSet(forTable, id, checked));
    this.refreshCheckedStatus(forTable);
  }

  updateCheckedSet(forTable: number, id: number, checked: boolean): void {
    const setOfIds = {
      [this.QuizTable]: this.setOfCheckedQuizTableIds,
      [this.QuestionTable]: this.setOfCheckedQuestionsTableIds,
      [this.QuizQuestionTable]: this.setOfCheckedQuizQuestionsTableIds,
    };
    const setOfCheckedIds = setOfIds[forTable];

    if (checked) {
      setOfCheckedIds.add(id);
    } else {
      setOfCheckedIds.delete(id);
    }
  }

  refreshCheckedStatus(forTable: number): void {

    const setOfIds = {
      [this.QuizTable]: this.setOfCheckedQuizTableIds,
      [this.QuestionTable]: this.setOfCheckedQuestionsTableIds,
      [this.QuizQuestionTable]: this.setOfCheckedQuizQuestionsTableIds,
    };

    const checkedObject = {
      [this.QuizTable]: this.quizTableChecked,
      [this.QuestionTable]: this.questionTableChecked,
      [this.QuizQuestionTable]: this.quizQuestionsTableChecked,
    };

    const intermediateObject = {
      [this.QuizTable]: this.quizTableIndeterminate,
      [this.QuestionTable]: this.questionTableIndeterminate,
      [this.QuizQuestionTable]: this.quizQuestionsTableIndeterminate,
    };

    const setOfCheckedIds = setOfIds[forTable];
    var checked = checkedObject[forTable];
    var indeterminate = intermediateObject[forTable];

    const listOfEnabledData = this.quizzes.filter(({ is_active }) => is_active === 1);
    checked = listOfEnabledData.every(({ id }) => setOfCheckedIds.has(id));
    indeterminate = listOfEnabledData.some(({ id }) => setOfCheckedIds.has(id)) && !checked;
  }
  tableVisible: boolean = true; // Initially set to true to show the table

  toggleTableVisibility() {
    this.tableVisible = !this.tableVisible; // Toggle the visibility
  }
  addQuestionsToQuiz(data: any = undefined): Promise<void> {
    this.formSubmitLoading = true;
    const apiUrl = `${environment.API_URL}add_bulk_quiz_question`;

    var requestData = data ?? {
      quiz_id: [...this.setOfCheckedQuizTableIds],
      question_id: [...this.setOfCheckedQuestionsTableIds]
    };
    // console.log(requestData);
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            // console.log('Add Response: ', response);
            if (response.success) {
              this.message.success('Question(s) Added');
            } else {
              this.message.error('An error occurred, please try later.');
            }
          },
          error: (error) => {
            console.error('Data Add Error: ', error);
            this.addQuestionToQuizDrawer = false;
            return reject(error);
          },
          complete: () => {
            this.formSubmitLoading = false;
            return resolve();
          },
        });
    });
  }

  handleCancel(): void {
    this.addQuestionToQuizDrawer = false;
  }


  RemoveQuestionFromQuiz() {
    const apiUrl = `${environment.API_URL}delete_quiz_questions`;
    this.formSubmitLoading = true;
    const requestData = {
      quiz_id: this.currentEditQuizId,
      question_id: [...this.setOfCheckedQuizQuestionsTableIds]
    };

    // console.log(requestData);
    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchQuizQuestions(this.currentEditQuizId!)
              .then(() => {
                this.setOfCheckedQuizQuestionsTableIds.clear();
                this.message.success('Questions(s) Removed');
              });

          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
          this.formSubmitLoading = false;
        },
        complete: () => {
          this.formSubmitLoading = false;
        }
      });
  }

  // =============================================================================

  listOfQuestions: any[] = [];
  listOfSelectedQuestionIds: number[] = [];
  fetchQuestionLoading: boolean = false;
  searchValue: string = '';

  isNotSelected(value: number): boolean {
    return this.listOfSelectedQuestionIds.indexOf(value) === -1;
  }

  search(value: string): void {
    console.log(value);
    this.searchValue = value;
    // Check if the length of the input value is at least 3 characters
    if (value.trim().length < 3) {
      this.listOfQuestions = [];
      // Do nothing if the input length is less than 3 characters
      return;
    }

    this.fetchQuestionLoading = true;

    const apiUrl = `${environment.API_URL}search_quiz_questions`;
    const requestData = {
      "question_text": value
    };

    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Search Response: ', response);
          this.listOfQuestions = response;
          this.fetchQuestionLoading = false;

        },
        error: (error) => {
          console.error('Error: ', error);
          this.fetchQuestionLoading = false;

        }
      });
  }

  addQuestion() {
    var requestData = {
      quiz_id: [this.currentEditQuizId],
      question_id: this.listOfSelectedQuestionIds
    };
    console.log(requestData);
    this.addQuestionsToQuiz(requestData).then(() => {
      this.fetchQuizQuestions(this.currentEditQuizId!);
      this.listOfSelectedQuestionIds = [];
    }).catch((error: any) => {
      console.log(error);
      this.message.error('An error occurred, please try later.');
    });
  }
}
