import { FormGroup } from "@angular/forms";


export function isFormValid(formGroupName: FormGroup): boolean {
    if (formGroupName.valid) {
        return true;
    } else {
        Object.values(formGroupName.controls).forEach(control => {
            if (control.invalid) {
                control.markAsDirty();
                control.updateValueAndValidity({ onlySelf: true });
            }
        });
        return false;
    }
}


export function logFormData(formData: FormData): void {
    formData.forEach((value, key) => {
        console.log(`${key}: ${value}`);
    });
}


