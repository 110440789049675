import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-article-card-design',
  templateUrl: './article-card-design.component.html',
  styleUrls: ['./article-card-design.component.scss'],
})
export class ArticleCardDesignComponent {
  loadingData: boolean = false;
  storageUrl = environment.STORAGE_URL;
  mainArticle: any = {};
  @Input() articleData: any[] = [];
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() showSlideButtons: boolean = false;
  @Output() slideEvent = new EventEmitter<number>();
  @Output() refresh = new EventEmitter<void>();
  fallbackImage: string = 'assets/fallback.webp';
  displayCount: number = 5; // Number of articles to display at a time
  currentIndex: number = 0;
  constructor(
    private http: HttpClient,
    private _activatedRoute: ActivatedRoute,
    private apiService: FetchApiService,
    private message: NzMessageService
  ) {}

  @ViewChild('leftButton') leftButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('rightButton') rightButton!: ElementRef<HTMLButtonElement>;

  slideLeft(): void {
    const imageList = document.querySelector('.latest_article') as HTMLElement;
    const articleList = document.querySelectorAll(
      '.article'
    ) as NodeListOf<Element>;
    const articleWidth = articleList[0].clientWidth + 16;
    const scrollAmount = articleWidth * -1;

    imageList.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  }

  slideRight(): void {
    const imageList = document.querySelector('.latest_article') as HTMLElement;
    const articleList = document.querySelectorAll(
      '.article'
    ) as NodeListOf<Element>;
    const articleWidth = articleList[0].clientWidth + 16;
    const scrollAmount = articleWidth;
    imageList.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  }

  addBookmarks(id: number): void {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      this.message.error('User ID not found.');
      return;
    }

    const obj = { user_id: userId, artical_id: id };
    this.apiService.addBookmarkArticles(obj).subscribe({
      next: (response: any) => {
        this.refreshData();
      },
    });
  }

  deleteBookmarkedArticle(id: number): void {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      this.message.error('User ID not found.');
      return;
    }

    const obj = { user_id: userId, artical_id: id };
    this.apiService.deleteBookmarkArticles(obj).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.refreshData();
        }
      },
    });
  }

  refreshData(): void {
    // Emit an event to notify the parent component to refresh the data
    this.refresh.emit();
  }

  confirm(id: number): void {
    this.deleteBookmarkedArticle(id);
  }
  cancel(): void {}
}
