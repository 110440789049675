<ng-container>
  <app-admin-layout>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-admin-layout>
</ng-container>

<ng-template #authorLayout>
  <app-author-layout>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-author-layout>
</ng-template>

<ng-template #content>
  <nz-breadcrumb>
    <nz-breadcrumb-item><a>Home</a></nz-breadcrumb-item>
    <nz-breadcrumb-item>Quiz Management</nz-breadcrumb-item>
    <nz-breadcrumb-item><a routerLink="/quizzes">Quiz's</a></nz-breadcrumb-item>
    <nz-breadcrumb-item>Edit Quiz</nz-breadcrumb-item>
  </nz-breadcrumb>


  <nz-card>
    <form nz-form nzLayout="vertical" [formGroup]="editQuizForm">
      <div class="row">
        <div class="col-12 d-flex justify-content-between mb-3">
          <h6 class="m-0">Quiz Details</h6>
          <button nz-button nzType="primary" [nzLoading]="formSubmitLoading" (click)="submitEditForm()"
            type="submit">Update</button>
        </div>

        <!-- Title and Description in a single row -->
        <div class="col-12 col-md-6">
          <nz-form-item>
            <nz-form-label nzRequired>Title</nz-form-label>
            <nz-form-control nzErrorTip="Enter Title">
              <nz-input-group>
                <input type="text" nz-input formControlName="title" placeholder="Quiz title" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-12 col-md-6">
          <nz-form-item>
            <nz-form-label>Description</nz-form-label>
            <nz-form-control nzHasFeedback nzErrorTip="Please write something here!">
              <nz-textarea-count [nzMaxCharacterCount]="1000">
                <textarea formControlName="description" nz-input rows="2"
                  placeholder="Write your description here"></textarea>
              </nz-textarea-count>
            </nz-form-control>
          </nz-form-item>
        </div>

        <!-- Select Ticker, Select Category, and Valid From - Valid To in a single row -->
        <div class="col-12 col-md-4">
          <nz-form-item>
            <nz-form-label>Select Ticker</nz-form-label>
            <nz-select [nzAllowClear]="true" formControlName="symbol_id" nzMode="multiple"
              nzPlaceHolder="Please select">
              <nz-option *ngFor="let item of allSymbols" [nzValue]="item.symbol_id" [nzLabel]="item.symbol"></nz-option>
            </nz-select>
          </nz-form-item>
        </div>

        <div class="col-12 col-md-4">
          <nz-form-item>
            <nz-form-label>Select category</nz-form-label>
            <nz-form-control>
              <nz-tree-select [nzDefaultExpandAll]="true" nzShowSearch [nzAllowClear]="true" [nzMultiple]="true"
                [nzExpandedKeys]="treeSelectExpandKeys" [nzNodes]="allcategories" nzPlaceHolder="Select Category"
                formControlName="category_id"></nz-tree-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="col-12 col-md-4">
          <nz-form-item>
            <nz-form-label>Valid From - Valid To</nz-form-label>
            <nz-range-picker [nzShowTime]="{ nzFormat: 'HH:mm' }" nzFormat="yyyy-MM-dd HH:mm"
              formControlName="valid_date_range"></nz-range-picker>
          </nz-form-item>
        </div>

        <!-- Quiz Level, Quiz Status, Quiz Time, Attempts, Bonus Points in a single row -->
        <div class="col-12 col-md-2">
          <nz-form-item>
            <nz-form-label>Quiz Level</nz-form-label>
            <nz-select class="w-100" nzShowSearch nzAllowClear nzPlaceHolder="Select Quiz Level"
              formControlName="quiz_level_id">
              <nz-option *ngFor="let level of quizLevel" [nzLabel]="level.quiz_level"
                [nzValue]="level.quiz_level_id"></nz-option>
            </nz-select>
          </nz-form-item>
        </div>

        <div class="col-12 col-md-2">
          <nz-form-item>
            <nz-form-label>Quiz status?</nz-form-label>
            <nz-radio-group formControlName="is_active">
              <label nz-radio [nzValue]="1">Active</label>
              <label nz-radio [nzValue]="0">Inactive</label>
            </nz-radio-group>
          </nz-form-item>
        </div>

        <div class="col-12 col-md-2">
          <nz-form-item>
            <nz-form-label>Quiz Time</nz-form-label>
            <nz-time-picker nzAllowClear formControlName="time"></nz-time-picker>
          </nz-form-item>
        </div>

        <div class="col-12 col-md-2">
          <nz-form-item>
            <nz-form-label>Attempts</nz-form-label>
            <nz-input-number formControlName="max_attempt" [nzMax]="100" [nzStep]="1"></nz-input-number> 
        </nz-form-item>
        </div>

        <div class="col-12 col-md-2">
          <nz-form-item>
            <nz-form-label>No of days</nz-form-label>
            <nz-input-number formControlName="no_of_days" [nzMin]="1" [nzMax]="100" [nzStep]="1"></nz-input-number> 
        </nz-form-item>
        </div>
        <div class="col-12 col-md-2">
          <nz-form-item>
              <nz-form-label>Bonus Point</nz-form-label>
              <nz-input-number formControlName="bonus_points" [nzMin]="1" [nzMax]="100" [nzStep]="1"></nz-input-number> 
          </nz-form-item>
      </div>
      </div>

      <!-- <div class="row">
        <div class="col-12 col-md-4">
            <nz-form-item>
                <nz-form-label>Bonus Point</nz-form-label>
                <nz-input-number formControlName="bonus_points" [nzMin]="1" [nzMax]="100" [nzStep]="1"></nz-input-number> 
            </nz-form-item>
        </div>
        <div class="col-12 col-md-4">
            <nz-form-item>

                <nz-form-label>Attempts</nz-form-label>
                <nz-input-number formControlName="max_attempt" [nzMax]="100" [nzStep]="1"></nz-input-number> 
            </nz-form-item>
        </div>
        <div class="col-12 col-md-4">
            <nz-form-item>
                <nz-form-label>No of days</nz-form-label>
                <nz-input-number formControlName="no_of_days" [nzMin]="1" [nzMax]="100" [nzStep]="1"></nz-input-number> 
            </nz-form-item>
        </div>
    </div> -->
      <nz-divider></nz-divider>
    </form>
    <div class="row">
      <div class="col-12 d-flex mb-3 align-items-center">
        <div class="mb-3">
          <nz-input-group [nzSuffix]="suffixTemplate">
            <input type="text" nz-input placeholder="Search Questions" [(ngModel)]="searchText"
              (ngModelChange)="searchQuestions()" />
            <ng-template #suffixTemplate>
              <i nz-icon nzType="search"></i>
            </ng-template>
          </nz-input-group>
        </div>
        <div class="mb-3" *ngIf="searchText">
          <span><i>Showing {{ filteredQuestions.length }} of {{ quizQuestions.length }} questions</i></span>
        </div>
        <button nz-button nzType="primary" class="ms-auto" (click)="showModal()">Add Question</button>
        <button nz-button nzType="default" [disabled]="selectedItemsArray.length == 0" nzDanger nzShape="circle"
          nz-popconfirm nzPopconfirmTitle="Are you sure you want to delete this record?"
          (nzOnConfirm)="RemoveQuestionFromQuiz()" (nzOnCancel)="cancel()">
          <span nz-icon nzType="delete"></span>
        </button>
      </div>

    </div>
    <div class="row">
      <div class="col-md-12">
        <nz-table #rowSelectionTable nzShowSizeChanger [nzData]="filteredQuestions"
          (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
          <thead>
            <tr>
              <th [(nzChecked)]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)">
              </th>
              <th width="5%" [nzSortFn]="sortByIndex">Sr.no</th>
              <th width="20%" [nzSortFn]="sortByQuestionText">Question</th>
              <th [nzSortFn]="sortByQuestionType">Type</th>
              <th width="10%" [nzSortFn]="sortByStatus">Status</th>
              <th [nzSortFn]="sortByCategory">Category</th>
              <th width="10%" [nzSortFn]="sortByEvaluationType">Evaluation Type</th>
            </tr>
          </thead>
          <tbody cdkDropList (cdkDropListDropped)="drop($event)">
            <tr *ngFor="let data of rowSelectionTable.data; let i = index" cdkDrag>
              <td [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
              <td width="5%">{{ i + 1 }}</td>
              <td nzEllipsis>{{ data.question_text }}</td>
              <td>{{ data.question_type_string }}</td>
              <td>{{ data.is_active === 1 ? 'Active': 'Inactive' }}</td>
              <td>{{ data.categories || '--' }}</td>
              <td>{{ data.evaluation_type }}</td>
            </tr>
              
          </tbody>
        </nz-table>
      </div>
    </div>






    <!-- dynamic table -->
    <!-- <app-question-table [listOfData]="filteredQuestions" [refreshFunction]="refreshQuestions"
        [deleteButtonConfig]="{ text: 'Remove From Quiz', tooltip: 'Remove selected questions' }"
        [addButtonConfig]="{ text: 'Add Question', tooltip: 'Add a new question' }"
        (deleteAction)="handleDelete($event)" (addAction)="handleAdd()">
      </app-question-table> -->


  </nz-card>
  <nz-modal [nzStyle]="{ top: '20px' }" [(nzVisible)]="isModalVisible" nzTitle="Add Question"
    (nzOnCancel)="handleCancel()" [nzFooter]="null" nzWidth="800px">
    <ng-container *nzModalContent>
      <nz-card>
        <div class="mb-3">
          <nz-input-group [nzSuffix]="suffixTemplate">
            <input type="text" nz-input placeholder="Search Questions" [(ngModel)]="searchQuery"
              (ngModelChange)="filterQuestions()" />
            <ng-template #suffixTemplate>
              <i nz-icon nzType="search"></i>
            </ng-template>
          </nz-input-group>
        </div>
        <div class="mb-3" *ngIf="searchQuery">
          <span><i>Showing {{ filteredQuestions.length }} of {{ allQuestions.length }} questions</i></span>
        </div>

        <div class="row">
          <div class="table-responsive">
            <div class="row">
              <div class="d-flex justify-content-end">
                <button nz-button nzType="primary" (click)="addSelectedQuestions()">Add Selected Questions</button>

              </div>
            </div>
            <nz-table [nzBordered]="true" [nzData]="filteredQuestions" [nzPageSize]="50" [nzScroll]="{ y: '200px' }"
              nzTableLayout="fixed">
              <thead>
                <tr>
                  <th width="5%"></th>
                  <th width="5%">S.No</th>
                  <th width="30%">Question</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Category</th>
                  <th>Evaluation Type</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let question of filteredQuestions; let i = index">
                  <tr>
                    <td>
                      <input [(ngModel)]="selectedQuestions[question.id]" class="form-check-input" type="checkbox"
                        value="" id="flexCheckDefault">
                    </td>
                    <td>{{ i + 1 }}</td>
                    <td nzEllipsis>{{ question.question_text }}</td>
                    <td>{{ question.question_type_string }}</td>
                    <td>{{ question.is_active === 1 ? 'Active' : 'Inactive' }}</td>
                    <td>{{ question.quiz_category_string || '--' }}</td>
                    <td>{{ question.evaluation_type }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </nz-table>
          </div>
        </div>


      </nz-card>
    </ng-container>

  </nz-modal>
</ng-template>