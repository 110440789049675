import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { forkJoin } from 'rxjs';
import { isFormValid } from 'src/app/Functions/FormFunctions';
import { Course, Section } from 'src/app/Interfaces/Course';
import { Quiz } from 'src/app/Interfaces/Quiz';
import { FetchApiService } from 'src/app/Services/FetchApis/fetch-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss']
})
export class SectionsComponent {
  courses: Course[] = [];
  quizzes: Quiz[] = [];
  sections: Section[] = [];
  roleId:any;
  tableLoading: boolean = true;
  formSubmitLoading: boolean = false;
  useAdminLayout: boolean = false; // Default to non-admin layout

  // This will store the id Section Currently Open in the Edit Drawer
  currentEditQuizId?: number;

  addDrawer: boolean = false;
  editDrawer: boolean = false;

  selectedCourseId?: number;

  addSectionForm: FormGroup = new FormGroup({
    course_id: new FormControl(null, [Validators.required]),
    title: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    is_active: new FormControl(1),
    quiz_id: new FormControl(null),
    created_by: new FormControl(sessionStorage.getItem('userId'))
  });

  editSectionForm: FormGroup = new FormGroup({
    course_id: new FormControl(null, [Validators.required]),
    section_id: new FormControl(''),
    title: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    is_active: new FormControl(0),
    quiz_id: new FormControl(null),
    updated_by: new FormControl(sessionStorage.getItem('userId'))
  });

  constructor(private http: HttpClient, private message: NzMessageService, private fetchApiService: FetchApiService,) { }

  ngOnInit() {
    this.roleId = localStorage.getItem('role_id');
    this.useAdminLayout = this.roleId === '1' || this.roleId === '2';
    this.fetchInitialData();
  }

  fetchInitialData(): Promise<void> {
    this.tableLoading = true;
    return new Promise((resolve, reject) => {
      const courses$ = this.fetchApiService.getCourses();
      const quizzes$ = this.fetchApiService.getAllQuizzes();

      forkJoin([courses$, quizzes$]).subscribe({
        next: ([coursesResponse, quizzesResponse]: any) => {
          console.log('Courses: ', coursesResponse);
          console.log('Quizzes: ', quizzesResponse);
          this.courses = coursesResponse;
          this.quizzes = quizzesResponse.data;
        },
        error: (error) => {
          console.error('Error: ', error);
          this.tableLoading = false;
          reject(error);
        },
        complete: () => {
          this.tableLoading = false;
          resolve();
        },
      });

    });
  }

  fetchCourseSections(courseId: any = this.selectedCourseId): Promise<void> {
    console.log(courseId);
    this.tableLoading = true;

    return new Promise<void>((resolve, reject) => {
      this.fetchApiService.getSections(courseId).subscribe({
        next: (res: any) => {
          console.log('Sections: ', res);
          this.sections = res;
        },
        error: (error: any) => {
          console.log(error);
          this.tableLoading = false;
          reject(error); // Reject the promise on error
        },
        complete: () => {
          this.tableLoading = false;
          resolve(); // Resolve the promise on completion
        }
      });
    });
  }

  openEditDrawer(sectionId: number): void {
    this.currentEditQuizId = sectionId;

    let sectionDetails = this.sections.find(section => section.section_id === sectionId);
    this.editSectionForm.patchValue({
      ...sectionDetails!,
    });
    this.editDrawer = true;
  }

  closeEditDrawer(): void {
    this.editDrawer = false;
    this.currentEditQuizId = undefined;
  }

  openAddDrawer(): void {
    this.addDrawer = true;
    this.addSectionForm.patchValue({ course_id: this.selectedCourseId });
  }

  closeAddDrawer() {
    this.addDrawer = false;
  }

  cancel(): void {
  }

  confirm(id: number): void {
    this.deleteRecord(id);
  }

  submitAddForm() {
    if (isFormValid(this.addSectionForm)) {
      this.formSubmitLoading = true;
      const apiUrl = `${environment.API_URL}add_section`;

      const requestData = {
        ...this.addSectionForm.value,

      };
      console.log(requestData);
      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            console.log('Response: ', response);
            if (response.success) {
              this.fetchCourseSections().then(() => {
                this.message.success('Section added');
                this.addSectionForm.reset();
                this.addSectionForm.patchValue({
                  is_active: 1,
                  created_by: sessionStorage.getItem('userId')
                });
                this.closeAddDrawer();
              });

            } else {
              this.message.error('Error occurred, please try later');
            }
            this.formSubmitLoading = false;

          },
          error: (error) => {
            console.error('Error: ', error);
            this.message.error('Error occurred, please try later');
            this.formSubmitLoading = false;
          }
        });
    }
  }

  submitEditForm() {
    if (isFormValid(this.editSectionForm)) {
      this.formSubmitLoading = true;

      const apiUrl = `${environment.API_URL}edit_section`;

      const requestData = {
        ...this.editSectionForm.value,
        updated_by: sessionStorage.getItem('userId')
      };
      console.log(requestData);
      this.http.post(apiUrl, requestData)
        .subscribe({
          next: (response: any) => {
            console.log('Response: ', response);
            if (response.success) {
              this.fetchCourseSections().then(() => {
                this.message.success('Section updated');
                this.editSectionForm.reset();
                this.closeEditDrawer();
              });
            } else {
              this.message.error('Error occurred, please try later');
            }
            this.formSubmitLoading = false;

          },
          error: (error) => {
            console.error('Error: ', error);
            this.formSubmitLoading = false;

          }
        });
    }
  }

  deleteRecord(id: number) {
    const apiUrl = `${environment.API_URL}delete_section`;

    const requestData = {
      section_id: id,
    };

    console.log(requestData);
    this.http.post(apiUrl, requestData)
      .subscribe({
        next: (response: any) => {
          console.log('Response: ', response);
          if (response.success) {
            this.fetchCourseSections().then(() => {
              this.message.success('Section deleted');
            });

          } else {
            this.message.error('Error occurred, please try later');
          }
        },
        error: (error) => {
          console.error('Error: ', error);
          this.message.error('Error occurred, please try later');
        }
      });
  }

  // Section Table Variables
  sectionTableChecked = false;
  sectionTableIndeterminate = false;

  setOfCheckedSectionTableIds = new Set<number>();


  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    const data = this.sections;
    data.forEach(({ section_id }: { section_id: number; }) => this.updateCheckedSet(section_id, checked));
  }

  updateCheckedSet(id: number, checked: boolean): void {
    const setOfCheckedIds = this.setOfCheckedSectionTableIds;
    if (checked) {
      setOfCheckedIds.add(id);
    } else {
      setOfCheckedIds.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    const setOfCheckedIds = this.setOfCheckedSectionTableIds;
    var checked = this.sectionTableChecked;
    var indeterminate = this.sectionTableIndeterminate;

    const listOfEnabledData = this.sections.filter(({ is_active }) => is_active === 1);
    checked = listOfEnabledData.every(({ section_id }) => setOfCheckedIds.has(section_id));
    indeterminate = listOfEnabledData.some(({ section_id }) => setOfCheckedIds.has(section_id)) && !checked;
  }
}
