import { Component, Input } from '@angular/core';
import { Article } from 'src/app/Interfaces/Content';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-article-display-style-one',
  templateUrl: './article-display-style-one.component.html',
  styleUrls: ['./article-display-style-one.component.scss']
})
export class ArticleDisplayStyleOneComponent {
  @Input() article!: Article;
  @Input() showBannerImage: boolean = true;
  @Input() centerAlign: boolean = false;


  randomImageUrl: string = '';

  fallbackImage: string = 'assets/fallback.webp';
  populateRandomImageUrls() {
    const randomIndex = Math.floor(Math.random() * 8); // 8 images preset in dummy-images folder
    // this.randomImageUrl = `assets/dummy-images/dumyimg${randomIndex + 1}.jpg`;
    this.randomImageUrl = `${environment.STORAGE_URL}${this.article.featured_img_path}`;
  }

  ngOnInit() {
    this.populateRandomImageUrls();
    console.log(this.article);
  }
}
