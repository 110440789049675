
// Common
export const HOME_PAGE = 'ID - Home Page';

export const LOGIN = 'ID - Login';

export const REGISTER = 'ID - Register';


// ADMIN Related
export const ADMIN_PANEL = 'ID - Admin Panel';

// AUTHOR Related
export const AUTHOR_PANEL = 'ID - Author Panel';

// READER Related
export const READER_PANEL = 'ID - Reader Panel';

// SUBSCRIBER Related
export const SUBSCRIBER_PANEL = 'ID - Subscriber Panel';


export const All_MODULE = 'ID - Module';
export const All_ROLE = 'ID - Role';
export const PERMISSION = 'ID - Permission';
export const USER_ROLE = 'ID - Set Role';
