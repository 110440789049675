<ng-container [ngSwitch]="userRole">
    <ng-container *ngSwitchCase="'admin'">
        <app-admin-layout>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </app-admin-layout>
    </ng-container>
    <ng-container *ngSwitchCase="'author'">
        <app-author-layout>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </app-author-layout>
    </ng-container>
    <ng-container *ngSwitchCase="'reader'">
        <app-reader-layout>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </app-reader-layout>
    </ng-container>
    <ng-container *ngSwitchCase="'subscriber'">
        <app-subscriber-layout>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </app-subscriber-layout>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <app-home-page-layout>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </app-home-page-layout>
    </ng-container>
</ng-container>


<ng-template #content>
    <nz-spin [ngClass]="{'spinner-with-overlay': isLoading}" [nzSpinning]="isLoading" nzSize="large"></nz-spin>

    <nz-card>
        <div class="row">
            <div class="col-12">
                <nz-breadcrumb>
                    <nz-breadcrumb-item>
                        <a (click)="goBack()">Back</a>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item>Author Profile</nz-breadcrumb-item>
                </nz-breadcrumb>
                <nz-divider></nz-divider>
            </div>

            <div class="col-12 col-md-9">
                <div class="row ">
                    <div class="col-12 col-md-2">
                        <img nz-image class="img-fluid rounded-circle light-shadow"
                            nzSrc="assets/profile-images/profile-image.png" />
                    </div>
                    <div class="col-12 col-md-10 align-self-center">
                        <div class="vstack">
                            <h5>{{authorString|dehyphenate}}</h5>
                            <h6>Author</h6>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <p>Hello, I'm passionate about sharing knowledge and experiences. My aim is to
                            provide
                            you with valuable insights and expertise on a variety of subjects. I hope my work enriches
                            your
                            understanding and inspires your learning journey. Explore my unique perspective and
                            contributions across different topics. Together, let's embark on a journey of discovery and
                            growth.</p>
                    </div>

                </div>
                <div class="row">
                    <!-- <app-section-heading title="My Articles"></app-section-heading> -->

                    <nz-divider></nz-divider>
                    <ng-container *ngIf="articles.length === 0">
                        <nz-empty></nz-empty>
                    </ng-container>

                    <div class="col-12 col-md-3" *ngFor="let article of articles; let i = index;">
                        <app-article-display-style-one [article]="article"></app-article-display-style-one>
                    </div>
                </div>


            </div>
            <div class="col-12 col-md-3 ">

                <app-section-heading title="Author Statistics"></app-section-heading>
                <ng-container *ngIf="isLoading">
                    <nz-empty></nz-empty>
                </ng-container>

                <div *ngIf="!isLoading" class="vstack gap-2">
                    <nz-card class="light-shadow">
                        <nz-statistic [nzValue]="(articles.length  | number)!" [nzTitle]="'Total Articles'"
                            [nzPrefix]="totalArticlesTemplates"></nz-statistic>
                        <ng-template #totalArticlesTemplates><span nz-icon nzType="check-circle"
                                class="me-2"></span></ng-template>


                    </nz-card>
                    <nz-card class="light-shadow">

                        <nz-statistic [nzValue]="(5420  | number)!" [nzTitle]="'Total Views'"
                            [nzPrefix]="totalViewsTemplates"></nz-statistic>
                        <ng-template #totalViewsTemplates><span nz-icon nzType="bar-chart"
                                class="me-2"></span></ng-template>
                    </nz-card>



                </div>

                <nz-divider *ngIf="!isLoading"></nz-divider>
            </div>
        </div>
    </nz-card>

</ng-template>