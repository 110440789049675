import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'invest-dost';

  onActivate() {
    if (typeof window !== 'undefined') // typeof comes as string 
      window.scroll(0, 0);
  }
}
