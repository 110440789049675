<app-main-fiminize-layout>
    <nz-breadcrumb>
        <nz-breadcrumb-item><a routerLink="/"><span nz-icon nzType="home"
                    nzTheme="outline"></span></a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a routerLink="/room-topics">Intelligence</a></nz-breadcrumb-item>
    </nz-breadcrumb>
    <form  [formGroup]="editArticleForm" nz-form
    nzLayout="vertical">
    <div class="row">
      
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-3">
            <nz-form-item>
              <nz-form-control>
                <nz-select formControlName="post_type" nzPlaceHolder="Select a Post Type">
                  <nz-option *ngFor="let item of postTypes" [nzValue]="item.ideahub_dropdown_id" [nzLabel]="item.sub_name"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        
        
          <div class="col-md-3">
            <nz-form-item>
              <nz-form-control>
                <nz-select formControlName="sentiments" nzPlaceHolder="Select a Sentiment">
                  <nz-option *ngFor="let item of sentiments" [nzValue]="item.ideahub_dropdown_id" [nzLabel]="item.sub_name"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="col-md-3">
            <button nz-button nzType="primary" class="mx-2" (click)="this.fetchlatestArticles()">Filter</button>
            <button nz-button nzType="primary" (click)="clear()">Clear</button>
        </div>
          
        </div>
        
        <app-article-card-design [articleData]="latestArticleData" title="#Stocks International" [showSlideButtons]="true" (slideLeftEvent)="slideLeft()"
          (slideRightEvent)="slideRight()" (refresh)="fetchlatestArticles()">
        </app-article-card-design>
        <app-article-card-design [articleData]="latestArticleData" title="#Crypto" [showSlideButtons]="true" (slideLeftEvent)="slideLeft()"
          (slideRightEvent)="slideRight()" (refresh)="fetchlatestArticles()">
        </app-article-card-design>
        <app-article-card-design [articleData]="latestArticleData" title="#macro" [showSlideButtons]="true" (slideLeftEvent)="slideLeft()"
          (slideRightEvent)="slideRight()" (refresh)="fetchlatestArticles()">
        </app-article-card-design>
        <app-article-card-design [articleData]="latestArticleData" title="#Personal Finance" [showSlideButtons]="true" (slideLeftEvent)="slideLeft()"
          (slideRightEvent)="slideRight()" (refresh)="fetchlatestArticles()">
        </app-article-card-design>
      </div>
      <!-- <div class="col-md-4">
          <div class="row ">
              <h2 >Market Overview</h2>
            <div class="col-md-12 mb-3 d-flex justify-content-end">
              <img src="assets\biggest fallers.jpg" class="img-fluid rounded" alt="" style="max-width: 200px; height: auto; padding: 5px;">
            </div>
            <div class="col-md-12 mb-3 d-flex justify-content-end">
              <img src="assets\top gainers.jpg" class="img-fluid rounded" alt="" style="max-width: 200px; height: auto; padding: 5px;">
            </div> 
            <div class="col-md-12 d-flex justify-content-end">
              <img src="assets\top gainers 2.jpg" class="img-fluid rounded" alt="" style="max-width: 200px; height: auto; padding: 5px;">
            </div>
          </div>
        </div>    -->
    </div>
  </form>
  
</app-main-fiminize-layout>